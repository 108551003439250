import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { InstantArchivingConfig } from 'instant-archiving/Types';
import fetch from 'logic/rest/FetchProvider';
import { INSTANT_ARCHIVING_CONFIG_QUERY_KEYS, INSTANT_ARCHIVING_API_ROUTES } from 'instant-archiving/Constants';
import UserNotification from 'util/UserNotification';
import { qualifyUrl } from 'util/URLUtils';

const createInstantArchivingConfig = async (configuration: InstantArchivingConfig) => (
  fetch('POST', qualifyUrl(INSTANT_ARCHIVING_API_ROUTES.InstantArchivingAPIController.config.base().url), { ...configuration })
);

const updateInstantArchivingConfig = async (configuration: InstantArchivingConfig) => (
  fetch('PUT', qualifyUrl(INSTANT_ARCHIVING_API_ROUTES.InstantArchivingAPIController.config.base().url), { ...configuration })
);
const bulkUpdateInstantArchivingConfig = async ({ config_ids, state }: { config_ids: Array<string>, state: boolean }) => (
  fetch('POST', qualifyUrl(INSTANT_ARCHIVING_API_ROUTES.InstantArchivingAPIController.config.bulk_enable().url), { config_ids, enabled: state })
);
const deleteInstantArchivingConfig = async (configurationIds: Array<string>) => (
  fetch('POST', qualifyUrl(INSTANT_ARCHIVING_API_ROUTES.InstantArchivingAPIController.config.delete().url), configurationIds)
);

const useCreateInstantArchivingConfig = (successHandler: () => void = undefined) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(createInstantArchivingConfig, {
    onSuccess: () => {
      UserNotification.success('Successfully created Instant Archiving configuration');
      queryClient.invalidateQueries(INSTANT_ARCHIVING_CONFIG_QUERY_KEYS);

      if (successHandler) {
        successHandler();
      }
    },
    onError: (err: Error) => {
      UserNotification.error(`Error creating Instant Archiving configuration: ${err.toString()}`, 'Unable to create Instant Archiving configuration.');
    },
  });

  return {
    mutateAsync,
    isLoading,
  };
};

const useUpdateInstantArchivingConfig = (successHandler: () => void = undefined) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(updateInstantArchivingConfig, {
    onSuccess: () => {
      UserNotification.success('Successfully updated Instant Archiving configuration');
      queryClient.invalidateQueries(INSTANT_ARCHIVING_CONFIG_QUERY_KEYS);

      if (successHandler) {
        successHandler();
      }
    },
    onError: (err: Error) => {
      UserNotification.error(`Error updating Instant Archiving configuration: ${err.toString()}`, 'Unable to update Instant Archiving configuration.');
    },
  });

  return {
    mutateAsync,
    isLoading,
  };
};

const useBulkUpdateInstantArchivingConfig = (successHandler: () => void = undefined) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(bulkUpdateInstantArchivingConfig, {
    onSuccess: () => {
      UserNotification.success('Successfully updated Instant Archiving archiver');
      queryClient.invalidateQueries(INSTANT_ARCHIVING_CONFIG_QUERY_KEYS);

      if (successHandler) {
        successHandler();
      }
    },
    onError: (err: Error) => {
      UserNotification.error(`Error updating Instant Archiving configuration: ${err.toString()}`, 'Unable to update Instant Archiving configuration.');
    },
  });

  return {
    mutateAsync,
    isLoading,
  };
};

const useDeleteInstantArchivingConfig = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(deleteInstantArchivingConfig, {
    onSuccess: () => {
      UserNotification.success('Successfully deleted Instant Archiving configuration');
      queryClient.invalidateQueries(INSTANT_ARCHIVING_CONFIG_QUERY_KEYS);
    },
    onError: (err: Error) => {
      UserNotification.error(`Error deleting Instant Archiving configuration: ${err.toString()}`, 'Unable to delete Instant Archiving configuration.');
    },
  });

  return {
    mutateAsync,
    isLoading,
  };
};

export {
  useCreateInstantArchivingConfig,
  useUpdateInstantArchivingConfig,
  useDeleteInstantArchivingConfig,
  useBulkUpdateInstantArchivingConfig,
  deleteInstantArchivingConfig,
};
