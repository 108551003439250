import React, { useEffect, useContext, useState } from 'react';

import ValidatedInput from './common/ValidatedInput';
import FormWrap from './common/FormWrap';
import { ApiRoutes } from './common/Routes';
import { FormDataContext } from './context/FormData';
import formValidation from './utils/formValidation';
import type {
  ErrorMessageType,
  HandleFieldUpdateType,
  FormDataContextType,
  HandleSubmitType,
} from './types';
import useFetch from './common/hooks/useFetch';

type StepAuthorizeProps = {
  onSubmit: HandleSubmitType;
  onChange: HandleFieldUpdateType;
};

const StepAuthorize: React.FC<StepAuthorizeProps> = ({
  onSubmit,
  onChange,
}) => {
  const { formData } = useContext<FormDataContextType>(FormDataContext);
  const [formError, setFormError] = useState<ErrorMessageType>(null);

  const { salesforceClientSecret } = formData;

  const [SalesforceValidationStatus, setSalesforceValidation] = useFetch(
    null,
    onSubmit,
    'POST',
    {
      salesforce_client_id: formData?.salesforceClientId?.value || '',
      salesforce_client_secret: formData?.salesforceClientSecret?.value || '',
      salesforce_instance_name: formData?.salesforceInstanceName?.value || '',
    },
  );

  useEffect(() => {
    setSalesforceValidation(null);

    if (SalesforceValidationStatus.error) {
      setFormError({
        full_message: SalesforceValidationStatus.error,
        nice_message: 'Unable to connect to Salesforce using provided configuration.',
      });
    }

    return () => {
      setFormError(null);
    };
  }, [SalesforceValidationStatus.error, setSalesforceValidation]);

  const handleSubmit = () => {
    setSalesforceValidation(ApiRoutes.INTEGRATIONS.Salesforce.CHECK_CREDENTIALS);
  };

  const isFormValid = formValidation.isFormValid(
    ['salesforceName',
      'salesforceClientId',
      'salesforceClientSecret',
      'salesforceInstanceName',
    ],
    formData,
  );

  return (
    <FormWrap onSubmit={handleSubmit}
              buttonContent="Verify Connection &amp; Proceed"
              disabled={isFormValid}
              loading={SalesforceValidationStatus.loading}
              title=""
              error={formError}
              description="">
      <ValidatedInput className=""
                      id="salesforceName"
                      type="text"
                      fieldData={formData.salesforceName}
                      onChange={onChange}
                      placeholder="Input Name"
                      label="Input Name"
                      autoComplete="off"
                      help="Select a name of your new input that describes it."
                      defaultValue={salesforceClientSecret?.value}
                      required />

      <ValidatedInput className=""
                      id="salesforceInstanceName"
                      type="text"
                      fieldData={formData.salesforceInstanceName}
                      onChange={onChange}
                      label="Instance Name"
                      help="Your Domain name to which the content belongs."
                      autoComplete="off"
                      required />

      <ValidatedInput className=""
                      id="salesforceClientId"
                      type="text"
                      fieldData={formData.salesforceClientId}
                      onChange={onChange}
                      label="Salesforce Client ID"
                      help="The Client ID of Salesforce Connected App created with sufficient API permissions."
                      autoComplete="off"
                      required />

      <ValidatedInput className=""
                      id="salesforceClientSecret"
                      type="password"
                      onChange={onChange}
                      fieldData={formData.salesforceClientSecret}
                      help="The Client Secret of the Salesforce Connected App."
                      label="Client Secret"
                      required />

    </FormWrap>
  );
};

export default StepAuthorize;
