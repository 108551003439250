import __request__ from 'routing/request';
interface CreateStaticFieldRequest {
    readonly value: string;
    readonly key: string;
}
/**
 * Add a static field to an input
 */
export function create(inputId: string, JSONbody: CreateStaticFieldRequest): Promise<unknown> {
    return __request__('POST', `/system/inputs/${inputId}/staticfields`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Remove static field of an input
 */
export function remove(key: string, inputId: string): Promise<void> {
    return __request__('DELETE', `/system/inputs/${inputId}/staticfields/${key}`, null, {}, {
        'Accept': ['application/json']
    });
}
