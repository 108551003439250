export const GRAYLOG_ERROR_CODES = {
  oktaUnknownError: 'E0001',
  oktaLoginRedirectError: 'E0002',
  nonceMismatch: 'E0003',
  graylogUnknownError: 'E0004',
};

export type MetricFailureSubsystem = 'gl' | 'okta' | 'http';

export type LoginMetric = {
  name: string,
  inc: number,
};

export const createSuccessLoginMetric = (action: string, inc: number = 1): LoginMetric => ({
  name: `org.graylog.plugins.cloud.auth.${action}.success`,
  inc: inc,
});

export const createFailureLoginMetric = (action: string, subsystem: MetricFailureSubsystem, code: string | number, inc: number = 1): LoginMetric => ({
  name: `org.graylog.plugins.cloud.auth.${action}.failure.${subsystem}.${code}`,
  inc: inc,
});
