import __request__ from 'routing/request';
interface IndexRangeSummary {
    readonly took_ms: number;
    readonly end: string;
    readonly calculated_at: string;
    readonly index_name: string;
    readonly begin: string;
}
interface IndexRangesResponse {
    readonly total: number;
    readonly ranges: IndexRangeSummary[];
}
/**
 * Get a list of all index ranges
 */
export function list(): Promise<IndexRangesResponse> {
    return __request__('GET', '/system/indices/ranges', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Rebuild/sync index range information for the given index set.
 */
export function rebuildIndexSet(indexSetId?: string): Promise<unknown> {
    return __request__('POST', `/system/indices/ranges/index_set/${indexSetId}/rebuild`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Rebuild/sync index range information.
 */
export function rebuild(): Promise<unknown> {
    return __request__('POST', '/system/indices/ranges/rebuild', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Rebuild/sync index range information.
 * @param index The name of the Graylog-managed Elasticsearch index
 */
export function rebuildIndex(index: string): Promise<unknown> {
    return __request__('POST', `/system/indices/ranges/${index}/rebuild`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Show single index range
 * @param index The name of the Graylog-managed Elasticsearch index
 */
export function show(index: string): Promise<IndexRangeSummary> {
    return __request__('GET', `/system/indices/ranges/${index}`, null, {}, {
        'Accept': ['application/json']
    });
}
