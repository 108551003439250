import * as Immutable from 'immutable';

import fetch from 'logic/rest/FetchProvider';
import type { WidgetValueSummary, ReportingWidgetResult, ParameterValues } from 'report/types';
import type { FieldTypeMappingJSON } from 'views/logic/fieldtypes/FieldTypeMapping';
import FieldTypeMapping from 'views/logic/fieldtypes/FieldTypeMapping';
import URLUtils from 'util/URLUtils';
import type { TimeRange } from 'views/logic/queries/Query';
import SearchExecutionState from 'views/logic/search/SearchExecutionState';
import ParameterBinding from 'views/logic/parameters/ParameterBinding';
import GlobalOverride from 'views/logic/search/GlobalOverride';

const sourceUrl = '/plugins/org.graylog.plugins.report/reports';

type SearchError = { description: string };
type WidgetResult = {
  result: Array<any>,
  calculated_at: string,
  field_types: Array<FieldTypeMappingJSON>,
  errors?: Array<SearchError>,
};
type WidgetValuesResult = { result: WidgetResult, dashboard_id: string, widget_id: string };
export const migrateWidgetValueResult = (value: WidgetResult): WidgetValueSummary => ({
  calculatedAt: value.calculated_at,
  result: value.result,
  types: Immutable.List(value.field_types.map((json) => FieldTypeMapping.fromJSON(json))),
  hasError: value.errors?.length > 0,
  errorMessage: value.errors?.map((e) => e.description).join('\n'),
});

const migrateWidgetResult = (widgetValue: WidgetValuesResult): ReportingWidgetResult => ({
  ...widgetValue,
  result: migrateWidgetValueResult(widgetValue.result),
});

const fetchReportValues = async (reportId: string, parameterValues: ParameterValues, timerangeOverride: TimeRange): Promise<Array<ReportingWidgetResult>> => {
  const parameterBindings = Immutable.Map<string, ParameterBinding>(Object.entries(parameterValues).map(([key, value]) => [key, ParameterBinding.forValue(value)]));
  const executionState = SearchExecutionState.create(parameterBindings, GlobalOverride.create(timerangeOverride));

  const results: Array<WidgetValuesResult> = await fetch('POST', URLUtils.qualifyUrl(`${sourceUrl}/${reportId}/values`), executionState);

  return results.map(migrateWidgetResult);
};

export default fetchReportValues;
