import React, { useContext, useState } from 'react';

import { Input } from 'components/bootstrap';

import FormWrap from './common/FormWrap';
import FormAdvancedOptions from './FormAdvancedOptions';
import { FormDataContext } from './context/FormData';
import type { ErrorMessageType, FormDataContextType, HandleFieldUpdateType, HandleSubmitType } from './types';

interface Props {
  onSubmit: HandleSubmitType;

  onChange: HandleFieldUpdateType;
}

const StepSubscribe: React.FC<Props> = ({ onSubmit, onChange }) => {
  const { formData } = useContext<FormDataContextType>(FormDataContext);
  const [formError, setFormError] = useState<ErrorMessageType>(null);

  const { checkpointIntervalSeconds } = formData;

  const handleSubmit = () => {
    if (checkpointIntervalSeconds.value < 5) {
      setFormError({
        full_message: 'Please provide valid checkpoint interval',
        nice_message:
          'Minimum allowable checkpoint interval is 5 seconds.',
      });

      return;
    }

    setFormError(null);
    onSubmit();
  };

  return (
    <FormWrap onSubmit={handleSubmit}
              buttonContent="Proceed"
              title=""
              error={formError}
              description="">
      <Input id="checkpointIntervalSeconds"
             name="checkpointIntervalSeconds"
             type="number"
             value={checkpointIntervalSeconds.value || checkpointIntervalSeconds.defaultValue || ''}
             min="1"
             max="60"
             step="1"
             onChange={onChange}
             required
             help="How often (in seconds) Graylog will record checkpoints for CrowdStrike data streams."
             label="Checkpoint Interval" />
      <FormAdvancedOptions onChange={onChange} />
    </FormWrap>
  );
};

export default StepSubscribe;
