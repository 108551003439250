import { useEffect, useState } from 'react';

import type { TeamSyncConfig } from 'logic/authentication/okta/types';
import { GroupSyncActions } from 'authentication/stores/directoryServices/GroupSyncStore';
import type GroupSyncBackend from 'authentication/logic/directoryServices/GroupSyncBackend';
import { OKTA_TYPE_KEY } from 'authentication/components/oidc/constants';

const prepareFormValues = (backendGroupSync, type) => {
  const {
    selectionType,
    selection,
    defaultRoles,
    config,
  } = backendGroupSync;

  return {
    teamDefaultRoles: defaultRoles.join(),
    teamSelectionType: selectionType,
    ...(type === OKTA_TYPE_KEY ? { oktaApiToken: config.oktaApiToken } : { groupsClaim: config.groupsClaim }),
    teamSelection: selection,
    synchronizeGroups: true,
  };
};

const useInitialGroupSyncValues = (backendId: string, type: string, initialFormValues: TeamSyncConfig) => {
  const [backendGroupSync, setBackendGroupSync] = useState<GroupSyncBackend | undefined>();
  const [finishedLoading, setFinishedLoading] = useState(false);

  useEffect(() => {
    if (backendId) {
      GroupSyncActions.load(backendId).then((result) => {
        if (result && result.backend) {
          setBackendGroupSync(result.backend);
        }

        setFinishedLoading(true);
      }).catch(() => {
        setFinishedLoading(true);
        setBackendGroupSync(undefined);
      });
    }
  }, [backendId]);

  return {
    finishedLoading: backendId ? finishedLoading : true,
    formValues: backendGroupSync ? prepareFormValues(backendGroupSync, type) : initialFormValues,
  };
};

export default useInitialGroupSyncValues;
