import * as React from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';

import { isPermitted } from 'util/PermissionsMixin';
import { Alert } from 'components/bootstrap';
import type { ValidationResult } from 'enterprise/parameters/components/ParameterDeclarationForm';
import useCurrentUser from 'hooks/useCurrentUser';

import type { ParameterDeclarationComponentProps } from './ParameterTypes';
import { useParameterType } from './ParameterTypes';

const PermissionsAlert = styled(Alert)`
  margin-top: 10px;
`;

type Props = ParameterDeclarationComponentProps & {
  type: string,
};

const useValidatePermissions = (idx, editPermissions, title, onValidate) => {
  const currentUser = useCurrentUser();
  const hasEditPermissions = isPermitted(currentUser?.permissions, editPermissions);

  useEffect(() => {
    const _validationState: ValidationResult = hasEditPermissions
      ? ['success']
      : ['error', ''];
    onValidate(idx, { typeEditPermissions: _validationState });

    return () => onValidate(idx, { typeEditPermissions: ['success'] });
  }, [idx, editPermissions, title, hasEditPermissions, onValidate]);

  return hasEditPermissions;
};

const TypeSpecificDeclarationFields = ({ idx, type, parameter, onChange, validationState, onValidate }: Props) => {
  const { editComponent: Component, editPermissions, title } = useParameterType(type);

  const hasEditPermissions = useValidatePermissions(idx, editPermissions, title, onValidate);

  if (!hasEditPermissions) {
    return (
      <PermissionsAlert bsStyle="danger">
        You currently do not have the permissions to create or edit a &quot;{title}&quot; parameter. Required
        permissions: {editPermissions?.join(',')}
      </PermissionsAlert>
    );
  }

  return <Component idx={idx} parameter={parameter} onChange={onChange} validationState={validationState} onValidate={onValidate} />;
};

export default TypeSpecificDeclarationFields;
