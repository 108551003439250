import __request__ from 'routing/request';
interface SystemJobSummary {
    readonly job_status: 'runnable' | 'running' | 'complete' | 'paused' | 'error' | 'cancelled';
    readonly provides_progress: boolean;
    readonly execution_duration: string;
    readonly name: string;
    readonly is_cancelable: boolean;
    readonly description: string;
    readonly started_at: string;
    readonly id: string;
    readonly percent_complete: number;
    readonly info: string;
    readonly node_id: string;
}
interface SystemJobSummaryArrayMap {
    readonly [_key: string]: SystemJobSummaryArray;
}
type SystemJobSummaryArray = SystemJobSummary[];
interface TriggerRequest {
    readonly job_name: string;
}
/**
 * Trigger new job
 */
export function trigger(JSONbody: TriggerRequest): Promise<unknown> {
    return __request__('POST', '/system/jobs', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * List currently running jobs
 */
export function list(): Promise<SystemJobSummaryArrayMap> {
    return __request__('GET', '/system/jobs', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Acknowledge job with the given ID
 */
export function acknowledgeJob(jobId: string): Promise<unknown> {
    return __request__('DELETE', `/system/jobs/acknowledge/${jobId}`, null, {}, {
        'Accept': []
    });
}
/**
 * Get information of a specific currently running job
 */
export function get(jobId: string): Promise<SystemJobSummary> {
    return __request__('GET', `/system/jobs/${jobId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Cancel running job
 */
export function cancel(jobId: string): Promise<SystemJobSummary> {
    return __request__('DELETE', `/system/jobs/${jobId}`, null, {}, {
        'Accept': ['application/json']
    });
}
