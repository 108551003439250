import type { ReactNode } from 'react';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import usePaginationQueryParameter from 'hooks/usePaginationQueryParameter';

import ForwardersContext, { getForwarderContextById } from './ForwardersContext';

import type { ForwarderListResponse } from '../stores/ForwardersStore';
import { ForwardersActions } from '../stores/ForwardersStore';
import type {
  Forwarder,
  ForwarderPagination,
  ForwardersContext as ForwardersContextType,
  ForwarderStateFilter,
  RequestedPagination,
  SortOrder,
} from '../Types';

type Props = {
  children: ReactNode,
  initialPagination?: RequestedPagination,
};

const PaginatedForwarderListProvider = ({ initialPagination, children }: Props) => {
  const { page, pageSize, resetPage } = usePaginationQueryParameter();
  const [forwarders, setForwarders] = useState<Forwarder[]>([]);
  const [forwardersPagination, setForwardersPagination] = useState<ForwarderPagination | undefined>(undefined);
  const [forwardersContext, setForwardersContext] = useState<ForwardersContextType>({});
  const [requestedPagination, setRequestedPagination] = useState<RequestedPagination | undefined>(initialPagination);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const timeout = useRef<number | undefined>();
  const fetchPromise = useRef<Promise<ForwarderListResponse> | void>();

  const forwardersContextValue = useMemo(() => {
    const changeQuery = (query: string = '') => {
      resetPage();

      setRequestedPagination((prevRequestedPagination: RequestedPagination = {}): RequestedPagination => ({ ...prevRequestedPagination, query, page: 1 }));
    };

    const changeSort = (sortByField: string, order: SortOrder) => {
      resetPage();

      setRequestedPagination((prevRequestedPagination: RequestedPagination = {}): RequestedPagination => ({ ...prevRequestedPagination, sortByField, order, page: 1 }));
    };

    const changeStateFilter = (stateFilter: ForwarderStateFilter) => {
      resetPage();

      setRequestedPagination((prevRequestedPagination: RequestedPagination = {}): RequestedPagination => ({ ...prevRequestedPagination, stateFilter, page: 1 }));
    };

    return ({
      forwarders,
      forwardersContext,
      pagination: forwardersPagination,
      changeQuery,
      changeSort,
      changeStateFilter,
      isLoading,
      getForwarderContext: getForwarderContextById(forwardersContext),
    });
  }, [
    forwarders,
    forwardersContext,
    forwardersPagination,
    isLoading,
    resetPage,
  ]);

  useEffect(() => {
    const fetchForwarders = (isPeriodicalRequest = false) => {
      if (fetchPromise.current) {
        return;
      }

      setIsLoading(true);

      fetchPromise.current = ForwardersActions.list({ ...requestedPagination, page, pageSize }, isPeriodicalRequest)
        .then((response: ForwarderListResponse) => {
          setForwarders(response.forwarders);
          setForwardersPagination(response.forwardersPagination);
          setForwardersContext(response.forwardersContext);

          return response;
        })
        .finally(() => {
          fetchPromise.current = undefined;
          setIsLoading(false);
        });
    };

    fetchForwarders();

    timeout.current = window.setInterval(() => {
      fetchForwarders(true);
    }, 5000);

    return () => {
      fetchPromise.current = undefined;
      setIsLoading(false);

      if (timeout.current) {
        clearInterval(timeout.current);
      }
    };
  }, [requestedPagination, page, pageSize]);

  return (
    <ForwardersContext.Provider value={forwardersContextValue}>
      {children}
    </ForwardersContext.Provider>
  );
};

PaginatedForwarderListProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialPagination: PropTypes.shape({
    query: PropTypes.string,
    page: PropTypes.number,
    pageSize: PropTypes.number,
    order: PropTypes.oneOf(['asc', 'desc']),
    sortByField: PropTypes.string,
    stateFilter: PropTypes.oneOf(['any', 'connected', 'disconnected']),
  }),
};

PaginatedForwarderListProvider.defaultProps = {
  initialPagination: {},
};

export default PaginatedForwarderListProvider;
