import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import fetch from 'logic/rest/FetchProvider';
import UserNotification from 'util/UserNotification';
import { qualifyUrl } from 'util/URLUtils';
import * as URLUtils from 'util/URLUtils';
import ApiRoutes from 'routing/ApiRoutes';

import { INSTANT_ARCHIVING_API_ROUTES } from '../Constants';
import type { InstantArchiveJob } from '../Types';

const refetchInterval = 3000;
const queryKey = ['instant-archives', 'jobs'];
const fetchInstantArchivingJob = (): Promise<{
  in_progress: number,
  progress: Array<InstantArchiveJob>
}> => fetch('GET', qualifyUrl(INSTANT_ARCHIVING_API_ROUTES.InstantArchivingAPIController.job.progress().url));
const cancelInstantArchivingJob = (id: string) => fetch('DELETE', qualifyUrl(INSTANT_ARCHIVING_API_ROUTES.InstantArchivingAPIController.job.cancel(id).url));
const acknowledgeInstantArchivingJob = (id: string) => fetch('DELETE', URLUtils.qualifyUrl(ApiRoutes.SystemJobsApiController.acknowledgeJob(id).url));

const useInstantArchivingJobs = (): {
  instantArchivingData: {
    inProgressCount: number,
    jobs: Array<InstantArchiveJob>,
  },
  refetchInstantArchiving: () => void,
  isLoadingInstantArchiving: boolean,
  onCancelInstantArchivingJob: (id: string) => Promise<void>,
  onAcknowledgeInstantArchivingJob: (id: string) => Promise<void>,
} => {
  const queryClient = useQueryClient();
  const { data, refetch: refetchInstantArchiving, isLoading: isLoadingInstantArchiving } = useQuery(
    queryKey,
    () => fetchInstantArchivingJob(),
    {
      onError: (errorThrown) => {
        UserNotification.error(`Loading Instant archiving jobs failed with status: ${errorThrown}`,
          'Could not load instant archiving jobs');
      },
      keepPreviousData: true,
      refetchInterval,
      notifyOnChangeProps: ['data', 'error'],
    },
  );
  const { mutateAsync: onCancelInstantArchivingJob } = useMutation(cancelInstantArchivingJob, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
    },
    onError: (errorThrown) => {
      UserNotification.error(`Canceling Instant archiving job failed with status: ${errorThrown}`,
        'Could not cancel instant archiving jobs');
    },
  });
  const { mutateAsync: onAcknowledgeInstantArchivingJob } = useMutation(acknowledgeInstantArchivingJob, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
    },
    onError: (errorThrown) => {
      UserNotification.error(`Acknowledging Instant archiving job status failed with status: ${errorThrown}`,
        'Could not acknowledge instant archiving jobs status');
    },
  });

  return ({
    instantArchivingData: {
      inProgressCount: data?.in_progress,
      jobs: data?.progress,
    },
    refetchInstantArchiving,
    isLoadingInstantArchiving,
    onCancelInstantArchivingJob,
    onAcknowledgeInstantArchivingJob,
  });
};

export default useInstantArchivingJobs;
