import Immutable from 'immutable';
import uniqBy from 'lodash/uniqBy';

import type { SearchFilter } from 'views/types';
import View from 'views/logic/views/View';
import isFilterInaccessible from 'search-filter/logic/isFilterInaccessible';

const assertUnreachable = (type: string): never => {
  throw new Error(`View type: ${type ?? '(undefined)'} is not supported.`);
};

const getInaccessibleFiltersInView = (view: View, userPermissions: Immutable.List<string>) => {
  switch (view.type) {
    case View.Type.Search:
      return view.search.queries.flatMap((query) => query.filters?.filter((filter) => isFilterInaccessible(filter, userPermissions)));

    case View.Type.Dashboard: {
      const inaccessibleFilter = view.search.queries.flatMap<number, SearchFilter>(({ searchTypes }) => searchTypes.flatMap(({ filters }) => filters?.filter((filter) => isFilterInaccessible(filter, userPermissions))));

      return Immutable.OrderedSet(uniqBy(inaccessibleFilter.toArray(), (filter: SearchFilter) => filter.id));
    }

    default:
      return assertUnreachable(view.type);
  }
};

export default getInaccessibleFiltersInView;
