const BackendTypes = {
  backendTypes: [
    { value: 'fs-1', label: 'File System' },
    { value: 's3-1', label: 'Amazon S3' },
  ],

  getBackendType(backendType) {
    return this.backendTypes.filter((t) => t.value === backendType)[0];
  },
};

export default BackendTypes;
