import * as React from 'react';

import { LinkContainer } from 'components/common/router';
import { DocumentTitle, PageHeader } from 'components/common';
import { Button } from 'components/bootstrap';
import UsersPageNavigation from 'components/users/navigation/UsersPageNavigation';
import Routes from 'routing/Routes';

import TeamCreate from '../components/team-create/TeamCreate';

const TeamCreatePage = () => (
  <DocumentTitle title="Team Create">
    <UsersPageNavigation />
    <PageHeader title="Team Create"
                actions={(
                  <LinkContainer to={Routes.pluginRoute('SYSTEM_TEAMS_NEW')}>
                    <Button bsStyle="success">
                      Create Team
                    </Button>
                  </LinkContainer>
                )}>
      <span>
        Create new team and assign users.
      </span>

      {/* TODO: Add documentation link once we have documentation */}
      <span />
    </PageHeader>

    <TeamCreate />

  </DocumentTitle>
);

export default TeamCreatePage;
