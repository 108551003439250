// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auditlog-entries table, .filter {
    margin-top: 15px;
}

.auditlog-entries table th {
    color: #333;
    font-weight: bold;
}

.auditlog-entries th.small-col {
    width: 200px;
}

.auditlog-entries th.tiny-col {
    width: 100px;
}

.auditlog-entries tbody.details-visible {
    border-left: 4px solid #2980b9;
}

.auditlog-entries tr.toggle-details {
    cursor: pointer;
}

.auditlog-entries-details dd:not(:last-child) {
    border-bottom: 1px solid #ececec;
    margin-bottom: 5px;
    padding-bottom: 5px;
}

.auditlog-entries-details td {
    padding-top: 13px !important;
    padding-left: 13px !important;
}

.auditlog-entries-details dl {
    margin-bottom: 13px;
}

.auditlog-entries-details .row {
    margin-bottom: 0;
}

.table-hover > tbody.auditlog-entry > tr.auditlog-entries-details:hover {
    background: none;
}

.auditlog-entries tbody.details-visible td.auditlog-message {
    font-weight: bold;
}

.popover-wide {
    max-width: 500px;
}
`, "",{"version":3,"sources":["webpack://./src/web/auditlog/AuditLogEntriesStyle.lazy.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gCAAgC;IAChC,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,4BAA4B;IAC5B,6BAA6B;AACjC;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".auditlog-entries table, .filter {\n    margin-top: 15px;\n}\n\n.auditlog-entries table th {\n    color: #333;\n    font-weight: bold;\n}\n\n.auditlog-entries th.small-col {\n    width: 200px;\n}\n\n.auditlog-entries th.tiny-col {\n    width: 100px;\n}\n\n.auditlog-entries tbody.details-visible {\n    border-left: 4px solid #2980b9;\n}\n\n.auditlog-entries tr.toggle-details {\n    cursor: pointer;\n}\n\n.auditlog-entries-details dd:not(:last-child) {\n    border-bottom: 1px solid #ececec;\n    margin-bottom: 5px;\n    padding-bottom: 5px;\n}\n\n.auditlog-entries-details td {\n    padding-top: 13px !important;\n    padding-left: 13px !important;\n}\n\n.auditlog-entries-details dl {\n    margin-bottom: 13px;\n}\n\n.auditlog-entries-details .row {\n    margin-bottom: 0;\n}\n\n.table-hover > tbody.auditlog-entry > tr.auditlog-entries-details:hover {\n    background: none;\n}\n\n.auditlog-entries tbody.details-visible td.auditlog-message {\n    font-weight: bold;\n}\n\n.popover-wide {\n    max-width: 500px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
