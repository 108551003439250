import __request__ from 'routing/request';
interface InputSummary {
    readonly creator_user_id: string;
    readonly node: string;
    readonly name: string;
    readonly created_at: string;
    readonly global: boolean;
    readonly attributes: {
        readonly [_key: string]: Object;
    };
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly content_pack: string;
    readonly static_fields: {
        readonly [_key: string]: string;
    };
}
interface InputStateSummary {
    readonly detailed_message: string;
    readonly started_at: string;
    readonly id: string;
    readonly state: string;
    readonly message_input: InputSummary;
}
interface InputStateSummaryArrayMap {
    readonly [_key: string]: InputStateSummaryArray;
}
interface InputDeletedMap {
    readonly [_key: string]: InputDeleted;
}
interface InputCreatedMap {
    readonly [_key: string]: InputCreated;
}
interface Object {
}
interface InputCreated {
    readonly id: string;
}
type InputStateSummaryArray = InputStateSummary[];
interface InputDeleted {
    readonly id: string;
}
/**
 * Get all input states
 */
export function get(): Promise<InputStateSummaryArrayMap> {
    return __request__('GET', '/cluster/inputstates', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Start or restart specified input in all nodes
 */
export function start(inputId: string): Promise<InputCreatedMap> {
    return __request__('PUT', `/cluster/inputstates/${inputId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Stop specified input in all nodes
 */
export function stop(inputId: string): Promise<InputDeletedMap> {
    return __request__('DELETE', `/cluster/inputstates/${inputId}`, null, {}, {
        'Accept': ['application/json']
    });
}
