import PropTypes from 'prop-types';
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import createReactClass from 'create-react-class';

import { Alert, Button, ControlLabel, FormControl, FormGroup } from 'components/bootstrap';
import BootstrapModalForm from 'components/bootstrap/BootstrapModalForm';
import { adjustFormat } from 'util/DateTime';
import UserNotification from 'util/UserNotification';

const formatDatetime = (datetime) => adjustFormat(datetime, 'date');

const ImportLicenseModal = createReactClass({
  // eslint-disable-next-line react/no-unused-class-component-methods
  displayName: 'ImportLicenseModal',

  // eslint-disable-next-line react/no-unused-class-component-methods
  propTypes: {
    verifiedLicense: PropTypes.object,
    errorMessage: PropTypes.string,
    verifyLicense: PropTypes.func.isRequired,
    saveLicense: PropTypes.func.isRequired,
  },

  getDefaultProps() {
    return {
      errorMessage: undefined,
      verifiedLicense: undefined,
    };
  },

  getInitialState() {
    return {
      token: undefined,
      showModal: false,
    };
  },

  openModal() {
    this.setState({ showModal: true });
  },

  _closeModal() {
    this.setState({ showModal: false });
  },

  _saved() {
    this._closeModal();
    UserNotification.success('Imported new license successfully', 'Import license');
    this.setState(this.getInitialState());
  },

  _save() {
    if (this.props.errorMessage) {
      UserNotification.error(`Import new license failed with status: ${this.props.errorMessage}`,
        'Could not import license');
    } else {
      this.props.saveLicense(this.state.token, this._saved);
    }
  },

  _updateLicenseInformation(ev) {
    const token = ev.target.value.trim();
    this.setState({ token: token });

    if (token) {
      this.props.verifyLicense(token);
    }
  },

  render() {
    let licensePreview = null;
    let errorMessage = null;

    if (this.props.errorMessage) {
      errorMessage = <p><b>Reason</b>: {this.props.errorMessage}</p>;
    }

    if (this.props.verifiedLicense) {
      const l = this.props.verifiedLicense;

      licensePreview = (
        <dl style={{ marginBottom: 0 }}>
          <dt>Licensed to</dt>
          <dd>{`${l.audience.company}: ${l.audience.name} <${l.audience.email}>`}</dd>
          <dt>Valid from</dt>
          <dd>{formatDatetime(l.not_before_date)}</dd>
          <dt>Valid until</dt>
          <dd>{formatDatetime(l.expiration_date)}</dd>
        </dl>
      );
    }

    let licenseStatus;

    if (this.state.token) {
      licenseStatus = (
        <Alert bsStyle={this.props.errorMessage ? 'danger' : 'success'} title={`${this.props.errorMessage ? 'Invalid' : 'Valid'} license`}>
          {errorMessage}
          {licensePreview}
        </Alert>
      );
    } else {
      licenseStatus = (
        <Alert bsStyle="info">
          Please add the license key for your cluster in the text field above.
        </Alert>
      );
    }

    return (
      <span>
        <Button onClick={this.openModal}
                bsStyle="success">
          Import new license
        </Button>
        <BootstrapModalForm show={this.state.showModal}
                            onCancel={this._closeModal}
                            title="Import new Graylog license"
                            onSubmitForm={this._save}
                            submitButtonText="Import license">
          <fieldset>
            <FormGroup id="licenseTextarea">
              <ControlLabel>License</ControlLabel>
              <FormControl componentClass="textarea"
                           defaultValue={this.state.token}
                           onChange={this._updateLicenseInformation}
                           rows={5}
                           autoFocus
                           required />
            </FormGroup>
          </fieldset>
          {licenseStatus}
        </BootstrapModalForm>
      </span>
    );
  },
});

export default ImportLicenseModal;
