import __request__ from 'routing/request';
interface Object {
}
interface PaginatedResponse {
    readonly [_key: string]: Object;
}
/**
 * Get the Favorites for the Start Page for the user
 */
export function getFavoriteItems(page: number = 1, per_page: number = 5, type?: string): Promise<PaginatedResponse> {
    return __request__('GET', '/favorites', null, { 'page': page, 'per_page': per_page, 'type': type }, {
        'Accept': ['application/json']
    });
}
/**
 * Add an item for inclusion on the Start Page for the user
 */
export function addItemToFavorites(grn: string): Promise<void> {
    return __request__('PUT', `/favorites/${grn}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Remove an item from inclusion on the Start Page for the user
 */
export function removeItemFromFavorites(grn: string): Promise<void> {
    return __request__('DELETE', `/favorites/${grn}`, null, {}, {
        'Accept': ['application/json']
    });
}
