import __request__ from 'routing/request';
interface SystemOverviewResponse {
    readonly timezone: string;
    readonly version: string;
    readonly is_leader: boolean;
    readonly lifecycle: string;
    readonly cluster_id: string;
    readonly hostname: string;
    readonly lb_status: string;
    readonly codename: string;
    readonly started_at: string;
    readonly operating_system: string;
    readonly is_processing: boolean;
    readonly facility: string;
    readonly node_id: string;
}
interface LocalesResponse {
    readonly locales: {
        readonly [_key: string]: LocaleDescription;
    };
}
interface SystemProcessBufferDumpResponse {
    readonly processbuffer_dump: {
        readonly [_key: string]: string;
    };
}
interface SystemThreadDumpResponse {
    readonly threaddump: string;
}
interface SystemJVMResponse {
    readonly total_memory: {
        readonly [_key: string]: number;
    };
    readonly free_memory: {
        readonly [_key: string]: number;
    };
    readonly used_memory: {
        readonly [_key: string]: number;
    };
    readonly pid: string;
    readonly max_memory: {
        readonly [_key: string]: number;
    };
    readonly node_id: string;
    readonly info: string;
}
interface LocaleDescription {
    readonly language_tag: string;
    readonly display_name: string;
}
/**
 * Get system overview
 */
export function system(): Promise<SystemOverviewResponse> {
    return __request__('GET', '/system', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get JVM information
 */
export function jvm(): Promise<SystemJVMResponse> {
    return __request__('GET', '/system/jvm', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get supported locales
 */
export function locales(): Promise<LocalesResponse> {
    return __request__('GET', '/system/locales', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a process buffer dump
 */
export function processBufferDump(): Promise<SystemProcessBufferDumpResponse> {
    return __request__('GET', '/system/processbufferdump', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a thread dump
 */
export function threaddump(): Promise<SystemThreadDumpResponse> {
    return __request__('GET', '/system/threaddump', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a thread dump as plain text
 */
export function threadDumpAsText(): Promise<string> {
    return __request__('GET', '/system/threaddump', null, {}, {
        'Accept': ['text/plain']
    });
}
