import React, {useContext, useState} from 'react';
import {Input} from 'components/bootstrap';

import FormWrap from './common/FormWrap';
import FormAdvancedOptions from './FormAdvancedOptions';
import {FormDataContext} from './context/FormData';
import {ErrorMessageType, FormDataContextType, HandleFieldUpdateType, HandleSubmitType} from './types';

interface Props {
  onSubmit: HandleSubmitType;
  onChange: HandleFieldUpdateType;
}

const StepSubscribe: React.FC<Props> = ({onSubmit, onChange}) => {
  const {formData} = useContext<FormDataContextType>(FormDataContext);
  const [formError, setFormError] = useState<ErrorMessageType>(null);
  const [azureBatchSizeError, setAzureBatchSizeError] = useState<boolean>(false);
  const [azureWaitTimeError, setAzureWaitTimeError] = useState<boolean>(false);
  const {azureBatchSize, azureWaitTime} = formData;

  const handleSubmit = () => {
    let error = false;
    if (azureBatchSize.value <1 || azureBatchSize.value >1000){
      error = true;
      setAzureBatchSizeError(true);
    }
    if (azureWaitTime.value < 1 || azureWaitTime.value > 30) {
      error = true;
      setAzureWaitTimeError(true);
    }
    if (!error) {
      setFormError(null);
      onSubmit();
    }
  };

  return (
    <FormWrap
      onSubmit={handleSubmit}
      buttonContent="Proceed"
      title=""
      error={formError}
      description=""
    >
      <Input id="azureBatchSize"
             name="azureBatchSize"
             type="number"
             value={azureBatchSize.value || azureBatchSize.defaultValue || ''}
             min="10"
             max="20000"
             onChange={onChange}
             required
             help="The Maximum message count received from Event Hub at once. The input will store checkpoints once per batch."
             label="Maximum Batch Size" />
      {azureBatchSizeError && <span> <strong>The Maximum Batch Size must be greater than 1.</strong></span>}
      <Input id="azureWaitTime"
             name="azureWaitTime"
             type="number"
             value={azureWaitTime.value || azureWaitTime.defaultValue || ''}
             min="1"
             max="30"
             onChange={onChange}
             required
             help="The maximum amount of time to wait for the Maximum Batch Size to be reached. The average wait time is 3 minutes"
             label="Maximum Wait Time" />
      {azureWaitTimeError && <span> <strong>The wait time can be between 1 to 30</strong></span>}
      <FormAdvancedOptions onChange={onChange}/>
    </FormWrap>
  );
};

export default StepSubscribe;
