import * as React from 'react';
import PropTypes from 'prop-types';

import { FormSubmit } from 'components/common';

type Props = {
  action: 'create' | 'edit',
  disabled?: boolean,
  formElementId: string,
  isLoading: boolean,
  onCancel: () => void,
};

const ReportContentsToolbar = ({ action, formElementId, isLoading, onCancel, disabled }: Props) => {
  const submitButtonText = action === 'create' ? 'Create report' : 'Update report';
  const submitLoadingText = action === 'create' ? 'Creating report...' : 'Updating report...';

  return (
    <FormSubmit isAsyncSubmit
                submitButtonText={submitButtonText}
                submitLoadingText={submitLoadingText}
                disabledSubmit={disabled}
                isSubmitting={isLoading}
                formId={formElementId}
                onCancel={onCancel} />

  );
};

ReportContentsToolbar.propTypes = {
  action: PropTypes.oneOf(['create', 'edit']).isRequired,
  formElementId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
};

ReportContentsToolbar.defaultProps = {
  disabled: false,
  isLoading: false,
};

export default ReportContentsToolbar;
