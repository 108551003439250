import { INSTANT_ARCHIVING_ROUTES, INSTANT_ARCHIVING_ENABLED } from 'instant-archiving/Constants';
import InstantArchivingsPage from 'instant-archiving/InstantArchivingsPage';
import InstantArchivingConfigPage from 'instant-archiving/InstantArchivingConfig/InstantArchivingConfigPage';
import InstantArchiveDetailsPage from 'instant-archiving/InstantArchiveDetailsPage';

const instantArchivingExport = {
  routes: INSTANT_ARCHIVING_ENABLED ? [
    {
      path: INSTANT_ARCHIVING_ROUTES.ARCHIVE.LIST,
      component: InstantArchivingsPage,
    },
    {
      path: INSTANT_ARCHIVING_ROUTES.ARCHIVE.show(':archiveId'),
      component: InstantArchiveDetailsPage,
    },
    {
      path: INSTANT_ARCHIVING_ROUTES.CONFIG,
      component: InstantArchivingConfigPage,
    },
  ] : [],
};

export default instantArchivingExport;
