import { useQuery } from '@tanstack/react-query';

import type { PaginatedSearchFilterUsages } from 'search-filter/types';
import { USER_FILTER_QUERY_KEY } from 'search-filter/hooks/useUserSearchFilterQuery';
import { DEFAULT_PAGINATION } from 'search-filter/components/filter-management-pages/FilterUsagesInSearches';
import PaginationURL from 'util/PaginationURL';
import { qualifyUrl } from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';
import UserNotification from 'util/UserNotification';

type RequestQuery = {
  page: number,
  per_page: number,
  query?: string,
  sort?: string,
  order?: string,
  type?: string
}

const fetchSearchFilterUsages = async (filterId: string, { query, page, per_page, type }: RequestQuery): Promise<PaginatedSearchFilterUsages> => {
  const url = PaginationURL(`/plugins/org.graylog.plugins.searchfilters/search_filters_usage/details/${filterId}/paginated`, page, per_page, query, { type });

  return fetch('GET', qualifyUrl(url));
};

const useSearchFilterUsagesQuery = (filterId: string, requestQuery: RequestQuery): { data: PaginatedSearchFilterUsages, isFetching: boolean } => useQuery([USER_FILTER_QUERY_KEY, filterId, requestQuery], () => fetchSearchFilterUsages(filterId, requestQuery), {
  onError: (errorThrown) => {
    UserNotification.error(`Loading search filter usages for filter with id ${filterId} failed with status: ${errorThrown}`,
      'Could not load search filter usages');
  },
  keepPreviousData: true,
  initialData: {
    elements: [],
    pagination: DEFAULT_PAGINATION,
  },
});

export default useSearchFilterUsagesQuery;
