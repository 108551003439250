import __request__ from 'routing/request';
interface SingleSubsystemSummary {
    readonly level_syslog: number;
    readonly level: string;
    readonly description: string;
    readonly categories: string[];
    readonly title: string;
}
interface SubsystemSummary {
    readonly subsystems: {
        readonly [_key: string]: SingleSubsystemSummary;
    };
}
interface SingleLoggerSummary {
    readonly level_syslog: number;
    readonly level: string;
}
interface LoggersSummary {
    readonly total: number;
    readonly loggers: {
        readonly [_key: string]: SingleLoggerSummary;
    };
}
/**
 * List all loggers and their current levels
 */
export function loggers(): Promise<LoggersSummary> {
    return __request__('GET', '/system/loggers', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get recent internal log messages
 * @param limit How many log messages should be returned. 0 returns all existing messages.The limit can be rounded up to the next batch size and thus return slightly more logs than requested.
 */
export function messages(limit: number = 1000): Promise<unknown> {
    return __request__('GET', '/system/loggers/messages/recent', null, { 'limit': limit }, {
        'Accept': ['text/plain']
    });
}
/**
 * List all logger subsystems and their current levels
 */
export function subsystems(): Promise<SubsystemSummary> {
    return __request__('GET', '/system/loggers/subsystems', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Set the loglevel of a whole subsystem
 */
export function setSubsystemLoggerLevel(subsystem: string, level: string): Promise<void> {
    return __request__('PUT', `/system/loggers/subsystems/${subsystem}/level/${level}`, null, {}, {
        'Accept': []
    });
}
/**
 * Set the loglevel of a single logger
 */
export function setSingleLoggerLevel(loggerName: string, level: string): Promise<void> {
    return __request__('PUT', `/system/loggers/${loggerName}/level/${level}`, null, {}, {
        'Accept': []
    });
}
