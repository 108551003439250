import React from 'react';
import { PropTypes } from 'prop-types';

import PageHeader from 'components/common/PageHeader';

import EmbeddedGmailApp from './EmbeddedGmailApp';

const ExternalLink = ({ href, title }) => <a href={href} rel="noopener noreferrer" target="_blank">{title}</a>;

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const GmailApp = () => {
  return (
    <>
      <PageHeader title="Google Workspace Gmail Integrations">
        <span>This feature retrieves log records from Google Workspace Gmail Logs</span>
        <p>
          You need to have <ExternalLink href="https://workspace.google.com/" title="Google Workspace Account" />,{' '}
          You need to have <ExternalLink href="https://console.cloud.google.com/" title="Google Cloud Platform" />,{' '}
        </p>
      </PageHeader>
      <EmbeddedGmailApp />
    </>
  );
};

GmailApp.propTypes = {};

export default GmailApp;
