import __request__ from 'routing/request';
interface CreateRolePermissionResponse {
    readonly result: string;
    readonly role_name: string;
    readonly role_arn: string;
}
interface CreateLogSubscriptionResponse {
    readonly result: string;
}
interface KinesisNewStreamResponse {
    readonly stream_arn: string;
    readonly result: string;
    readonly stream_name: string;
}
interface CreateRolePermissionRequest {
    readonly stream_arn: string;
    readonly dynamodb_endpoint: string;
    readonly stream_name: string;
    readonly aws_access_key_id: string;
    readonly kinesis_endpoint: string;
    readonly aws_secret_access_key: unknown;
    readonly assume_role_arn: string;
    readonly iam_endpoint: string;
    readonly cloudwatch_endpoint: string;
    readonly region: string;
}
interface KinesisNewStreamRequest {
    readonly dynamodb_endpoint: string;
    readonly stream_name: string;
    readonly aws_access_key_id: string;
    readonly kinesis_endpoint: string;
    readonly aws_secret_access_key: unknown;
    readonly assume_role_arn: string;
    readonly iam_endpoint: string;
    readonly cloudwatch_endpoint: string;
    readonly region: string;
}
interface CreateLogSubscriptionRequest {
    readonly log_group_name: string;
    readonly dynamodb_endpoint: string;
    readonly filter_pattern: string;
    readonly filter_name: string;
    readonly destination_stream_arn: string;
    readonly aws_secret_access_key: unknown;
    readonly iam_endpoint: string;
    readonly role_arn: string;
    readonly aws_access_key_id: string;
    readonly kinesis_endpoint: string;
    readonly assume_role_arn: string;
    readonly cloudwatch_endpoint: string;
    readonly region: string;
}
/**
 * Step 1: Attempt to create a new kinesis stream and wait for it to be ready.
 */
export function createNewKinesisStream(JSONbody: KinesisNewStreamRequest): Promise<KinesisNewStreamResponse> {
    return __request__('POST', '/aws/kinesis/auto_setup/create_stream', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Step 3: Subscribe a Kinesis stream to a CloudWatch log group
 */
export function createSubscription(JSONbody: CreateLogSubscriptionRequest): Promise<CreateLogSubscriptionResponse> {
    return __request__('POST', '/aws/kinesis/auto_setup/create_subscription', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Step 2: Create AWS IAM policy needed for CloudWatch to write logs to Kinesis
 */
export function autoKinesisPermissions(JSONbody: CreateRolePermissionRequest): Promise<CreateRolePermissionResponse> {
    return __request__('POST', '/aws/kinesis/auto_setup/create_subscription_policy', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
