import __request__ from 'routing/request';
interface MetricsReadRequest {
    readonly metrics: string[];
}
/**
 * Get all metrics of all nodes in the cluster
 */
export function multipleMetricsAllNodes(Requestedmetrics: MetricsReadRequest): Promise<void> {
    return __request__('POST', '/cluster/metrics/multiple', Requestedmetrics, {}, {
        'Accept': ['application/json']
    });
}
