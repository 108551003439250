import styled from 'styled-components';

const PageHeaderWrapper = styled.div`
  .content-head.content.row {
    margin-left: 0;
    margin-right: 0;
  }
`;

export default PageHeaderWrapper;
