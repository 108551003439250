// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.V2o6vHsKMbH3Qu8dQexi dt::after {
    content: ':';
}

.cAiU6QUfHhhYwBOv7PIU dt::after {
    content: '';
}

.auditlog-status dl {
    margin-top: 8px;
}`, "",{"version":3,"sources":["webpack://./src/web/auditlog/AuditLogStatus.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,eAAe;AACnB","sourcesContent":[":local(.descriptionList dt::after) {\n    content: ':';\n}\n\n:local(.innerDl dt::after) {\n    content: '';\n}\n\n.auditlog-status dl {\n    margin-top: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"descriptionList": `V2o6vHsKMbH3Qu8dQexi`,
	"innerDl": `cAiU6QUfHhhYwBOv7PIU`
};
export default ___CSS_LOADER_EXPORT___;
