import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { LinkContainer } from 'components/common/router';
import Routes from 'routing/Routes';
import { Button, Col, Popover, Table, Row } from 'components/bootstrap';
import { Icon, IfPermitted, OverlayTrigger, PaginatedList, SearchForm } from 'components/common';

import InputProfileEntry from './InputProfileEntry';

import type { Forwarder, InputProfile, Pagination } from '../Types';
import { ForwarderPropType, InputProfilePropType, PaginationPropType } from '../Types';
import { StyledSearchFormWrapper, StyledToolbar } from '../wizard/StyledWizardComponents';
import { QueryHelpButton } from '../CommonStyledComponents';

type Props = {
  inputProfiles: Array<InputProfile>,
  forwarders: Array<Forwarder>,
  pagination: Pagination,
  onForwardersAssign: (updatedForwarders: Array<{forwarderId: string, inputProfileId: string | null}>) => void,
  onQueryChange: (page?: number, perPage?: number, query?: string) => void,
  onInputProfileUpdate: (inputProfile: InputProfile) => void,
};

const StyledTable = styled(Table)`
   .searchHelpButton {
      cursor: help;
      padding: 0 0 0 2px;
      margin-left: 5px;
    }
    .popoverWide {
      max-width: 500px;
      min-width: 350px;
    }
    th.rowActions {
      width: 180px;
    }
    tr > td {
      &.rowName {
        width: 35%;
      }
      &.rowDescription {
        max-width: 50%;
        width: 35%
      }
      &.rowNumberForwarder {
        text-align: center;
      }
    }
`;

const StyledPopover = styled(Popover)`
  max-width: 500px;
`;

const _helpPopover = () => (
  <StyledPopover id="search-query-help" title="Search Syntax Help">
    <p><strong>Available search fields</strong></p>
    <Table condensed>
      <thead>
        <tr>
          <th>Field</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>title</td>
          <td>The title of the Input Profile</td>
        </tr>
        <tr>
          <td>description</td>
          <td>The description of Input Profile</td>
        </tr>
      </tbody>
    </Table>
    <p><strong>Examples</strong></p>
    <p>
      Find Input profile by parts of their title:&nbsp;
      <kbd>title:local</kbd>&nbsp;
      <kbd>title:database</kbd>
    </p>
    <p>
      Searching without a field name matches against the <code>title</code> field:&nbsp;
      <kbd>local</kbd> is the same as &nbsp;
      <kbd>title:local</kbd>
    </p>
  </StyledPopover>
);

const InputProfilesListComponent = ({ inputProfiles, forwarders, onForwardersAssign, pagination, onQueryChange, onInputProfileUpdate }: Props) => {
  const { total, perPage, query } = pagination;

  const _getInputProfilesTable = () => inputProfiles.map((inputProfile) => {
    const forwarderCount = forwarders.filter((f) => f.input_profile_id === inputProfile.id).length;

    return (
      <InputProfileEntry key={inputProfile.id}
                         inputProfile={inputProfile}
                         forwarderCount={forwarderCount}
                         onForwardersAssign={onForwardersAssign}
                         onInputProfileUpdate={onInputProfileUpdate}
                         forwarders={forwarders} />
    );
  });

  const _handleOnSearch = (nextQuery) => onQueryChange(1, 10, nextQuery);

  const _handleSearchReset = () => onQueryChange();

  const queryHelp = (
    <OverlayTrigger trigger="click" rootClose placement="right" overlay={_helpPopover()}>
      <QueryHelpButton bsStyle="link"><Icon name="question-circle" fixedWidth /></QueryHelpButton>
    </OverlayTrigger>
  );

  return (
    <div>
      <Row className="content">
        <Col md={12}>
          <h2>
            Input Profiles
            &nbsp;<small>{total} Total</small>
          </h2>
          <StyledSearchFormWrapper>
            <SearchForm query={query}
                        queryHelpComponent={queryHelp}
                        onSearch={_handleOnSearch}
                        onReset={_handleSearchReset}>
              <StyledToolbar>
                <IfPermitted permissions="inputprofiles:create">
                  <LinkContainer to={Routes.pluginRoute('SYSTEM_INPUT_PROFILES_NEW')}>
                    <Button bsStyle="success">New Input Profile</Button>
                  </LinkContainer>
                </IfPermitted>
              </StyledToolbar>
            </SearchForm>
          </StyledSearchFormWrapper>
          <PaginatedList totalItems={total}
                         pageSize={perPage}
                         showPageSizeSelect={false}>
            <StyledTable hover>
              <thead>
                <tr>
                  <th className="rowName">Title</th>
                  <th className="rowDescription">Description</th>
                  <th className="rowNumberForwarder">Assigned to Forwarders</th>
                  <th className="rowActions">Actions</th>
                </tr>
              </thead>
              <tbody>
                {_getInputProfilesTable()}
              </tbody>
            </StyledTable>
          </PaginatedList>
        </Col>
      </Row>
    </div>
  );
};

InputProfilesListComponent.propTypes = {
  forwarders: PropTypes.arrayOf(ForwarderPropType).isRequired,
  inputProfiles: PropTypes.arrayOf(InputProfilePropType).isRequired,
  pagination: PaginationPropType.isRequired,
  onForwardersAssign: PropTypes.func.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  onInputProfileUpdate: PropTypes.func.isRequired,
};

export default InputProfilesListComponent;
