import * as React from 'react';

import { LinkContainer } from 'components/common/router';
import { Button, ButtonToolbar, Tooltip } from 'components/bootstrap';
import Routes from 'routing/Routes';
import { OverlayTrigger } from 'components/common';

const SearchFiltersPageSubactions = ({ licenseIsValid }: { licenseIsValid: boolean }) => (
  <ButtonToolbar>
    {licenseIsValid ? (
      <LinkContainer to={Routes.pluginRoute('MY-FILTERS_NEW')}>
        <Button bsStyle="success">Create filter</Button>
      </LinkContainer>
    ) : (
      <OverlayTrigger overlay={<Tooltip id="missing-license">Creating new filter is disabled because there is no valid license.</Tooltip>}
                      placement="top">
        <Button bsStyle="success" className="disabled">Create filter</Button>
      </OverlayTrigger>
    )}
  </ButtonToolbar>
);

export default SearchFiltersPageSubactions;
