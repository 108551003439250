const DEFAULT_SETTINGS = {
  /*
  fieldId: { // Same ID as supplied to <Input />
    value: '',
    defaultValue: '', // Update StepReview.jsx & relevant step(s) if you need to output
  }
  */

  /* Default Advanced Settings */
  batchSize: {
    defaultValue: '10000',
  },
  throttleEnabled: {
    value: false, // We want to default to true on render, but never compare the default
  },
  subscribeToAllLog: {
    value: true, // We want to default to true on render, but never compare the default
  },

  workspaceName: {
    value: '',
  },

  availableLogs: {
  value: 'DRIVE_ACTIVITY_LOGS,ADMIN_ACTIVITY_LOGS,LOGIN_ACTIVITY_LOGS,AUTHORIZATION_TOKEN_LOGS,MESSAGE_TRACKING_LOGS,CALENDAR_ACTIVITY_LOGS',
},

  logs: {
    value: '',
  },

  workspaceThrottleEnabled: {
    value: undefined,
  },


  pollingInterval: {
    value: '5',
  },

};

export default DEFAULT_SETTINGS;
