import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Button, MenuItem } from 'components/bootstrap';
import { INSTANT_ARCHIVING_CONFIG_ACTION } from 'instant-archiving/Types';
import type { LicenseStatus } from 'archive/types';
import { isValidLicense } from 'report/logic/license';

import InstantArchivingConfigModal from './InstantArchivingConfigModal';

type Props = {
  buttonType: 'button' | 'menuItem',
  configurationId?: string,
  licenseStatus?: LicenseStatus,
}

const InstantArchivingConfigButton = ({ configurationId, buttonType, licenseStatus }: Props) => {
  const action: INSTANT_ARCHIVING_CONFIG_ACTION = configurationId ? INSTANT_ARCHIVING_CONFIG_ACTION.Update : INSTANT_ARCHIVING_CONFIG_ACTION.Create;
  const buttonText = action === INSTANT_ARCHIVING_CONFIG_ACTION.Update ? 'Edit Archive Configuration' : 'Create Archive Configuration';
  const menuItemText = action === INSTANT_ARCHIVING_CONFIG_ACTION.Update ? 'Edit' : 'Create';

  const [showModal, setShowModal] = useState<boolean>(false);

  const onModalClose = () => {
    setShowModal(false);
  };

  const onButtonClick = () => {
    setShowModal(true);
  };

  if (licenseStatus && (!isValidLicense(licenseStatus))) {
    return null;
  }

  return (
    <>
      {showModal && <InstantArchivingConfigModal configurationId={configurationId} onClose={onModalClose} />}
      {buttonType === 'button' ? (<Button bsStyle="success" onClick={onButtonClick}>{buttonText}</Button>
      ) : (
        <MenuItem bsSize="xsmall" onClick={onButtonClick}>{menuItemText}</MenuItem>
      )}
    </>
  );
};

InstantArchivingConfigButton.propTypes = {
  buttonType: PropTypes.oneOf(['button', 'menuItem']),
  configurationId: PropTypes.string,
};

InstantArchivingConfigButton.defaultProps = {
  buttonType: 'button',
  configurationId: undefined,
  licenseStatus: undefined,
};

export default InstantArchivingConfigButton;
