import { useEffect } from 'react';

import useHistory from 'routing/useHistory';

import DefenderRoutes from './common/Routes';

type Props = { url: string };

const DefenderInputConfiguration: React.FC<Props> = (
) => {
  const history = useHistory();

  useEffect(() => {
    const indexUrl = DefenderRoutes.INTEGRATIONS.Defender.ACTIVITYAPI.index;
    history.push(indexUrl);
  }, [history]);

  return null;
};

export default DefenderInputConfiguration;
