import React from 'react';

import { ExternalLink } from 'components/common';

const GreyNoiseFullIPAdapterDocumentation = () => {
  const style = { marginBottom: 10 };
  return (
      <div>
        <p style={style}>
            The GreyNoise Full IP Lookup data adapter uses the <ExternalLink href="https://docs.greynoise.io/">GreyNoise API</ExternalLink> to
            lookup indicators for the given IP address key and combines the return values from the GreyNoise IP Context and RIOT endpoints.
            See <ExternalLink href="https://docs.greynoise.io/reference/noisecontextip-1">IP Context</ExternalLink> and <ExternalLink href="https://docs.greynoise.io/reference/riotip">RIOT</ExternalLink> for documentation.
        </p>
      </div>
  );
};

export default GreyNoiseFullIPAdapterDocumentation;
