import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

import { DocumentTitle, PageHeader, Spinner } from 'components/common';
import { useStore } from 'stores/connect';
import ForwarderDocs from 'forwarder/util/ForwarderDocs';

import ForwardersListComponent from './forwarders/ForwardersListComponent';
import InputProfilesStore, { InputProfilesActions } from './stores/InputProfilesStore';
import EmptyForwarderListComponent from './forwarders/EmptyForwarderListComponent';
import ForwardersContext from './forwarders/ForwardersContext';
import PaginatedForwarderListProvider from './forwarders/PaginatedForwarderListProvider';
import withIsPermitted from './util/withIsPermitted';
import ForwarderLicenseStatus from './ForwarderLicenseStatus';
import ForwarderLicenseStore, { ForwarderLicenseActions } from './stores/ForwarderLicenseStore';
import ForwarderPageNavigation from './ForwarderPageNavigation';

const REQUIRED_PERMISSIONS = ['forwarders:read', 'inputprofiles:read'];

const ForwardersPage = () => {
  const { all: inputProfiles } = useStore(InputProfilesStore);
  const licenseStatus = useStore(ForwarderLicenseStore);

  useEffect(() => {
    InputProfilesActions.listAll();
    ForwarderLicenseActions.getLicenseStatus();
  }, []);

  return (
    <DocumentTitle title="Forwarders">
      <ForwarderPageNavigation />
      <PageHeader title="Forwarders"
                  documentationLink={{
                    title: 'Forwarder documentation',
                    path: ForwarderDocs.FORWARDER,
                  }}>
        <span>
          List of Forwarders that have registered with this Graylog instance.
        </span>
      </PageHeader>

      <ForwarderLicenseStatus licenseStatus={licenseStatus} />
      <PaginatedForwarderListProvider>
        <ForwardersContext.Consumer>
          {({ forwarders, pagination, changeQuery, changeSort, changeStateFilter, isLoading }) => {
            if (forwarders && inputProfiles && pagination) {
              return forwarders.length === 0 && isEmpty(pagination?.query) && pagination?.stateFilter === 'any'
                ? <EmptyForwarderListComponent licenseStatus={licenseStatus} />
                : (
                  <ForwardersListComponent forwarders={forwarders}
                                           isLoading={isLoading}
                                           licenseStatus={licenseStatus}
                                           inputProfiles={inputProfiles}
                                           pagination={pagination}
                                           onQueryChange={changeQuery}
                                           onSortChange={changeSort}
                                           onStateFilterChange={changeStateFilter} />
                );
            }

            return <Spinner />;
          }}
        </ForwardersContext.Consumer>
      </PaginatedForwarderListProvider>
    </DocumentTitle>
  );
};

export default withIsPermitted(ForwardersPage, REQUIRED_PERMISSIONS);
