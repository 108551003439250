import Reflux from 'reflux';

import { Builder } from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';

import { OidcSessionActions } from './OidcSessionStore';
import type { LoginMetric } from './LoginMetrics';
import {
  createFailureLoginMetric,
  createSuccessLoginMetric,
  GRAYLOG_ERROR_CODES,
} from './LoginMetrics';

type MetricsRequest = {
  metrics: Array<LoginMetric>,
};
type LoginMetricsActionsType = {
  post: (metrics: LoginMetric | Array<LoginMetric>) => Promise<void>,
};
export const LoginMetricsActions = Reflux.createActions<LoginMetricsActionsType>({
  post: { asyncResult: true },
});
export const LoginMetricsStore = Reflux.createStore({
  listenables: [LoginMetricsActions],
  sourceUrl: '/plugins/org.graylog.plugins.cloud/auth_metrics',

  init() {
    this.listenTo(OidcSessionActions.login.completed, this.handleSuccessfulLogin);
    this.listenTo(OidcSessionActions.login.failed, this.handleFailedLogin);
    this.listenTo(OidcSessionActions.logout, this.handleSuccessfulLogout);
  },

  post(metrics: LoginMetric | Array<LoginMetric>) {
    const body: MetricsRequest = {
      metrics: Array.isArray(metrics) ? metrics : [metrics],
    };

    const promise = new Builder('POST', qualifyUrl(this.sourceUrl))
      .json(body)
      .build();

    LoginMetricsActions.post.promise(promise);
  },

  handleSuccessfulLogin() {
    this.post(createSuccessLoginMetric('login'));
  },

  handleFailedLogin(error) {
    if (error?.additional?.status) {
      this.post(createFailureLoginMetric('login', 'http', error.additional.status));

      return;
    }

    this.post(createFailureLoginMetric('login', 'gl', GRAYLOG_ERROR_CODES.graylogUnknownError));
  },

  handleSuccessfulLogout() {
    this.post(createSuccessLoginMetric('logout'));
  },
});
