import Parameter from 'views/logic/parameters/Parameter';
import ParameterBinding from 'views/logic/parameters/ParameterBinding';
import type { ParameterJson } from 'views/logic/parameters/Parameter';
import type { OptionSourceJson } from 'enterprise/parameters/components/option/OptionSource';
import OptionSource from 'enterprise/parameters/components/option/OptionSource';

interface OptionParameterJson extends ParameterJson {
  source: OptionSourceJson;
}

export default class OptionParameter extends Parameter {
  static type = 'option-parameter-v1';

  static Builder: typeof Builder;

  private readonly _source: OptionSource;

  constructor(name: string, title: string, description: string, dataType: string, defaultValue: any, optional: boolean, source: OptionSource, binding?: ParameterBinding) {
    super(OptionParameter.type, name, title, description, dataType, defaultValue, optional, binding);

    this._source = source;
  }

  static create(name: string, title: string, description: string, dataType: string, defaultValue: any, optional: boolean, source: OptionSource, binding?: ParameterBinding): OptionParameter {
    return new OptionParameter(name, title, description, dataType, defaultValue, optional, source, binding);
  }

  get source(): OptionSource {
    return this._source;
  }

  toBuilder(): Builder {
    const { type, name, title, description, dataType, defaultValue, optional, binding } = this._value;

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return new Builder({ type, name, title, description, dataType, defaultValue, optional, binding, source: this._source });
  }

  toJSON(): OptionParameterJson {
    const { type, name, title, description, dataType, defaultValue, optional, binding } = this._value;

    return {
      type,
      name,
      title,
      description,
      data_type: dataType,
      default_value: defaultValue,
      optional,
      binding: binding ? binding.toJSON() : undefined,
      source: this._source ? this._source.toJSON() : undefined,
    };
  }

  static fromJSON(value: OptionParameterJson): OptionParameter {
    const { name, title, description, data_type, default_value, optional, binding, source } = value;

    return new OptionParameter(name, title, description, data_type, default_value, optional, OptionSource.fromJSON(source), ParameterBinding.fromJSON(binding));
  }

  static builder(): Builder {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return new Builder()
      .type(OptionParameter.type)
      .optional(false)
      .dataType('any')
      .binding(ParameterBinding.empty());
  }
}

type InternalBuilderState = {
  type?: string,
  name?: string,
  title?: string,
  description?: string,
  dataType?: string,
  defaultValue?: any,
  optional?: boolean,
  binding?: ParameterBinding,
  source?: OptionSource,
};

class Builder {
  private readonly value: InternalBuilderState;

  constructor(value: InternalBuilderState = {}) {
    this.value = value;
    Object.freeze(this.value);
    Object.seal(this.value);
    Object.freeze(this);
    Object.seal(this);
  }

  type(value: string): Builder {
    return new Builder({ ...this.value, type: value });
  }

  name(value: string): Builder {
    return new Builder({ ...this.value, name: value });
  }

  title(value: string): Builder {
    return new Builder({ ...this.value, title: value });
  }

  description(value: string): Builder {
    return new Builder({ ...this.value, description: value });
  }

  dataType(value: string): Builder {
    return new Builder({ ...this.value, dataType: value });
  }

  defaultValue(value: any): Builder {
    return new Builder({ ...this.value, defaultValue: value });
  }

  optional(value: boolean): Builder {
    return new Builder({ ...this.value, optional: value });
  }

  binding(value: ParameterBinding): Builder {
    return new Builder({ ...this.value, binding: value });
  }

  source(value: OptionSource): Builder {
    return new Builder({ ...this.value, source: value });
  }

  build(): OptionParameter {
    const { name, title, description, dataType, defaultValue, optional, binding, source } = this.value;

    return new OptionParameter(name, title, description, dataType, defaultValue, optional, source, binding);
  }
}

OptionParameter.Builder = Builder;
