import React from 'react';
import PropTypes from 'prop-types';

import CorrelationRule from './CorrelationRule';
import commonStyles from './commonStyles.css';

class CorrelationRules extends React.Component {
  static propTypes = {
    rules: PropTypes.array.isRequired,
    eventDefinitions: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
  };

  renderEventRule = (correlationRule, idx = 0) => {
    const { rules, eventDefinitions, onChange, onRemove } = this.props;

    return (
      <React.Fragment key={correlationRule.id}>
        <CorrelationRule number={idx + 1}
                         correlationRule={correlationRule}
                         eventDefinitions={eventDefinitions}
                         onChange={onChange}
                         onRemove={onRemove} />
        {idx + 1 < rules.length && <p className={commonStyles.followedBy}>Followed by</p>}
      </React.Fragment>
    );
  };

  render() {
    const { rules } = this.props;

    return rules.map(this.renderEventRule);
  }
}

export default CorrelationRules;
