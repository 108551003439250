import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ArchiveActions from 'archive/ArchiveActions';
import { Row, Col, MenuItem } from 'components/bootstrap';
import BootstrapModalForm from 'components/bootstrap/BootstrapModalForm';
import { Spinner } from 'components/common';

const StyledTextArea = styled.textarea`
  width: 100%;
  min-height: 400px;
`;

type Props = {
  query: string,
};

const ArchiveCatalogExportModal = ({ query }: Props) => {
  const [filenames, setFilenames] = useState<Array<string>>([]);
  const [showModal, setShowModal] = useState<boolean>(false);

  const close = () => setShowModal(false);

  const open = () => {
    setShowModal(true);

    ArchiveActions.exportFilenames(query)
      .then((response) => setFilenames(response.filenames));
  };

  const _onSubmit = (e) => {
    e.preventDefault();
    close();
  };

  const _isLoading = () => !filenames;

  const modalContent = () => {
    if (_isLoading()) {
      return <Spinner text="Loading filenames..." />;
    }

    return (
      <Row>
        <Col md={12}>
          <StyledTextArea value={filenames.join('\n')}
                          readOnly />
        </Col>
      </Row>
    );
  };

  return (
    <>
      <MenuItem onClick={open}>Filenames</MenuItem>

      <BootstrapModalForm show={showModal}
                          onCancel={close}
                          title="Catalog Filenames Export"
                          onSubmitForm={_onSubmit}
                          submitButtonText="Close">
        {modalContent()}
      </BootstrapModalForm>
    </>
  );
};

ArchiveCatalogExportModal.propTypes = {
  query: PropTypes.string.isRequired,
};

export default ArchiveCatalogExportModal;
