import Reflux from 'reflux';

const LicensesActions = Reflux.createActions({
  list: { asyncResult: true },
  listStatus: { asyncResult: true },
  save: { asyncResult: true },
  remove: { asyncResult: true },
  verify: { asyncResult: true },
});

export default LicensesActions;
