import type { Notification, Notifications } from 'theme/types';
import { qualifyUrl } from 'util/URLUtils';
import fetch, { fetchPeriodically } from 'logic/rest/FetchProvider';

export const createPublicNotification = (notification: Notification): Promise<Notification> => {
  const url = qualifyUrl('/plugins/org.graylog.plugins.customization/notifications');

  return fetch<Notification>('POST', url, notification);
};

export const updatePublicNotification = ({ id, notification }: { id: string, notification: Notification }): Promise<Notification> => {
  const url = qualifyUrl(`/plugins/org.graylog.plugins.customization/notifications/${id}`);

  return fetch<Notification>('PUT', url, notification);
};

export const deletePublicNotification = (id: string): Promise<void> => {
  const url = qualifyUrl(`/plugins/org.graylog.plugins.customization/notifications/${id}`);

  return fetch('DELETE', url);
};

export const fetchPublicNotifications = () => {
  const url = qualifyUrl('/plugins/org.graylog.plugins.customization/notifications');

  return fetchPeriodically<Notifications>('GET', url);
};
