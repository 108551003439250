import React from 'react';

import { SidebarProvider } from './context/Sidebar';
import { FormDataProvider } from './context/FormData';
import { StepsProvider } from './context/Steps';
import { AdvancedOptionsProvider } from './context/AdvancedOptions';
import Defender from './Defender';
import INITIAL_FORMDATA from './_initialFormData';

const EmbeddedDefenderApp: React.FC = () => {
  return (
    <StepsProvider>
      <FormDataProvider initialFormData={INITIAL_FORMDATA}>
        <SidebarProvider>
          <AdvancedOptionsProvider>
            <Defender externalInputSubmit={false} />
          </AdvancedOptionsProvider>
        </SidebarProvider>
      </FormDataProvider>
    </StepsProvider>
  );
};

export default EmbeddedDefenderApp;
