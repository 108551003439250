import * as React from 'react';
import styled from 'styled-components';

import { useGetLatestBundle } from 'illuminate/hooks/useBundlesAPI';

const Badge = styled.span`
  position: relative;

  &::after {
    display: 'block';
    content: ' ';
    position: absolute;
    width: 8px;
    height: 8px;

    background-color: ${({ theme }) => theme.colors.brand.primary};
    border-radius: 50%;
    top: 0;
    right: -12px;
  }
`;

function IlluminateLinkBadge({ text }: { text: string }) {
  const { version, isNew } = useGetLatestBundle();

  return isNew ? (
    <Badge title={`New Illuminate bundle version ${version}`}>{text}</Badge>
  ) : <span>{text}</span>;
}

export default IlluminateLinkBadge;
