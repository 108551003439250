import type { AWSSecurityLakeGenericInputCreateRequest, AWSSecurityLakeInputCreateRequest, FormDataType } from '../types';

export const toAWSSecurityLakeInputCreateRequest = ({
  awsSecurityLakeName,
  awsSecurityLakeStoreFullMessage,
  awsSecurityLakeThrottleEnabled,
  pollingInterval,
  awsSecurityLakeAccessKeyId,
  awsSecurityLakeSecretKey,
  securityLakeSqsQueueName,
  securityLakeRegion,
}: FormDataType): AWSSecurityLakeInputCreateRequest => ({
  name: awsSecurityLakeName.value,
  polling_interval: pollingInterval.value,
  polling_time_unit: 'MINUTES',
  store_full_message: !!awsSecurityLakeStoreFullMessage?.value,
  enable_throttling: !!awsSecurityLakeThrottleEnabled?.value,
  security_lake_queue_name: securityLakeSqsQueueName.value,
  aws_access_key: awsSecurityLakeAccessKeyId.value,
  aws_secret_key: awsSecurityLakeSecretKey.value,
  aws_region: securityLakeRegion.value,
});

export const toGenericInputCreateRequest = ({
  awsSecurityLakeName,
  awsSecurityLakeStoreFullMessage,
  awsSecurityLakeThrottleEnabled,
  awsSecurityLakeAccessKeyId,
  awsSecurityLakeSecretKey,
  pollingInterval,
  securityLakeSqsQueueName,
  securityLakeRegion,
}: FormDataType): AWSSecurityLakeGenericInputCreateRequest => ({
  type: 'org.graylog.enterprise.integrations.awssecuritylake.SecurityLakeInput',
  title: awsSecurityLakeName.value,
  global: false,
  configuration: {
    polling_interval: pollingInterval.value,
    polling_time_unit: 'MINUTES',
    store_full_message: !!awsSecurityLakeStoreFullMessage?.value,
    throttling_allowed: !!awsSecurityLakeThrottleEnabled?.value,
    security_lake_queue_name: securityLakeSqsQueueName.value,
    aws_access_key: awsSecurityLakeAccessKeyId.value,
    aws_secret_key: awsSecurityLakeSecretKey.value,
    aws_region: securityLakeRegion.value,
  },
});
