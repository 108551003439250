import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import defaultTo from 'lodash/defaultTo';
import max from 'lodash/max';
import get from 'lodash/get';
import without from 'lodash/without';
import moment from 'moment';

import { isPermitted } from 'util/PermissionsMixin';
import { getValueFromInput } from 'util/FormsUtils';
import generateId from 'logic/generateId';
import { Alert, Button, Col, ControlLabel, FormGroup, HelpBlock, Row, Input } from 'components/bootstrap';
import { extractDurationAndUnit } from 'components/common/TimeUnitInput';
import { TimeUnitInput, Spinner } from 'components/common';
import useCurrentUser from 'hooks/useCurrentUser';
import { getPathnameWithoutId } from 'util/URLUtils';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import useLocation from 'routing/useLocation';
import { TELEMETRY_EVENT_TYPE } from 'logic/telemetry/Constants';

import CorrelationRules from './CorrelationRules';
import CorrelationPreview from './CorrelationPreview';
import commonStyles from './commonStyles.css';

export const TIME_UNITS = ['HOURS', 'MINUTES', 'SECONDS'];

const getNewRule = () => ({ id: generateId(), occurrence: 1, event_creator_equals: '' });

type Rule = {
  event_creator_equals: string,
  id: string,
  occurrence: number,
};

type Source = {
  event_definition_id: string,
  title: string,
  event_definition_type: string,
};

type Config = {
  execute_every_ms: number,
  search_within_ms: number,
  rules: Rule[],
  sources: Source[],
  _is_scheduled: boolean,
};

type Validation = {
  errors: {
    search_within_ms: string,
    rules: string,
    execute_every_ms: string,

  },
};

type Props = {
  eventDefinition: {
    config: Config,
  },
  validation: Validation,
  eventDefinitions: {
    id: string,
    title: string,
    config: {
      type: string,
    },
  }[],
  onChange: (string, Config) => void,
};

const CorrelationForm = ({ eventDefinition, validation, eventDefinitions, onChange }: Props) => {
  const { execute_every_ms: executeEveryMs, search_within_ms: searchWithinMs } = eventDefinition.config;
  const searchWithin = extractDurationAndUnit(searchWithinMs, TIME_UNITS);
  const executeEvery = extractDurationAndUnit(executeEveryMs, TIME_UNITS);

  const [searchWithinMsDuration, setSearchWithinMsDuration] = useState(searchWithin.duration);
  const [searchWithinMsUnit, setSearchWithinMsUnit] = useState(searchWithin.unit);
  const [executeEveryMsDuration, setExecuteEveryMsDuration] = useState(executeEvery.duration);
  const [executeEveryMsUnit, setExecuteEveryMsUnit] = useState(executeEvery.unit);

  const currentUser = useCurrentUser();
  const { pathname } = useLocation();
  const sendTelemetry = useSendTelemetry();

  const updateSources = (config, currentEventDefinitionId, nextEventDefinitionId) => {
    const nextConfig = cloneDeep(config);
    const isEventDefinitionUsed = nextConfig.rules
      .filter((r) => r.event_creator_equals === currentEventDefinitionId).length > 1;

    if (!isEventDefinitionUsed) {
      // Event definition is not used in any rule, delete it from sources
      nextConfig.sources = nextConfig.sources.filter((s) => s.event_definition_id !== currentEventDefinitionId);
    }

    if (nextEventDefinitionId === undefined) {
      return nextConfig;
    }

    const isNextEventDefinitionInSources = nextConfig.sources.find((s) => s.event_definition_id === nextEventDefinitionId);

    if (!isNextEventDefinitionInSources) {
      // Add source with ID to new Event Definition
      const nextEventDefinition = eventDefinitions.find((d) => d.id === nextEventDefinitionId);

      if (!nextEventDefinition) {
        throw new Error(`Could not find event definition with id: ${nextEventDefinitionId}`);
      }

      const source = {
        title: nextEventDefinition.title,
        event_definition_type: nextEventDefinition.config.type,
        event_definition_id: nextEventDefinition.id,
      };
      nextConfig.sources.push(source);
    }

    return nextConfig;
  };

  const handleTimeRangeChange = (key) => (nextValue, nextUnit) => {
    if (key === 'search_within_ms') {
      if (nextUnit !== searchWithinMsUnit) {
        sendTelemetry(TELEMETRY_EVENT_TYPE.EVENTDEFINITION_CONDITION.CORRELATION_WITHIN_THE_LAST_UNIT_CHANGED, {
          app_pathname: getPathnameWithoutId(pathname),
          app_section: 'event-definition-condition',
          app_action_value: 'searchWithinMsUnit-select',
          new_unit: nextUnit,
        });
      }

      setSearchWithinMsDuration(nextValue);
      setSearchWithinMsUnit(nextUnit);
    } else {
      if (nextUnit !== executeEveryMsUnit) {
        sendTelemetry(TELEMETRY_EVENT_TYPE.EVENTDEFINITION_CONDITION.CORRELATION_EXECUTE_EVERY_UNIT_CHANGED, {
          app_pathname: getPathnameWithoutId(pathname),
          app_section: 'event-definition-condition',
          app_action_value: 'executeEveryMsUnit-select',
          new_unit: nextUnit,
        });
      }

      setExecuteEveryMsDuration(nextValue);
      setExecuteEveryMsUnit(nextUnit);
    }

    const config = cloneDeep(eventDefinition.config);
    config[key] = moment.duration(max([nextValue, 1]), nextUnit).asMilliseconds();
    onChange('config', config);
  };

  const handleRuleChange = (id, nextRule) => {
    let config = cloneDeep(eventDefinition.config);
    const rule = config.rules.find((r) => r.id === id);
    const ruleIdx = config.rules.indexOf(rule);

    if (rule && rule.event_creator_equals !== nextRule.event_creator_equals) {
      config = updateSources(config, rule.event_creator_equals, nextRule.event_creator_equals);
    }

    config.rules[ruleIdx] = nextRule;
    onChange('config', config);
  };

  const handleConfigChange = (event) => {
    const { name } = event.target;
    const config = cloneDeep(eventDefinition.config);
    config[name] = getValueFromInput(event.target);

    if (name === '_is_scheduled') {
      sendTelemetry(TELEMETRY_EVENT_TYPE.EVENTDEFINITION_CONDITION.CORRELATION_EXECUTED_AUTOMATICALLY_TOGGLED, {
        app_pathname: getPathnameWithoutId(pathname),
        app_section: 'event-definition-condition',
        app_action_value: 'enable-checkbox',
        is_scheduled: config[name],
      });
    }

    onChange('config', config);
  };

  const handleAddEvent = () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.EVENTDEFINITION_CONDITION.CORRELATION_ADD_EVENT_CLICKED, {
      app_pathname: getPathnameWithoutId(pathname),
      app_section: 'event-definition-condition',
      app_action_value: 'add-event-button',
    });

    const config = cloneDeep(eventDefinition.config);
    config.rules.push(getNewRule());
    onChange('config', config);
  };

  const handleRemoveEvent = (id) => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.EVENTDEFINITION_CONDITION.CORRELATION_REMOVE_EVENT_CLICKED, {
      app_pathname: getPathnameWithoutId(pathname),
      app_section: 'event-definition-condition',
      app_action_value: 'remove-event-button',
    });

    let config = cloneDeep(eventDefinition.config);
    const rule = config.rules.find((r) => r.id === id);
    config.rules = without(config.rules, rule);

    if (rule && rule.event_creator_equals) {
      config = updateSources(config, rule.event_creator_equals, undefined);
    }

    onChange('config', config);
  };

  const missingEventPermissions = () => {
    const rules = eventDefinition?.config?.rules;
    const eventDefinitionIds = rules.map((r) => r.event_creator_equals);

    return eventDefinitionIds.filter(
      (eventId) => !isPermitted(currentUser?.permissions, `eventdefinitions:read:${eventId}`),
    );
  };

  if (!eventDefinition.config || !eventDefinition.config.rules || eventDefinition.config.rules.length === 0) {
    return <Spinner />;
  }

  const missingEventDefinitionPermissions = missingEventPermissions();

  if (missingEventDefinitionPermissions.length > 0) {
    return (
      <Alert bsStyle="danger">
        Missing Event Definition Permissions for:<br />
        {missingEventDefinitionPermissions.join(', ')}
      </Alert>
    );
  }

  const ruleErrors = get(validation, 'errors.rules', []);
  const eventCreatorErrors = get(validation, 'errors.event_creator_equals', []);
  const errors = [...ruleErrors, ...eventCreatorErrors];

  return (
    <Row>
      <Col md={7} lg={6}>
        <h2 className={commonStyles.title}>Event Correlation Rules</h2>
        <p>
          Event Correlation Rules allow you to analyze complex sequences of Events to identify meaningful incidents.
        </p>
        {eventDefinitions.length === 0 ? (
          <Alert bsStyle="info">
            Event Correlation Rules work on top of other Events. Please start creating Filter & Aggregation Event
            Definitions first.
          </Alert>
        ) : (
          <div className={commonStyles.correlationForm}>
            <fieldset>
              <div className={`form-inline ${commonStyles.inlineFormGroup}`}>
                <FormGroup validationState={validation.errors.search_within_ms ? 'error' : null}>
                  <ControlLabel>The following Sequence of Events must be satisfied within</ControlLabel>
                  <TimeUnitInput update={handleTimeRangeChange('search_within_ms')}
                                 value={searchWithinMsDuration}
                                 unit={searchWithinMsUnit}
                                 units={TIME_UNITS}
                                 clearable
                                 required />
                  {validation.errors.search_within_ms && (
                    <HelpBlock>{validation.errors.search_within_ms[0]}</HelpBlock>
                  )}
                </FormGroup>
              </div>

              <div className={`form-inline ${commonStyles.inlineFormGroup}`}>
                <FormGroup validationState={validation.errors.execute_every_ms ? 'error' : null}>
                  <ControlLabel>The correlation should execute every</ControlLabel>
                  <TimeUnitInput update={handleTimeRangeChange('execute_every_ms')}
                                 value={executeEveryMsDuration}
                                 unit={executeEveryMsUnit}
                                 units={TIME_UNITS}
                                 clearable
                                 required />
                  {validation.errors.execute_every_ms && (
                    <HelpBlock>{validation.errors.execute_every_ms[0]}</HelpBlock>
                  )}
                </FormGroup>
              </div>
              <Input id="schedule-checkbox"
                     type="checkbox"
                     name="_is_scheduled"
                     label="Enable"
                     help="Should this event definition be executed automatically?"
                     checked={defaultTo(eventDefinition.config._is_scheduled, true)}
                     onChange={handleConfigChange} />
            </fieldset>

            {errors.length > 0 && (
              <Alert bsStyle="danger" className={commonStyles.validationSummary} title="Correlation rules with errors">
                <p>Please correct the following errors before saving this Event Definition:</p>
                <ul>
                  {errors.map((error) => <li key={error}>{error}</li>)}
                </ul>
              </Alert>
            )}

            <CorrelationRules rules={eventDefinition.config.rules}
                              eventDefinitions={eventDefinitions}
                              onChange={handleRuleChange}
                              onRemove={handleRemoveEvent} />
            <Button bsStyle="success" onClick={handleAddEvent}>Add Event</Button>
          </div>
        )}
      </Col>
      <Col md={5} lg={5} lgOffset={1}>
        {eventDefinitions.length > 0 && (
          <CorrelationPreview rules={eventDefinition.config.rules}
                              eventDefinitions={eventDefinitions} />
        )}
      </Col>
    </Row>
  );
};

CorrelationForm.propTypes = {
  eventDefinition: PropTypes.object.isRequired,
  validation: PropTypes.object.isRequired,
  eventDefinitions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

CorrelationForm.defaultConfig = {
  sources: [],
  rules: [getNewRule()],
  search_within_ms: 5 * 60 * 1000,
  execute_every_ms: 5 * 60 * 1000,
  _is_scheduled: true,
};

export default CorrelationForm;
