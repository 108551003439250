const NAV_BAR_WIDTH = 200;

const securityContentThemeVariables = {
  measures: {
    SIDE_NAV_WIDTH: NAV_BAR_WIDTH,
    CLOSED_NAV_LEFT: NAV_BAR_WIDTH,
  },
  colors: {
    primaryDull: 'rgba(255,54,51, 0.2)',
  },
};

export default securityContentThemeVariables;
