import * as React from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import { useState, useEffect, useCallback, useRef } from 'react';

const WIDGET_ASPECT_RATIO = 10 / 16;

type Dimensions = {
  height: number | undefined,
  width: number | undefined,
}

type Props = {
  children: (dimensions: Dimensions) => React.ReactElement,
  onDimensionsChange: (dimensions: Dimensions) => void,
}

const useUpdateOnResize = ({
  container,
  dimensions,
  setDimensions,
  areDimensionsSet,
  onDimensionsChange,
}: {
  container: React.RefObject<HTMLDivElement>,
  dimensions: Dimensions,
  setDimensions: (dimensions: Dimensions) => void,
  areDimensionsSet: () => boolean,
  onDimensionsChange: (dimensions: Dimensions) => void,
}) => {
  const calculateContainerDimensions = useCallback((isUpdate: boolean) => {
    if (!container.current) {
      return {
        width: undefined,
        height: undefined,
      };
    }

    // On first render we need to include the whole available space in consideration
    const widthKey = isUpdate ? 'clientWidth' : 'scrollWidth';

    const width = container.current[widthKey];

    return {
      height: width * WIDGET_ASPECT_RATIO,
      width: width,
    };
  }, [container]);

  const propagateDimensions = useCallback((isUpdate: boolean) => {
    const newDimensions = calculateContainerDimensions(isUpdate);

    if (dimensions.height !== newDimensions.height || dimensions.width !== newDimensions.width) {
      setDimensions(newDimensions);
      onDimensionsChange(newDimensions);
    }
  }, [calculateContainerDimensions, dimensions.height, dimensions.width, setDimensions, onDimensionsChange]);

  useEffect(() => {
    const onResize = throttle(() => {
      if (areDimensionsSet()) {
        propagateDimensions(true);
      }
    }, 100);

    propagateDimensions(false);
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [propagateDimensions, areDimensionsSet]);
};

const ReportingWidgetContainer = ({ children, onDimensionsChange }: Props) => {
  const [dimensions, setDimensions] = useState<Dimensions>({ width: undefined, height: undefined });
  const container = useRef(null);

  const areDimensionsSet = React.useCallback(() => dimensions.height !== undefined && dimensions.width !== undefined, [dimensions]);

  useUpdateOnResize({ container, dimensions, setDimensions, areDimensionsSet, onDimensionsChange });

  return (
    <div ref={container} style={{ height: '100%', width: '100%' }}>
      {areDimensionsSet() && children(dimensions)}
    </div>
  );
};

ReportingWidgetContainer.propTypes = {
  onDimensionsChange: PropTypes.func,
  children: PropTypes.node.isRequired,
};

ReportingWidgetContainer.defaultProps = {
  onDimensionsChange: () => {},
};

export default ReportingWidgetContainer;
