import type { Sort } from 'stores/PaginationTypes';

export const ENTITY_TABLE_ID = 'archive_configs';
export const DEFAULT_LAYOUT = {
  pageSize: 20,
  sort: { attributeId: 'archive_name', direction: 'asc' } as Sort,
  displayedColumns: ['archive_name', 'streams', 'enabled'],
  columnsOrder: ['archive_name', 'streams', 'enabled'],
};

export const ADDITIONAL_ATTRIBUTES = [
  { id: 'streams', title: 'Streams', hidden: false },
];
