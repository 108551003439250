import { toDateObject, adjustFormat } from 'util/DateTime';

const toUTCFrequencyTime = (time: string = '', timezone: string = 'UTC') => {
  const [hours, minutes] = time.split(':');

  const localDateTime = toDateObject(new Date(), undefined, timezone ?? 'UTC')
    .hours(Number(hours))
    .minutes(Number(minutes));

  return adjustFormat(localDateTime, 'hourAndMinute', 'UTC');
};

export default toUTCFrequencyTime;
