/*
 * Copyright (C) 2020 Graylog, Inc.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the Server Side Public License, version 1,
 * as published by MongoDB, Inc.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * Server Side Public License for more details.
 *
 * You should have received a copy of the Server Side Public License
 * along with this program. If not, see
 * <http://www.mongodb.com/licensing/server-side-public-license>.
 */

import React from 'react';
import styled from 'styled-components';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';

import { Input, ControlLabel, Label } from 'components/bootstrap';
import { Select, ConfirmDialog } from 'components/common';
import {
  useGetPriorities,
  useGetStatuses,
  useGetUsers,
  useGetTeams,
  useGetInvestigations,
} from 'security-app/hooks/useInvestigationsAPI';
import type {
  PriorityAPIType,
  StatusAPIType,
  UserAPIType,
  TeamAPIType,
  InvestigationAPIType,
} from 'security-app/hooks/api/investigationsAPI.types';
import { getValueFromInput } from 'util/FormsUtils';
import { defaultCompare } from 'logic/DefaultCompare';
import type {
  CreateInvestigationNotificationValidationType,
  CreateInvestigationNotificationConfigType,
} from 'security-app/types';

const DefaultLabel = styled(Label)`
  display: inline-flex;
  margin-left: 5px;
  vertical-align: inherit;
`;

const SelectWrapper = styled.div`
  margin-bottom: 1rem;
`;

type Props = {
  config: CreateInvestigationNotificationConfigType,
  validation: CreateInvestigationNotificationValidationType,
  onChange: any,
};

type OptionType = {
  value: string,
  label: string,
  default?: boolean,
};

function CreateInvestigationNotificationForm({ config, validation, onChange }: Props) {
  const [showConfirmationDialog, setShowConfirmationDialog] = React.useState(false);

  const { investigations } = useGetInvestigations({
    direction: null,
    filters: {},
    orderBy: null,
    page: 1,
    query: null,
    perPage: 1000,
  });

  const investigationOptions = React.useMemo(() => (
    investigations.map((investigation: InvestigationAPIType) => ({
      value: investigation.id,
      label: investigation.name,
    }))
      .sort((a: { label: string }, b: { label: string }) => defaultCompare(a.label, b.label))
  ), [investigations]);

  const { priorities, loadingPriorities } = useGetPriorities();
  const priorityOptions = React.useMemo(() => (
    priorities.map((priority: PriorityAPIType) => ({
      value: priority.priority,
      label: priority.text,
      default: priority.default,
    }))
      .sort((a: { value: number; }, b: { value: number; }) => defaultCompare(a.value, b.value))
  ), [priorities]);

  const { statuses, loadingStatuses } = useGetStatuses();
  const statusOptions = React.useMemo(() => (
    statuses.map((status: StatusAPIType) => ({ value: status.status, label: status.status, default: status.default }))
      .sort((a: { value: string; }, b: { value: string; }) => defaultCompare(a.value, b.value))
  ), [statuses]);

  const { users } = useGetUsers();
  const { teams } = useGetTeams();
  const assignToOptions = React.useMemo(() => ([
    { value: 'Users', label: 'Users', disabled: true },
    ...users.map((user: UserAPIType) => ({ value: user.id, label: user.full_name }))
      .sort((a: { label: string; }, b: { label: string; }) => defaultCompare(a.label, b.label)),

    { value: 'Teams', label: 'Teams', disabled: true },
    ...teams.map((team: TeamAPIType) => ({ value: team.id, label: team.name }))
      .sort((a: { label: string; }, b: { label: string; }) => defaultCompare(a.label, b.label)),
  ]), [users, teams]);

  const renderOption = (option: OptionType) => (
    <span key={option.value} title={option.value}>
      {option.label}{option.default && <DefaultLabel bsStyle="primary" bsSize="xsmall">Default</DefaultLabel>}
    </span>
  );

  const propagateChange = (key, value) => {
    const nextConfig = cloneDeep(config);
    nextConfig[key] = value;
    if (key === 'create_new_investigation' && value === true) nextConfig.investigation_id = null;
    onChange(nextConfig);
  };

  const handleChange = (event) => {
    const { name } = event.target;
    propagateChange(name, getValueFromInput(event.target));
    if (name === 'create_new_investigation' && getValueFromInput(event.target) === true) setShowConfirmationDialog(true);
  };

  const cancelCreateNewInvestigationForAll = (): void => {
    propagateChange('create_new_investigation', false);
    setShowConfirmationDialog(false);
  };

  const confirmCreateNewInvestigationForAll = (): void => {
    propagateChange('create_new_investigation', true);
    setShowConfirmationDialog(false);
  };

  return (
    <div>
      {!loadingPriorities && !loadingStatuses && (
        <>
          <Input id="notification-investigation-create-new"
                 name="create_new_investigation"
                 label="Create a New Investigation for Every Alert"
                 type="checkbox"
                 bsStyle={validation.errors.create_new_investigation ? 'error' : null}
                 help={get(validation, 'errors.create_new_investigation[0]', 'Create a new investigation any time an alert is triggered')}
                 checked={!!config.create_new_investigation}
                 onChange={handleChange} />
          {!config.create_new_investigation && (
            <SelectWrapper>
              <ControlLabel>Investigation</ControlLabel>
              <Select id="notification-investigation-id"
                      inputProps={{ name: 'investigation_id' }}
                      placeholder="Select an investigation..."
                      options={investigationOptions}
                      value={config.investigation_id}
                      onChange={(value: string) => handleChange({ target: { name: 'investigation_id', value: value } })}
                      optionRenderer={(option: OptionType) => renderOption(option)} />
            </SelectWrapper>
          )}
          {!config.investigation_id && (
            <>
              <SelectWrapper>
                <ControlLabel>Assign Investigation To</ControlLabel>
                <Select id="notification-investigation-assigned-to"
                        inputProps={{ name: 'investigation_assigned_to' }}
                        placeholder="Assign investigation to..."
                        options={assignToOptions}
                        value={config.investigation_assigned_to}
                        onChange={(value: string) => handleChange({
                          target: {
                            name: 'investigation_assigned_to',
                            value: value,
                          },
                        })}
                        optionRenderer={(option: OptionType) => renderOption(option)} />
              </SelectWrapper>
              <SelectWrapper>
                <ControlLabel>Investigation Priority</ControlLabel>
                <Select id="notification-investigation-investigation-priority"
                        inputProps={{ name: 'investigation_priority' }}
                        placeholder="Select a priority..."
                        options={priorityOptions}
                        value={config.investigation_priority}
                        onChange={(value: number) => handleChange({
                          target: {
                            name: 'investigation_priority',
                            value: +value,
                          },
                        })}
                        optionRenderer={(option: OptionType) => renderOption(option)} />
              </SelectWrapper>
              <SelectWrapper>
                <ControlLabel>Investigation Status</ControlLabel>
                <Select id="notification-investigation-investigation-status"
                        inputProps={{ name: 'investigation_status' }}
                        placeholder="Select a status..."
                        options={statusOptions}
                        value={config.investigation_status}
                        onChange={(value: string) => handleChange({ target: { name: 'investigation_status', value: value } })}
                        optionRenderer={(option: OptionType) => renderOption(option)} />
              </SelectWrapper>
            </>
          )}
          <ConfirmDialog show={showConfirmationDialog}
                         hideCancelButton
                         title="Are you sure you want to enable this?"
                         onCancel={cancelCreateNewInvestigationForAll}
                         onConfirm={confirmCreateNewInvestigationForAll}>
            <div>Enabling the creation of a new investigation for every alert triggered may result in a large number of investigations created. Are you sure this is the desired outcome you want?</div>
          </ConfirmDialog>
        </>
      )}
    </div>
  );
}

export default CreateInvestigationNotificationForm;
