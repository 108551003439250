import { useLayoutEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';

import { LOADING_ROW_HEIGHT } from '../LogViewTable';
import { HEADER_HEIGHT } from '../LogViewHeader';

export default ({
  tableRef,
  pageRefs,
  lastPositionRef,
  scrollPositionUpdate,
  setFinishedScrollPositionUpdate,
}) => {
  const prevScrollPositionUpdate = useRef(scrollPositionUpdate);

  useLayoutEffect(() => {
    let scrollPositionAnimation;

    if (!isEqual(prevScrollPositionUpdate.current, scrollPositionUpdate)) {
      const { targetPage, direction, targetPageOffsetTop, loadedAllPrevMessages } = scrollPositionUpdate;

      const updateScrollPosition = () => {
        const targetPageRef = targetPage ? pageRefs.current[targetPage] : undefined;
        const loadingRowHeight = loadedAllPrevMessages ? 0 : LOADING_ROW_HEIGHT;

        if (targetPageRef) {
          if (direction === 'up') {
            // eslint-disable-next-line no-param-reassign
            tableRef.current.scrollTop = targetPageRef.offsetTop + lastPositionRef.current - (loadingRowHeight + HEADER_HEIGHT);
          }

          if (direction === 'down') {
            // eslint-disable-next-line no-param-reassign
            tableRef.current.scrollTop = lastPositionRef.current - targetPageOffsetTop + (loadingRowHeight + HEADER_HEIGHT);
          }
        }

        prevScrollPositionUpdate.current = scrollPositionUpdate;
      };

      scrollPositionAnimation = requestAnimationFrame(updateScrollPosition);
      setFinishedScrollPositionUpdate(true);
    }

    return () => {
      if (scrollPositionAnimation) {
        cancelAnimationFrame(scrollPositionAnimation);
      }
    };
  }, [tableRef, pageRefs, prevScrollPositionUpdate, scrollPositionUpdate, lastPositionRef, setFinishedScrollPositionUpdate]);
};
