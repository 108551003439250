import Immutable from 'immutable';
import { useFormikContext } from 'formik';

import type { SelectedSearchFilter } from 'search-filter/types';

const useSearchFiltersFormState = () => {
  const {
    values: {
      searchFilters = Immutable.OrderedMap(),
    },
  } = useFormikContext<{
    searchFilters: Immutable.OrderedMap<string, SelectedSearchFilter> | undefined
  }>();

  return searchFilters;
};

export default useSearchFiltersFormState;
