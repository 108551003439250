import type { BulkActions } from 'security-app/types';

const API_PLUGIN_ROUTE = '/plugins/org.graylog.plugins';

const EnterpriseApiRoutes = {
  Illuminate: {
    bundles: {
      index: () => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles` }),
      one: (bundleId: string) => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/${bundleId}` }),
      status: () => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/status` }),
      upload: () => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/upload` }),
      packs: (bundleId: string) => ({
        index: () => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/${bundleId}/packs` }),
        update: () => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/${bundleId}/packs` }),
      }),
      hub: {
        index: () => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/hub` }),
        latest: () => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/hub/latest` }),
        install: (bundleId: string) => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/hub/${bundleId}` }),
        releaseNotes: (version: string) => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/hub/release_notes/${version}` }),
      },
    },
  },
  SecurityApp: {
    anomalyConfiguration: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.anomaly/detectors/config` }),
    updateAnomalyConfiguration: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.anomaly/detectors/update_status` }),
    updateAnomalyEventDefinition: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.anomaly/detectors/event_definition/update_status` }),
    fetchDetectorDefinition: (id: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.anomaly/detectors/config/${id}` }),
    updateDetectorDefinition: (id: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.anomaly/detectors/config/${id}` }),
    createDetectorDefinition: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.anomaly/detectors/config` }),
    deleteDetectorDefinition: (id: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.anomaly/detectors/config/${id}` }),
    createSigmaRule: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules` }),
    uploadSigmaRules: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules/upload` }),
    validateSigmaRule: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules/validate` }),
    validateSigmaRuleZip: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules/validate_zip` }),
    allSigmaRules: (options: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules?${options}` }),
    oneSigmaRule: (id: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules/${id}` }),
    importRepoSigmaRule: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules/import` }),
    toggleSigmaRule: (id: string, action: 'enable' | 'disable') => (
      { url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules/${id}/${action}` }
    ),
    sigmaBulkOperation: (action: BulkActions) => (
      { url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules/bulk/${action}` }
    ),
    sigmaGitRepos: (options?: string) => ({
      url: options
        ? `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/repositories/?${options}`
        : `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/repositories/`,
    }),
    sigmaGitRepo: (repoId: string, path: string = '', query: string = '') => {
      const route = `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/repositories/${repoId}`;

      const params = [];
      if (path) params.push(`path=${path}`);
      if (query) params.push(`query=${query}`);

      return { url: params.length > 0 ? `${route}?${encodeURI(params.join('&'))}` : route };
    },
    allSigmaRepos: (options: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/repositories?${options}` }),
    bulkDeleteSigmaRepos: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/repositories/bulk/delete/` }),
    defaultSigmaRepo: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/repositories/default` }),
    downloadSigmaRule: (params: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/rules/download?${params}` }),
    importAllRules: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/repositories/import_all` }),
    refreshRepoRules: (id: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.sigma/sigma/repositories/refresh/${id}` }),
    Investigations: {
      index: (params: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations?${params}` }),
      update: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations` }),
      archive: (invId: string, archive: boolean) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/archive/${invId}/${archive}` }),
      bulkArchive: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/archive` }),
      new: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations` }),
      priorities: {
        index: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/priorities?page=1&per_page=10000&sort=text&direction=asc` }),
        add: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/priorities` }),
        reprioritize: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/priorities/reprioritize` }),
        update: (priorityId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/priorities/${priorityId}` }),
        delete: (priorityId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/priorities/${priorityId}` }),
        setDefault: (priorityId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/priorities/${priorityId}/default` }),
        removeDefault: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/priorities/default` }),
      },
      statuses: {
        index: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/statuses?page=1&per_page=10000&sort=status&direction=asc` }),
        add: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/statuses` }),
        update: (statusId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/statuses/${statusId}` }),
        delete: (statusId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/statuses/${statusId}` }),
        setDefault: (statusId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/statuses/${statusId}/default` }),
        removeDefault: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/statuses/default` }),
      },
      singleInvestigation: (invId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/${invId}` }),
      bulkDelete: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/bulk/delete` }),
      addEvidenceToInvestigation: (invId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/evidence/${invId}` }),
      bulkAddEvidenceToInvestigation: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/bulk/evidence/add` }),
      removeEvidenceFromInvestigation: (invId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/evidence/remove/${invId}` }),
      addNoteToInvestigation: (invId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/${invId}/notes` }),
      editInvestigationNote: (invId: string, noteId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/${invId}/notes/${noteId}` }),
      deleteInvestigationNote: (invId: string, noteId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/${invId}/notes/${noteId}` }),
      fetchUsers: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/users` }),
      fetchTeams: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/teams` }),
      fetchConfig: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/config` }),
      enableNotifications: (enabled: boolean) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.investigations/investigations/config/notifications/${enabled}` }),
    },
    Teams: {
      index: () => ({ url: `${API_PLUGIN_ROUTE}.security/teams?page=1&per_page=10000` }),
    },
    Assets: {
      index: (params: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets?${params}` }),
      singleAsset: (assetId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/${assetId}` }),
      assetIdLookup: (query: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets?filters=id:${query}` }),
      new: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets` }),
      priorities: {
        index: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/priorities?page=1&per_page=10000&direction=asc` }),
        add: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/priorities` }),
        reprioritize: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/priorities/reprioritize` }),
        update: (priorityId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/priorities/${priorityId}` }),
        delete: (priorityId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/priorities/${priorityId}` }),
      },
      categories: {
        index: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/categories?page=1&per_page=10000&sort=status&direction=asc` }),
        add: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/categories` }),
        update: (categoryId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/categories/${categoryId}` }),
        delete: (categoryId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/categories/${categoryId}` }),
      },
      sources: {
        index: (params: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/sources?${params}` }),
        new: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/sources` }),
        details: (sourceId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/sources/${sourceId}` }),
        update: (sourceId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/sources/${sourceId}` }),
        delete: (sourceId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/sources/${sourceId}` }),
        testNew: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/sources/test` }),
        testSaved: (sourceId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/sources/${sourceId}/test` }),
        bulkTest: () => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/sources/bulk/test` }),
        mappings: {
          index: (sourceId: string, params: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/sources/${sourceId}/import-backends?${params}` }),
          new: (sourceId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/sources/${sourceId}/import-backends` }),
          details: (sourceId: string, mappingId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/sources/${sourceId}/import-backends/${mappingId}` }),
          update: (sourceId: string, mappingId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/sources/${sourceId}/import-backends/${mappingId}` }),
          delete: (sourceId: string, mappingId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/sources/${sourceId}/import-backends/${mappingId}` }),
          testNew: (sourceId: string, mappingId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/sources/${sourceId}/import-backends/use/${mappingId}/test` }),
          testSaved: (sourceId: string, mappingId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/sources/${sourceId}/import-backends/use/${mappingId}/test` }),
          import: (sourceId: string, mappingId: string) => ({ url: `${API_PLUGIN_ROUTE}.securityapp.asset/assets/sources/${sourceId}/import-backends/use/${mappingId}/import` }),
        },
      },
    },
  },
  ContentUI: {
    eventTypeCategory: () => ({ url: `${API_PLUGIN_ROUTE}.contentui/event_type_category/ui_mapping` }),
    externalValueActions: () => ({ url: `${API_PLUGIN_ROUTE}.contentui/external_actions/ui_mapping` }),
  },
};

export default EnterpriseApiRoutes;
