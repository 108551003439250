import * as React from 'react';

import { StyledCheckbox } from './GLCheckbox.styles';

const GLCheckbox = (
  props: React.HTMLProps<HTMLInputElement>,
  ref: React.Ref<HTMLInputElement>,
) => (
  <StyledCheckbox>
    <input ref={ref} type="checkbox" {...props} />
    <div className="styled-checkbox" />
  </StyledCheckbox>
);

export default React.forwardRef(GLCheckbox);
