import * as Immutable from 'immutable';

import Parameters from 'enterprise/parameters/components/Parameters';
import OptionParameter from 'enterprise/parameters/components/option/OptionParameter';
import useParameters from 'views/hooks/useParameters';
import ParameterBinding from 'views/logic/parameters/ParameterBinding';
import type Parameter from 'views/logic/parameters/Parameter';
import type { SearchBarControl } from 'views/types';
import type { AppDispatch } from 'stores/useAppDispatch';
import { setParameterValues } from 'views/logic/slices/searchExecutionSlice';

const parameterIsRequired = (parameter: Parameter) => (parameter.needsBinding && !parameter.optional) && parameter.type !== OptionParameter.type;

const onParameterSubmit = async ({ parameterBindings }: { parameterBindings?: { [parameterName: string]: string | number | boolean } }, dispatch: AppDispatch) => {
  if (parameterBindings) {
    const newParameterBindings: Immutable.Map<string, any> = Immutable.Map(Object.entries(parameterBindings ?? {}));

    await dispatch(setParameterValues(newParameterBindings));
  }

  return Promise.resolve(undefined);
};

const useInitialParameterValues = () => {
  const { parameterBindings } = useParameters();

  return ({
    parameterBindings: parameterBindings.mapEntries(([fieldName, { value }]) => ([fieldName, value])).toObject(),
  });
};

const parameterMap = (parameters: Immutable.Set<Parameter> = Immutable.Set()) => Immutable.Map<string, Parameter>(parameters.map((p) => [p.name, p]));
const parameterSearchBarControls: SearchBarControl = {
  id: 'parameter-input-bar',
  component: Parameters,
  useInitialSearchValues: useInitialParameterValues,
  useInitialDashboardWidgetValues: useInitialParameterValues,
  onSearchSubmit: onParameterSubmit,
  onDashboardWidgetSubmit: onParameterSubmit,
  validationPayload: (values: { parameterBindings }, { view }) => {
    const parameters = parameterMap(view?.search?.parameters);
    const parameterBindings = Object.fromEntries(Object.entries(values.parameterBindings ?? {}).map(([fieldName, value]) => [fieldName, ParameterBinding.forValue(value)]));

    return ({ parameters: parameters.toArray(), parameter_bindings: parameterBindings });
  },
  onValidate: ({ parameterBindings }, { view }) => {
    const parameters = parameterMap(view?.search?.parameters);
    const parametersWhichNeedABinding = parameters?.filter(parameterIsRequired) ?? Immutable.List();
    const parameterErrors = parametersWhichNeedABinding.map(({ name }) => [name, !parameterBindings?.[name] && 'Parameter value is required']).filter(([_name, error]) => !!error);

    if (parameterErrors.size) {
      return { parameterBindings: Object.fromEntries(parameterErrors.toArray()) };
    }

    return {};
  },
  placement: 'right',
} as const;

export default parameterSearchBarControls;
