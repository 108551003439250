import __request__ from 'routing/request';
interface integerMap {
    readonly [_key: string]: number;
}
/**
 * List current status of ServiceManager
 */
export function list(): Promise<integerMap> {
    return __request__('GET', '/system/serviceManager', null, {}, {
        'Accept': ['application/json']
    });
}
