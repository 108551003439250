import startCase from 'lodash/startCase';

import ValueParameter from 'views/logic/parameters/ValueParameter';
import ParameterBinding from 'views/logic/parameters/ParameterBinding';
import type Parameter from 'views/logic/parameters/Parameter';

const newParameterState = (parameterName: string): Parameter => new ValueParameter(
  parameterName,
  startCase(parameterName),
  '',
  'any',
  '',
  false,
  ParameterBinding.empty(),
);

export default newParameterState;
