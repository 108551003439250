import * as React from 'react';

import type { ColumnRenderers } from 'components/common/EntityDataTable';
import type { InstantArchive } from 'instant-archiving/Types';
import RestoredArchivesCell from 'instant-archiving/InstantArchivingOverview/cells/RestoredArchivesCell';
import ArchiverStatus from 'instant-archiving/InstantArchivingOverview/cells/ArchiverStatus';
import { Timestamp } from 'components/common';
import { Link } from 'components/common/router';
import { INSTANT_ARCHIVING_ROUTES } from 'instant-archiving/Constants';

const customColumnRenderers = (): ColumnRenderers<InstantArchive> => ({
  attributes: {
    archive_name: {
      renderCell: (archive_name: string, archive) => (
        <Link to={INSTANT_ARCHIVING_ROUTES.ARCHIVE.show(archive.id)}>{archive_name}</Link>
      ),
    },
    restored_indices: {
      renderCell: (_restored_indices: string, archive) => <RestoredArchivesCell archive={archive} />,
    },
    archiver_status: {
      renderCell: (_archiver_status: string, archive) => <ArchiverStatus archive={archive} />,
    },
    timestamp_from: {
      renderCell: (_timestamp_from: string, archive) => archive?.timestamp_from
        && <Timestamp dateTime={archive.timestamp_from} />,
    },
    timestamp_to: {
      renderCell: (_timestamp_to: string, archive) => archive?.timestamp_to
        && <Timestamp dateTime={archive.timestamp_to} />,
    },
  },
});

export default customColumnRenderers;
