import __request__ from 'routing/request';
interface SystemJobSummary {
    readonly job_status: 'runnable' | 'running' | 'complete' | 'paused' | 'error' | 'cancelled';
    readonly provides_progress: boolean;
    readonly execution_duration: string;
    readonly name: string;
    readonly is_cancelable: boolean;
    readonly description: string;
    readonly started_at: string;
    readonly id: string;
    readonly percent_complete: number;
    readonly info: string;
    readonly node_id: string;
}
interface SystemJobSummaryArrayMapMap {
    readonly [_key: string]: SystemJobSummaryArrayMap;
}
interface SystemJobSummaryArrayMap {
    readonly [_key: string]: SystemJobSummaryArray;
}
type SystemJobSummaryArray = SystemJobSummary[];
/**
 * List currently running jobs
 */
export function list(): Promise<SystemJobSummaryArrayMapMap> {
    return __request__('GET', '/cluster/jobs', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get job with the given ID
 */
export function getJob(jobId: string): Promise<SystemJobSummary> {
    return __request__('GET', `/cluster/jobs/${jobId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Cancel job with the given ID
 */
export function cancelJob(jobId: string): Promise<SystemJobSummary> {
    return __request__('DELETE', `/cluster/jobs/${jobId}`, null, {}, {
        'Accept': ['application/json']
    });
}
