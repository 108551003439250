import __request__ from 'routing/request';
interface stringMap {
    readonly [_key: string]: string;
}
interface FieldTypeChangeRequest {
    readonly rotate: boolean;
    readonly field: string;
    readonly index_sets: string[];
    readonly type: string;
}
/**
 * Change field type for certain index sets
 */
export function changeFieldType(request?: FieldTypeChangeRequest): Promise<unknown> {
    return __request__('PUT', '/system/indices/mappings', request, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get list of all types valid inside the indexer
 */
export function getAllFieldTypes(): Promise<stringMap> {
    return __request__('GET', '/system/indices/mappings/types', null, {}, {
        'Accept': ['application/json']
    });
}
