import React from "react";
import PageHeader from "components/common/PageHeader";

import EmbeddedAzureApp from "./EmbeddedAzureApp";
import { ExternalLink } from "components/common";

const AzureApp: React.FC = () => {
  return (
    <>
      <PageHeader title="Azure EventHubs">
        <span>This feature retrieves log records from the Azure Event Hubs platform.</span>
        <p>
          An {" "}
          <ExternalLink href="https://azure.microsoft.com/en-us/products/event-hubs">
            Azure EventHubs
          </ExternalLink>
          &nbsp;subscription is required.{" "}
        </p>
      </PageHeader>
      <EmbeddedAzureApp />
    </>
  );
};

export default AzureApp;
