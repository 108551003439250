import * as React from 'react';
import { useContext } from 'react';
import * as Immutable from 'immutable';
import { useFormikContext } from 'formik';

import { Button } from 'components/bootstrap';
import { Spinner } from 'components/common';
import { formHasErrors } from 'util/FormsUtils';
import { OKTA_TYPE_KEY } from 'authentication/components/oidc/constants';
import type { ConfigFormValues, OidcSubmitPayload } from 'authentication/components/oidc/config/types';
import { GroupSyncActions } from 'authentication/stores/directoryServices/GroupSyncStore';
import prepareOktaLoadGroupsPayload from 'authentication/logic/okta/prepareOktaLoadGroupsPayload';
import { OidcBackendMetaContext } from 'authentication/components/oidc/config/components/OidcBackendMetaProvider';

import OktaMatchingGroupsContext from './OktaMatchingGroupsContext';

type Props = {
  disabled: boolean,
  prepareSubmitPayload: (fromValues: ConfigFormValues | null | undefined) => OidcSubmitPayload,
};

const _loadGroups = (prepareSubmitPayload, setMatchingGroupsContext, currentFormValues, validateForm, backendId) => {
  const payload = prepareOktaLoadGroupsPayload(prepareSubmitPayload, currentFormValues, backendId, OKTA_TYPE_KEY);

  setMatchingGroupsContext({ finishedLoading: false, result: undefined });

  // Validate form before loading groups, but exclude team selection
  return validateForm({ ...currentFormValues, teamSelection: Immutable.Set(['']) }).then((validationErrors) => {
    if (!formHasErrors(validationErrors)) {
      return GroupSyncActions.loadGroups(payload).then((loadGroupsResult) => {
        if (loadGroupsResult) {
          setMatchingGroupsContext({ finishedLoading: true, result: loadGroupsResult });
        }
      }).catch((error) => {
        const errors = Immutable.List([error?.message, error?.additional?.res?.text]);
        setMatchingGroupsContext({ finishedLoading: true, result: { errors } });
      });
    }

    setMatchingGroupsContext({ finishedLoading: true, result: undefined });

    return null;
  });
};

const _getButtonText = (loadedGroups, teamSelectionType) => {
  if (!loadedGroups || loadedGroups?.size === 0) return 'Load matching groups';
  if (teamSelectionType !== 'all') return 'Reload matching groups';

  return 'Reload matching groups';
};

const OktaLoadMatchingGroupsButton = ({ prepareSubmitPayload, disabled }: Props) => {
  const { result, finishedLoading, setContextValue: setMatchingGroupsContext } = useContext(OktaMatchingGroupsContext);
  const { values: currentFormValues, validateForm } = useFormikContext<ConfigFormValues>();
  const { backendId } = useContext(OidcBackendMetaContext);
  const buttonText = _getButtonText(result?.list, currentFormValues.teamSelectionType);

  return (
    <Button bsStyle="info"
            disabled={disabled}
            onClick={() => _loadGroups(prepareSubmitPayload, setMatchingGroupsContext, currentFormValues, validateForm, backendId)}
            type="button">
      {!finishedLoading ? <Spinner delay={0} text={buttonText} /> : buttonText}
    </Button>
  );
};

export default OktaLoadMatchingGroupsButton;
