import __request__ from 'routing/request';
interface AuthServiceBackendConfig {
    readonly type: string;
}
interface AuthServiceBackendTestRequest {
    readonly backend_id: string;
    readonly user_login: UserLogin;
    readonly backend_configuration: AuthServiceBackendDTO;
}
interface AuthServiceBackendDTO {
    readonly default_roles: string[];
    readonly description: string;
    readonly id: string;
    readonly title: string;
    readonly config: AuthServiceBackendConfig;
}
interface UserLogin {
    readonly password: unknown;
    readonly username: string;
}
/**
 * Test authentication service backend connection
 */
export function backendConnection(JSONbody: AuthServiceBackendTestRequest): Promise<unknown> {
    return __request__('POST', '/system/authentication/services/test/backend/connection', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Test authentication service backend login
 */
export function backendLogin(JSONbody: AuthServiceBackendTestRequest): Promise<unknown> {
    return __request__('POST', '/system/authentication/services/test/backend/login', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
