import { useQuery } from '@tanstack/react-query';

import UserNotification from 'util/UserNotification';
import { getInstantArchivingUriParams } from 'instant-archiving/logic/Utils';
import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import { INSTANT_ARCHIVING_API_ROUTES } from 'instant-archiving/Constants';
import useUserDateTime from 'hooks/useUserDateTime';

type Options = {
  enabled: boolean,
}

export type EstimateParams = {
  to: string,
  from: string,
  archiveId: string,
}

export type EstimateType = {
  files_count: number,
  from: string,
  row_count: number,
  size_bytes: number,
  table_identifier: string,
  to: string
}

const fetchEstimate = (params: EstimateParams, userTimezone: string) => {
  const { archiveId, from, to } = params;

  const uriParams = (from !== '' && to !== '' && archiveId !== '')
    ? `archive_id=${encodeURIComponent(archiveId)}&${getInstantArchivingUriParams({ from, to }, userTimezone)}`
    : '';

  if (uriParams === '') {
    return null;
  }

  return fetch(
    'GET',
    qualifyUrl(`${INSTANT_ARCHIVING_API_ROUTES.InstantArchivingAPIController.estimate().url}?${uriParams}`),
  );
};

const useInstantArchivingEstimate = (params: EstimateParams, { enabled }: Options = { enabled: true }): {
  data: {
    table_identifier: string,
    from: string,
    to: string,
    row_count: number,
    size_bytes: number,
    files_count: number,
  },
  refetch: () => void,
  isInitialLoading: boolean,
} => {
  const { userTimezone } = useUserDateTime();
  const { data, refetch, isInitialLoading } = useQuery(
    ['instant-archiving', 'estimate'],
    () => fetchEstimate(params, userTimezone),
    {
      onError: (errorThrown) => {
        UserNotification.error(`Loading instant archiving action estimate failed with status: ${errorThrown}`,
          'Could not load instant archiving estimate.');
      },
      notifyOnChangeProps: ['data', 'error'],
      enabled,
    },
  );

  return ({
    data,
    refetch,
    isInitialLoading,
  });
};

export default useInstantArchivingEstimate;
