import __request__ from 'routing/request';
interface PaginationInfo {
    readonly per_page: number;
    readonly total: number;
    readonly count: number;
    readonly page: number;
}
interface CollectorStatus {
    readonly verbose_message: string;
    readonly collector_id: string;
    readonly message: string;
    readonly configuration_id: string;
    readonly status: number;
}
interface ConfigurationAssignment {
    readonly assigned_from_tags: string[];
    readonly collector_id: string;
    readonly configuration_id: string;
}
interface NodeLogFile {
    readonly path: string;
    readonly mod_time: string;
    readonly size: number;
    readonly is_dir: boolean;
}
interface SidecarSummary {
    readonly node_details: NodeDetails;
    readonly assignments: ConfigurationAssignment[];
    readonly collectors: string[];
    readonly last_seen: string;
    readonly sidecar_version: string;
    readonly node_name: string;
    readonly active: boolean;
    readonly node_id: string;
}
interface NodeMetrics {
    readonly cpu_idle: number;
    readonly disks_75: string[];
    readonly load_1: number;
}
interface CollectorStatusList {
    readonly collectors: CollectorStatus[];
    readonly message: string;
    readonly status: number;
}
interface NodeDetails {
    readonly ip: string;
    readonly collector_configuration_directory: string;
    readonly operating_system: string;
    readonly metrics: NodeMetrics;
    readonly log_file_list: NodeLogFile[];
    readonly status: CollectorStatusList;
    readonly tags: string[];
}
interface NodeConfigurationRequest {
    readonly nodes: NodeConfiguration[];
}
interface NodeConfiguration {
    readonly assignments: ConfigurationAssignment[];
    readonly node_id: string;
}
interface RegistrationRequest {
    readonly node_details: NodeDetails;
    readonly node_name: string;
}
interface SidecarListResponse {
    readonly sidecars: SidecarSummary[];
    readonly total: number;
    readonly pagination: PaginationInfo;
    readonly query: string;
    readonly sort: string;
    readonly filters: {
        readonly [_key: string]: string;
    };
    readonly only_active: boolean;
    readonly order: string;
}
/**
 * Lists existing Sidecar registrations using pagination
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function sidecars(sort: 'title' | 'description' | 'name' | 'id' | 'node_name' = 'node_name', page: number = 1, per_page: number = 50, query?: string, order: 'asc' | ' desc' = 'asc', only_active: boolean = false): Promise<SidecarListResponse> {
    return __request__('GET', '/sidecars', null, { 'page': page, 'per_page': per_page, 'query': query, 'sort': sort, 'order': order, 'only_active': only_active }, {
        'Accept': ['application/json']
    });
}
/**
 * Lists all existing Sidecar registrations
 */
export function all(): Promise<SidecarListResponse> {
    return __request__('GET', '/sidecars/all', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Assign configurations to sidecars
 */
export function assignConfiguration(JSONbody: NodeConfigurationRequest): Promise<unknown> {
    return __request__('PUT', '/sidecars/configurations', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Returns at most one Sidecar summary for the specified id
 */
export function get(sidecarId: string): Promise<SidecarSummary> {
    return __request__('GET', `/sidecars/${sidecarId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Create/update a Sidecar registration
 * @param sidecarId The id this Sidecar is registering as.
 */
export function register(sidecarId: string, JSONbody: RegistrationRequest): Promise<unknown> {
    return __request__('PUT', `/sidecars/${sidecarId}`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
