import React, { useEffect } from 'react';

import { DocumentTitle, PageHeader } from 'components/common';
import { Row, Col } from 'components/bootstrap';
import InstantArchivingOverview from 'instant-archiving/InstantArchivingOverview/InstantArchivingOverview';
import InstantArchivingJobs from 'instant-archiving/components/InstantArchivingJobs';
import InstantArchivingPageNavigation from 'instant-archiving/components/InstantArchivingPageNavigation';
import InstantArchivingConfigButton from 'instant-archiving/InstantArchivingConfig/InstantArchivingConfigButton';
import InstantArchivingLicenseStatusContainer from 'instant-archiving/InstantArchivinLicenseStatusContainer';
import type { LicenseStatus } from 'archive/types';
import { useStore } from 'stores/connect';
import ArchiveLicenseStore from 'archive/ArchiveLicenseStore';
import ArchiveLicenseActions from 'archive/ArchiveLicenseActions';

const InstantArchivingsPage = () => {
  const licenseStatus: LicenseStatus = useStore(ArchiveLicenseStore);

  useEffect(() => {
    ArchiveLicenseActions.getLicenseStatus();
  }, []);

  return (

    <DocumentTitle title="Instant Archiving Overview">
      <InstantArchivingPageNavigation />
      <PageHeader title="Instant Archiving Overview"
                  actions={<InstantArchivingConfigButton licenseStatus={licenseStatus} />}>
        <span>
          Instant Archiving Overview
        </span>
      </PageHeader>
      <InstantArchivingLicenseStatusContainer />
      <InstantArchivingJobs />
      <Row className="content">
        <Col md={12}>
          <InstantArchivingOverview />
        </Col>
      </Row>
    </DocumentTitle>
  );
};

export default InstantArchivingsPage;
