import __request__ from 'routing/request';
interface DisplayGettingStarted {
    readonly show: boolean;
}
/**
 * Check whether to display the Getting started guide for this version
 */
export function displayGettingStarted(): Promise<DisplayGettingStarted> {
    return __request__('GET', '/system/gettingstarted', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Dismiss auto-showing getting started guide for this version
 */
export function dismissGettingStarted(): Promise<void> {
    return __request__('POST', '/system/gettingstarted/dismiss', null, {}, {
        'Accept': ['application/json']
    });
}
