import __request__ from 'routing/request';
interface StreamRuleInput {
    readonly name: string;
    readonly id: string;
    readonly title: string;
}
interface StreamRuleInputsList {
    readonly inputs: StreamRuleInput[];
}
/**
 * Get a list of all inputs for stream rules
 */
export function list(): Promise<StreamRuleInputsList> {
    return __request__('GET', '/streams/rules/inputs', null, {}, {
        'Accept': ['application/json']
    });
}
