import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useStore } from 'stores/connect';
import type { LicenseStatus } from 'archive/types';

import ReportLicenseStatus from './ReportLicenseStatus';

import ReportLicenseActions from '../../ReportLicenseActions';
import ReportLicenseStore from '../../ReportLicenseStore';
import { licenseIsLoading } from '../../logic/license';

type Props = {
  summary?: boolean,
  message?: string,
  rowClass?: string,
}

const ReportLicenseStatusContainer = ({ summary, message, rowClass }: Props) => {
  const licenseStatus: LicenseStatus = useStore(ReportLicenseStore);

  useEffect(() => {
    ReportLicenseActions.getLicenseStatus();
  }, []);

  if (licenseIsLoading(licenseStatus)) {
    return null;
  }

  return <ReportLicenseStatus licenseStatus={licenseStatus} summary={summary} message={message} rowClass={rowClass} />;
};

ReportLicenseStatusContainer.propTypes = {
  message: PropTypes.string,
  rowClass: PropTypes.string,
  summary: PropTypes.bool,
};

ReportLicenseStatusContainer.defaultProps = {
  message: undefined,
  rowClass: undefined,
  summary: undefined,
};

export default ReportLicenseStatusContainer;
