import styled, { css } from 'styled-components';

import securityContentThemeVariables from 'security-app/theme/constants';

const SideNav = styled.div<{ $isOpen: boolean }>(({ $isOpen, theme }) => css`
  background-color: ${theme.colors.global.background};
  border-right: 1px solid ${theme.colors.global.contentBackground};
  height: 100%;
  width: ${securityContentThemeVariables.measures.SIDE_NAV_WIDTH}px;
  transition: all 0.33s ease-in-out;
  position: absolute;
  left: ${$isOpen ? '0px' : `-${securityContentThemeVariables.measures.CLOSED_NAV_LEFT}px`};
  z-index: 11;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`);

export default SideNav;
