import * as React from 'react';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import capitalize from 'lodash/capitalize';

import { Alert, Button, Col, Row } from 'components/bootstrap';
import { Spinner } from 'components/common';
import Routes from 'routing/Routes';
import DocsHelper from 'util/DocsHelper';
import { LinkContainer } from 'components/common/router';
import { DocumentationLink } from 'components/support';
import type { FailureOverview } from 'failure/actions/FailureActions';
import FailureActions from 'failure/actions/FailureActions';

const FAILURES_STREAM_ID = '000000000000000000000004';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StatCount = styled.span<{$enabled: boolean}>(({ $enabled }) => css`
  grid-column: 2;
  font-style: ${$enabled ? 'normal' : 'italic'};
`);

function FailureProcessingOverview() {
  const [overviewStats, setOverviewStats] = useState<FailureOverview>();

  useEffect(() => {
    if (!overviewStats) {
      FailureActions.loadFailureProcessingOverview().then((res) => {
        setOverviewStats(res);
      });
    }
  }, [overviewStats]);

  return (
    <Row className="content">
      <Col md={12}>
        <Header>
          <h2>Indexing & Processing Failures</h2>
          <DocumentationLink page={DocsHelper.PAGES.INDEXER_FAILURES} text="Indexer failures documentation" displayIcon />
        </Header>
        <p className="description">
          Every message that was not successfully indexed or processed will be logged as a failure.
        </p>

        <div>
          {!overviewStats ? <Spinner /> : Object.keys(overviewStats).map((key) => {
            const noErrors = overviewStats[key].count === 0;
            const isEnabled = overviewStats[key].enabled;
            const alertStyle = !isEnabled ? 'info' : 'success';
            const capitalizedKey = capitalize(key);
            const keyTitle = overviewStats[key].count === 0 ? `${capitalizedKey} is good` : `${capitalizedKey} has errors`;

            return (
              <Alert key={key} bsStyle={noErrors ? alertStyle : 'danger'} title={keyTitle}>
                <StatCount $enabled={overviewStats[key].enabled}>
                  {overviewStats[key].enabled ? `${overviewStats[key].count.toLocaleString()} errors in the past 24 hours` : 'Disabled'}

                  {!noErrors && isEnabled ? (
                    <LinkContainer to={overviewStats[key].source === 'mongodb'
                      ? Routes.SYSTEM.INDICES.FAILURES
                      : Routes.stream_search(FAILURES_STREAM_ID, `failure_type:${key}`, { relative: 86400 })}>
                      <Button bsStyle="info" bsSize="xs" className="pull-right">
                        Show errors
                      </Button>
                    </LinkContainer>
                  ) : null}
                </StatCount>
              </Alert>
            );
          })}
        </div>
      </Col>
    </Row>
  );
}

export default FailureProcessingOverview;
