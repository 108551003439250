import * as React from 'react';
import { useState, useEffect } from 'react';

import { Link } from 'components/common/router';
import { Col, Row } from 'components/bootstrap';
import DocsHelper from 'util/DocsHelper';
import { DocumentTitle, PageHeader, Spinner } from 'components/common';
import Routes from 'routing/Routes';
import { NodesActions, NodesStore } from 'stores/nodes/NodesStore';
import { LicensesActions } from 'license/LicensesStore';
import useLicensesStatus from 'license/hooks/useLicensesStatus';
import ImportLicenseModal from 'license/ImportLicenseModal';
import { useStore } from 'stores/connect';
import LicensesStatusList from 'license/LicensesStatusList';
import type { License } from 'license/types';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'logic/telemetry/Constants';

import { LICENSE_TYPES } from './constants';

const LicensesPage = () => {
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [verifiedLicense, setVerifiedLicense] = useState(undefined);
  const sendTelemetry = useSendTelemetry();

  const { nodes } = useStore(NodesStore);

  const { refetch, data: { status: licensesStatus } } = useLicensesStatus();

  useEffect(() => {
    NodesActions.list();
  }, []);

  const formatSubject = (license: License) => LICENSE_TYPES[license.subject];

  const verifyLicense = (token) => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.LICENSE.VALIDATED, {
      app_pathname: 'license',
      app_section: 'license',
    });

    LicensesActions.verify(token).then(({ license, error_message }) => {
      setErrorMessage(error_message);
      setVerifiedLicense(license);
    });
  };

  const saveLicense = (token: string, callback) => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.LICENSE.SAVED, {
      app_pathname: 'license',
      app_section: 'license',
    });

    LicensesActions.save(token).then(() => {
      refetch();
      callback();
    });
  };

  const deleteLicense = (license: License) => () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.LICENSE.DELETED, {
      app_pathname: 'license',
      app_section: 'license',
    });

    // eslint-disable-next-line no-restricted-globals,no-alert
    if (confirm(`Do you really want to remove the license for "${formatSubject(license)}"? This action cannot be undone.`)) {
      LicensesActions.remove(license.id).then(() => {
        refetch();
      });
    }
  };

  const clusterIdFromFirstNode = () => {
    if (nodes) {
      return Object.keys(nodes).map((id) => nodes[id]).map((node) => node.cluster_id)[0].toUpperCase();
    }

    return undefined;
  };

  const clusterId = clusterIdFromFirstNode();

  return (
    <DocumentTitle title="Graylog license management">
      <span>
        <PageHeader title="Graylog License Management"
                    actions={(
                      <ImportLicenseModal key="new-license"
                                          saveLicense={saveLicense}
                                          verifyLicense={verifyLicense}
                                          errorMessage={errorMessage}
                                          verifiedLicense={verifiedLicense} />
                    )}
                    documentationLink={{
                      title: 'License documentation',
                      path: DocsHelper.PAGES.LICENSE,
                    }}>
          <span>
            Manage your Graylog Enterprise licenses. You can get a new license from the{' '}
            <Link to={Routes.SYSTEM.ENTERPRISE}>Enterprise page</Link>.
          </span>
        </PageHeader>

        <Row className="content">
          <Col md={12}>
            <h2 style={{ marginBottom: 5 }}>Installed licenses</h2>
            {clusterId && <p>This Graylog cluster ID is {clusterId}</p>}
            {licensesStatus && nodes
              ? (
                <LicensesStatusList licensesStatus={licensesStatus}
                                    formatSubject={formatSubject}
                                    deleteLicense={deleteLicense} />
              )
              : <Spinner />}
          </Col>
        </Row>
      </span>
    </DocumentTitle>
  );
};

export default LicensesPage;
