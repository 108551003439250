import * as React from 'react';

import useCurrentUser from 'hooks/useCurrentUser';
import BootstrapModalConfirm from 'components/bootstrap/BootstrapModalConfirm';
import { Input, Button } from 'components/bootstrap';
import { Icon } from 'components/common';
import Routes from 'routing/Routes';
import { useAddEvidence, useRemoveEvidence } from 'security-app/hooks/useInvestigationsAPI';
import type { InvestigationsDetailsAPIType, DashboardEvidenceType } from 'security-app/hooks/api/investigationsAPI.types';
import useHistory from 'routing/useHistory';

import {
  Row,
  FormGroup,
  EvidenceList,
  EvidenceRow,
  IconButton,
  StyledLink,
} from '../styled.components';

type Props = {
  investigation: InvestigationsDetailsAPIType,
};

function Dashboards({ investigation }: Props) {
  const { permissions } = useCurrentUser();
  const [newDashboardId, setNewDashboardId] = React.useState<string>('');
  const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false);
  const [deleteDashboardId, setDeleteDashboardId] = React.useState<string>(null);
  const history = useHistory();

  const canManageEvidence = React.useMemo(() => !investigation.archived
      && (permissions.includes('investigations:edit') || permissions.includes('*')), [investigation.archived, permissions]);

  const { addEvidence } = useAddEvidence();
  const { removeEvidence, removingEvidence } = useRemoveEvidence();

  const isFormValid = React.useMemo(() => (
    newDashboardId && newDashboardId.replace(/\s/g, '').length > 0
  ), [newDashboardId]);

  const onAddDashboard = async (e: React.BaseSyntheticEvent) => {
    e.preventDefault();

    if (isFormValid) {
      await addEvidence({ invId: investigation.id, payload: { dashboards: [newDashboardId] } });
    }

    setNewDashboardId('');
  };

  const removeDashboard = async () => {
    await removeEvidence({ invId: investigation.id, payload: { dashboards: [deleteDashboardId] } });
    setShowDeleteDialog(false);
    setDeleteDashboardId(null);
  };

  const onDelete = (dashboardId: string) => (e: React.BaseSyntheticEvent) => {
    e.stopPropagation();
    setShowDeleteDialog(true);
    setDeleteDashboardId(dashboardId);
  };

  const onCancelDelete = () => {
    setShowDeleteDialog(false);
    setDeleteDashboardId(null);
  };

  return (
    <>
      {canManageEvidence && (
        <form onSubmit={onAddDashboard}>
          <Row>
            <FormGroup>
              <Input id="add-dashboard-id"
                     type="text"
                     label="Add Dashboard"
                     data-testid="add-dashboard-id"
                     autoComplete="off"
                     placeholder="Enter dashboard ID"
                     value={newDashboardId}
                     onChange={(e: React.BaseSyntheticEvent) => setNewDashboardId(e.target.value)} />
            </FormGroup>
            <Button type="submit"
                    bsStyle="success"
                    onClick={onAddDashboard}
                    disabled={!isFormValid}>
              Add
            </Button>
          </Row>
        </form>
      )}
      <EvidenceList>
        {investigation.dashboards.length === 0
          ? <div style={{ color: 'gray' }}>There are no dashboards to list</div>
          : investigation.dashboards.map((dashboard: DashboardEvidenceType) => (
            <EvidenceRow key={dashboard.id} $fullWidth $gap="0">
              {canManageEvidence && (
                <IconButton $danger
                            data-testid={`delete-${dashboard.id}`}
                            onClick={onDelete(dashboard.id)}>
                  <Icon name="times" />
                </IconButton>
              )}
              <StyledLink onClick={() => history.push(Routes.VIEWS.VIEWID(dashboard.id))}>
                {dashboard.title}
              </StyledLink>
            </EvidenceRow>
          ))}
      </EvidenceList>
      <BootstrapModalConfirm showModal={showDeleteDialog}
                             title="Delete Dashboard"
                             onConfirm={removeDashboard}
                             onCancel={onCancelDelete}
                             confirmButtonDisabled={removingEvidence}>
        Are you sure you want to delete this Dashboard?
      </BootstrapModalConfirm>
    </>
  );
}

export default Dashboards;
