import __request__ from 'routing/request';
interface PaginationInfo {
    readonly per_page: number;
    readonly total: number;
    readonly count: number;
    readonly page: number;
}
interface EntityAttribute {
    readonly filterable: boolean;
    readonly related_collection: string;
    readonly hidden: boolean;
    readonly sortable: boolean;
    readonly id: string;
    readonly type: 'STRING' | 'DATE' | 'INT' | 'LONG' | 'OBJECT_ID' | 'BOOLEAN';
    readonly title: string;
    readonly searchable: boolean;
    readonly filter_options: FilterOption[];
}
interface EntityDefaults {
    readonly sort: Sorting;
}
interface FilterOption {
    readonly title: string;
    readonly value: string;
}
interface PageListResponse {
    readonly total: number;
    readonly pagination: PaginationInfo;
    readonly defaults: EntityDefaults;
    readonly query: string;
    readonly elements: IndexSetFieldTypeSummary[];
    readonly attributes: EntityAttribute[];
    readonly sort: string;
    readonly order: string;
}
interface FieldTypeSummaryRequest {
    readonly field: string;
    readonly streams: string[];
}
interface IndexSetFieldTypeSummary {
    readonly stream_titles: string[];
    readonly index_set_title: string;
    readonly types: string[];
    readonly index_set_id: string;
}
interface Sorting {
    readonly id: string;
    readonly direction: 'ASC' | 'DESC';
}
/**
 * Get field type summaries for given index sets and field
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function fieldTypeSummaries(JSONbody: FieldTypeSummaryRequest, sort: 'index_set_id' | 'index_set_title' = 'index_set_id', page: number = 1, per_page: number = 50, order: 'asc' | 'desc' = 'asc'): Promise<PageListResponse> {
    return __request__('POST', '/system/indices/index_sets/types', JSONbody, { 'page': page, 'per_page': per_page, 'sort': sort, 'order': order }, {
        'Accept': ['application/json']
    });
}
