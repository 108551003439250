import * as React from 'react';
import styled from 'styled-components';

const RenderError = styled.div`
  white-space: break-spaces;
`;
type Props = {
  error: Error
}
const ReportRenderErrorPage = ({ error }: Props) => (
  <RenderError id="render-error">
    {error.stack}
  </RenderError>
);

export default ReportRenderErrorPage;
