import MongoDBDataAdapterFieldSet from './components/MongoDBDataAdapterFieldSet';
import MongoDBDataAdapterSummary from './components/MongoDBDataAdapterSummary';
import MongoDBDataAdapterDocumentation from './components/MongoDBDataAdapterDocumentation';
import IlluminateCSVFileAdapterFieldSet from './components/IlluminateCSVFileAdapterFieldSet';
import IlluminateCSVFileAdapterSummary from './components/IlluminateCSVFileAdapterSummary';
import IlluminateCSVFileAdapterDocumentation from './components/IlluminateCSVFileAdapterDocumentation';

const lookupBindings = {
  lookupTableAdapters: [
    {
      type: 'mongodb',
      displayName: 'MongoDB',
      formComponent: MongoDBDataAdapterFieldSet,
      summaryComponent: MongoDBDataAdapterSummary,
      documentationComponent: MongoDBDataAdapterDocumentation,
    },
    {
      type: 'illuminate_csv',
      displayName: 'Illuminate Customizable',
      formComponent: IlluminateCSVFileAdapterFieldSet,
      summaryComponent: IlluminateCSVFileAdapterSummary,
      documentationComponent: IlluminateCSVFileAdapterDocumentation,
    },
  ],
};

export default lookupBindings;
