import PropTypes from 'prop-types';
import React from 'react';

import { ArchiveConfigurationActions } from 'archive/ArchiveConfigurationStore';
import { BackendPropType } from 'archive/propTypes';
import { Alert, Row, Col, Input } from 'components/bootstrap';
import BootstrapModalForm from 'components/bootstrap/BootstrapModalForm';
import { Spinner } from 'components/common';

import type { Backend, ArchiveConfiguration } from '../types';

type Props = {
  backend: Backend,
  onDelete: (backend: Backend, deleteArchives: boolean) => void,
};
type State = {
  config?: ArchiveConfiguration,
  deleteArchives: boolean,
  showModal: boolean,
};

class ArchiveBackendDeletionForm extends React.Component<Props, State> {
  static propTypes = {
    backend: BackendPropType.isRequired,
    onDelete: PropTypes.func.isRequired,
  };

  constructor(props: Props) {
    super(props);
    this.state = { config: undefined, deleteArchives: false, showModal: false };
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  open = () => {
    this.setState({ showModal: true });

    ArchiveConfigurationActions.getConfig().then((config: ArchiveConfiguration) => this.setState({ config: config }));
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  close = () => {
    this.setState({ showModal: false });
  };

  _onDelete = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { backend, onDelete } = this.props;
    const { deleteArchives } = this.state;
    e.preventDefault();

    if (this._isDeletable()) {
      onDelete(backend, deleteArchives);
    }
  };

  _onRemoveClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ deleteArchives: e.target.checked });
  };

  _isLoading = () => {
    const { config } = this.state;

    return !config;
  };

  _isDeletable = () => {
    const { config: { backend_id: backendID } = {} } = this.state;
    const { backend } = this.props;

    return !this._isLoading() && backendID !== backend.id;
  };

  _modalContent = () => {
    const { backend } = this.props;
    const { deleteArchives } = this.state;

    if (this._isLoading()) {
      return <Spinner text="Loading assigned streams..." />;
    }

    if (!this._isDeletable()) {
      return (
        <div>
          <Row>
            <Col md={12}>
              <Alert bsStyle="danger">
                The <em>{backend.title}</em> backend cannot be deleted because it is in use.
              </Alert>
            </Col>
          </Row>
        </div>
      );
    }

    return (
      <Row>
        <Col md={12}>
          <Input type="checkbox"
                 id="remove-data-checkbox"
                 label="Remove all archive data for this backend?"
                 help={<span>All archives related to this backend will be deleted.</span>}
                 checked={deleteArchives}
                 onChange={this._onRemoveClick} />
        </Col>
      </Row>
    );
  };

  render() {
    const { backend } = this.props;

    return (
      <BootstrapModalForm show={this.state.showModal}
                          onCancel={this.close}
                          title={`Delete backend "${backend.title}"?`}
                          data-telemetry-title="Delete backend"
                          onSubmitForm={this._onDelete}
                          submitButtonText="Delete"
                          submitButtonDisabled={!this._isDeletable()}>
        {this._modalContent()}
      </BootstrapModalForm>
    );
  }
}

export default ArchiveBackendDeletionForm;
