import __request__ from 'routing/request';
interface CallResult {
    readonly response: void;
    readonly server_error_message: string;
    readonly call_executed: boolean;
}
interface CallResultMap {
    readonly [_key: string]: CallResult;
}
/**
 * Purge Lookup Table Cache on the cluster-wide level
 */
export function performPurge(idOrName?: string, key?: string): Promise<CallResultMap> {
    return __request__('POST', `/cluster/system/lookup/tables/${idOrName}/purge`, null, { 'key': key }, {
        'Accept': ['application/json']
    });
}
