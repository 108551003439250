import * as React from 'react';
import PropTypes from 'prop-types';

import OverlayTrigger from 'components/common/OverlayTrigger';
import { LinkContainer } from 'components/common/router';
import { Button, ButtonToolbar, Tooltip } from 'components/bootstrap';
import Routes from 'routing/Routes';
import type { LicenseStatus } from 'archive/types';

import { licenseIsLoading, isValidLicense } from '../logic/license';

const ReportsSubNav = ({ licenseStatus }: { licenseStatus: LicenseStatus }) => (
  <ButtonToolbar>
    {licenseIsLoading(licenseStatus) || isValidLicense(licenseStatus) ? (
      <LinkContainer to={Routes.pluginRoute('REPORTS_NEW')}>
        <Button bsStyle="success">Create report</Button>
      </LinkContainer>
    ) : (
      <OverlayTrigger overlay={<Tooltip id="missing-license">Creating new report definitions is disabled because there is no valid license.</Tooltip>}
                      placement="top">
        <Button bsStyle="success" className="disabled">Create report</Button>
      </OverlayTrigger>
    )}
  </ButtonToolbar>
);

ReportsSubNav.propTypes = {
  licenseStatus: PropTypes.object.isRequired,
};

export default ReportsSubNav;
