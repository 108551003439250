import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import ValidatedInput from './common/ValidatedInput';
import FormWrap from './common/FormWrap';
import { FormDataContext } from './context/FormData';
import formValidation from './utils/formValidation';

import handleFileUpload from '../helpers/fileReader';

const StepAuthorize = ({ onSubmit, onChange }) => {
  const { formData } = useContext(FormDataContext);
  const [formValidated, setFormValidated] = useState({
    workspaceNameCheck: true,
    accountIdCheck: true,
    accountUserCheck: true,
    serviceAccountKeyCheck: true,
    clientIdCheck: true,
  });
  const ValidatedInputData = [
    { name: 'workspaceName', type: 'string', minLength: 3, maxLength: 31 },
    { name: 'accountId', type: 'email', minLength: 0 },
    { name: 'accountUser', type: 'email', minLength: 0 },
    { name: 'serviceAccountKey', type: 'string', minLength: 0 },
    { name: 'clientId', type: 'number', minLength: 11, maxLength: 31 },
  ];

  const isFormValid = formValidation.isFormValid(
    ['workspaceName', 'accountId', 'accountUser', 'serviceAccountKey', 'clientId'],
    formData,
  );

  const formSubmit = (event) => {
    const errors = [];
    const email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const number = /^[0-9\b]+$/;

    ValidatedInputData.map((data) => {
      if (
        data.name === 'workspaceName'
        && (formData[data.name]?.value?.length <= data.minLength || formData[data.name]?.value?.length >= data.maxLength)
      ) {
        errors.push(data.name);

        setFormValidated((prevData) => {
          return {
            ...prevData,
            [`${data.name}Check`]: false,
          };
        });
      } else if (
        data.type === 'email'
        && !email.test(formData[data.name]?.value)
      ) {
        errors.push(data.name);

        setFormValidated((prevData) => {
          return {
            ...prevData,
            [`${data.name}Check`]: false,
          };
        });
      } else if (
        data.name === 'clientId'
        && (!number.test(formData[data.name]?.value) || formData[data.name]?.value?.length <= data.minLength
        || formData[data.name]?.value?.length >= data.maxLength)
      ) {
        errors.push(data.name);

        setFormValidated((prevData) => {
          return {
            ...prevData,
            [`${data.name}Check`]: false,
          };
        });
      }
    });

    if (errors.length === 0) {
      onSubmit(event);
    }
  };

  const fieldChange = (event, value) => {
    if (event) {
      setFormValidated((prevData) => {
        return {
          ...prevData,
          [value]: true,
        };
      });

      onChange(event);
    }
  };

  const onFileUpload = (e, validationKey) => {
    e.persist();

    setFormValidated((prevData) => {
      return {
        ...prevData,
        [validationKey]: true,
      };
    });

    handleFileUpload(e.target.files[0], (fileName, fileContent) => onChange(e, { fileName, fileContent }));
  };

  const accountKeyHelpText = formData.serviceAccountKey && formData.serviceAccountKey.fileName ? `Currently using file: ${formData.serviceAccountKey.fileName}.` : 'Service account key p12.';

  return (
    <FormWrap onSubmit={(e) => formSubmit(e)}
              buttonContent="Proceed"
              disabled={isFormValid}
              title=""
              description="">
      <ValidatedInput id="workspaceName"
                      type="text"
                      fieldData={formData.workspaceName}
                      onChange={(e) => fieldChange(e, 'workspaceNameCheck')}
                      placeholder="Input Name"
                      label="Input Name"
                      autoComplete="off"
                      help="Select a name of your new input that describes it."
                      required />

      {!formValidated.workspaceNameCheck && <label>Name must be between 4 to 30 characters</label>}
      <ValidatedInput id="clientId"
                      type="text"
                      fieldData={formData.clientId}
                      onChange={(e) => fieldChange(e, 'clientIdCheck')}
                      label="Client ID"
                      help="The Client ID of the workspace to which the logs belong."
                      autoComplete="off"
                      required />
      {!formValidated.clientIdCheck && <label>Client ID must only be numbers between 12 to 30 characters</label>}
      <ValidatedInput id="accountId"
                      type="text"
                      fieldData={formData.accountId}
                      onChange={(e) => fieldChange(e, 'accountIdCheck')}
                      label="Service Account ID"
                      help="The Service Account ID of the workspace to which the logs belong."
                      autoComplete="off"
                      required />
      {!formValidated.accountIdCheck && <label>Enter a valid email ID</label>}
      <ValidatedInput id="accountUser"
                      type="text"
                      onChange={(e) => fieldChange(e, 'accountUserCheck')}
                      fieldData={formData.accountUser}
                      help="The account user email of your workspace."
                      label="Account User Email"
                      required />
      {!formValidated.accountUserCheck && <label>Enter a valid email ID</label>}
      <ValidatedInput id="serviceAccountKey"
                      type="file"
                      onChange={(e) => onFileUpload(e, 'serviceAccountKeyCheck')}
                      fieldData={formData.serviceAccountKey}
                      label="Service Account key"
                      help={accountKeyHelpText}
                      accept=".p12"
                      required />

    </FormWrap>
  );
};

StepAuthorize.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default StepAuthorize;
