import __request__ from 'routing/request';
interface AvailableEntityTypesSummary {
    readonly storage_handler_types: string[];
    readonly processor_types: string[];
    readonly aggregation_functions: string[];
    readonly field_provider_types: string[];
}
/**
 * List all available entity types
 */
export function all(): Promise<AvailableEntityTypesSummary> {
    return __request__('GET', '/events/entity_types', null, {}, {
        'Accept': ['application/json']
    });
}
