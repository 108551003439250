import { useQuery } from '@tanstack/react-query';

import AppConfig from 'util/AppConfig';
import { CustomizationActions } from 'customization/stores/CustomizationStore';

const fetchCustomColors = (fromAppConfig: boolean, defaultCustomColors: any) => () => {
  if (fromAppConfig) {
    return Promise.resolve(defaultCustomColors);
  }

  return CustomizationActions.loadTheme();
};

const useCustomThemeColors = (fromAppConfig = true) => {
  const defaultCustomColors = fromAppConfig ? AppConfig.customThemeColors() : null;
  const { data, isInitialLoading } = useQuery({
    queryKey: ['customization', 'colors'],
    queryFn: fetchCustomColors(fromAppConfig, defaultCustomColors),
    keepPreviousData: true,
    enabled: !fromAppConfig,
    initialData: defaultCustomColors,
  });

  return { data, isInitialLoading };
};

export default useCustomThemeColors;
