import React from 'react';
import styled from 'styled-components';
import { Overlay } from 'react-overlays';

import type { SelectedSearchFilter } from 'search-filter/types';
import type { Button } from 'components/bootstrap';
import { Popover } from 'components/bootstrap';
import type { SearchFilterEdit } from 'search-filter/components/search-filter-bar/SearchFilterForm';
import SearchFilterForm from 'search-filter/components/search-filter-bar/SearchFilterForm';
import useUserSearchFilterMutation from 'search-filter/hooks/useUserSearchFilterMutation';
import { Portal } from 'components/common';
import generateId from 'logic/generateId';
import { REFERENCED_USER_SEARCH_FILTER_TYPE, INLINE_SEARCH_FILTER_TYPE } from 'search-filter/constants';
import { CurrentUserStore } from 'stores/users/CurrentUserStore';

const StyledPopover = styled(Popover)`
  max-width: 450px;
  margin-left: 10px;

  &.popover.bottom > .arrow {
    margin-left: -20px;
  }
`;

type Props = {
  onSubmit: (searchFilter: SelectedSearchFilter) => void,
  onCancel: () => void,
  target: Button,
  show: boolean,
}

const SearchFilterCreatePopover = ({ target, onSubmit, onCancel, show }: Props) => {
  const { post } = useUserSearchFilterMutation();

  const handleFormSubmit = (filterData: SearchFilterEdit, checked: boolean) => {
    if (checked) {
      return post({ data: filterData }).then((data: SelectedSearchFilter) => CurrentUserStore.reload().then(() => onSubmit({ ...data, frontendId: data.id, type: REFERENCED_USER_SEARCH_FILTER_TYPE })));
    }

    const newId = generateId();

    return onSubmit({ ...filterData, id: newId, frontendId: newId, type: INLINE_SEARCH_FILTER_TYPE });
  };

  return (
    <Portal>
      <Overlay target={target} placement="bottom" show={show} onHide={onCancel}>
        <StyledPopover title="Create Filter" id="create-filter-search-popover">
          <SearchFilterForm onCancel={onCancel}
                            onSubmit={handleFormSubmit}
                            checkboxLabel='Save to "My Filters"'
                            helpText='This action will save this filter in your "My Filters" collection. These filters can be reused in other searches. Changes of this filter will effect all related searched.'
                            helpTitle='Save to "My Filters"'
                            submitButtonText="Create filter"
                            submitLoadingText="Creating filter..." />
        </StyledPopover>
      </Overlay>
    </Portal>
  );
};

export default SearchFilterCreatePopover;
