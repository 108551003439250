import React, { useCallback, useRef } from 'react';

import type { SelectedSearchFilter } from 'search-filter/types';
import SearchFilterCreatePopover from 'search-filter/components/search-filter-bar/SearchFilterCreatePopover';
import { Button } from 'components/bootstrap';
import { Icon } from 'components/common';
import useSearchFiltersFormActions from 'search-filter/hooks/useSearchFiltersFormActions';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'logic/telemetry/Constants';

type Props = {
  showPopover: boolean,
  toggleShowPopover: () => void,
}

const SearchFilterCreate = ({ showPopover, toggleShowPopover }: Props) => {
  const sendTelemetry = useSendTelemetry();
  const addButtonRef = useRef();
  const { addFilter } = useSearchFiltersFormActions();

  const _onSave = useCallback((searchFilter: SelectedSearchFilter) => {
    addFilter(searchFilter);
    toggleShowPopover();

    sendTelemetry(TELEMETRY_EVENT_TYPE.SEARCH_FILTER_CREATED, {
      app_pathname: 'search',
      app_section: 'search-filter',
      app_action_value: 'create',
    });
  }, [addFilter, sendTelemetry, toggleShowPopover]);

  return (
    <>
      <Button ref={addButtonRef} onClick={toggleShowPopover} title="Create new filter" bsSize="small">
        <Icon name="plus" />
      </Button>
      {showPopover && (
        <SearchFilterCreatePopover onSubmit={_onSave}
                                   onCancel={toggleShowPopover}
                                   target={addButtonRef.current}
                                   show />
      )}
    </>
  );
};

export default SearchFilterCreate;
