/*
 * Copyright (C) 2020 Graylog, Inc.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the Server Side Public License, version 1,
 * as published by MongoDB, Inc.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * Server Side Public License for more details.
 *
 * You should have received a copy of the Server Side Public License
 * along with this program. If not, see
 * <http://www.mongodb.com/licensing/server-side-public-license>.
 */
import * as React from 'react';
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import UIUtils from 'util/UIUtils';

type Props = {
  children: React.ReactNode,
  value: any,
};

const ScrollToHint = ({ children, value }: Props) => {
  const spanRef = useRef();

  useEffect(() => {
    UIUtils.scrollToHint(spanRef.current);
  }, [value]);

  return (
    <span ref={spanRef}>
      {children}
    </span>
  );
};

ScrollToHint.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.any.isRequired,
};

export default ScrollToHint;
