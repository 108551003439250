import * as React from 'react';
import { useContext, useCallback } from 'react';
import styled, { css } from 'styled-components';
import type * as Immutable from 'immutable';

import type { BackendMessage } from 'views/components/messagelist/Types';
import Spinner from 'components/common/Spinner';
import { RefreshActions } from 'views/stores/RefreshStore';
import CustomHighlighting from 'views/components/highlighting/CustomHighlighting';
import DecoratedValue from 'views/components/messagelist/decoration/DecoratedValue';
import TypeSpecificValue from 'views/components/TypeSpecificValue';
import type { FieldTypeMappingsList } from 'views/logic/fieldtypes/types';
import FieldType from 'views/logic/fieldtypes/FieldType';

import onTableScroll from './OnTableScroll';
import LogViewRow from './LogViewRow';
import LogViewCell from './LogViewCell';
import LogViewHeader from './LogViewHeader';
import ListStateContext from './contexts/ListStateContext';
import MessageDetailsContext from './contexts/MessageDetailsContext';
import type { PageRefs, TableRef } from './LogViewWidget';
import { CELL_SEP } from './Constants';

import type LogViewWidgetConfig from '../logic/LogViewWidgetConfig';

export const LOADING_ROW_HEIGHT = 40;
const compareMessage = ({ message: { _id: m1 } }, message) => m1 === message?.message._id;

const LoadingIndicator = styled.div(({ theme }) => css`
  min-height: ${LOADING_ROW_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: ${theme.fonts.family.body};
  font-size: ${theme.fonts.size.body};
`);

const Table = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-anchor: none;
  overflow-y: auto;
  position: relative;
`;

const TableInner = styled.div`
  /* Remove bottom border of last row of last page */
  > *:last-child > *:last-child {
    border-bottom: 0;
  }
`;

const _getBottomPageHeight = (lastVisiblePageIndex, pageRefs) => pageRefs.current[lastVisiblePageIndex]?.offsetHeight || 0;

type MessageProps = {
  tableHeader: Immutable.OrderedSet<string>,
  setActiveMessageDetailsId: (messageId: string) => void,
  highlight: boolean,
  completeMessage: BackendMessage,
  fields: FieldTypeMappingsList,
}

const isDecoratedField = (field, decorationStats) => decorationStats
  && (decorationStats.added_fields[field] !== undefined || decorationStats.changed_fields[field] !== undefined);

const fieldType = (fieldName, { decoration_stats: decorationStats }: { decoration_stats?: any }, fields) => (isDecoratedField(fieldName, decorationStats)
  ? FieldType.Decorated
  : ((fields && fields.find((f) => f.name === fieldName)) || { type: FieldType.Unknown }).type);

const Message = ({ tableHeader, setActiveMessageDetailsId, highlight, completeMessage, fields }:MessageProps) => {
  const { index, message } = completeMessage;
  const key = `${index}-${message._id}`;

  return (
    <LogViewRow key={`table-row-${key}`} onShowDetails={() => setActiveMessageDetailsId(message._id)} highlight={highlight}>
      {tableHeader.map((fieldName) => {
        const value = message[fieldName];

        return (
          <React.Fragment key={`table-body-cell-${message._id} - ${fieldName}`}>
            <LogViewCell fieldName={fieldName}>
              {value && (
                <CustomHighlighting field={fieldName} value={value}>
                  <TypeSpecificValue value={value}
                                     field={fieldName}
                                     type={fieldType(fieldName, completeMessage, fields)}
                                     render={DecoratedValue} />
                </CustomHighlighting>
              )}
            </LogViewCell>

            {fieldName !== tableHeader.last() ? CELL_SEP : null}
          </React.Fragment>
        );
      }).toArray()}
    </LogViewRow>
  );
};

type Props = {
  columns: LogViewWidgetConfig['fields'],
  pageRefs: PageRefs,
  tableRef: TableRef,
  fields: FieldTypeMappingsList,
};

const LogViewTable = ({ columns, tableRef, pageRefs, fields }: Props) => {
  const {
    actions: { loadNextPage, loadPrevPage, cancelLoadPrevPage },
    bottomPageId,
    loadedAllPrevMessages,
    pages,
  } = useContext(ListStateContext);
  const { setActiveMessageDetailsId, activeMessageDetails } = useContext(MessageDetailsContext);
  const _setActiveMessageDetailsId = useCallback((messageId: string) => {
    setActiveMessageDetailsId(messageId);
    RefreshActions.disable();
  }, [setActiveMessageDetailsId]);
  const lastPageHeight = _getBottomPageHeight(bottomPageId, pageRefs);

  const _onTableScroll = () => onTableScroll({
    lastPageHeight,
    onTopReach: loadPrevPage,
    onBottomReach: loadNextPage,
    onBottomPageReach: cancelLoadPrevPage,
    tableRef,
  });

  return (
    (
      <Table ref={tableRef} onScroll={_onTableScroll}>
        {/* This div is required to position the header sticky in a scrollable div with Safari */}
        <TableInner>
          <LogViewHeader columns={columns} />
          {!loadedAllPrevMessages && (
          <LoadingIndicator key="loading-test">
            <Spinner delay={0} text="Loading previous messages..." />
          </LoadingIndicator>
          )}
          {pages.map(([pageId, messages]) => (
            (
              // eslint-disable-next-line no-param-reassign
              <div ref={(ref) => { pageRefs.current[pageId] = ref; }} key={`page-${pageId}`}>
                {messages.map((message) => (
                  <Message key={message?.message?._id}
                           tableHeader={columns}
                           fields={fields}
                           highlight={compareMessage(message, activeMessageDetails)}
                           setActiveMessageDetailsId={_setActiveMessageDetailsId}
                           completeMessage={message} />
                ))}
              </div>
            )
          )).toArray()}
        </TableInner>
      </Table>
    )
  );
};

export default LogViewTable;
