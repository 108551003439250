import * as React from 'react';
import { Field, useFormikContext } from 'formik';

import TimeSelect from 'report/common/TimeSelect';
import MinuteOfHourInput from 'report/common/MinuteOfHourInput';
import DayOfWeekSelect from 'report/common/DayOfWeekSelect';
import DayOfMonthSelect from 'report/common/DayOfMonthSelect';
import { Row, Col } from 'components/bootstrap';
import type { SchedulingFrequencyFormValues } from 'report/report-configuration-page/SchedulingFrequencyForm';
import { FREQUENCY_OPTIONS } from 'report/report-configuration-page/SchedulingFrequencyForm';
import TimezoneSelect from 'components/common/TimezoneSelect';

const FormikTimezoneSelect = ({ id }: { id: string}) => (
  <Field name={id}>
    {({ field: { name, value, onChange } }) => (
      <TimezoneSelect value={value ?? 'UTC'}
                      clearable={false}
                      onChange={(newValue) => onChange({
                        target: { value: newValue, name },
                      })} />
    )}
  </Field>
);

const TimeAndTimezoneControls = () => (
  <>
    <Col sm={4}>
      <TimeSelect id="frequency_configuration.time"
                  required />
    </Col>
    <Col sm={4}>
      <FormikTimezoneSelect id="frequency_configuration.timezone" />
    </Col>
  </>
);

const FrequencyControls = () => {
  const { values: { frequency } } = useFormikContext<SchedulingFrequencyFormValues>();

  switch (frequency) {
    case FREQUENCY_OPTIONS.HOURLY.value:
      return (
        <Row>
          <Col sm={4}>
            <MinuteOfHourInput id="frequency_configuration.time"
                               required />
          </Col>
        </Row>
      );
    case FREQUENCY_OPTIONS.DAILY.value:
      return (
        <Row>
          <Col sm={4}>
            <TimeSelect id="frequency_configuration.time"
                        required />
          </Col>
          <Col sm={4}>
            <FormikTimezoneSelect id="frequency_configuration.timezone" />
          </Col>
        </Row>
      );
    case FREQUENCY_OPTIONS.WEEKLY.value:
      return (
        <Row>
          <Col sm={4}>
            <DayOfWeekSelect id="frequency_configuration.day_of_week"
                             required />
          </Col>
          <TimeAndTimezoneControls />
        </Row>
      );
    case FREQUENCY_OPTIONS.MONTHLY.value:
      return (
        <Row>
          <Col sm={4}>
            <DayOfMonthSelect id="frequency_configuration.day_of_month"
                              required />
          </Col>
          <TimeAndTimezoneControls />
        </Row>
      );
    default:
      return <div />;
  }
};

export default FrequencyControls;
