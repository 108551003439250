import __request__ from 'routing/request';
interface RetentionStrategyConfig {
    readonly max_number_of_indices: number;
    readonly type: string;
}
interface Context {
    readonly max_index_retention_period: string;
}
interface RetentionStrategies {
    readonly total: number;
    readonly strategies: RetentionStrategyDescription[];
    readonly context: Context;
}
interface RetentionStrategyDescription {
    readonly json_schema: JsonSchema;
    readonly default_config: RetentionStrategyConfig;
    readonly type: string;
}
interface JsonSchema {
    readonly disallow: JsonSchema[];
    readonly $schema: string;
    readonly readonly: boolean;
    readonly extends: JsonSchema[];
    readonly description: string;
    readonly id: string;
    readonly $ref: string;
    readonly required: boolean;
}
/**
 * List available retention strategies
 */
export function list(): Promise<RetentionStrategies> {
    return __request__('GET', '/system/indices/retention/strategies', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Show JSON schema for configuration of given retention strategies
 * @param strategy The name of the retention strategy
 */
export function configSchema(strategy: string): Promise<RetentionStrategyDescription> {
    return __request__('GET', `/system/indices/retention/strategies/${strategy}`, null, {}, {
        'Accept': ['application/json']
    });
}
