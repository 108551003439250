import * as React from 'react';

import ReportLicenseStatusContainer from './validations/ReportLicenseStatusContainer';

const ReportValidations = () => (
  <div>
    <ReportLicenseStatusContainer rowClass="content row-sm" />
  </div>
);

export default ReportValidations;
