import __request__ from 'routing/request';
interface PluginList {
    readonly total: number;
    readonly plugins: PluginMetaDataValue[];
}
interface PluginMetaDataValue {
    readonly unique_id: string;
    readonly required_version: string;
    readonly author: string;
    readonly required_capabilities: string[];
    readonly name: string;
    readonly description: string;
    readonly version: string;
    readonly url: string;
}
/**
 * List all installed plugins on the given node
 * @param nodeId The id of the node where processing will be paused.
 */
export function list(nodeId: string): Promise<PluginList> {
    return __request__('GET', `/cluster/${nodeId}/plugins`, null, {}, {
        'Accept': ['application/json']
    });
}
