import Reflux from 'reflux';

type ActionsType = {
  getLicenseStatus: () => Promise<unknown>
}

const ReportLicenseActions = Reflux.createActions<ActionsType>({
  getLicenseStatus: { asyncResult: true },
});

export default ReportLicenseActions;
