import __request__ from 'routing/request';
interface SatisfiesVersionResponse {
    readonly satisfied: boolean;
    readonly errorMessage: string;
}
/**
 * Confirms whether the current search version satisfies a given distribution and an optional Semantic Versioning version
 */
export function satisfiesVersion(distribution: string, version?: string): Promise<SatisfiesVersionResponse> {
    return __request__('GET', `/system/searchVersion/satisfiesVersion/${distribution}`, null, { 'version': version }, {
        'Accept': ['application/json']
    });
}
