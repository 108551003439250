import { useEffect } from 'react';

import useHistory from 'routing/useHistory';

import AWSSecurityLakeRoutes from './common/Routes';

type Props = { url: string };

// https://stackoverflow.com/questions/52912238/render-methods-should-be-a-pure-function-of-props-and-state

const AWSSecurityLakeInputConfiguration: React.FC<Props> = (
) => {
  const history = useHistory();

  useEffect(() => {
    const url = AWSSecurityLakeRoutes.INTEGRATIONS.AWSSecurityLake.ACTIVITYAPI.index;
    history.push(url);
  }, []);

  return null;
};

export default AWSSecurityLakeInputConfiguration;
