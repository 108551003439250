export const parseErrorResponse = (errors) => {
  const oktaError = JSON.parse(errors?.additional?.res?.text);

  return oktaError && oktaError?.message;
};

export const customizeUserPayload = (formData) => ({ ...formData, username: formData.email });

export default {
  parseErrorResponse,
  customizeUserPayload,
};
