import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { Input } from 'components/bootstrap';

import UploadFieldContainer from './UploadField/UploadFieldContainer';

type UploadCSVFileAdapterFieldSetProps = {
  config: {
    [name: string]: string;
  };
  handleFormEvent: (e: { target: { name: string; value?: string } }) => void;
  setDisableFormSubmission: (formValidationState: boolean) => void;
};

function UploadCSVFileAdapterFieldSet({ config, handleFormEvent, setDisableFormSubmission }: UploadCSVFileAdapterFieldSetProps) {
  const _onFileUpdate = useCallback((fileId?: string) => {
    handleFormEvent({
      target: {
        name: 'file_id',
        value: fileId,
      },
    });
  }, [handleFormEvent]);

  return (
    <fieldset>
      <UploadFieldContainer onUpdate={_onFileUpdate}
                            fileId={config.file_id}
                            setDisableFormSubmission={setDisableFormSubmission}
                            accept=".csv" />
      <Input type="text"
             id="separator"
             name="separator"
             label="Separator"
             required
             onChange={handleFormEvent}
             help="The delimiter to use for separating entries."
             value={config.separator}
             labelClassName="col-sm-3"
             wrapperClassName="col-sm-9" />
      <Input type="text"
             id="quotechar"
             name="quotechar"
             label="Quote character"
             required
             onChange={handleFormEvent}
             help="The character to use for quoted elements."
             value={config.quotechar}
             labelClassName="col-sm-3"
             wrapperClassName="col-sm-9" />
      <Input type="text"
             id="key_column"
             name="key_column"
             label="Key column"
             required
             onChange={handleFormEvent}
             help="The column name that should be used for the key lookup."
             value={config.key_column}
             labelClassName="col-sm-3"
             wrapperClassName="col-sm-9" />
      <Input type="text"
             id="value_column"
             name="value_column"
             label="Value column"
             required
             onChange={handleFormEvent}
             help="The column name that should be used as the value for a key."
             value={config.value_column}
             labelClassName="col-sm-3"
             wrapperClassName="col-sm-9" />
      <Input type="checkbox"
             id="case_insensitive_lookup"
             name="case_insensitive_lookup"
             label="Allow case-insensitive lookups"
             checked={config.case_insensitive_lookup}
             onChange={handleFormEvent}
             help="Enable if the key lookup should be case-insensitive."
             wrapperClassName="col-md-offset-3 col-md-9" />
    </fieldset>
  );
}

UploadCSVFileAdapterFieldSet.propTypes = {
  config: PropTypes.object.isRequired,
  handleFormEvent: PropTypes.func.isRequired,
  setDisableFormSubmission: PropTypes.func.isRequired,
};

export default UploadCSVFileAdapterFieldSet;
