import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import UserNotification from 'util/UserNotification';
import type { InstantArchive } from 'instant-archiving/Types';
import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import { INSTANT_ARCHIVING_API_ROUTES } from 'instant-archiving/Constants';

export type InstantArchiveRestoreOperationDeleteProps = {
  archiveId: string,
  restoredOperationId: string
}
const fetchInstantArchive = (archiveId: string) => fetch('GET', qualifyUrl(INSTANT_ARCHIVING_API_ROUTES.InstantArchivingAPIController.get(archiveId).url));
const onDeleteInstantArchiveRestoreOperation = ({
  archiveId,
  restoredOperationId,
}: InstantArchiveRestoreOperationDeleteProps) => fetch('DELETE', qualifyUrl(INSTANT_ARCHIVING_API_ROUTES.InstantArchivingAPIController.delete(archiveId, restoredOperationId).url));

const useInstantArchiving = (archiveId: string): {
  instantArchiveData: {
    data: InstantArchive,
    refetch: () => void,
    isFetching: boolean,
    error: Error,
  },
  onDeleteInstantArchiveRestoredOperation: (restoreOperationDeleteProps: InstantArchiveRestoreOperationDeleteProps) => Promise<void>,
} => {
  const queryClient = useQueryClient();
  const { data, refetch, isFetching, error } = useQuery<InstantArchive, Error>(
    ['instant-archiving', archiveId],
    () => fetchInstantArchive(archiveId),
    {
      keepPreviousData: true,
      retry: 1,
    },
  );

  const { mutateAsync: onDeleteInstantArchiveRestoredOperation } = useMutation(onDeleteInstantArchiveRestoreOperation, {
    onSuccess: () => {
      queryClient.invalidateQueries(['instant-archiving']);
    },
    onError: (errorThrown) => {
      UserNotification.error(`Canceling Instant archiving job failed with status: ${errorThrown}`,
        'Could not cancel instant archiving jobs');
    },
  });

  return ({
    instantArchiveData: {
      data,
      refetch,
      isFetching,
      error,
    },
    onDeleteInstantArchiveRestoredOperation,
  });
};

export default useInstantArchiving;
