import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Field, Formik, Form } from 'formik';

import useInstantArchivingConfig from 'instant-archiving/InstantArchivingConfig/hooks/useInstantArchivingConfig';
import { useCreateInstantArchivingConfig, useUpdateInstantArchivingConfig } from 'instant-archiving/InstantArchivingConfig/hooks/useInstantArchivingConfigMutation';
import type { Stream } from 'stores/streams/StreamsStore';
import useStreams from 'components/streams/hooks/useStreams';
import type { InstantArchivingConfig } from 'instant-archiving/Types';
import { INSTANT_ARCHIVING_CONFIG_ACTION } from 'instant-archiving/Types';
import { Modal, Input } from 'components/bootstrap';
import { FormikInput, ModalSubmit, Select } from 'components/common';

type FormValues = Partial<Pick<InstantArchivingConfig, 'archive_name' | 'enabled'>> & { stream_ids: string }

type Props = {
  configurationId?: string,
  onClose: () => void,
}

const InstantArchivingConfigModal = ({ configurationId, onClose } : Props) => {
  const action: INSTANT_ARCHIVING_CONFIG_ACTION = configurationId ? INSTANT_ARCHIVING_CONFIG_ACTION.Update : INSTANT_ARCHIVING_CONFIG_ACTION.Create;
  const modalTitle = action === INSTANT_ARCHIVING_CONFIG_ACTION.Update ? 'Edit Archive Configuration' : 'Create Archive Configuration';
  const submitButtonText = action === INSTANT_ARCHIVING_CONFIG_ACTION.Update ? 'Update' : 'Create';
  const submitLoadingText = action === INSTANT_ARCHIVING_CONFIG_ACTION.Update ? 'Updating...' : 'Creating...';
  const { data: configuration, isSuccess: isArchiveConfigurationSuccess } = useInstantArchivingConfig(configurationId, !!configurationId);
  const { mutateAsync: mutateAsyncCreate } = useCreateInstantArchivingConfig(onClose);
  const { mutateAsync: mutateAsyncUpdate } = useUpdateInstantArchivingConfig(onClose);

  const prepareInitialValues = (existingConfiguration: InstantArchivingConfig | undefined) => ({
    archive_name: existingConfiguration?.archive_name ?? '',
    stream_ids: existingConfiguration?.stream_ids?.toString() ?? undefined,
    enabled: existingConfiguration?.enabled ?? true,
  });

  const initialValues = useMemo(
    () => prepareInitialValues(configuration),
    [configuration],
  );

  const validateArchiveName = (value) => {
    let error;

    if (value?.length === 0) {
      error = 'Invalid archive name: cannot be empty';
    } else if (value?.indexOf('_') === 0 || value?.indexOf('-') === 0 || value?.indexOf('+') === 0) {
      error = 'Invalid archive name: must start with a letter or number';
    } else if (value?.toLocaleLowerCase() !== value) {
      error = 'Invalid archive name: must be lower case';
    } else if (!value?.match(/^[a-z0-9][a-z0-9_\-+]*$/)) {
      error = 'Invalid archive name: must only contain letters, numbers, \'_\', \'-\' and \'+\'';
    }

    return error;
  };

  const validateStreamIds = (value) => {
    let error;

    if (!value || value?.length === 0) {
      error = 'Invalid streams: must at least contain one stream';
    }

    return error;
  };

  const handleSubmit = (values) => {
    const newStreamIds = values.stream_ids.split(',');
    const newConfiguration = { ...values, stream_ids: newStreamIds };

    if (action === INSTANT_ARCHIVING_CONFIG_ACTION.Update) {
      mutateAsyncUpdate({ ...newConfiguration, id: configuration.id },
      );
    } else {
      mutateAsyncCreate(newConfiguration);
    }
  };

  const { data: allStreams, isInitialLoading: isLoadingAllStreams } = useStreams(
    { query: '', page: 1, pageSize: 0, sort: { direction: 'asc', attributeId: 'title' } },
  );

  const buildOptions = (streams: Stream[]) => (
    streams.map((stream: Stream) => ({ label: stream.title, value: stream.id }))
  );

  if (configurationId && !isArchiveConfigurationSuccess) { return null; }

  return (
    <Modal title={modalTitle}
           onHide={onClose}
           show>
      <Formik<FormValues> initialValues={initialValues}
                          onSubmit={handleSubmit}>
        {({ isSubmitting, isValidating, setFieldTouched }) => (
          <Form>
            <Modal.Header closeButton>
              <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FormikInput label="Archive Name"
                           name="archive_name"
                           id="archive_name"
                           disabled={action === INSTANT_ARCHIVING_CONFIG_ACTION.Update}
                           validate={validateArchiveName}
                           help="Name of the archive" />
              {!isLoadingAllStreams && allStreams.elements?.length > 0 && (
                <Field name="stream_ids"
                       validate={validateStreamIds}>
                  {({ field: { name, value, onChange }, meta: { error, touched } }) => (
                    <Input bsStyle={(touched && error) ? 'error' : undefined}
                           id="stream_ids"
                           label="Streams"
                           error={(touched && error) ? error : undefined}
                           help="Streams to include in the archive">
                      <Select clearable={false}
                              multi
                              matchProp="label"
                              onBlur={() => setFieldTouched('stream_ids', true)}
                              onChange={(selectedStreams) => { onChange({ target: { value: selectedStreams, name } }); }}
                              options={buildOptions(allStreams.elements)}
                              placeholder="Select the streams to be included"
                              value={value} />
                    </Input>
                  )}
                </Field>
              )}
              <Input id="enable"
                     label="Archive Enabled">
                <FormikInput label="Enabled"
                             name="enabled"
                             id="enabled"
                             type="checkbox" />
              </Input>
            </Modal.Body>
            <Modal.Footer>
              <ModalSubmit submitButtonText={submitButtonText}
                           submitLoadingText={submitLoadingText}
                           onCancel={onClose}
                           disabledSubmit={isValidating}
                           isSubmitting={isSubmitting} />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

InstantArchivingConfigModal.propTypes = {
  configurationId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

InstantArchivingConfigModal.defaultProps = {
  configurationId: undefined,
};

export default InstantArchivingConfigModal;
