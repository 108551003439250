import * as React from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SecurityPage from 'pages/SecurityPage';

import Routes from 'routing/Routes';
import { ContentArea, Container, MenuButton, SideNav } from 'security-app/components/Layout/index';
import { StyledLink } from 'security-app/components/common';
import useLicenseCheck from 'license/useLicenseCheck';
import { securityRoutes } from 'security-app/routing/routes';
import withIsPermitted from 'forwarder/util/withIsPermitted';
import StringUtils from 'util/StringUtils';
import { DocumentTitle, Icon, Spinner, IfPermitted } from 'components/common';
import PageContentLayout from 'components/layout/PageContentLayout';
import useSearchVersionCheck from 'hooks/useSearchVersionCheck';

type Props = {
  children: any,
};

const REQUIRED_PERMISSIONS = ['graylog_security:read'];

const SecurityLayout = ({ children }: Props) => {
  const [showSideBar, setShowSideBar] = useState(true);
  const [title, setTitle] = useState('security');
  const { security: { isValid: isValidSecurityLicense, isLoading: isLoadingSecurityLicense } } = useLicenseCheck();
  const location = useLocation();
  const { isLoading: isLoadingSearchVersion } = useSearchVersionCheck('opensearch');

  useEffect(() => {
    const searchPattern = /\/security\//;
    const newLocation: string = location.pathname.replace(searchPattern, '');

    if (newLocation === '/security') {
      setTitle('Security');
    } else {
      setTitle(StringUtils.toTitleCase(newLocation, '-'));
    }
  }, [location]);

  if (isLoadingSecurityLicense || isLoadingSearchVersion) {
    return (
      <Spinner text="Loading security module ..." />
    );
  }

  if (!isValidSecurityLicense) {
    return (
      <PageContentLayout>
        <SecurityPage />
      </PageContentLayout>
    );
  }

  // TODO: Add PageContentLayout, you can override the styles for it.
  return (
    <DocumentTitle title={title}>
      <Container>
        <SideNav $isOpen={showSideBar}>
          <ul>
            {securityRoutes.map((route) => (
              <IfPermitted key={route.id} permissions={route.permissions}>
                <li>
                  <StyledLink to={Routes.pluginRoute(route.pathName)} end={route.end}>
                    <Icon name={route.iconName} size="xs" />
                    {route.title}
                  </StyledLink>
                </li>
              </IfPermitted>
            ))}
          </ul>

          <MenuButton $sideNavIsOpen={showSideBar} onClick={() => setShowSideBar(!showSideBar)}>
            <Icon name={showSideBar ? 'arrow-left' : 'th'} />
          </MenuButton>
        </SideNav>
        <ContentArea $sideNavIsOpen={showSideBar}>
          {children}
        </ContentArea>
      </Container>
    </DocumentTitle>
  );
};

export default withIsPermitted(SecurityLayout, REQUIRED_PERMISSIONS);
