import LicensesPage from 'license/LicensesPage';
import LicenseNotificationContainer from 'license/notifications/LicenseNotificationContainer';
import EnterpriseTrafficGraph from 'license/EnterpriseTrafficGraph';

import LicenseCheckProvider from './LicenseCheckProvider';
import EnterpriseProductLink from './EnterpriseProductLink';

export default {
  license: {
    EnterpriseTrafficGraph,
    EnterpriseProductLink,
  },
  routes: [
    { path: '/system/licenses', component: LicensesPage },
  ],
  globalNotifications: [
    {
      key: 'org.graylog.plugins.license.LicenseNotificationContainer',
      component: LicenseNotificationContainer,
    },
  ],
  globalContextProviders: [
    LicenseCheckProvider,
  ],
};
