import * as React from 'react';
import { useCallback } from 'react';

import Input from 'components/bootstrap/Input';
import * as FormsUtils from 'util/FormsUtils';

import type { ParameterDeclarationComponentProps } from '../ParameterTypes';

const GenericParameterForm = ({ idx, validationState, parameter: { defaultValue }, onChange }: ParameterDeclarationComponentProps) => {
  const _onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e.target.name;
    const value = FormsUtils.getValueFromInput(e.target);

    return onChange(key, value);
  }, [onChange]);

  return (
    <Input id={`default-value-${idx}`}
           type="text"
           name="defaultValue"
           label="Default"
           bsStyle={validationState?.defaultValue?.[0]}
           help={validationState?.defaultValue?.[1]}
           value={defaultValue}
           onChange={_onChange} />
  );
};

export default GenericParameterForm;
