import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';

import ValidatedInput from './common/ValidatedInput';
import FormWrap from './common/FormWrap';
import { ApiRoutes } from './common/Routes';
import { FormDataContext } from './context/FormData';
import formValidation from './utils/formValidation';

import handleFileUpload from '../helpers/fileReader';

const StepAuthorize = ({ onSubmit, onChange }) => {
  const { formData } = useContext(FormDataContext);
  const [formError, setFormError] = useState(null);

  const handleSubmit = () => {
    let fetcher = Promise.resolve();

    fetcher = fetch('POST', qualifyUrl(ApiRoutes.INTEGRATIONS.Gmail.CHECK_CREDENTIALS), {
      gmail_project_id: formData?.projectId?.value || '',
      gmail_client_id: formData?.clientId?.value || '',
      gmail_service_account_key: formData?.serviceAccountKey?.fileContent || '',
    });

    fetcher
      .then((result) => {
        if (result.result === 'valid') {
          setFormError(null);
          onSubmit();
        } else {
          setFormError({
            full_message: result.result,
            nice_message:
              'Unable to connect to Gmail using provided configuration.',
          });
        }
      })
      .catch((err) => {
        setFormError({
          full_message: err.message,
          nice_message:
            'Unable to connect to Gmail using provided configuration.',
        });
      });
  };

  const onFileUpload = (e) => {
    e.persist();
    handleFileUpload(e.target.files[0], (fileName, fileContent) => onChange(e, { fileName, fileContent }));
  };

  const accountKeyHelpText = formData.serviceAccountKey && formData.serviceAccountKey.fileName ? `Currently using file: ${formData.serviceAccountKey.fileName}.` : 'Service account key JSON.';

  const isFormValid = formValidation.isFormValid(
    ['gmailName', 'projectId', 'clientId', 'serviceAccountKey'],
    formData,
  );

  return (
    <FormWrap onSubmit={handleSubmit}
              buttonContent="Verify &amp; Proceed"
              disabled={isFormValid}
              title=""
              error={formError}
              description="">
      <ValidatedInput id="gmailName"
                      type="text"
                      fieldData={formData.gmailName}
                      onChange={onChange}
                      placeholder="Input Name"
                      label="Input Name"
                      autoComplete="off"
                      help="Select a name of your new input that describes it."
                      required />
      <ValidatedInput id="projectId"
                      type="text"
                      fieldData={formData.projectId}
                      onChange={onChange}
                      label="Project ID"
                      help="The GUID of the project to which the logs belong."
                      autoComplete="off"
                      required />
      <ValidatedInput id="clientId"
                      type="text"
                      onChange={onChange}
                      fieldData={formData.clientId}
                      help="The GUID of your application."
                      label="Application (client) ID"
                      required />
      <ValidatedInput id="serviceAccountKey"
                      type="file"
                      onChange={onFileUpload}
                      fieldData={formData.serviceAccountKey}
                      label="Service account key"
                      help={accountKeyHelpText}
                      accept=".json"
                      required />
    </FormWrap>
  );
};

StepAuthorize.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default StepAuthorize;
