import trim from 'lodash/trim';

import generateId from 'logic/generateId';
import type { SelectedSearchFilter } from 'search-filter/types';

interface MinimalEditor {
  getValue: () => string;
  setValue: (newValue: string) => void;
}

interface MinimalContext {
  searchFilters: {
    addFilter: (filter: SelectedSearchFilter, submit: boolean) => void;
  };
}

const CreateSearchFilterFromCurrentQuery = (editor: MinimalEditor, { searchFilters: { addFilter } }: MinimalContext) => {
  const queryString = editor.getValue();

  if (trim(queryString) === '') {
    return;
  }

  const frontendId = generateId();

  addFilter({
    frontendId,
    queryString,
    disabled: false,
    negation: false,
    type: 'inlineQueryString',
  }, false);

  editor.setValue('');
};

export default CreateSearchFilterFromCurrentQuery;
