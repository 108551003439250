import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Row } from 'components/bootstrap';
import { EntityList, Icon, OverlayTrigger, PaginatedList, SearchForm, Spinner, NoEntitiesExist, NoSearchResult } from 'components/common';
import usePaginationQueryParameter from 'hooks/usePaginationQueryParameter';
import type { LicenseStatus } from 'archive/types';
import type { Report as ReportType } from 'report/types';

import Report from './Report';
import ReportQueryHelper from './ReportQueryHelper';

import ReportsActions from '../ReportsActions';

type Props = {
  licenseStatus: LicenseStatus,
};

type PaginatedReports = {
  reports: Array<ReportType>
  count: number,
  total: number,
  page: number,
  perPage: number,
  query: string,
};

const _loadData = (pagination, setReports) => {
  ReportsActions.listPage(pagination)
    .then((reports) => {
      setReports(reports);
    });
};

const _updateListOnReportDelete = (pagination, setReports, callback: () => void) => ReportsActions.delete.completed.listen(() => {
  _loadData(pagination, setReports);
  callback();
});

const queryHelperComponent = (
  <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={<ReportQueryHelper />}>
    <Button bsStyle="link" className="archive-search-help-button">
      <Icon name="question-circle" fixedWidth />
    </Button>
  </OverlayTrigger>
);

const ReportList = ({ licenseStatus }: Props) => {
  const { page, pageSize: perPage, resetPage } = usePaginationQueryParameter();
  const [query, setQuery] = useState('');
  const [paginatedReports, setPaginatedReports] = useState<PaginatedReports | undefined>();

  useEffect(() => _loadData({ page, perPage, query }, setPaginatedReports), [page, perPage, query]);
  useEffect(() => _updateListOnReportDelete({ page, perPage, query }, setPaginatedReports, resetPage), [page, perPage, query, resetPage]);

  if (!paginatedReports) {
    return <div><Spinner delay={500} /></div>;
  }

  const handleSearch = (newQuery) => {
    resetPage();
    setQuery(newQuery);
  };

  return (
    <div>
      <Row className="row-sm">
        <Col md={8}>
          <SearchForm onSearch={handleSearch}
                      queryHelpComponent={queryHelperComponent}
                      onReset={() => handleSearch('')} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          {(!paginatedReports.total && !query) && (
            <NoEntitiesExist>
              No reports have been created yet. Click on &apos;Create report&apos; to get started.
            </NoEntitiesExist>
          )}

          {(!paginatedReports.total && query) && (
            <NoSearchResult>
              No reports have been found.
            </NoSearchResult>
          )}

          {!!paginatedReports.total && (
            <PaginatedList totalItems={paginatedReports.total}>
              <br />
              <br />
              <EntityList noItemsText="There are no reports to display, try adjusting your search filter"
                          items={paginatedReports.reports.map((report) => (
                            <Report key={report.id} report={report} licenseStatus={licenseStatus} />
                          ))} />
            </PaginatedList>
          )}
        </Col>
      </Row>
    </div>
  );
};

ReportList.propTypes = {
  licenseStatus: PropTypes.object.isRequired,
};

export default ReportList;
