import loadAsync from 'routing/loadAsync';

const IlluminateInstall = loadAsync(() => import('illuminate/pages/IlluminateInstallPage'));
const IlluminateCustomize = loadAsync(() => import('illuminate/pages/IlluminateCustomizePage'));
const Illuminate = loadAsync(() => import('illuminate/pages/IlluminatePage'));

export {
  IlluminateInstall,
  IlluminateCustomize,
  Illuminate,
};
