import __request__ from 'routing/request';
interface ProcessingStatusSummary {
    readonly receive_times: ReceiveTimes;
}
interface ReceiveTimes {
    readonly post_indexing: string;
    readonly post_processing: string;
    readonly ingest: string;
}
interface ProcessingStatusSummaryMap {
    readonly [_key: string]: ProcessingStatusSummary;
}
/**
 * Get processing status from all nodes in the cluster
 */
export function getStatus(): Promise<ProcessingStatusSummaryMap> {
    return __request__('GET', '/cluster/processing/status', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get persisted processing status from all nodes in the cluster
 */
export function getPersistedStatus(): Promise<ProcessingStatusSummaryMap> {
    return __request__('GET', '/cluster/processing/status/persisted', null, {}, {
        'Accept': ['application/json']
    });
}
