import * as React from 'react';
import { useState, useCallback } from 'react';

import StringUtils from 'util/StringUtils';
import UserNotification from 'util/UserNotification';
import { MenuItem } from 'components/bootstrap';
import { ConfirmDialog } from 'components/common';
import { deleteInstantArchivingConfig } from 'instant-archiving/InstantArchivingConfig/hooks/useInstantArchivingConfigMutation';

type Props = {
  handleFailures: (failures: Array<{ entity_id: string }>, actionPastTense: string) => void,
  onSelect?: () => void,
  refetchArchiveConfigs: () => void,
  selectedArchiveConfigIds: Array<string>,
};

const DeleteArchiveConfigAction = ({ selectedArchiveConfigIds, handleFailures, refetchArchiveConfigs, onSelect }: Props) => {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const descriptor = StringUtils.pluralize(selectedArchiveConfigIds?.length, 'archive configuration', 'archive configurations');
  const onDeleteSubmit = useCallback(() => {
    deleteInstantArchivingConfig(selectedArchiveConfigIds).then((response) => {
      const failures = response?.failures || [];
      handleFailures(failures, 'submitted');
    }).catch((error) => {
      UserNotification.error(`An error occurred while deleting Instant Archive configurations. ${error}`);
    }).finally(() => {
      setShowConfirmDelete(false);
      refetchArchiveConfigs();
    });
  }, [selectedArchiveConfigIds, handleFailures, refetchArchiveConfigs]);

  const onDelete = () => {
    if (typeof onSelect === 'function') {
      onSelect();
    }

    setShowConfirmDelete(true);
  };

  return (
    <>
      <MenuItem bsSize="xsmall" onClick={() => onDelete()}>Delete</MenuItem>
      {showConfirmDelete && (
      <ConfirmDialog title="Deleting archive configurations, are you sure?"
                     show
                     onConfirm={onDeleteSubmit}
                     onCancel={() => setShowConfirmDelete(false)}>
        <p>{`You are about to remove ${selectedArchiveConfigIds?.length} ${descriptor}. This action cannot be undone.`}</p>
      </ConfirmDialog>
      )}
    </>
  );
};

DeleteArchiveConfigAction.defaultProps = {
  onSelect: undefined,
};

export default DeleteArchiveConfigAction;
