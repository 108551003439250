import __request__ from 'routing/request';
interface anyMap {
    readonly [_key: string]: unknown;
}
/**
 * Get internal Graylog system messages
 * @param page Page
 */
export function all(page?: number): Promise<anyMap> {
    return __request__('GET', '/system/messages', null, { 'page': page }, {
        'Accept': ['application/json']
    });
}
