import debounceWithPromise from 'views/logic/debounceWithPromise';
import validateQuery from 'views/components/searchbar/queryvalidation/validateQuery';

type FormValues = {
  title?: string,
  description?: string
  queryString?: string
}

const debouncedValidateQuery = debounceWithPromise(validateQuery, 350);

const validateForm = async (
  values: FormValues,
  { setFieldWarning, pluggableValidationPayload = {}, userTimezone }: { setFieldWarning: (fieldName: string, warning: unknown) => void; userTimezone: string, pluggableValidationPayload?: object },
) => {
  const errors: { queryString?: object } = {};

  const queryValidation = await debouncedValidateQuery({
    queryString: values?.queryString,
    validation_mode: 'SEARCH_FILTER',
    ...pluggableValidationPayload,
  }, userTimezone);

  if (queryValidation?.status === 'OK') {
    setFieldWarning('queryString', undefined);
  }

  if (queryValidation?.status === 'WARNING') {
    setFieldWarning('queryString', queryValidation);
  }

  if (queryValidation?.status === 'ERROR') {
    setFieldWarning('queryString', undefined);
    errors.queryString = queryValidation;
  }

  return errors;
};

export default validateForm;
