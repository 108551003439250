import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { IndicesPropType } from 'archive/propTypes';
import { Button } from 'components/bootstrap';
import { Select } from 'components/common';
import NumberUtils from 'util/NumberUtils';
import type { Indices } from 'stores/indices/IndicesStore';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'logic/telemetry/Constants';

const StyledForm = styled.form`
  margin-top: 10px;
  margin-bottom: 15px;

  .form-group {
    width: 400px;
  }
`;

const StyledP = styled.p`
  margin-top: 5px;
`;

const _getDefaultIndex = (indices: Indices) => {
  const defaultIndex = indices
    .filter((index) => !index.index_name.endsWith('restored_archive') && index.primary_shards.documents.count >= 1)[0];

  return defaultIndex?.index_name;
};

type Props = {
  indices: Indices,
  createArchive: (archiveName: string) => void,
};

const ArchiveCreationForm = ({ indices = [], createArchive }: Props) => {
  const storableIndices = indices.filter(({ index_name }) => !index_name.endsWith('restored_archive'));
  const [selectedIndexName, setSelectedIndexName] = useState<string | undefined>(_getDefaultIndex(indices));
  const indicesOptions = useMemo(() => {
    if (storableIndices.length) {
      return storableIndices.map((index) => {
        const docs = index.primary_shards.documents.count;
        const size = NumberUtils.formatBytes(index.primary_shards.store_size_bytes);

        return {
          value: index.index_name,
          label: `${index.index_name} (${NumberUtils.formatNumber(docs)} documents / ${size})`,
          disabled: docs < 1,
        };
      });
    }

    return ([{ value: 'none', label: 'No indices available', disabled: true }]);
  }, [storableIndices]);
  const sendTelemetry = useSendTelemetry();

  const _onIndexSelect = (selection) => {
    if (selection === '') {
      setSelectedIndexName(_getDefaultIndex(indices));
    }

    setSelectedIndexName(selection);
  };

  const _onSubmit = (e) => {
    e.preventDefault();

    if (selectedIndexName) {
      createArchive(selectedIndexName);
    }

    sendTelemetry(TELEMETRY_EVENT_TYPE.ARCHIVE.CREATED, {
      app_pathname: 'archive',
      app_section: 'archive-catalog',
    });
  };

  const _isButtonDisabled = () => !selectedIndexName || indices.find(({ index_name }) => index_name === selectedIndexName).primary_shards.documents.count < 1;

  return (
    <span>
      <h2>Create Archive for Index</h2>
      <StyledP>Select an index to be archived to disk. This action will not close or delete the original index.</StyledP>
      <StyledForm className="form-inline" onSubmit={_onSubmit}>
        <div className="form-group">
          <Select inputProps={{ 'aria-label': 'Select index for archiving' }}
                  placeholder="Select index"
                  options={indicesOptions}
                  matchProp="value"
                  onChange={_onIndexSelect}
                  value={selectedIndexName} />
        </div>
        &nbsp;
        <Button bsStyle="success" type="submit" disabled={_isButtonDisabled()}>Archive index</Button>
      </StyledForm>
    </span>
  );
};

ArchiveCreationForm.propTypes = {
  indices: IndicesPropType.isRequired,
  createArchive: PropTypes.func,
};

ArchiveCreationForm.defaultProps = {
  createArchive: () => {
  },
};

export default ArchiveCreationForm;
