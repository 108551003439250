import React, { useMemo, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';

import useAppSelector from 'stores/useAppSelector';
import type ParameterType from 'views/logic/parameters/Parameter';
import { Icon } from 'components/common';
import { Button } from 'components/bootstrap';
import CreateParameterDialog from 'enterprise/parameters/creatoractions/CreateParameterDialog';
import { selectSearchId } from 'views/logic/slices/viewSelectors';
import useParameters from 'views/hooks/useParameters';
import { removeParameter, updateParameter } from 'views/logic/slices/searchExecutionSlice';
import useAppDispatch from 'stores/useAppDispatch';

import Parameter from './Parameter';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
`;

const CreateButton = styled(Button)(({ $displaySpacer }: { $displaySpacer: boolean }) => `
  margin-left: 5px;
  margin-right: ${$displaySpacer ? '5px;' : '0'};
`);

const Parameters = () => {
  const { setFieldValue } = useFormikContext();
  const { parameters } = useParameters();
  const searchId = useAppSelector(selectSearchId);
  const [isCreateFormOpen, setIsCreateFormOpen] = useState(false);
  const toggleIsCreateFormOpen = useCallback(() => setIsCreateFormOpen((isOpen) => !isOpen), []);
  const dispatch = useAppDispatch();
  const handleRemoveParameter = useCallback((parameterName: string) => dispatch(removeParameter(parameterName)), [dispatch]);
  const handleEdit = useCallback((name: string, parameter: ParameterType) => dispatch(updateParameter(name, parameter)), [dispatch]);

  const parameterFields = useMemo(() => {
    const onDelete = (parameterName: string, formFieldName) => handleRemoveParameter(parameterName).then(() => {
      setFieldValue(formFieldName, undefined);
    });

    return parameters
      .sort((p1: ParameterType, p2: ParameterType) => p1.title.localeCompare(p2.title))
      .map((param) => (
        <div key={param.name}>
          <Parameter parameter={param}
                     searchId={searchId}
                     onDelete={onDelete}
                     onEdit={handleEdit} />
        </div>

      ))
      .valueSeq()
      .toArray();
  }, [handleEdit, handleRemoveParameter, parameters, searchId, setFieldValue]);

  return (
    <Wrapper>
      <div>
        Parameters
        <CreateButton bsSize="small" onClick={toggleIsCreateFormOpen} title="Declare parameters" $displaySpacer={!!parameterFields?.length}>
          <Icon name="plus" />
        </CreateButton>
        {isCreateFormOpen && <CreateParameterDialog onClose={toggleIsCreateFormOpen} />}
      </div>
      {parameterFields}
    </Wrapper>
  );
};

export default Parameters;
