import React from 'react';
import PropTypes from 'prop-types';

import { SidebarProvider } from './context/Sidebar';
import { FormDataProvider } from './context/FormData';
import { StepsProvider } from './context/Steps';
import { AdvancedOptionsProvider } from './context/AdvancedOptions';
import O365 from './O365';
import INITIAL_FORMDATA from './_initialFormData';
import { toGenericInputCreateRequest } from './common/formDataAdapter';

type Props = {
  onSubmit: (InputCreateRequest) => void,
};

const EmbeddedO365App = ({ onSubmit }: Props) => {
  const handleSubmit = (formData) => {
    if (!onSubmit) {
      return;
    }

    onSubmit(toGenericInputCreateRequest(formData));
  };

  return (
    <StepsProvider>
      <FormDataProvider initialFormData={INITIAL_FORMDATA}>
        <SidebarProvider>
          <AdvancedOptionsProvider>
            <O365 onSubmit={handleSubmit} externalInputSubmit={typeof onSubmit === 'function'} />
          </AdvancedOptionsProvider>
        </SidebarProvider>
      </FormDataProvider>
    </StepsProvider>
  );
};

EmbeddedO365App.propTypes = {
  onSubmit: PropTypes.func,
};

EmbeddedO365App.defaultProps = {
  onSubmit: undefined,
};

export default EmbeddedO365App;
