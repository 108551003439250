import * as React from 'react';
import PropTypes from 'prop-types';
import { styled, css } from 'styled-components';
import type { DefaultTheme } from 'styled-components';

import { Label } from 'components/bootstrap';

const Stream = styled(Label)(({ theme }: { theme: DefaultTheme }) => css`
  margin-right: ${theme.spacings.xs};
  margin-bottom: ${theme.spacings.xs};
  display: inline-block;
  padding: ${theme.spacings.xxs} ${theme.spacings.xs};
`);

type Props = {
  streamNames?: Array<string>,
  isArchiveStream?: boolean,
}

const InstantArchiveStreamList = ({ streamNames, isArchiveStream }: Props) => (
  <span>
    {streamNames?.map((title) => <Stream key={title} bsStyle={isArchiveStream ? 'default' : 'info'}>{title}</Stream>)}
  </span>
);

export default InstantArchiveStreamList;

InstantArchiveStreamList.propTypes = {
  streamNames: PropTypes.arrayOf(PropTypes.string),
};

InstantArchiveStreamList.defaultProps = {
  streamNames: [],
  isArchiveStream: false,
};
