// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ff_wq_JFNaus16g2urPi {
    margin-top: 10px;
    margin-bottom: 10px;
}

.gscL0954M9nQgQNOk0gk label,
.w45MXyOBMs6iaAbYG5A2 p {
    font-size: 14px;
    font-weight: initial;
}

.LZ2378ON5wkbTa4lX6pQ {
    margin: 10px 0;
    text-align: center;
}

.w45MXyOBMs6iaAbYG5A2 {
    margin-bottom: 15px;
}

.radio .w45MXyOBMs6iaAbYG5A2 {
    margin-bottom: 0;
}

.w45MXyOBMs6iaAbYG5A2 .form-group:first-child {
    margin-right: 10px;
}

.w45MXyOBMs6iaAbYG5A2 .form-group+*,
.w45MXyOBMs6iaAbYG5A2 *+.form-group {
    margin-right: 10px;
}

.aWEGiMrJiXTD940vTvTN .panel-body {
    padding: 5px 15px;
}

.bT4HY2IM0lO1LcXgfwqr {
    list-style-position: inside;
    margin: 20px 0;
    padding: 0;
    text-align: center;
}

.bT4HY2IM0lO1LcXgfwqr li:first-child {
    margin: 0;
}

.bT4HY2IM0lO1LcXgfwqr li {
    margin-top: 5px;
}

.bT4HY2IM0lO1LcXgfwqr li::after {
    content: '\\2193'; /* Arrow down */
    display: block;
    font-size: 1.5em;
}

.bT4HY2IM0lO1LcXgfwqr li:last-child::after {
    content: '';
}

.TctHPH9CZx3wVwFdH5kd.alert {
    margin-bottom: 10px;
}

.TctHPH9CZx3wVwFdH5kd h4 {
    font-size: 16px;
    margin-bottom: 0.5em;
}

.TctHPH9CZx3wVwFdH5kd ul {
    list-style: disc;
    margin: 0.5em 0;
}
`, "",{"version":3,"sources":["webpack://./src/web/event-correlation/commonStyles.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;;IAEI,eAAe;IACf,oBAAoB;AACxB;;AAEA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;;IAEI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,2BAA2B;IAC3B,cAAc;IACd,UAAU;IACV,kBAAkB;AACtB;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB,EAAE,eAAe;IACjC,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[":local(.title) {\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n\n:local(.correlationForm) label,\n:local(.inlineFormGroup) p {\n    font-size: 14px;\n    font-weight: initial;\n}\n\n:local(.followedBy) {\n    margin: 10px 0;\n    text-align: center;\n}\n\n:local(.inlineFormGroup) {\n    margin-bottom: 15px;\n}\n\n.radio :local(.inlineFormGroup) {\n    margin-bottom: 0;\n}\n\n:local(.inlineFormGroup) .form-group:first-child {\n    margin-right: 10px;\n}\n\n:local(.inlineFormGroup) .form-group+*,\n:local(.inlineFormGroup) *+.form-group {\n    margin-right: 10px;\n}\n\n:local(.preview) .panel-body {\n    padding: 5px 15px;\n}\n\n:local(.previewList) {\n    list-style-position: inside;\n    margin: 20px 0;\n    padding: 0;\n    text-align: center;\n}\n\n:local(.previewList) li:first-child {\n    margin: 0;\n}\n\n:local(.previewList) li {\n    margin-top: 5px;\n}\n\n:local(.previewList) li::after {\n    content: '\\2193'; /* Arrow down */\n    display: block;\n    font-size: 1.5em;\n}\n\n:local(.previewList) li:last-child::after {\n    content: '';\n}\n\n:local(.validationSummary).alert {\n    margin-bottom: 10px;\n}\n\n:local(.validationSummary) h4 {\n    font-size: 16px;\n    margin-bottom: 0.5em;\n}\n\n:local(.validationSummary) ul {\n    list-style: disc;\n    margin: 0.5em 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `Ff_wq_JFNaus16g2urPi`,
	"correlationForm": `gscL0954M9nQgQNOk0gk`,
	"inlineFormGroup": `w45MXyOBMs6iaAbYG5A2`,
	"followedBy": `LZ2378ON5wkbTa4lX6pQ`,
	"preview": `aWEGiMrJiXTD940vTvTN`,
	"previewList": `bT4HY2IM0lO1LcXgfwqr`,
	"validationSummary": `TctHPH9CZx3wVwFdH5kd`
};
export default ___CSS_LOADER_EXPORT___;
