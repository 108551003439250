import * as Immutable from 'immutable';
import type { $PropertyType } from 'utility-types';

type TeamSourceTypes = 'INTERNAL' | 'SYNCED';

type InternalState = {
  id: string,
  name: string,
  description: string,
  users: Immutable.Set<string>,
  roles: Immutable.Set<string>,
  authServiceId: string | null | undefined,
  authServiceUid: string | null | undefined,
  type: TeamSourceTypes,
};

export type TeamJSON = {
  id: string,
  name: string,
  description: string,
  users: Array<string>,
  roles: Array<string>,
  auth_service_id: string | null | undefined,
  auth_service_uid: string | null | undefined,
  type: TeamSourceTypes,
};

export default class Team {
  _value: InternalState;

  // eslint-disable-next-line no-undef
  constructor(
    id: $PropertyType<InternalState, 'id'>,
    name: $PropertyType<InternalState, 'name'>,
    description: $PropertyType<InternalState, 'description'>,
    users: $PropertyType<InternalState, 'users'>,
    roles: $PropertyType<InternalState, 'roles'>,
    authServiceId: $PropertyType<InternalState, 'authServiceId'>,
    authServiceUid: $PropertyType<InternalState, 'authServiceUid'>,
    type: $PropertyType<InternalState, 'type'>,
  ) {
    this._value = {
      id,
      name,
      description,
      users: users ?? Immutable.Set(),
      roles: roles ?? Immutable.Set(),
      authServiceId,
      authServiceUid,
      type,
    };
  }

  get id() {
    return this._value.id;
  }

  get name() {
    return this._value.name;
  }

  get description() {
    return this._value.description;
  }

  get users() {
    return this._value.users;
  }

  get roles() {
    return this._value.roles;
  }

  get authServiceId() {
    return this._value.authServiceId;
  }

  get authServiceUid() {
    return this._value.authServiceUid;
  }

  get type() {
    return this._value.type;
  }

  get isSynced() {
    return this._value.type === 'SYNCED';
  }

  toBuilder() {
    const {
      id,
      name,
      description,
      users,
      roles,
      authServiceId,
      authServiceUid,
      type,
    } = this._value;

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return new Builder(Immutable.Map({
      id,
      name,
      description,
      users,
      roles,
      authServiceId,
      authServiceUid,
      type,
    }));
  }

  static builder() {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return new Builder(Immutable.Map({}));
  }

  // eslint-disable-next-line no-undef
  static create(
    id: $PropertyType<InternalState, 'id'>,
    name: $PropertyType<InternalState, 'name'>,
    description: $PropertyType<InternalState, 'description'>,
    users: $PropertyType<InternalState, 'users'>,
    roles: $PropertyType<InternalState, 'roles'>,
    authServiceId: $PropertyType<InternalState, 'authServiceId'>,
    authServiceUid: $PropertyType<InternalState, 'authServiceUid'>,
    type: $PropertyType<InternalState, 'type'>,
  ) {
    return new Team(id, name, description, users, roles, authServiceId, authServiceUid, type);
  }

  static empty() {
    return Team.create('', '', '', Immutable.Set(), Immutable.Set(), undefined, undefined, 'INTERNAL');
  }

  toJSON() {
    const {
      id,
      name,
      description,
      users = Immutable.Set(),
      roles = Immutable.Set(),
      authServiceId,
      authServiceUid,
      type,
    } = this._value;

    return {
      id,
      name,
      description,
      users: users.toJS(),
      roles: roles.toJS(),
      auth_service_id: authServiceId,
      auth_service_uid: authServiceUid,
      type,
    };
  }

  static fromJSON(value: TeamJSON) {
    const {
      id,
      name,
      description,
      roles = [],
      users = [],
      auth_service_id: authServiceId,
      auth_service_uid: authServiceUid,
      type,
    } = value;

    return Team.create(id, name, description, Immutable.Set(users), Immutable.Set(roles), authServiceId, authServiceUid, type);
  }
}

type BuilderState = Immutable.Map<string, any>;

class Builder {
  value: BuilderState;

  constructor(value: BuilderState = Immutable.Map()) {
    this.value = value;
  }

  id(value: $PropertyType<InternalState, 'id'>) {
    return new Builder(this.value.set('id', value));
  }

  name(value: $PropertyType<InternalState, 'name'>) {
    return new Builder(this.value.set('name', value));
  }

  description(value: $PropertyType<InternalState, 'description'>) {
    return new Builder(this.value.set('description', value));
  }

  users(value: $PropertyType<InternalState, 'users'>) {
    return new Builder(this.value.set('users', value));
  }

  roles(value: $PropertyType<InternalState, 'roles'>) {
    return new Builder(this.value.set('roles', value));
  }

  authServiceId(value: $PropertyType<InternalState, 'authServiceId'>) {
    return new Builder(this.value.set('authServiceId', value));
  }

  authServiceUid(value: $PropertyType<InternalState, 'authServiceUid'>) {
    return new Builder(this.value.set('authServiceUid', value));
  }

  type(value: $PropertyType<InternalState, 'type'>) {
    return new Builder(this.value.set('type', value));
  }

  build() {
    const {
      id,
      name,
      description,
      users,
      roles,
      authServiceId,
      authServiceUid,
      type,
    } = this.value.toObject();

    return new Team(id, name, description, users, roles, authServiceId, authServiceUid, type);
  }
}
