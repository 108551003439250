import React from 'react';
import PropTypes from 'prop-types';

import getDisplayName from 'authentication/components/oidc/config/helpers/getDisplayName';
import { FormikFormGroup } from 'components/common';

const OidcBaseUrlField = ({ help, type, isOkta, disabled, validate }) => {
  const label = `${getDisplayName(type)} base URL`;

  const name = isOkta ? 'oktaBaseUrl' : 'baseUrl';

  return (
    <div>
      <FormikFormGroup help={help}
                       label={label}
                       name={name}
                       disabled={disabled}
                       validate={validate}
                       placeholder={label} />
    </div>
  );
};

OidcBaseUrlField.propTypes = {
  help: PropTypes.element.isRequired,
  type: PropTypes.string.isRequired,
  isOkta: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  validate: PropTypes.func.isRequired,
};

export default OidcBaseUrlField;
