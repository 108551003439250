import { qualifyUrl } from 'util/URLUtils';
import EnterpriseApiRoutes from 'common/ApiRoutes';
import glFetch from 'logic/rest/FetchProvider';
import { stringifyFilters } from 'security-app/components/common/Filters/stringifyFilters';
import type { ColumnFilterData } from 'security-app/components/common/Filters/ColumnFilter.types';

import type {
  AssetsIndexAPIType,
  AssetAPIType,
  PriorityAPIType,
  AssetDetailsType,
  AssetSourceIndexAPIType,
  AssetSourceAPIType,
  AssetSourceMappingAPIType,
  AssetSourceMappingConfigsType,
  AssetSourceMappingsIndexAPIType,
} from './assetsAPI.types';

export const fetchAssets = async (
  page: number,
  perPage: number,
  query: string = null,
  orderBy: string = null,
  direction: string = null,
  filters: ColumnFilterData = {},
): Promise<AssetsIndexAPIType> => {
  const params = [
    `page=${page}`,
    `per_page=${perPage}`,
    `sort=${orderBy}`,
    `direction=${direction}`,
  ];
  if (query) params.push(`query=${encodeURI(query)}`);

  if (filters && Object.keys(filters).length > 0) {
    params.push(`filters=${encodeURI(stringifyFilters(filters))}`);
  }

  return glFetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.index(params.join('&')).url));
};

export const fetchAsset = async (assetId: string) => (
  glFetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.singleAsset(assetId).url))
);

export const getAssetsById = async (query: string) => (
  glFetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.assetIdLookup(query).url))
);

export const deleteAsset = async ({ assetId }: { assetId: string }) => glFetch(
  'DELETE',
  qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.singleAsset(assetId).url),
);

export const updateAsset = async ({ assetId, asset }: { assetId: string, asset: AssetAPIType<AssetDetailsType> }) => (
  glFetch('PUT', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.singleAsset(assetId).url), asset)
);

export const createAsset = async ({ asset }: { asset: AssetAPIType<AssetDetailsType>}) => (
  glFetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.new().url), asset)
);

export const fetchPriorities = async () => (glFetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.priorities.index().url)));

export const fetchCategories = async () => (glFetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.categories.index().url)));

export const addPriority = async ({ priority }: { priority: string }) => (
  glFetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.priorities.add().url), priority)
);

export const reprioritizePriorities = async ({ payload }: { payload: { priorities: PriorityAPIType[] }}) => (
  glFetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.priorities.reprioritize().url), payload)
);

export const updatePriority = async ({ priorityId, priority }: { priorityId: string, priority: string }) => (
  glFetch('PUT', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.priorities.update(priorityId).url), priority)
);

export const deletePriority = async ({ priorityId }: { priorityId: string }) => (
  glFetch('DELETE', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.priorities.delete(priorityId).url))
);

export const addCategory = async ({ category }: { category: string }) => (
  glFetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.categories.add().url), category)
);

export const updateCategory = async ({ categoryId, category }: { categoryId: string, category: string }) => (
  glFetch('PUT', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.categories.update(categoryId).url), category)
);

export const deleteCategory = async ({ categoryId }: { categoryId: string }) => (
  glFetch('DELETE', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.categories.delete(categoryId).url))
);

/* Asset Sources */
export const fetchAssetSources = async (
  page: number,
  perPage: number,
  query: string = null,
  orderBy: string = null,
  direction: string = null,
  filters: ColumnFilterData = {},
): Promise<AssetSourceIndexAPIType> => {
  const params = [
    `page=${page}`,
    `per_page=${perPage}`,
    `sort=${orderBy}`,
    `direction=${direction}`,
  ];
  if (query) params.push(`query=${encodeURI(query)}`);

  if (filters && Object.keys(filters).length > 0) {
    params.push(`filters=${encodeURI(stringifyFilters(filters))}`);
  }

  return glFetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.sources.index(params.join('&')).url));
};

export const fetchAssetSource = async (sourceId: string) => (
  glFetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.sources.details(sourceId).url))
);

export const createAssetSource = async ({ source }: { source: AssetSourceAPIType}) => (
  glFetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.sources.new().url), source)
);

export const updateAssetSource = async ({ sourceId, source }: { sourceId: string, source: AssetSourceAPIType }) => (
  glFetch('PUT', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.sources.update(sourceId).url), source)
);

export const deleteAssetSource = async ({ sourceId }: { sourceId: string }) => (
  glFetch('DELETE', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.sources.delete(sourceId).url))
);

/* Asset Source Mappings */
export const fetchAssetSourceMappings = async (
  sourceId: string,
  page: number,
  perPage: number,
): Promise<AssetSourceMappingsIndexAPIType> => {
  const params = [
    `page=${page}`,
    `per_page=${perPage}`,
    'sort=updated_at',
    'direction=desc',
  ];

  return glFetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.sources.mappings.index(sourceId, params.join('&')).url));
};

export const fetchAssetSourceMapping = async (sourceId: string, mappingId: string) => (
  glFetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.sources.mappings.details(sourceId, mappingId).url))
);

export const createAssetSourceMapping = async ({ sourceId, mapping }: { sourceId: string, mapping: AssetSourceMappingAPIType<AssetSourceMappingConfigsType> }) => (
  new Promise((resolve, reject) => {
    fetch(qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.sources.mappings.new(sourceId).url), {
      method: 'POST',
      body: JSON.stringify(mapping),
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-By': 'XMLHttpRequest',
      },
    }).then(async (response) => {
      const respBody = await response.json();
      if (response.ok) resolve(respBody);
      else reject(respBody);
    });
  })
);

export const updateAssetSourceMapping = async ({ sourceId, mappingId, mapping }: { sourceId: string, mappingId: string, mapping: AssetSourceMappingAPIType<AssetSourceMappingConfigsType> }) => (
  new Promise((resolve, reject) => {
    fetch(qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.sources.mappings.update(sourceId, mappingId).url), {
      method: 'PUT',
      body: JSON.stringify(mapping),
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-By': 'XMLHttpRequest',
      },
    }).then(async (response) => {
      const respBody = await response.json();
      if (response.ok) resolve(respBody);
      else reject(respBody);
    });
  })
);

export const deleteAssetSourceMapping = async ({ sourceId, mappingId }: { sourceId: string, mappingId: string }) => (
  glFetch('DELETE', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.sources.mappings.delete(sourceId, mappingId).url))
);

export const importAssets = async (sourceId: string, mappingId: string) => (
  glFetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.sources.mappings.import(sourceId, mappingId).url))
);

/* Test Asset Source & Mappings */
export const testNewAssetSource = async ({ source }: { source: AssetSourceAPIType }) => (
  glFetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.sources.testNew().url), source)
);

export const testNewAssetSrouceMapping = async ({ sourceId, mapping }: { sourceId: string, mapping: AssetSourceMappingAPIType<AssetSourceMappingConfigsType> }) => {
  const mappingId = mapping.id || 'new';
  const payload = { asset_import_backend_configuration: mapping };

  return glFetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.sources.mappings.testNew(sourceId, mappingId).url), payload);
};

export const testSavedAssetSource = async (sourceId: string) => (
  glFetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.sources.testSaved(sourceId).url))
);

export const bulkTestAssetSource = async ({ ids }: { ids: string[] }) => {
  const requestBody = {
    ids,
  };

  return glFetch('POST', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.sources.bulkTest().url), requestBody);
};

export const testSavedAssetSourceMapping = async (sourceId: string, mappingId: string) => (
  glFetch('GET', qualifyUrl(EnterpriseApiRoutes.SecurityApp.Assets.sources.mappings.testSaved(sourceId, mappingId).url))
);
