import * as React from 'react';

import SectionComponent from 'components/common/Section/SectionComponent';
import ReadOnlyFormGroup from 'components/common/ReadOnlyFormGroup';
import Routes from 'routing/Routes';
import { Link } from 'components/common/router';
import AppConfig from 'util/AppConfig';

import type Team from '../../logic/Team';

const _getSourceType = (authServiceId, type = 'unknown') => {
  if (type === 'SYNCED' && authServiceId) {
    return (
      <>
        {type.toLowerCase()} with <Link to={Routes.SYSTEM.AUTHENTICATION.BACKENDS.show(authServiceId)}>authentication service</Link>
      </>
    );
  }

  return type.toLowerCase();
};

type Props = {
  team: Team,
};

const isCloud = AppConfig.isCloud();

const ProfileSection = ({
  team: {
    authServiceId,
    description,
    name,
    type,
  },
}: Props) => {
  const sourceType = _getSourceType(authServiceId, type);

  return (
    <SectionComponent title="Profile">
      <ReadOnlyFormGroup label="Name" value={name} />
      <ReadOnlyFormGroup label="Description" value={description} />
      {!isCloud && <ReadOnlyFormGroup label="Source" value={sourceType} />}
    </SectionComponent>
  );
};

export default ProfileSection;
