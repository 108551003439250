import React from 'react';
import PropTypes from 'prop-types';

import { Col, Row, Panel } from 'components/bootstrap';
import { Warning as LicenseCheckWarning } from 'license/LicenseCheck';
import { LicenseStatusPropType } from 'archive/propTypes';
import type { LicenseStatus as LicenseStatusType } from 'archive/types';

type Props = {
  licenseStatus: LicenseStatusType,
  summary?: boolean,
  rowClass?: string,
  colWidth?: number,
};

const ForwarderLicenseStatus = ({ licenseStatus, summary, rowClass, colWidth }: Props) => {
  const { status, missing } = licenseStatus;

  if (!missing && (status && status.valid)) {
    return null;
  }

  if (summary) {
    return <LicenseCheckWarning licenseSubject="/license/enterprise" featureName="Forwarder" />;
  }

  return (
    <Row className={rowClass}>
      <Col md={colWidth}>
        <Panel bsStyle="warning">
          <LicenseCheckWarning licenseSubject="/license/enterprise" featureName="Forwarder" />
        </Panel>
      </Col>
    </Row>
  );
};

ForwarderLicenseStatus.propTypes = {
  licenseStatus: LicenseStatusPropType.isRequired,
  colWidth: PropTypes.number,
  rowClass: PropTypes.string,
  summary: PropTypes.bool,
};

ForwarderLicenseStatus.defaultProps = {
  colWidth: 12,
  rowClass: 'content',
  summary: false,
};

export default ForwarderLicenseStatus;
