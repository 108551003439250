import * as React from 'react';
import { useCallback, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import { MenuItem, Button } from 'components/bootstrap';
import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import { INSTANT_ARCHIVING_API_ROUTES } from 'instant-archiving/Constants';
import UserNotification from 'util/UserNotification';
import useUserDateTime from 'hooks/useUserDateTime';
import type {
  InstantArchivingActionFormValues,
} from 'instant-archiving/components/InstantArchivingActionModalForm';
import InstantArchivingActionModalForm from 'instant-archiving/components/InstantArchivingActionModalForm';
import { getInstantArchivingUriParams } from 'instant-archiving/logic/Utils';

type Props = {
  archiveStreams?: {
    [key: string]: string,
  },
  descriptor: string,
  handleFailures: (failures: Array<{
    entity_id: string
  }>, actionPastTense: string, archiveId: string) => void,
  refetchArchives: () => void,
  onSelect?: () => void,
  selectedArchiveIds: Array<string>,
  buttonType?: 'menu' | 'button',

};

const RestoreArchiveAction = ({
  archiveStreams,
  selectedArchiveIds,
  handleFailures,
  refetchArchives,
  descriptor,
  onSelect,
  buttonType,
}: Props) => {
  const [showRestoreModal, setShowRestoreModal] = useState(false);
  const { userTimezone } = useUserDateTime();

  const onRestoreSubmit = useCallback((values: InstantArchivingActionFormValues) => {
    const uriParams = getInstantArchivingUriParams(values, userTimezone);
    const { streamFilter } = values;

    fetch(
      'POST',
      qualifyUrl(`${INSTANT_ARCHIVING_API_ROUTES.InstantArchivingAPIController.bulk_restore().url}?${uriParams}`),
      { archives: [...selectedArchiveIds], stream_filter: isEmpty(streamFilter) ? [] : streamFilter?.split(',') },
    ).then((response) => {
      const failures = response?.failures || [];
      handleFailures(failures, 'submitted', selectedArchiveIds[0]);
    }).catch((error) => {
      UserNotification.error(`An error occurred while restoring archive. ${error}`);
    }).finally(() => {
      setShowRestoreModal(false);
      refetchArchives();
    });
  }, [selectedArchiveIds, userTimezone, handleFailures, refetchArchives]);

  const onRestore = () => {
    if (typeof onSelect === 'function') {
      onSelect();
    }

    setShowRestoreModal(true);
  };

  const modalTitle = 'Restore Archives';
  const isMenuButton = buttonType === 'menu';
  const ButtonComponent = isMenuButton ? MenuItem : Button;

  return (
    <>
      <ButtonComponent bsStyle={isMenuButton ? 'default' : 'success'}
                       onClick={() => onRestore()}>Restore
      </ButtonComponent>
      {showRestoreModal && (
        <InstantArchivingActionModalForm modalTitle={modalTitle}
                                         type="restore"
                                         archiveId={(selectedArchiveIds?.length === 1) ? selectedArchiveIds[0] : undefined}
                                         show={showRestoreModal}
                                         archiveStreams={archiveStreams}
                                         onHide={() => setShowRestoreModal(false)}
                                         submitButtonText="Restore"
                                         onSubmit={onRestoreSubmit}>
          <p>{`You are about to restore ${selectedArchiveIds?.length} ${descriptor}.`}</p>
        </InstantArchivingActionModalForm>
      )}
    </>
  );
};

RestoreArchiveAction.defaultProps = {
  onSelect: undefined,
  buttonType: 'menu',
  archiveStreams: undefined,
};

export default RestoreArchiveAction;
