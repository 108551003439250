import * as React from 'react';

import LicenseCheckContext from 'license/LicenseCheckContext';

export default function useLicenseCheck() {
  const context = React.useContext(LicenseCheckContext);

  if (context === undefined) {
    throw new Error('useLicenseCheck must be used within a LicenseCheckProvider.');
  }

  return context;
}
