import { PluginStore } from 'graylog-web-plugin/plugin';

const getDisplayName = (type: string) => {
  const authentication = PluginStore.exports('authentication.services').filter((auth) => auth.name === type)[0];

  if (!authentication) {
    throw new Error(`Unable to find authentication service component for type: ${type}`);
  }

  return authentication.displayName;
};

export default getDisplayName;
