import React from 'react';
import { styled } from 'styled-components';

import type { InstantArchive } from 'instant-archiving/Types';
import { Row, Col } from 'components/bootstrap';
import { Timestamp } from 'components/common';
import InstantArchiveStreams from 'instant-archiving/components/InstantArchiveStreams';

type Props = {
  archive: InstantArchive,
}
const StyledDl = styled.dl`
  margin-bottom: 0;
  margin-top: 10px;

  dt {
    float: left;
  }

  dd {
    margin-left: 150px;
  }

  & > dt::after {
    content: ':';
  }
`;

const InstantArchiveSummary = ({ archive }: Props) => (
  <Row>
    <Col md={12}>
      <Row>
        <Col md={5}>
          <h3>Details</h3>
          <StyledDl>
            <dt>Archive Name</dt>
            <dd>{archive.archive_name}</dd>
            <dt>Message Count</dt>
            <dd>{archive.message_count}</dd>
            <dt>From</dt>
            <dd><Timestamp dateTime={archive.timestamp_from} /></dd>
            <dt>To</dt>
            <dd><Timestamp dateTime={archive.timestamp_to} /></dd>
          </StyledDl>
        </Col>
        <Col md={5}>
          <StyledDl>
            <InstantArchiveStreams configId={archive.archive_config_id} archiveStream={archive.stream_map} />
          </StyledDl>
        </Col>
      </Row>
    </Col>
  </Row>
);

export default InstantArchiveSummary;
