import __request__ from 'routing/request';
interface Data {
    readonly active_backend: string;
}
/**
 * Get global authentication services configuration
 */
export function get(): Promise<unknown> {
    return __request__('GET', '/system/authentication/services/configuration', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update global authentication services configuration
 */
export function update(JSONbody: Data): Promise<unknown> {
    return __request__('POST', '/system/authentication/services/configuration', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
