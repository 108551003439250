import type { SearchFilterUsagesSummary } from 'search-filter/types';
import StringUtils from 'util/StringUtils';

const readableEntityType = (entityType: string, usages: number) => {
  switch (entityType) {
    case 'SEARCH':
      return StringUtils.pluralize(usages, 'saved search', 'saved searches');
    case 'DASHBOARD_WIDGET':
      return StringUtils.pluralize(usages, 'dashboard widget', 'dashboard widgets');
    default:
      return entityType;
  }
};

const readableFilterUsagesSummary = (summary: SearchFilterUsagesSummary['summary']) => summary.map(({ usages, type }) => `${usages} ${readableEntityType(type, usages)}`).join(', ');

export default readableFilterUsagesSummary;
