import { useEffect } from 'react';

import useHistory from 'routing/useHistory';

import SalesforceRoutes from './common/Routes';

type Props = { url: string };

// https://stackoverflow.com/questions/52912238/render-methods-should-be-a-pure-function-of-props-and-state

const SalesforceInputConfiguration: React.FC<Props> = (
) => {
  const history = useHistory();

  useEffect(() => {
    const url = SalesforceRoutes.INTEGRATIONS.Salesforce.ACTIVITYAPI.index;
    history.push(url);
  }, [history]);

  return null;
};

export default SalesforceInputConfiguration;
