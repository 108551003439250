import * as React from 'react';

import type { InstantArchive } from 'instant-archiving/Types';
import { Table } from 'components/bootstrap';
import InstantArchiveRestoreTableEntry from 'instant-archiving/components/InstantArchiveRestoreTableEntry';
import type { InstantArchiveRestoreOperationDeleteProps } from 'instant-archiving/hooks/useInstantArchiving';

type Props = {
  archive: InstantArchive,
  onDeleteOperation: (deleteProps: InstantArchiveRestoreOperationDeleteProps) => Promise<void>
}

const Header = () => (
  <thead>
    <tr>
      <th>#</th>
      <th>Restored at</th>
      <th aria-label="Restore status" />
      <th>From</th>
      <th>To</th>
      <th>Documents count</th>
      <th>Index failures</th>
      <th>Indices count</th>
      <th className="text-right">Actions</th>
    </tr>
  </thead>
);

const InstantArchiveRestore = ({ archive, onDeleteOperation }: Props) => {
  const hasRestoredIndices = !!archive?.restore_history?.length;

  return (
    <>
      <h2>Restore Operations</h2>
      <Table condensed hover>
        <Header />
        {hasRestoredIndices && archive.restore_history.map((restoreOp, index) => (
          <InstantArchiveRestoreTableEntry key={restoreOp.id}
                                           restoreOperation={restoreOp}
                                           index={index}
                                           archiveId={archive.id}
                                           onDeleteOperation={onDeleteOperation} />
        ))}
        {!hasRestoredIndices && (
          <tbody>
            <tr key="no-indices">
              <td>No restored indices.</td>
            </tr>
          </tbody>
        )}
      </Table>
    </>
  );
};

export default InstantArchiveRestore;
