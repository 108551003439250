import * as React from 'react';

import { singleton } from 'logic/singleton';

import type { LoadGroupsResponse } from '../../actions/directoryServices/GroupSyncActions';

export type MatchingGroupsContextType = {
  result: Partial<LoadGroupsResponse> | null | undefined,
  finishedLoading: boolean,
  setContextValue: ({ finishedLoading, result }: { finishedLoading: boolean, result: Partial<LoadGroupsResponse> | null | undefined }) => void,
};

export const initialState = {
  result: undefined,
  finishedLoading: false,
  setContextValue: () => {},
};

const MatchingGroupsContext = React.createContext<MatchingGroupsContextType>(initialState);
export default singleton('contexts.authentication.enterprise.ldap.groupSync', () => MatchingGroupsContext);
