import PropTypes from 'prop-types';
import React from 'react';

import type { Stream } from 'stores/streams/StreamsStore';
import useStreams from 'components/streams/hooks/useStreams';
import InstantArchiveStreamsList from 'instant-archiving/components/InstantArchiveStreamList';

type Props = {
  streamIds: Array<string>,
};

const StreamsCell = ({ streamIds }: Props) => {
  const { data: allStreams, isInitialLoading: isLoadingAllStreams } = useStreams(
    { query: '', page: 1, pageSize: 0, sort: { direction: 'asc', attributeId: 'title' } },
  );

  if (!streamIds || isLoadingAllStreams || allStreams.elements?.length < 1) {
    return null;
  }

  const streamTitles = (): Array<string> => (
    streamIds.map((id) => (
      allStreams.elements.find((stream: Stream) => stream.id === id)?.title
    ))
  );

  return (
    <InstantArchiveStreamsList streamNames={streamTitles()} />
  );
};

export default StreamsCell;

StreamsCell.propTypes = {
  streamIds: PropTypes.arrayOf(PropTypes.string),
};

StreamsCell.defaultProps = {
  streamIds: undefined,
};
