const DEFAULT_SETTINGS = {
  /*
  fieldId: { // Same ID as supplied to <Input />
    value: '',
    defaultValue: '', // Update StepReview.jsx & relevant step(s) if you need to output
  }
  */

  /* Default Advanced Settings */

  throttleEnabled: {
    value: false, // We want to default to true on render, but never compare the default
  },
  subscribeToAllLog: {
    value: true, // We want to default to true on render, but never compare the default
  },

  f5Name: {
    value: '',
  },

  f5ThrottleEnabled: {
    value: undefined,
  },

  pollingInterval: {
    value: 5,
  },
  content_type: {
    value: '',
  },
  f5StoreFullMessage: {
    value: true,
  },
  disableTLSCheck: {
    value: false,
  },
  availableContents: {
    value: 'GTM,LTM,MAIL,SECURITY,TMM,USER,MESSAGES,AUDIT,DAEMON,KERNEL,WEBUI',
  },
};

export default DEFAULT_SETTINGS;
