import React, { useEffect } from 'react';

import type { Version } from 'util/Version';
import { parseVersion, getFullVersion } from 'util/Version';
import { useStore } from 'stores/connect';

import SystemPluginsStore, { SystemPluginsActions } from './SystemPluginsStore';

const CloudFooter = () => {
  const { plugins } = useStore(SystemPluginsStore);
  let effectiveVersion = getFullVersion();

  useEffect(() => {
    SystemPluginsActions.list();
  }, []);

  const getCloudFooter = (version: string) => (
    <>
      Graylog Cloud {version}
    </>
  );

  if (!plugins) {
    return getCloudFooter(effectiveVersion);
  }

  const cloudPlugin = plugins.filter((plugin) => plugin.unique_id === 'org.graylog.plugins.cloud.GraylogCloudPlugin')[0];

  if (cloudPlugin?.version) {
    const cloudVersion: Version | void = parseVersion(cloudPlugin.version);

    if (cloudVersion) {
      const { major, minor, patch, buildMetadata } = cloudVersion;
      effectiveVersion = `${major}.${minor}.${patch} ${buildMetadata ? `(${buildMetadata})` : ''}`;
    }
  }

  return getCloudFooter(effectiveVersion);
};

export default CloudFooter;
