import * as React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/bootstrap';
import { DataTable } from 'components/common';
import { adjustFormat } from 'util/DateTime';
import LicenseDetails from 'license/LicenseDetails';
import LicenseViolations from 'license/LicenseViolations';
import type { DateTime } from 'util/DateTime';
import type { License, Status } from 'license/types';

type Props = {
  licensesStatus: Status[],
  formatSubject: (license: License) => string,
  deleteLicense: (license: License) => void,
}

const LicensesStatusList = ({ licensesStatus, formatSubject, deleteLicense }: Props) => {
  const formatDateTime = (dateTime: DateTime): string => adjustFormat(dateTime, 'date');

  const licenseInfoFormatter = (licenseStatus: Status, counter: number): React.ReactElement => {
    const { license } = licenseStatus;
    const violations = {
      cluster_not_covered: licenseStatus.cluster_not_covered,
      nodes_exceeded: licenseStatus.nodes_exceeded,
      traffic_exceeded: licenseStatus.traffic_exceeded,
      remote_checks_failed: licenseStatus.remote_checks_failed,
      expired: licenseStatus.expired,
      violated: licenseStatus.violated,
    };

    return (
      <tr key={`${license.id}-${counter}`} className={violations.violated || violations.expired ? 'danger' : null}>
        <td className="limited">{formatSubject(license)}</td>
        <td className="limited">{license.audience.company}</td>
        <td className="limited">{`${license.audience.name} <${license.audience.email}>`}</td>
        <td className="limited">{formatDateTime(license.not_before_date)}</td>
        <td className="limited">{formatDateTime(license.expiration_date)}</td>
        <td className="limited">{license.id}</td>
        <td className="limited" style={{ width: '20%' }}>
          <LicenseDetails trial={license.trial}
                          version={license.version}
                          enterprise={license.enterprise}
                          violations={violations} />
        </td>
        <td className="limited" style={{ width: '20%' }}>
          <LicenseViolations trial={license.trial}
                             version={license.version}
                             violations={violations} />
        </td>
        <td className="limited">
          <Button bsStyle="danger" bsSize="xsmall" onClick={deleteLicense(license)}>Delete</Button>
        </td>
      </tr>
    );
  };

  const headers = ['License Type', 'Licensed to', 'Contact', 'Valid from', 'Expiration date', 'Id', 'Details', 'State', 'Actions'];
  const headerCellFormatter = (header: string): React.ReactElement => <th>{header}</th>;

  return (
    <DataTable id="license-list"
               rowClassName="row-sm"
               className="table-hover table-condensed table-striped"
               headers={headers}
               headerCellFormatter={headerCellFormatter}
               sortByKey="expires_in"
               rows={licensesStatus}
               dataRowFormatter={licenseInfoFormatter}
               filterLabel="Filter"
               filterKeys={[]}
               noDataText="There are no licenses installed in this cluster." />
  );
};

LicensesStatusList.propTypes = {
  licensesStatus: PropTypes.arrayOf(PropTypes.object).isRequired,
  formatSubject: PropTypes.func.isRequired,
  deleteLicense: PropTypes.func.isRequired,
};

export default LicensesStatusList;
