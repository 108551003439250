import styled from 'styled-components';

import PageContentLayout from 'components/layout/PageContentLayout';

const StyledPageContentLayout = styled(PageContentLayout)`
  padding: 10px;
  overflow-x: hidden;
  .container-fluid {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`;

export default StyledPageContentLayout;
