import * as React from 'react';

import EntityShareModal from 'components/permissions/EntityShareModal';
import type { UserSearchFilter, SelectedSearchFilter as SelectedSearchFilterType } from 'search-filter/types';

type Props = {
  filter: UserSearchFilter | SelectedSearchFilterType,
  toggleShareModalOpen: () => void,
}
const UserFilterShareModal = ({ filter, toggleShareModalOpen }: Props) => (
  <EntityShareModal entityId={filter.id}
                    entityType="search_filter"
                    entityTitle={filter.title || 'No Title'}
                    description="Search for a User or Team to add as collaborator on this search filter"
                    onClose={toggleShareModalOpen}
                    showShareableEntityURL={false}
                    entityTypeTitle="search filter" />
);

export default UserFilterShareModal;
