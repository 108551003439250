import * as React from 'react';

import Routes from 'routing/Routes';
import useCurrentUser from 'hooks/useCurrentUser';
import BootstrapModalConfirm from 'components/bootstrap/BootstrapModalConfirm';
import { Input, Button } from 'components/bootstrap';
import { Icon } from 'components/common';
import ReplaySearchButton from 'views/components/widgets/ReplaySearchButton';
import type { AbsoluteTimeRange } from 'views/logic/queries/Query';
import { useAddEvidence, useRemoveEvidence } from 'security-app/hooks/useInvestigationsAPI';
import type { InvestigationsDetailsAPIType, EventEvidenceType } from 'security-app/hooks/api/investigationsAPI.types';
import useHistory from 'routing/useHistory';

import {
  Row,
  FormGroup,
  EvidenceList,
  EvidenceRow,
  IconButton,
  StyledLink,
} from '../styled.components';

type Props = {
  investigation: InvestigationsDetailsAPIType,
};

function Events({ investigation }: Props) {
  const { permissions } = useCurrentUser();
  const [newEventId, setNewEventId] = React.useState<string>('');
  const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false);
  const [deleteEventId, setDeleteEventId] = React.useState<string>(null);
  const history = useHistory();

  const canManageEvidence = React.useMemo(() => (
    !investigation.archived
      && (permissions.includes('investigations:edit') || permissions.includes('*'))
  ), [investigation.archived, permissions]);

  const { addEvidence } = useAddEvidence();
  const { removeEvidence, removingEvidence } = useRemoveEvidence();

  const isFormValid = React.useMemo(() => (
    newEventId && newEventId.replace(/\s/g, '').length > 0
  ), [newEventId]);

  const onAddEvent = async (e: React.BaseSyntheticEvent) => {
    e.preventDefault();

    if (isFormValid) {
      await addEvidence({ invId: investigation.id, payload: { events: [newEventId] } });
    }

    setNewEventId('');
  };

  const removeEvent = async () => {
    await removeEvidence({ invId: investigation.id, payload: { events: [deleteEventId] } });
    setShowDeleteDialog(false);
    setDeleteEventId(null);
  };

  const onDelete = (eventId: string) => (e: React.BaseSyntheticEvent) => {
    e.stopPropagation();
    setShowDeleteDialog(true);
    setDeleteEventId(eventId);
  };

  const onCancelDelete = () => {
    setShowDeleteDialog(false);
    setDeleteEventId(null);
  };

  const getTimeRange = (replayInfo: { timerange_start: string, timerange_end: string }): AbsoluteTimeRange => {
    if (!replayInfo) return null;

    return {
      type: 'absolute',
      from: replayInfo.timerange_start,
      to: replayInfo.timerange_end,
    };
  };

  return (
    <>
      {canManageEvidence && (
        <form onSubmit={onAddEvent}>
          <Row>
            <FormGroup>
              <Input id="add-event-id"
                     type="text"
                     label="Add Event"
                     data-testid="add-event-id"
                     autoComplete="off"
                     placeholder="Enter event ID"
                     value={newEventId}
                     onChange={(e: React.BaseSyntheticEvent) => setNewEventId(e.target.value)} />
            </FormGroup>
            <Button type="submit"
                    bsStyle="success"
                    onClick={onAddEvent}
                    disabled={!isFormValid}>
              Add
            </Button>
          </Row>
        </form>
      )}
      <EvidenceList>
        {investigation.events.length === 0
          ? <div style={{ color: 'gray' }}>There are no events to list</div>
          : investigation.events.map((event: EventEvidenceType) => (
            <EvidenceRow key={event.id} $fullWidth $gap="0.5rem">
              <Row $gap="0">
                {canManageEvidence && (
                  <IconButton $danger
                              data-testid={`delete-${event.id}`}
                              onClick={onDelete(event.id)}>
                    <Icon name="times" />
                  </IconButton>
                )}
                <StyledLink onClick={() => history.push(Routes.ALERTS.LIST)}>
                  {event.message}
                </StyledLink>
              </Row>
              {event.replay_info && (
                <ReplaySearchButton queryString={event.replay_info.query}
                                    timerange={getTimeRange(event.replay_info)}
                                    streams={event.replay_info.streams}>
                  Replay search
                </ReplaySearchButton>
              )}
            </EvidenceRow>
          ))}
      </EvidenceList>
      <BootstrapModalConfirm showModal={showDeleteDialog}
                             title="Delete Event"
                             onConfirm={removeEvent}
                             onCancel={onCancelDelete}
                             confirmButtonDisabled={removingEvidence}>
        Are you sure you want to delete this Event?
      </BootstrapModalConfirm>
    </>
  );
}

export default Events;
