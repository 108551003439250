import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css, createGlobalStyle } from 'styled-components';

import { Icon } from 'components/common';
import { Button, Panel } from 'components/bootstrap';

const ErrorOutputStyle = createGlobalStyle`
  /* NOTE: This is to remove Bootstrap styles from the anchor element I can't override in Panel.Header */
  form {
    .panel.panel-danger {
      .panel-heading > a {
        font-size: 14px;
        text-decoration: none;
        color: #AD0707;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
`;

const ErrorOutput = styled.span`
  display: block;
`;

const ErrorToggleInfo = styled.button(({ theme }) => css`
  border: 0;
  background: none;
  color: ${theme.colors.gray[10]};
  font-size: 13px;
  text-transform: uppercase;
  margin: 12px 0 0;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
`);

type ErrorMessageProps = {
  fullMessage: string,
  niceMessage?: React.ReactNode,
};

export const ErrorMessage = ({ fullMessage, niceMessage }: ErrorMessageProps) => {
  const [expanded, toggleExpanded] = useState(false);

  const Header = (
    <>
      <ErrorOutputStyle />
      <ErrorOutput>{niceMessage || fullMessage}</ErrorOutput>
      {niceMessage
        && (
          <ErrorToggleInfo onClick={() => toggleExpanded(!expanded)}>
            More Info  <Icon name={expanded ? 'angle-down' : 'angle-right'} fixedWidth />
          </ErrorToggleInfo>
        )}
    </>
  );

  if (!niceMessage) {
    return <Panel header={Header} bsStyle="danger" />;
  }

  return (
    <Panel header={Header}
           bsStyle="danger"
           collapsible
           expanded={expanded}>
      <strong>Additional Information: </strong>{fullMessage}
    </Panel>
  );
};

ErrorMessage.propTypes = {
  fullMessage: PropTypes.string.isRequired,
  niceMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
};

ErrorMessage.defaultProps = {
  niceMessage: null,
};

type Props = {
  buttonContent?: React.ReactNode,
  children: React.ReactNode,
  className?: string,
  disabled: boolean,
  description: React.ReactNode,
  error?: {
    full_message: string,
    nice_message?: React.ReactNode,
  },
  loading: boolean,
  onSubmit: () => void,
  title: React.ReactNode,
};

const FormWrap = ({
  buttonContent,
  children,
  className,
  disabled,
  description,
  error,
  loading,
  onSubmit,
  title,
}: Props) => {
  const formRef = useRef();
  const [disabledButton, setDisabledButton] = useState(disabled);

  const prevent = (event) => {
    event.preventDefault();

    return false;
  };

  useEffect(() => {
    setDisabledButton(loading || disabled);
  }, [loading, disabled]);

  return (
    <form onSubmit={prevent}
          autoComplete="off"
          noValidate
          className={className}
          ref={formRef}>

      {title && ((typeof (title) === 'string') ? <h2>{title}</h2> : title)}
      {description && ((typeof (description) === 'string') ? <p>{description}</p> : description)}

      {error && error.full_message && (
        <ErrorMessage fullMessage={error.full_message}
                      niceMessage={error.nice_message} />
      )}

      {children}

      <Button type="button"
              onClick={disabledButton ? null : onSubmit}
              bsStyle="primary"
              disabled={disabledButton}>
        {loading ? 'Loading...' : buttonContent}
      </Button>
    </form>
  );
};

FormWrap.propTypes = {
  buttonContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  children: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.shape({
    full_message: PropTypes.string.isRequired,
    nice_message: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
  }),
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  className: PropTypes.string,
};

FormWrap.defaultProps = {
  buttonContent: 'Submit',
  disabled: false,
  error: null,
  description: null,
  loading: false,
  onSubmit: () => {},
  title: null,
  className: undefined,
};

export default FormWrap;
