import __request__ from 'routing/request';
interface RotationStrategyConfig {
    readonly type: string;
}
interface Context {
    readonly time_size_optimizing_retention_fixed_leeway: string;
}
interface RotationStrategyDescription {
    readonly json_schema: JsonSchema;
    readonly default_config: RotationStrategyConfig;
    readonly type: string;
}
interface RotationStrategies {
    readonly total: number;
    readonly strategies: RotationStrategyDescription[];
    readonly context: Context;
}
interface JsonSchema {
    readonly disallow: JsonSchema[];
    readonly $schema: string;
    readonly readonly: boolean;
    readonly extends: JsonSchema[];
    readonly description: string;
    readonly id: string;
    readonly $ref: string;
    readonly required: boolean;
}
/**
 * List available rotation strategies
 */
export function list(): Promise<RotationStrategies> {
    return __request__('GET', '/system/indices/rotation/strategies', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Show JSON schema for configuration of given rotation strategies
 * @param strategy The name of the rotation strategy
 */
export function configSchema(strategy: string): Promise<RotationStrategyDescription> {
    return __request__('GET', `/system/indices/rotation/strategies/${strategy}`, null, {}, {
        'Accept': ['application/json']
    });
}
