import * as React from 'react';
import { useCallback, useEffect } from 'react';

import Input from 'components/bootstrap/Input';
import type { ValidationResult } from 'enterprise/parameters/components/ParameterDeclarationForm';
import type FieldTypeMapping from 'views/logic/fieldtypes/FieldTypeMapping';
import { Properties } from 'views/logic/fieldtypes/FieldType';
import { FieldSource } from 'enterprise/parameters/components/option/OptionSource';
import type { OptionSourceEditComponentProps } from 'enterprise/parameters/components/option/types';
import FieldSelect from 'views/components/aggregationwizard/FieldSelect';

const isFieldQualified = ({ name, type: { properties } }: FieldTypeMapping): boolean => (name === 'source')
  || properties.includes(Properties.Enumerable)
  || properties.includes(Properties.Numeric);

const FieldSourceForm = ({ idx, source: { field, limit }, onChange, onValidate, validationState }: OptionSourceEditComponentProps<FieldSource>) => {
  useEffect(() => {
    const fieldValidationState: ValidationResult = (field !== undefined && field !== '')
      ? ['success']
      : ['error', 'Please select field'];
    const limitValidationState: ValidationResult = (limit !== undefined && limit > 0)
      ? ['success']
      : ['error', 'Limit must be a positive number.'];

    onValidate(idx, {
      'source.field': fieldValidationState,
      'source.limit': limitValidationState,
    });
  }, [field, idx, limit, onValidate]);

  useEffect(() => () => {
    onValidate(idx, { 'source.field': ['success'], 'source.limit': ['success'] });
  }, [idx, onValidate]);

  const _onChangeField = useCallback((newField: string) => onChange(FieldSource.create(newField, limit)), [limit, onChange]);
  const _onChangeLimit = useCallback((event: React.ChangeEvent<HTMLInputElement>) => onChange(
    FieldSource.create(field, Number.parseInt(event.target.value, 10)),
  ), [field, onChange]);

  return (
    <>
      <Input id={`field-${idx}`}
             name="field"
             label="Field"
             bsStyle={validationState?.['source.field']?.[0]}
             help={validationState?.['source.field']?.[1]}>
        <FieldSelect placeholder="Select or insert field"
                     isFieldQualified={isFieldQualified}
                     ariaLabel="Select or insert field"
                     name="parameter-value-input"
                     id="parameter-value-input"
                     onChange={_onChangeField}
                     allowCreate
                     value={field} />
      </Input>
      <Input id={`limit-${idx}`}
             name="limit"
             label="Limit"
             type="number"
             value={limit}
             onChange={_onChangeLimit}
             bsStyle={validationState?.['source.limit']?.[0]}
             help={validationState?.['source.limit']?.[1]} />
    </>
  );
};

export default FieldSourceForm;
