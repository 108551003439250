import styled, { css } from 'styled-components';
import type { DefaultTheme } from 'styled-components';
import { NavLink } from 'react-router-dom';

const StyledLink = styled(NavLink)(({ theme }: { theme:DefaultTheme }) => css`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.6rem;
  transition: all 0.33s ease-in-out;
  padding: 5px 7px;
  margin: 16px 10px;
  border-radius: 5px;
  color: ${theme.colors.gray[30]};

  & > svg {
    width: 20px;
  }

  &:hover {
    background: ${theme.colors.global.contentBackground};
    text-decoration: none;
    color: ${theme.colors.global.textDefault};
  }

  &.active {
    background: ${theme.colors.global.contentBackground};
    color: ${theme.colors.brand.primary};
  }
`);

export default StyledLink;
