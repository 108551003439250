import * as React from 'react';
import { useParams } from 'react-router-dom';

import { Col, Row } from 'components/bootstrap';
import ReadOnlyFormGroup from 'components/common/ReadOnlyFormGroup';
import { Headline } from 'components/common/Section/SectionComponent';
import useUserSearchFilterQuery from 'search-filter/hooks/useUserSearchFilterQuery';
import { Spinner } from 'components/common';

import FilterUsagesInSearches from './FilterUsagesInSearches';
import FilterUsagesInDashboardWidgets from './FilterUsagesInDashboardWidgets';

const UserSearchFilterDetails = () => {
  const { filterId } = useParams();
  const { data: searchFilter, isFetching } = useUserSearchFilterQuery(filterId);

  if (isFetching) {
    return <Spinner />;
  }

  return (
    <>
      <Row className="content">
        <Col lg={8}>
          <Row>
            <Col xs={12}>
              <Headline>Filter Details</Headline>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <ReadOnlyFormGroup label="Query" value={searchFilter.queryString} />
              <ReadOnlyFormGroup label="Title" value={searchFilter.title} />
              <ReadOnlyFormGroup label="Description" value={searchFilter.description} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="content">
        <Col xs={12} className="has-bm">
          <Headline>Usages In Saved Searches</Headline>
        </Col>
        <Col xs={12}>
          <FilterUsagesInSearches filterId={filterId} />
        </Col>
      </Row>
      <Row className="content">
        <Col xs={12} className="has-bm">
          <Headline>Usages In Dashboard Widgets</Headline>
        </Col>
        <Col xs={12}>
          <FilterUsagesInDashboardWidgets filterId={filterId} />
        </Col>
      </Row>
    </>
  );
};

export default UserSearchFilterDetails;
