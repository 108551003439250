// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#toast-container .toast {
  display: grid;
  grid-template-columns: 8fr 0.5fr;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0;
  grid-row-gap: 0;
}

#toast-container .toast-title {
  grid-area: 1 / 1 / 2 / 1;
}

#toast-container .toast-message {
  grid-area: 2 / 2 / 2 / 1;
}

#toast-container button.toast-close-button {
  font-size: 0.889rem; /* theme.fonts.size.small */
  grid-area: 1 / 2 / 3 / 3;
  height: 100%;
  top: 0;
  right: 0;
  font-weight: normal;
}

#toast-container button.toast-close-button:focus,
#toast-container button.toast-close-button:hover {
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./../../graylog2-server/graylog2-web-interface/src/util/UserNotification.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gCAAgC;EAChC,kCAAkC;EAClC,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,mBAAmB,EAAE,2BAA2B;EAChD,wBAAwB;EACxB,YAAY;EACZ,MAAM;EACN,QAAQ;EACR,mBAAmB;AACrB;;AAEA;;EAEE,WAAW;AACb","sourcesContent":["#toast-container .toast {\n  display: grid;\n  grid-template-columns: 8fr 0.5fr;\n  grid-template-rows: repeat(2, 1fr);\n  grid-column-gap: 0;\n  grid-row-gap: 0;\n}\n\n#toast-container .toast-title {\n  grid-area: 1 / 1 / 2 / 1;\n}\n\n#toast-container .toast-message {\n  grid-area: 2 / 2 / 2 / 1;\n}\n\n#toast-container button.toast-close-button {\n  font-size: 0.889rem; /* theme.fonts.size.small */\n  grid-area: 1 / 2 / 3 / 3;\n  height: 100%;\n  top: 0;\n  right: 0;\n  font-weight: normal;\n}\n\n#toast-container button.toast-close-button:focus,\n#toast-container button.toast-close-button:hover {\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
