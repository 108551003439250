import __request__ from 'routing/request';
interface StreamRuleTypeResponse {
    readonly name: string;
    readonly id: number;
    readonly short_desc: string;
    readonly long_desc: string;
}
interface SingleStreamRuleSummaryResponse {
    readonly streamrule_id: string;
}
interface StreamRuleListResponse {
    readonly total: number;
    readonly stream_rules: StreamRule[];
}
interface StreamRule {
    readonly field: string;
    readonly stream_id: string;
    readonly description: string;
    readonly id: string;
    readonly validations: {
        readonly [_key: string]: Validator;
    };
    readonly inverted: boolean;
    readonly type: 'EXACT' | 'REGEX' | 'GREATER' | 'SMALLER' | 'PRESENCE' | 'CONTAINS' | 'ALWAYS_MATCH' | 'MATCH_INPUT';
    readonly fields: {
        readonly [_key: string]: Object;
    };
    readonly content_pack: string;
    readonly value: string;
}
interface CreateStreamRuleRequest {
    readonly field: string;
    readonly description: string;
    readonly type: number;
    readonly inverted: boolean;
    readonly value: string;
}
interface Object {
}
type StreamRuleTypeResponseArray = StreamRuleTypeResponse[];
interface Validator {
}
/**
 * Get a list of all stream rules
 * @param streamid The id of the stream whose stream rules we want.
 */
export function get(streamid: string): Promise<StreamRuleListResponse> {
    return __request__('GET', `/streams/${streamid}/rules`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Create a stream rule
 * @param streamid The stream id this new rule belongs to.
 */
export function create(streamid: string, JSONbody: CreateStreamRuleRequest): Promise<unknown> {
    return __request__('POST', `/streams/${streamid}/rules`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get all available stream types
 * @param streamid The stream id this new rule belongs to.
 */
export function types(streamid: string): Promise<StreamRuleTypeResponseArray> {
    return __request__('GET', `/streams/${streamid}/rules/types`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a single stream rules
 * @param streamid The id of the stream whose stream rule we want.
 * @param streamRuleId The stream rule id we are getting
 */
export function getBystreamidAndstreamRuleId(streamid: string, streamRuleId: string): Promise<StreamRule> {
    return __request__('GET', `/streams/${streamid}/rules/${streamRuleId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update a stream rule
 * @param streamid The stream id this rule belongs to.
 * @param streamRuleId The stream rule id we are updating
 */
export function update(streamid: string, streamRuleId: string, JSONbody: CreateStreamRuleRequest): Promise<SingleStreamRuleSummaryResponse> {
    return __request__('PUT', `/streams/${streamid}/rules/${streamRuleId}`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete a stream rule
 * @param streamid The stream id this new rule belongs to.
 */
export function remove(streamid: string, streamRuleId: string): Promise<void> {
    return __request__('DELETE', `/streams/${streamid}/rules/${streamRuleId}`, null, {}, {
        'Accept': []
    });
}
