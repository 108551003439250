import __request__ from 'routing/request';
interface ContentStreamSettings {
    readonly content_stream_topics: string[];
    readonly releases_enabled: boolean;
    readonly content_stream_enabled: boolean;
}
type stringArray = string[];
/**
 * Retrieve Content Stream settings for specified user
 */
export function getContentStreamUserSettings(username?: string): Promise<ContentStreamSettings> {
    return __request__('GET', `/contentstream/settings/${username}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update Content Stream settings for specified user
 * @param JSONbody Content Stream settings for the specified user.
 */
export function setContentStreamUserSettings(JSONbody: ContentStreamSettings, username?: string): Promise<ContentStreamSettings> {
    return __request__('PUT', `/contentstream/settings/${username}`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Retrieve valid feed tags based on license
 */
export function getContentStreamTags(): Promise<stringArray> {
    return __request__('GET', '/contentstream/tags', null, {}, {
        'Accept': ['application/json']
    });
}
