import * as React from 'react';
import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import StringUtils from 'util/StringUtils';
import UserNotification from 'util/UserNotification';
import BulkActionsDropdown from 'components/common/EntityDataTable/BulkActionsDropdown';
import DeleteArchiveConfigAction from 'instant-archiving/InstantArchivingConfig/InstantArchivingConfigOverview/BulkActions/DeleteArchiveConfigAction';

type Props = {
  selectedArchiveConfigIds: Array<string>,
  setSelectedArchiveConfigIds: (archiveConfigIds: Array<string>) => void,
}

const BulkActions = ({ selectedArchiveConfigIds, setSelectedArchiveConfigIds }: Props) => {
  const queryClient = useQueryClient();
  const selectedItemsAmount = selectedArchiveConfigIds?.length;
  const refetchArchiveConfigs = useCallback(() => queryClient.invalidateQueries(['instant-archiving', 'overview']), [queryClient]);
  const descriptor = StringUtils.pluralize(selectedArchiveConfigIds?.length, 'archive configuration', 'archive configurations');

  const handleFailures = useCallback((failures: Array<{ entity_id: string }>, actionPastTense: string) => {
    if (failures?.length) {
      const notDeletedArchiveIds = failures.map(({ entity_id }) => entity_id);
      setSelectedArchiveConfigIds(notDeletedArchiveIds);
      UserNotification.error(`${notDeletedArchiveIds.length} out of ${selectedItemsAmount} selected ${descriptor} could not be ${actionPastTense}.`);
    } else {
      setSelectedArchiveConfigIds([]);
      UserNotification.success(`${selectedItemsAmount} ${descriptor} ${StringUtils.pluralize(selectedItemsAmount, 'was', 'were')} ${actionPastTense} successfully.`, 'Success');
    }
  }, [descriptor, selectedItemsAmount, setSelectedArchiveConfigIds]);

  return (
    <BulkActionsDropdown selectedEntities={selectedArchiveConfigIds} setSelectedEntities={setSelectedArchiveConfigIds}>
      <DeleteArchiveConfigAction handleFailures={handleFailures}
                                 selectedArchiveConfigIds={selectedArchiveConfigIds}
                                 refetchArchiveConfigs={refetchArchiveConfigs} />

    </BulkActionsDropdown>
  );
};

export default BulkActions;
