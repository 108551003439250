import __request__ from 'routing/request';
interface FailureCount {
    readonly count: number;
}
interface anyMap {
    readonly [_key: string]: unknown;
}
/**
 * Get a list of failed index operations.
 * @param limit Limit
 * @param offset Offset
 */
export function single(limit: number, offset: number): Promise<anyMap> {
    return __request__('GET', '/system/indexer/failures', null, { 'limit': limit, 'offset': offset }, {
        'Accept': ['application/json']
    });
}
/**
 * Total count of failed index operations since the given date.
 * @param since ISO8601 date
 */
export function count(since: string): Promise<FailureCount> {
    return __request__('GET', '/system/indexer/failures/count', null, { 'since': since }, {
        'Accept': ['application/json']
    });
}
