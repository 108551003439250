import React from 'react';

import SelectedSearchFilter from 'search-filter/components/search-filter-bar/SelectedSearchFilter';
import useSearchFiltersFormState from 'search-filter/hooks/useSearchFiltersFormState';

const SelectedFiltersOverview = () => {
  const selectedFilters = useSearchFiltersFormState();

  return (
    <>
      {selectedFilters.toList().map((filter) => (
        <SelectedSearchFilter filter={filter}
                              key={filter.frontendId} />
      ))}
    </>
  );
};

export default SelectedFiltersOverview;
