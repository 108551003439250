const externalActions = {
    ipWatchlist: {
        type: 'lookup-table' as const,
        options: {
            fieldType: 'ip'
        }
    },
    userWatchlist: {
        type: 'lookup-table' as const,
        options: {
            fieldType: 'user'
        }
    },
    hashWatchlist: {
        type: 'lookup-table' as const,
        options: {
            fieldType: 'hash'
        }
    }
};
export default externalActions;
