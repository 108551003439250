import * as React from 'react';

import { DropdownButton, MenuItem } from 'components/bootstrap';
import { IfPermitted } from 'components/common';
import useLicenseCheck from 'license/useLicenseCheck';
import { useInvestigationDrawer } from 'security-app/components/Investigations/contexts';
import { useAddEvidence, useGetInvestigationDetails } from 'security-app/hooks/useInvestigationsAPI';

import AddEvidenceModal from './shared/AddEvidenceModal';

type Props = {
  id: string,
  index: string,
};

const InvestigationsMessageAction = ({ id, index }: Props) => {
  const addEvidenceModalRef = React.useRef(null);
  const { security } = useLicenseCheck();

  const { selectedInvestigationId, setCollapsed } = useInvestigationDrawer();
  const { currentInvestigation } = useGetInvestigationDetails(selectedInvestigationId);

  const { addEvidence } = useAddEvidence();

  const disabledAdd = !currentInvestigation || currentInvestigation?.archived;

  const onAddLog = async () => {
    if (disabledAdd) return;

    await addEvidence({
      invId: selectedInvestigationId,
      payload: { logs: [{ id, index }] },
    });

    setCollapsed(false);
  };

  return security?.isValid && (
    <IfPermitted permissions="investigations:edit">
      <DropdownButton id="investigations-dropdown" bsSize="small" title="Investigations">
        <MenuItem disabled={disabledAdd} onClick={onAddLog} icon="puzzle-piece">
          Add to investigation
        </MenuItem>
        <MenuItem onClick={() => addEvidenceModalRef.current.toggle()} icon="puzzle-piece">
          Select an investigation
        </MenuItem>
      </DropdownButton>
      <AddEvidenceModal index={index} id={id} type="logs" ref={addEvidenceModalRef} />
    </IfPermitted>
  );
};

export default InvestigationsMessageAction;
