import { useQuery } from '@tanstack/react-query';

import UserNotification from 'util/UserNotification';
import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import PaginationURL from 'util/PaginationURL';
import { INSTANT_ARCHIVING_API_ROUTES, INSTANT_ARCHIVING_CONFIG_QUERY_KEYS } from 'instant-archiving/Constants';
import type { InstantArchivingConfig } from 'instant-archiving/Types';
import type { Attributes, Attribute, SearchParams } from 'stores/PaginationTypes';

const INITIAL_DATA = {
  pagination: { total: 0 },
  elements: [],
  attributes: [],
};

type Options = {
  enabled: boolean,
}

type PaginatedResponse = {
  pagination: {
    count: number,
    total: number,
    page: number,
    per_page: number,
  },
  query: string,
  attributes: Attributes,
  elements: Array<InstantArchivingConfig>,
};

const refetchInterval = 5000;

const fetchSearchPaginated = (newPage, newPerPage, newQuery, additional) => {
  const url = PaginationURL(INSTANT_ARCHIVING_API_ROUTES.InstantArchivingAPIController.config.base().url, newPage, newPerPage, newQuery, additional);

  return fetch('GET', qualifyUrl(url))
    .then((response: PaginatedResponse) => {
      const {
        elements,
        query,
        attributes,
        pagination: {
          count,
          total,
          page,
          per_page: perPage,
        },
      } = response;

      return {
        elements,
        attributes,
        pagination: {
          count,
          total,
          page,
          perPage,
          query,
        },
      };
    });
};

const usePaginatedInstantArchivingConfig = (searchParams: SearchParams, { enabled }: Options = { enabled: true }): {
  data: {
    elements: Array<InstantArchivingConfig>,
    pagination: { total: number }
    attributes: Array<Attribute>
  },
  refetch: () => void,
  isInitialLoading: boolean,
} => {
  const { data, refetch, isInitialLoading } = useQuery(
    [...INSTANT_ARCHIVING_CONFIG_QUERY_KEYS, 'paginated', searchParams],
    () => fetchSearchPaginated(
      searchParams.page,
      searchParams.pageSize,
      searchParams.query,
      {
        sort: searchParams?.sort.attributeId,
        order: searchParams?.sort.direction,
      }),
    {
      onError: (errorThrown) => {
        UserNotification.error(`Loading Instant Archiving config failed with status: ${errorThrown}`,
          'Could not load Instant Archiving config');
      },
      keepPreviousData: true,
      refetchInterval,
      notifyOnChangeProps: ['data', 'error'],
      enabled,
    },
  );

  return ({
    data: data ?? INITIAL_DATA,
    refetch,
    isInitialLoading,
  });
};

export default usePaginatedInstantArchivingConfig;
