import React from 'react';
import PropTypes from 'prop-types';

import CommonNotificationSummary from 'components/event-notifications/event-notification-types/CommonNotificationSummary';

function ScriptNotificationSummary({ notification, ...restProps }) {
  return (
    <CommonNotificationSummary {...restProps} notification={notification}>
      <tr>
        <td>Script Path</td>
        <td><code>{notification?.config?.script_path}</code></td>
      </tr>
      <tr>
        <td>Script Arguments</td>
        <td><code>{notification?.config?.script_args}</code></td>
      </tr>
      <tr>
        <td>Send Alert Data Through STDIN</td>
        <td><code>{notification?.config?.script_send_stdin ? 'Yes' : 'No'}</code></td>
      </tr>
      <tr>
        <td>Script Timeout</td>
        <td><code>{notification?.config?.script_timeout} ms</code></td>
      </tr>
    </CommonNotificationSummary>
  );
}

ScriptNotificationSummary.propTypes = {
  type: PropTypes.string.isRequired,
  notification: PropTypes.shape({
    config: PropTypes.shape({
      script_path: PropTypes.string,
      script_args: PropTypes.string,
      script_send_stdin: PropTypes.bool,
      script_timeout: PropTypes.number,
    }).isRequired,
  }).isRequired,
  definitionNotification: PropTypes.shape.isRequired,
};

ScriptNotificationSummary.defaultProps = {
};

export default ScriptNotificationSummary;
