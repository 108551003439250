import Reflux from 'reflux';

import { qualifyUrl } from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';
import type FetchError from 'logic/errors/FetchError';

export type Configuration = {
  oauth_client_id: string,
  oauth_base_url: string,
  oauth_auth_url: string,
  oauth_callback_url: string,
  oauth_type: string,
  oauth_title: string
}

export type OidcConfiguration = {
  configuration?: Configuration,
  error?: FetchError,
}

type OidcConfigurationStoreType = {
  getConfig: () => Promise<OidcConfiguration>,
  getInitialState: () => void,
} & OidcConfiguration

type OidcConfigurationActionType = {
  getConfig: () => Promise<OidcConfiguration>,
}

export const OidcConfigurationActions = Reflux.createActions<OidcConfigurationActionType>({
  getConfig: { asyncResult: true },
});

export const OidcConfigurationStore = Reflux.createStore<OidcConfigurationStoreType>({
  listenables: [OidcConfigurationActions],
  sourceUrl: '/plugins/org.graylog.plugins.security/oauth/authconfig',
  configuration: undefined,
  error: undefined,

  getState() {
    return {
      configuration: this.configuration,
      error: this.error,
    };
  },

  getInitialState() {
    return this.getState();
  },

  propagateState() {
    this.trigger(this.getState());
  },

  getConfig() {
    const promise = fetch('GET', qualifyUrl(this.sourceUrl), null, false).then((response) => {
      this.configuration = response;
      this.propagateState();

      return response;
    }, (error) => {
      this.error = error;
      this.propagateState();
    });

    OidcConfigurationActions.getConfig.promise(promise);
  },
});
