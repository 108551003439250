import React, { useEffect } from 'react';
import styled from 'styled-components';

import QueryEditModeContext from 'views/components/contexts/QueryEditModeContext';
import useUndeclaredParameters from 'enterprise/parameters/components/useUndeclaredParameters';

import ParameterBar from './ParameterBar';

const CenteringContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CenteredBox = styled.div`
  position: absolute;
  background: white;
  z-index: 1;
  left: 0;
  right: 0;
  width: max-content;
  margin: 0 auto;
`;

type Props = {
  retry: () => void,
};

const UndeclaredParameterAlert = ({ retry }: Props) => {
  const undeclaredParameterNames = useUndeclaredParameters();

  if (undeclaredParameterNames.size === 0) {
    retry();

    return null;
  }

  return (
    <QueryEditModeContext.Provider value="widget">
      <CenteringContainer>
        <CenteredBox>
          <ParameterBar />
        </CenteredBox>
      </CenteringContainer>
    </QueryEditModeContext.Provider>
  );
};

type WidgetEditModeParameterBarProps = {
  override: (Component: React.ComponentType<Props>) => void,
}

const WidgetEditModeParameterBar = ({ override }: WidgetEditModeParameterBarProps) => {
  const undeclaredParameterNames = useUndeclaredParameters();

  useEffect(() => {
    if (undeclaredParameterNames.size > 0) {
      override(UndeclaredParameterAlert);
    }
  }, [override, undeclaredParameterNames]);

  return null;
};

export default WidgetEditModeParameterBar;
