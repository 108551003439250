import * as React from 'react';
import styled from 'styled-components';

import { Well } from 'components/bootstrap';
import NumberUtils from 'util/NumberUtils';
import type { EstimateType } from 'instant-archiving/hooks/useInstantArchivingEstimate';

type Props = {
  estimate: EstimateType
}

const StyledWell = styled(Well)`
  margin-top: 0;
`;

const StyledP = styled.p`
  font-weight: bold;
`;

const ArchiveSizeEstimate = ({ estimate }: Props) => (
  <>
    <StyledP>Estimation:</StyledP>
    <StyledWell bsSize="small" className="configuration-well react-configuration-well">
      <ul>
        <li>Message count: {estimate.row_count}</li>
        <li>Size: {NumberUtils.formatBytes(estimate.size_bytes)}</li>
      </ul>
    </StyledWell>
  </>
);

export default ArchiveSizeEstimate;
