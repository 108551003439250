import { useState } from 'react';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import UserNotification from 'util/UserNotification';
import type { ColumnFilterData } from 'security-app/components/common/Filters/ColumnFilter.types';

import {
  fetchAssets,
  fetchAsset,
  getAssetsById,
  deleteAsset,
  createAsset,
  updateAsset,
  fetchPriorities,
  fetchCategories,
  addPriority,
  updatePriority,
  reprioritizePriorities,
  deletePriority,
  addCategory,
  updateCategory,
  deleteCategory,
  fetchAssetSources,
  createAssetSource,
  updateAssetSource,
  deleteAssetSource,
  testNewAssetSource,
  fetchAssetSourceMappings,
  createAssetSourceMapping,
  updateAssetSourceMapping,
  deleteAssetSourceMapping,
  importAssets,
  testSavedAssetSource,
  bulkTestAssetSource,
  testNewAssetSrouceMapping,
  testSavedAssetSourceMapping,
  fetchAssetSource,
  fetchAssetSourceMapping,
} from './api/assetsAPI';
import type {
  AssetsIndexAPIType,
  AssetAPIType,
  PriorityAPIType,
  CategoryAPIType,
  AssetDetailsType,
  AssetSourceIndexAPIType,
  AssetSourceMappingsIndexAPIType,
  AssetSourceTestAPIType,
  AssetSourceAPIType,
  AssetSourceMappingAPIType,
  AssetSourceMappingConfigsType,
} from './api/assetsAPI.types';

export type PaginatedProps = {
  page: number,
  perPage: number,
  query?: string,
  orderBy?: string,
  direction?: 'asc' | 'desc',
  filters?: ColumnFilterData,
};

export function useGetAssets({
  page,
  perPage,
  query,
  orderBy,
  direction,
  filters,
}: PaginatedProps) {
  const { data, isLoading } = useQuery<AssetsIndexAPIType, Error>(
    ['get-assets', page, perPage, query, orderBy, direction, filters],
    () => fetchAssets(page, perPage, query, orderBy, direction, filters),
    {
      onError: (error: Error) => UserNotification.error(error.message),
      retry: 2,
      keepPreviousData: true,
    },
  );

  return {
    loadingAssets: isLoading,
    assets: isLoading ? [] : data?.assets,
    pagination: {
      page: data?.page || page,
      perPage: data?.per_page || perPage,
      total: data?.total || 0,
      grandTotal: data?.grand_total || 0,
      count: data?.count || 0,
    },
  };
}

export function useGetAsset(assetId: string) {
  const { data, isFetching } = useQuery<AssetAPIType<AssetDetailsType>, Error>(
    ['get-asset', assetId],
    () => fetchAsset(assetId),
    {
      onError: (error: Error) => UserNotification.error(error.message),
      retry: 1,
      enabled: !!assetId,
    },
  );

  return {
    asset: isFetching ? null : data,
    loadingAsset: isFetching,
  };
}

export function useGetAssetsByIds(assetIds: string[]) {
  const { data, isFetching } = useQuery<AssetsIndexAPIType, Error>(
    ['get-asset-by-ids', assetIds],
    () => getAssetsById(assetIds.join()),
    {
      onError: (error: Error) => UserNotification.error(error.message),
      retry: 1,
    },
  );

  return {
    assets: isFetching ? [] : data?.assets,
    fetchingAssets: isFetching,
  };
}

export function useCreateAsset() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    createAsset,
    {
      onSuccess: () => {
        UserNotification.success('Asset created successfully');
        queryClient.invalidateQueries(['get-assets']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return {
    createAsset: mutateAsync,
    creatingAsset: isLoading,
  };
}

export function useUpdateAsset() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    updateAsset,
    {
      onSuccess: () => {
        UserNotification.success('Asset updated successfully');
        queryClient.invalidateQueries(['get-assets']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return {
    updateAsset: mutateAsync,
    updatingAsset: isLoading,
  };
}

export function useDeleteAsset() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    deleteAsset,
    {
      onSuccess: () => {
        UserNotification.success('Asset deleted successfully');
        queryClient.invalidateQueries(['get-assets']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return {
    deleteAsset: mutateAsync,
    deletingAsset: isLoading,
  };
}

export function useGetPriorities(execute: boolean = true) {
  const { data, isLoading } = useQuery<PriorityAPIType[], Error>(
    ['get-all-priorities'],
    fetchPriorities,
    {
      onError: (error: Error) => UserNotification.error(error.message),
      retry: 2,
      enabled: execute,
    },
  );

  return {
    priorities: data || [],
    loadingPriorities: isLoading,
  };
}

export function useGetCategories(execute: boolean = true) {
  const { data, isLoading } = useQuery<CategoryAPIType[], Error>(
    ['get-all-categories'],
    fetchCategories,
    {
      onError: (error: Error) => UserNotification.error(error.message),
      retry: 2,
      enabled: execute,
    },
  );

  return {
    categories: data || [],
    loadingCategoryes: isLoading,
  };
}

export function useAddPriority() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    addPriority,
    {
      onSuccess: () => {
        UserNotification.success('New priority tag added');
        queryClient.invalidateQueries(['get-all-priorities']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return { addPriority: mutateAsync, addingPriority: isLoading };
}

export function useUpdatePriority() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    updatePriority,
    {
      onSuccess: () => {
        UserNotification.success('Priority tag updated');
        queryClient.invalidateQueries(['get-all-priorities']);
        queryClient.invalidateQueries(['get-assets']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return { updatePriority: mutateAsync, updatingPriority: isLoading };
}

export function useReprioritizePriorities() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    reprioritizePriorities,
    {
      onSuccess: () => {
        UserNotification.success('Priority tags updated');
        queryClient.invalidateQueries(['get-all-priorities']);
        queryClient.invalidateQueries(['get-assets']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return { reprioritizePriorities: mutateAsync, reprioritizingPriorities: isLoading };
}

export function useDeletePriority() {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(
    deletePriority,
    {
      onSuccess: () => {
        UserNotification.success('Priority tag deleted');
        queryClient.invalidateQueries(['get-all-priorities']);
        queryClient.invalidateQueries(['get-assets']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return { deletePriority: mutateAsync };
}

export function useAddCategory() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    addCategory,
    {
      onSuccess: () => {
        UserNotification.success('New Category tag added');
        queryClient.invalidateQueries(['get-all-categories']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return { addCategory: mutateAsync, addingCategory: isLoading };
}

export function useUpdateCategory() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    updateCategory,
    {
      onSuccess: () => {
        UserNotification.success('Category tag updated');
        queryClient.invalidateQueries(['get-all-categories']);
        queryClient.invalidateQueries(['get-assets']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return { updateCategory: mutateAsync, updatingCategory: isLoading };
}

export function useDeleteCategory() {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(
    deleteCategory,
    {
      onSuccess: () => {
        UserNotification.success('Category tag deleted');
        queryClient.invalidateQueries(['get-all-categories']);
        queryClient.invalidateQueries(['get-assets']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return { deleteCategory: mutateAsync };
}

/* Asset Sources */
export function useGetAssetSources({
  page,
  perPage,
  query,
  orderBy,
  direction,
  filters,
}: PaginatedProps) {
  const { data, isLoading } = useQuery<AssetSourceIndexAPIType, Error>(
    ['get-assets-sources', page, perPage, query, orderBy, direction, filters],
    () => fetchAssetSources(page, perPage, query, orderBy, direction, filters),
    {
      onError: (error: Error) => UserNotification.error(error.message),
      retry: 2,
      keepPreviousData: true,
    },
  );

  return {
    loadingAssetSources: isLoading,
    assetSources: isLoading ? [] : data?.asset_source_backend_configs,
    pagination: {
      page: data?.page || page,
      perPage: data?.per_page || perPage,
      total: data?.total || 0,
      grandTotal: data?.grand_total || 0,
      count: data?.count || 0,
    },
  };
}

export function useGetAssetSource(sourceId: string) {
  const { data, isFetching } = useQuery<AssetSourceAPIType, Error>(
    ['get-asset-source', sourceId],
    () => fetchAssetSource(sourceId),
    {
      onError: (error: Error) => UserNotification.error(error.message),
      retry: 2,
      enabled: !!sourceId,
    },
  );

  return {
    assetSource: data || null,
    loadingAssetSource: isFetching,
  };
}

export function useCreateAssetSource() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    createAssetSource,
    {
      onSuccess: () => {
        UserNotification.success('Asset Source created successfully');
        queryClient.invalidateQueries(['get-asset-source']);
        queryClient.invalidateQueries(['get-assets-sources']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return {
    createAssetSource: mutateAsync,
    creatingAssetSource: isLoading,
  };
}

export function useUpdateAssetSource() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    updateAssetSource,
    {
      onSuccess: () => {
        UserNotification.success('Asset Source updated successfully');
        queryClient.invalidateQueries(['get-asset-source']);
        queryClient.invalidateQueries(['get-assets-sources']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return {
    updateAssetSource: mutateAsync,
    updatingAssetSource: isLoading,
  };
}

export function useDeleteAssetSource() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    deleteAssetSource,
    {
      onSuccess: () => {
        UserNotification.success('Asset Source deleted successfully');
        queryClient.invalidateQueries(['get-assets-sources']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return {
    deleteAssetSource: mutateAsync,
    deletingAssetSource: isLoading,
  };
}

/* Asset Source Mappings */
export function useGetAssetSourceMappings({
  page,
  perPage,
}: PaginatedProps, sourceId: string) {
  const { data, isLoading } = useQuery<AssetSourceMappingsIndexAPIType, Error>(
    ['get-asset-source-mappings', sourceId, page, perPage],
    () => fetchAssetSourceMappings(sourceId, page, perPage),
    {
      onError: (error: Error) => UserNotification.error(error.message),
      retry: 2,
      enabled: !!sourceId,
      keepPreviousData: true,
    },
  );

  return {
    loadingAssetSourceMappings: isLoading,
    assetSourceMappings: isLoading ? [] : data?.asset_import_backend_configs,
    pagination: {
      page: data?.page || page,
      perPage: data?.per_page || perPage,
      total: data?.total || 0,
      grandTotal: data?.grand_total || 0,
      count: data?.count || 0,
    },
  };
}

export function useGetAssetSourceMapping(sourceId: string, mappingId: string) {
  const { data, isLoading } = useQuery<AssetSourceMappingAPIType<AssetSourceMappingConfigsType>, Error>(
    ['get-asset-source-mapping', sourceId, mappingId],
    () => fetchAssetSourceMapping(sourceId, mappingId),
    {
      onError: (error: Error) => UserNotification.error(error.message),
      retry: 2,
      enabled: !!mappingId,
    },
  );

  return {
    assetSourceMapping: data || null,
    loadingAssetSourceMapping: isLoading,
  };
}

export function useCreateAssetSourceMapping() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    createAssetSourceMapping,
    {
      onSuccess: () => {
        UserNotification.success('Asset Source Mapping added successfully');
        queryClient.invalidateQueries(['get-asset-source-mappings']);
      },
      onError: (error: any) => {
        if ('failed' in error) {
          UserNotification.error(
            Object.entries(error.errors).map(([key, errors]: [string, string[]]) => `${key}: ${errors.join('\n')}`).join('\n'),
            'API validation failed',
          );
        } else UserNotification.error(error.message);
      },
    },
  );

  return {
    createAssetSourceMapping: mutateAsync,
    creatingAssetSourceMapping: isLoading,
  };
}

export function useUpdateAssetSourceMapping() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    updateAssetSourceMapping,
    {
      onSuccess: () => {
        UserNotification.success('Asset Source Mapping updated successfully');
        queryClient.invalidateQueries(['get-asset-source-mappings']);
      },
      onError: (error: any) => {
        if ('failed' in error) {
          UserNotification.error(
            Object.entries(error.errors).map(([key, errors]: [string, string[]]) => `${key}: ${errors.join('\n')}`).join('\n'),
            'API validation failed',
          );
        } else UserNotification.error(error.message);
      },
    },
  );

  return {
    updateAssetSourceMapping: mutateAsync,
    updatingAssetSourceMapping: isLoading,
  };
}

export function useDeleteAssetSourceMapping() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    deleteAssetSourceMapping,
    {
      onSuccess: () => {
        UserNotification.success('Asset Source Mapping deleted successfully');
        queryClient.invalidateQueries(['get-asset-source-mappings']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return {
    deleteAssetSourceMapping: mutateAsync,
    deletingAssetSourceMapping: isLoading,
  };
}

export function useImportAssetsFromSource() {
  const [queryKey, setQueryKey] = useState<string[]>();
  const queryClient = useQueryClient();

  return {
    importAssets: async (sourceId: string, mappingId: string) => (new Promise((resolve: (arg: AssetSourceTestAPIType) => void) => {
      setQueryKey(['import-assets', sourceId, mappingId]);

      try {
        queryClient.fetchQuery<AssetSourceTestAPIType>({
          queryKey: ['import-assets', sourceId, mappingId],
          queryFn: () => importAssets(sourceId, mappingId),
        }).then((importData) => {
          if (importData.success === false) {
            UserNotification.error(importData.message, 'Import failed');
          } else {
            UserNotification.success('Successfully imported assets for current mapping');
            queryClient.invalidateQueries(['get-assets-sources-mappings']);
            queryClient.invalidateQueries(['get-asset-source']);
          }

          resolve(importData);
        });
      } catch (error) {
        UserNotification.error(error.message);
        resolve(error);
      }
    })),
    importingAssets: queryClient.getQueryState(queryKey)?.fetchStatus === 'fetching',
  };
}

/* Asset Source Tests */
export function useTestNewAssetSource() {
  const { mutateAsync, isLoading } = useMutation(
    testNewAssetSource,
    {
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return {
    testNewAssetSource: mutateAsync,
    testingNewAssetSource: isLoading,
  };
}

export function useTestSavedAssetSource(sourceId: string) {
  const { data, isLoading } = useQuery<AssetSourceTestAPIType, Error>(
    ['test-saved-asset-source', sourceId],
    () => testSavedAssetSource(sourceId),
    {
      onError: (error: Error) => UserNotification.error(error.message),
      retry: 2,
    },
  );

  return {
    testSavedAssetSource: data,
    testingSavedAssetSource: isLoading,
  };
}

export function useTestNewAssetSourceMapping() {
  const { mutateAsync, isLoading } = useMutation(
    testNewAssetSrouceMapping,
    {
      onError: (error: Error) => UserNotification.error(error.message),
      retry: 2,
    },
  );

  return {
    testAssetSourceNewMapping: mutateAsync,
    testingAssetSourceNewMapping: isLoading,
  };
}

export function useBulkTestAssetSource() {
  const { mutateAsync, isLoading } = useMutation(
    bulkTestAssetSource,
    {
      onError: (error: Error) => UserNotification.error(error.message),
      retry: 2,
    },
  );

  return {
    testAssetSources: mutateAsync,
    testingAssetSources: isLoading,
  };
}

export function useTestSavedAssetSourceMapping() {
  const [queryKey, setQueryKey] = useState<string[]>();
  const queryClient = useQueryClient();

  return {
    testSavedAssetSourceMapping: async (sourceId: string, mappingId: string) => (new Promise((resolve: (testResult: AssetSourceTestAPIType) => void) => {
      setQueryKey(['test-saved-asset-source-mapping', sourceId, mappingId]);

      try {
        queryClient.fetchQuery<AssetSourceTestAPIType>({
          queryKey: ['test-saved-asset-source-mapping', sourceId, mappingId],
          queryFn: () => testSavedAssetSourceMapping(sourceId, mappingId),
        }).then((testResponse) => resolve(testResponse));
      } catch (error) {
        UserNotification.error(error.message);
      }
    })),
    testingSavedAssetSourceMapping: queryClient.getQueryState(queryKey)?.fetchStatus === 'fetching',
  };
}
