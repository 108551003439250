import * as React from 'react';
import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { ButtonToolbar } from 'components/bootstrap/';
import InstantArchivingConfigButton from 'instant-archiving/InstantArchivingConfig/InstantArchivingConfigButton';
import RestoreArchiveAction from 'instant-archiving/InstantArchivingOverview/BulkActions/RestoreArchiveAction';
import DeleteArchiveAction from 'instant-archiving/InstantArchivingOverview/BulkActions/DeleteArchiveAction';
import { handleArchiveActionFailures } from 'instant-archiving/InstantArchivingOverview/InstantArchivingActions';
import type { LicenseStatus } from 'archive/types';

type Props = {
  archiveId: string,
  configurationId: string,
  archiveStreams?: {
    [key: string]: string,
  },
  licenseStatus: LicenseStatus
}

const InstantArchiveDetailsPageSubactions = ({ archiveId, archiveStreams, configurationId, licenseStatus }: Props) => {
  const queryClient = useQueryClient();
  const refetchArchives = useCallback(() => queryClient.invalidateQueries(['instant-archiving', 'overview']), [queryClient]);

  return (
    <ButtonToolbar>

      <RestoreArchiveAction handleFailures={handleArchiveActionFailures}
                            buttonType="button"
                            selectedArchiveIds={[archiveId]}
                            archiveStreams={archiveStreams}
                            refetchArchives={refetchArchives}
                            descriptor="archive" />
      <DeleteArchiveAction handleFailures={handleArchiveActionFailures}
                           buttonType="button"
                           selectedArchiveIds={[archiveId]}
                           refetchArchives={refetchArchives}
                           descriptor="archive" />
      <InstantArchivingConfigButton licenseStatus={licenseStatus} configurationId={configurationId} />
    </ButtonToolbar>
  );
};

InstantArchiveDetailsPageSubactions.defaultProps = {
  archiveStreams: undefined,
};

export default InstantArchiveDetailsPageSubactions;
