import __request__ from 'routing/request';
/**
 * Finds leader node and triggers deflector cycle
 */
export function cycle(): Promise<void> {
    return __request__('POST', '/cluster/deflector/cycle', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Finds leader node and triggers deflector cycle
 */
export function cycleByindexSetId(indexSetId?: string): Promise<void> {
    return __request__('POST', `/cluster/deflector/${indexSetId}/cycle`, null, {}, {
        'Accept': ['application/json']
    });
}
