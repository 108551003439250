import InvestigationsMessageAction from './CoreMessageActions';
import InvestigationsSearchActions from './CoreSearchActions';
import InvestigationsEventAction from './CoreEventActions';
import InvestigationsDashboardAction, { InvestigationsModal } from './CoreDashboardActions';

export default {
  'views.components.dashboardActions': [
    { component: InvestigationsDashboardAction, key: 'investigations', modal: InvestigationsModal },
  ],
  'views.components.eventActions': [
    { component: InvestigationsEventAction, key: 'investigations' },
  ],
  'views.components.widgets.messageTable.messageActions': [
    { component: InvestigationsMessageAction, key: 'investigations' },
  ],
  'views.components.searchActions': [
    { component: InvestigationsSearchActions, key: 'investigations' },
  ],
};
