import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';

import withParams from 'routing/withParams';
import { DocumentTitle, PageHeader } from 'components/common';
import UsersPageNavigation from 'components/users/navigation/UsersPageNavigation';
import DocsHelper from 'util/DocsHelper';

import type Team from '../logic/Team';
import { TeamsActions } from '../stores/TeamsStore';
import TeamsDomain from '../domainActions/TeamsDomain';
import TeamEdit from '../components/team-edit/TeamEdit';
import TeamActionLinks from '../components/navigation/TeamActionLinks';

type Props = {
  params: {
    teamId: string,
  },
};

const PageTitle = ({ name }: {name: string | null | undefined}) => (
  <>
    Team Edit {name && (
    <>
      - <i>{name}</i>
    </>
  )}
  </>
);

const TeamEditPage = ({ params }: Props) => {
  const teamId = params?.teamId;
  const [team, setTeam] = useState<Team | undefined>();
  const name = team?.name;
  const fetchTeam = useCallback(() => TeamsDomain.load(teamId).then(setTeam), [teamId]);

  useEffect(() => { fetchTeam(); }, [teamId, fetchTeam]);
  useEffect(() => TeamsActions.update.completed.listen(fetchTeam), [teamId, fetchTeam]);
  useEffect(() => TeamsActions.addMembers.completed.listen(fetchTeam), [teamId, fetchTeam]);
  useEffect(() => TeamsActions.removeMember.completed.listen(fetchTeam), [teamId, fetchTeam]);

  return (
    <DocumentTitle title={`Team Edit ${name ?? ''}`}>
      <UsersPageNavigation />
      <PageHeader title={<PageTitle name={name} />}
                  actions={<TeamActionLinks team={team} />}
                  documentationLink={{
                    title: 'Permissions documentation',
                    path: DocsHelper.PAGES.USERS_ROLES,
                  }}>
        <span>
          Edit settings and assign users and roles for this team.
        </span>
      </PageHeader>

      <TeamEdit team={team} />
    </DocumentTitle>
  );
};

export default withParams(TeamEditPage);
