import React, { useEffect } from 'react';

import connect from 'stores/connect';
import type { LookupTableAdapter } from 'logic/lookup-tables/types';
import type { Store } from 'stores/StoreTypes';

import type { StatsData, MongoDBDataAdapterState } from '../stores/MongoDBDataAdapterStore';
import { MongoDBDataAdapterStore, MongoDBDataAdapterActions } from '../stores/MongoDBDataAdapterStore';

type Props = {
  statsData: StatsData,
  dataAdapter: LookupTableAdapter,
};

const MongoDBDataAdapterSummary = ({ statsData, dataAdapter }: Props) => {
  useEffect(() => {
    MongoDBDataAdapterActions.stats(dataAdapter.id);
  }, [dataAdapter]);

  return (
    <dl>
      <dt>Database Entries</dt>
      <dd>{statsData?.entriesCount || 0}</dd>
      <dt>CIDR lookup</dt>
      <dd>{dataAdapter.config.cidr_lookup ? 'yes' : 'no'}</dd>
    </dl>
  );
};

export default connect(MongoDBDataAdapterSummary,
  { adapter: MongoDBDataAdapterStore as Store<MongoDBDataAdapterState> },
  (props) => ({
    ...props,
    statsData: props.adapter?.statsData || 0,
  }));
