import * as React from 'react';
import styled, { css } from 'styled-components';

import { GLCheckbox, ColumnSort, ColumnFilter } from 'security-app/components/common';
import { useGetInvestigations } from 'security-app/hooks/useInvestigationsAPI';
import type { InvestigationAPIType } from 'security-app/hooks/api/investigationsAPI.types';
import type { ColumnFilterData, FilterData } from 'security-app/components/common/Filters/ColumnFilter.types';
import {
  usePagination,
  useSetPagination,
  useSelectedRows,
  useSelectedRowsDispatch,
} from 'security-app/components/common/contexts';

const TH = styled.th(({ theme }) => css`
  vertical-align: middle !important;
  max-width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 16px 8px !important;
  border-bottom: 2px solid ${theme.colors.gray[80]} !important;
  background-color: ${theme.colors.table.background} !important;
`);

type Props = {
  investigations: InvestigationAPIType[],
};

const columnsWithFilter = ['status'];

function ListHeader({ investigations }: Props) {
  const pagination = usePagination();
  const setPagination = useSetPagination();

  const selectedInvestigations = useSelectedRows();
  const selectedInvestigationsDispatch = useSelectedRowsDispatch();

  const { investigations: filtersData } = useGetInvestigations({ page: 1, perPage: 10000 });

  const filtersByColumn = React.useMemo(() => filtersData.reduce((acc: ColumnFilterData, inv: InvestigationAPIType) => {
    Object.entries(inv).filter(([key, _]) => columnsWithFilter.includes(key)).forEach(([key, value]) => {
      if (value == null) return;
      if (!acc[key]) acc[key] = [];
      const labelValue = value.toString();
      const filterValue = value.toString();
      const valueType = typeof value;

      if (!acc[key].find((filter: FilterData) => filter.value.toString() === filterValue)) {
        acc[key].push({ label: labelValue, value: filterValue, type: valueType });
      }
    });

    return acc;
  }, {} as ColumnFilterData), [filtersData]);

  const applySort = (field: string, direction: string) => {
    setPagination({ ...pagination, orderBy: field, direction });
  };

  const applyFilters = (filterKey: string, filterValues: FilterData[]) => {
    const auxFilters = { ...pagination.filters, [filterKey]: filterValues };
    if (!filterValues || filterValues.length === 0) delete auxFilters[filterKey];

    setPagination({ ...pagination, filters: auxFilters });
  };

  const selectAll = (e: React.BaseSyntheticEvent) => {
    if (e.target.checked) {
      selectedInvestigationsDispatch({ type: 'add', payload: investigations });
    } else {
      selectedInvestigationsDispatch({ type: 'remove', payload: investigations });
    }
  };

  const allSelected = React.useMemo(() => (
    investigations.length > 0
    && selectedInvestigations.length > 0
    && investigations.every((inv: InvestigationAPIType) => (
      !!selectedInvestigations.find((sInv: InvestigationAPIType) => inv.id === sInv.id)
    ))
  ), [investigations, selectedInvestigations]);

  return (
    <>
      <colgroup>
        <col style={{ width: 32 }} />
        <col style={{ width: '70%' }} />
        <col style={{ width: 'auto' }} />
      </colgroup>
      <thead>
        <tr>
          <TH><GLCheckbox checked={allSelected} onChange={selectAll} data-testid="select-all" /></TH>
          <TH>
            <ColumnSort field="name"
                        orderBy={pagination.orderBy}
                        direction={pagination.direction}
                        onSort={applySort}>
              Name
            </ColumnSort>
          </TH>
          <TH>
            <ColumnSort field="status"
                        orderBy={pagination.orderBy}
                        direction={pagination.direction}
                        onSort={applySort}>
              <ColumnFilter filterBy="status"
                            type="text"
                            filterData={filtersByColumn.status}
                            appliedFilters={pagination.filters}
                            onClose={applyFilters}>
                Status
              </ColumnFilter>
            </ColumnSort>
          </TH>
        </tr>
      </thead>
    </>
  );
}

export default ListHeader;
