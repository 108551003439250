import Reflux from 'reflux';
import deepEqual from 'deep-equal';

import { MetricsActions, MetricsStore } from 'stores/metrics/MetricsStore';

const LICENSE_VIOLATION_METRIC = 'org.graylog.enterprise.license.status.violated';
const LICENSE_EXPIRATION_METRIC = 'org.graylog.enterprise.license.status.expired';
const LICENSE_EXPIRATION_UPCOMING_METRIC = 'org.graylog.enterprise.license.status.expiration-upcoming';
const LICENSE_TRIAL_METRIC = 'org.graylog.enterprise.license.status.trial';
const LICENSE_TRAFFIC_VIOLATION_UPCOMING_METRIC = 'org.graylog.enterprise.license.traffic.violation-upcoming';

const LICENSE_METRICS = [
  LICENSE_VIOLATION_METRIC,
  LICENSE_EXPIRATION_METRIC,
  LICENSE_EXPIRATION_UPCOMING_METRIC,
  LICENSE_TRIAL_METRIC,
  LICENSE_TRAFFIC_VIOLATION_UPCOMING_METRIC,
];

/**
 * The store provides license related information like expiration and violation to components.
 */
const LicenseStatusStore = Reflux.createStore({
  prevLicenseStatus: {
    violated: false,
    expired: false,
    expiredSince: 0,
    expirationUpcoming: false,
    expiresIn: 0,
    trial: false,
    trialExpiresIn: 0,
    trafficViolation: false,
  },

  getInitialState() {
    return this.prevLicenseStatus;
  },

  /**
   * We are using the metrics subsystem to expose the license status information.
   *
   * That way we can avoid creating more regular HTTP requests against the Graylog server because we are
   * doing regular metrics updates anyway.
   */
  init() {
    this.listenTo(MetricsStore, this.onMetricsUpdate);

    MetricsActions.addGlobal(LICENSE_VIOLATION_METRIC);
    MetricsActions.addGlobal(LICENSE_EXPIRATION_METRIC);
    MetricsActions.addGlobal(LICENSE_EXPIRATION_UPCOMING_METRIC);
    MetricsActions.addGlobal(LICENSE_TRIAL_METRIC);
    MetricsActions.addGlobal(LICENSE_TRAFFIC_VIOLATION_UPCOMING_METRIC);
  },

  /**
   * Extracts the license status information from updated metrics data.
   *
   * @param data MetricsStore state
   */
  onMetricsUpdate(data) {
    // Guard against an update from the MetricsStore not containing actual metrics (i.e. `names()`)
    if (!data.metrics) {
      return;
    }

    const licenseStatus = { ...this.prevLicenseStatus };

    this.selectLicenseMetrics(data.metrics, (nodeId, metricName, metricValue) => {
      switch (metricName) {
        case LICENSE_VIOLATION_METRIC:
          licenseStatus.violated = metricValue > 0;
          break;
        case LICENSE_EXPIRATION_METRIC:
          licenseStatus.expired = metricValue > 0;
          licenseStatus.expiredSince = metricValue;
          break;
        case LICENSE_EXPIRATION_UPCOMING_METRIC:
          licenseStatus.expirationUpcoming = metricValue > 0;
          licenseStatus.expiresIn = metricValue;
          break;
        case LICENSE_TRIAL_METRIC:
          licenseStatus.trial = metricValue > 0;
          licenseStatus.trialExpiresIn = metricValue;
          break;
        case LICENSE_TRAFFIC_VIOLATION_UPCOMING_METRIC:
          licenseStatus.trafficViolation = metricValue > 0;
          break;
        default:
          break;
      }
    });

    if (!deepEqual(this.prevLicenseStatus, licenseStatus)) {
      this.trigger(licenseStatus);
    }

    this.prevLicenseStatus = licenseStatus;
  },

  /**
   * Returns only the license related metrics and their values.
   *
   * @param metrics metrics data
   * @param callback will be called with the nodeId, metricName and metricValue
   */
  selectLicenseMetrics(metrics, callback) {
    if (!callback) {
      throw new Error('Missing callback parameter in LicenseStatusStore#processMetrics()');
    }

    Object.keys(metrics).forEach((nodeId) => {
      Object.keys(metrics[nodeId])
        .filter((metricName) => LICENSE_METRICS.indexOf(metricName) > -1)
        .forEach((metricName) => {
          callback(nodeId, metricName, metrics[nodeId][metricName].metric.value);
        });
    });
  },
});

export default LicenseStatusStore;
