import * as React from 'react';

import type View from 'views/logic/views/View';
import { MenuItem, Button } from 'components/bootstrap';
import { IfPermitted } from 'components/common';
import useLicenseCheck from 'license/useLicenseCheck';
import { Modal as ConfirmDialog } from 'security-app/components/common';
import { useInvestigationDrawer } from 'security-app/components/Investigations/contexts';
import { useAddEvidence, useGetInvestigationDetails } from 'security-app/hooks/useInvestigationsAPI';

import useSearchSrvc from './shared/useSearchSrvc';
import AddEvidenceModal from './shared/AddEvidenceModal';

type Props = {
  loaded: boolean,
  view: View,
}

const InvestigationsSearchActions = ({ loaded, view }: Props) => {
  const addEvidenceModalRef = React.useRef(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = React.useState(false);
  const { security } = useLicenseCheck();

  const { isSearchRelative, saveAsAbsoluteSearch } = useSearchSrvc(view);
  const { selectedInvestigationId, setCollapsed } = useInvestigationDrawer();
  const { currentInvestigation } = useGetInvestigationDetails(selectedInvestigationId);

  const { addEvidence } = useAddEvidence();

  const disabledAdd = !currentInvestigation || currentInvestigation?.archived;

  const addSearch = React.useCallback(async (invId: string, search: View) => {
    await addEvidence({ invId, payload: { searches: [search.id] } });
    setCollapsed(false);
  }, [addEvidence, setCollapsed]);

  const onAddSearch = async () => {
    if (disabledAdd) return;

    if (isSearchRelative) {
      setShowConfirmationDialog(true);
    } else {
      addSearch(selectedInvestigationId, view);
    }
  };

  const onSaveAs = React.useCallback((timeRangeType: 'absolute' | 'relative') => async () => {
    setShowConfirmationDialog(false);

    const search = timeRangeType === 'absolute'
      ? await saveAsAbsoluteSearch(`Absolute - ${view.title}`)
      : view;

    addSearch(selectedInvestigationId, search);
  }, [addSearch, saveAsAbsoluteSearch, selectedInvestigationId, view]);

  const onCancel = React.useCallback(() => {
    setShowConfirmationDialog(false);
  }, []);

  const buttons = React.useMemo(() => (
    <>
      <Button variant="secondary" onClick={onCancel}>Cancel</Button>
      <Button variant="secondary" onClick={onSaveAs('relative')}>Save as Relative</Button>
      <Button bsStyle="success" onClick={onSaveAs('absolute')}>Save as Absolute</Button>
    </>
  ), [onCancel, onSaveAs]);

  return (security?.isValid && loaded) ? (
    <IfPermitted permissions="investigations:edit">
      <MenuItem disabled={disabledAdd} onClick={onAddSearch} icon="puzzle-piece">
        Add to investigation
      </MenuItem>
      <MenuItem icon="puzzle-piece" onClick={() => addEvidenceModalRef.current?.toggle()}>
        Select an investigation
      </MenuItem>
      <AddEvidenceModal id={view.id}
                        type="searches"
                        searchMeta={{ searchTitle: view.title, isSearchRelative, saveAsAbsoluteSearch }}
                        ref={addEvidenceModalRef} />
      <ConfirmDialog show={showConfirmationDialog}
                     title="Relative search as evidence"
                     confirmDialog
                     onClose={onCancel}
                     buttons={buttons}>
        <div>
          The search you are trying to add is relative to the current time. This means that the results will change.
          <br />
          <br />
          Would you like to make an absolute copy of this search and add it to the investigation?
        </div>
      </ConfirmDialog>
    </IfPermitted>
  ) : null;
};

export default InvestigationsSearchActions;
