import * as React from 'react';

import { InvalidLicenseWarning } from 'license/LicenseCheck';
import SearchFilterPageSubactions from 'search-filter/components/filter-management-pages/SearchFilterPageSubactions';
import UserSearchFilterDetails from 'search-filter/components/filter-management-pages/UserSearchFilterDetails';
import { PageHeader, DocumentTitle, Spinner } from 'components/common';
import DocsHelper from 'util/DocsHelper';
import useSearchFilterLicenseStatus from 'search-filter/hooks/useSearchFilterLicenseStatus';

const MyFiltersDetailsPage = () => {
  const { isFetchingLicense, isValidLicense } = useSearchFilterLicenseStatus();

  if (isFetchingLicense) {
    return <Spinner />;
  }

  return (
    <DocumentTitle title="View Filter Details">
      <PageHeader title="View Filter Details"
                  actions={<SearchFilterPageSubactions />}
                  documentationLink={{
                    title: 'Search filter documentation',
                    path: DocsHelper.PAGES.WELCOME,
                  }}>
        <span>
          View search filter details and usages in searches and dashboards.
        </span>
      </PageHeader>
      {isValidLicense ? <UserSearchFilterDetails /> : (
        <InvalidLicenseWarning featureName="search filter"
                               licenseSubject="/license/enterprise/search-filter"
                               displayWarningContainer />
      )}
    </DocumentTitle>
  );
};

export default MyFiltersDetailsPage;
