import __request__ from 'routing/request';
interface HTTPHeaderAuthConfig {
    readonly username_header: string;
    readonly enabled: boolean;
}
/**
 * Update HTTP header authentication config
 */
export function updateConfig(): Promise<HTTPHeaderAuthConfig> {
    return __request__('PUT', '/system/authentication/http-header-auth-config', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get HTTP header authentication config
 */
export function getConfig(): Promise<HTTPHeaderAuthConfig> {
    return __request__('GET', '/system/authentication/http-header-auth-config', null, {}, {
        'Accept': ['application/json']
    });
}
