import React from 'react';
import PropTypes from 'prop-types';

import connect from 'stores/connect';
import { ViewsLicenseStore } from 'enterprise/parameters/stores/ViewsLicenseStore';
import Spinner from 'components/common/Spinner';
import CustomPropTypes from 'views/components/CustomPropTypes';

const validLicensePresent = (license) => (license && !license.missing && license.status && license.status.valid);

const LicenseCheck = ({ children, license, errorComponent, hideOnMissing }) => {
  if (license && license.loading) {
    return <Spinner />;
  }

  const ErrorComponent = errorComponent;

  // don't show the error if there's no license at all
  if (hideOnMissing && (!license || license.missing)) {
    return null;
  }

  if (!validLicensePresent(license)) {
    return <ErrorComponent license={license} />;
  }

  return children;
};

LicenseCheck.propTypes = {
  children: CustomPropTypes.OneOrMoreChildren.isRequired,
  errorComponent: PropTypes.func.isRequired,
  hideOnMissing: PropTypes.bool,
  license: PropTypes.shape({
    status: PropTypes.object,
    missing: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
  }).isRequired,
};

export default connect(LicenseCheck, { license: ViewsLicenseStore });
