// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Xs05u6g6T94RXQPWqmgX {
    height: 100%;
    /* The grid prop is a workaround to fix
     * horizontal scrolling for safari */
    display: grid;
    display: -ms-grid;
    grid-template-rows: 1fr;
    -ms-grid-rows: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-columns: 1fr;
}

.tezUr3fyRnWnYXoSKy4b {
    overflow: auto;
    grid-row: 1;
    -ms-grid-row: 1;
    grid-column: 1;
    -ms-grid-column: 1;
}

.cUxYxJT0iMrajuP7YHXA {
    left: 0px;
}

@media print {
    table.messages {
        font-size: 1rem !important; /* theme.fonts.size.body */
        min-width: 50%;
        padding-left: 0;
    }

    table.messages th {
        font-weight: bold !important;
        font-size: inherit !important;
    }

    table.messages th,
    table.messages td {
        border: 1px #ccc solid !important;
        left: 0;
        padding: 5px;
        position: static;
    }
}
`, "",{"version":3,"sources":["webpack://./src/views/components/datatable/DataTable.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ;wCACoC;IACpC,aAAa;IACb,iBAAiB;IACjB,uBAAuB;IACvB,kBAAkB;IAClB,0BAA0B;IAC1B,qBAAqB;AACzB;;AAEA;IACI,cAAc;IACd,WAAW;IACX,eAAe;IACf,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,SAAS;AACb;;AAEA;IACI;QACI,0BAA0B,EAAE,0BAA0B;QACtD,cAAc;QACd,eAAe;IACnB;;IAEA;QACI,4BAA4B;QAC5B,6BAA6B;IACjC;;IAEA;;QAEI,iCAAiC;QACjC,OAAO;QACP,YAAY;QACZ,gBAAgB;IACpB;AACJ","sourcesContent":[":local(.container) {\n    height: 100%;\n    /* The grid prop is a workaround to fix\n     * horizontal scrolling for safari */\n    display: grid;\n    display: -ms-grid;\n    grid-template-rows: 1fr;\n    -ms-grid-rows: 1fr;\n    grid-template-columns: 1fr;\n    -ms-grid-columns: 1fr;\n}\n\n:local(.scrollContainer) {\n    overflow: auto;\n    grid-row: 1;\n    -ms-grid-row: 1;\n    grid-column: 1;\n    -ms-grid-column: 1;\n}\n\n:local(.leftAligned) {\n    left: 0px;\n}\n\n@media print {\n    table.messages {\n        font-size: 1rem !important; /* theme.fonts.size.body */\n        min-width: 50%;\n        padding-left: 0;\n    }\n\n    table.messages th {\n        font-weight: bold !important;\n        font-size: inherit !important;\n    }\n\n    table.messages th,\n    table.messages td {\n        border: 1px #ccc solid !important;\n        left: 0;\n        padding: 5px;\n        position: static;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Xs05u6g6T94RXQPWqmgX`,
	"scrollContainer": `tezUr3fyRnWnYXoSKy4b`,
	"leftAligned": `cUxYxJT0iMrajuP7YHXA`
};
export default ___CSS_LOADER_EXPORT___;
