import __request__ from 'routing/request';
type stringArray = string[];
interface stringArrayMap {
    readonly [_key: string]: stringArray;
}
/**
 * Get list of message fields that exist
 * @param limit Maximum number of fields to return. Set to 0 for all fields.
 */
export function fields(limit?: number): Promise<stringArrayMap> {
    return __request__('GET', '/system/fields', null, { 'limit': limit }, {
        'Accept': ['application/json']
    });
}
