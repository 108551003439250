import { useQuery } from '@tanstack/react-query';

import type FetchError from 'logic/errors/FetchError';
import { qualifyUrl } from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';
import { defaultCompare } from 'logic/DefaultCompare';
import type { StaticSource } from 'enterprise/parameters/components/option/OptionSource';
import OptionParameter from 'enterprise/parameters/components/option/OptionParameter';
import type Parameter from 'views/logic/parameters/Parameter';

const retrieveFieldValuesFor = (parameter: OptionParameter, searchId: string): Promise<Array<string>> => fetch('POST',
  qualifyUrl(`/plugins/org.graylog.plugins.enterprise/fieldvalues/${searchId}`),
  { parameter: parameter.name });

const isOptionParameter = (parameter: Parameter): parameter is OptionParameter => (parameter.type === OptionParameter.type);

const retrieveValuesOf = (parameter: Parameter, searchId: string) => {
  if (!isOptionParameter(parameter)) {
    throw new Error(`Invalid parameter passed, not option parameter: ${parameter}`);
  }

  const { source } = parameter;

  switch (source.type) {
    case 'field': return retrieveFieldValuesFor(parameter, searchId).then((values) => values.sort(defaultCompare));
    case 'static': return Promise.resolve((source as StaticSource).values);
    default:
      throw new Error(`Invalid option source type: ${source.type}`);
  }
};

type UseValuesOfResult = {
  data: string[],
  isFetching: boolean,
  isLoading: boolean,
  isError: boolean,
  error: FetchError,
  refetch: () => void,
};
const useValuesOf = (parameter: Parameter, searchId: string): UseValuesOfResult => useQuery(
  ['fieldValues', searchId, parameter.name],
  () => retrieveValuesOf(parameter, searchId),
);

export default useValuesOf;
