import __request__ from 'routing/request';
interface DebugEvent {
    readonly date: string;
    readonly text: string;
    readonly node_id: string;
}
/**
 * Create and send a cluster debug event.
 */
export function generateClusterDebugEvent(text: string = 'Cluster Test'): Promise<void> {
    return __request__('POST', '/system/debug/events/cluster', text, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Show last received cluster debug event.
 */
export function showLastClusterDebugEvent(): Promise<DebugEvent> {
    return __request__('GET', '/system/debug/events/cluster', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Create and send a local debug event.
 */
export function generateDebugEvent(text: string = 'Local Test'): Promise<void> {
    return __request__('POST', '/system/debug/events/local', text, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Show last received local debug event.
 */
export function showLastDebugEvent(): Promise<DebugEvent> {
    return __request__('GET', '/system/debug/events/local', null, {}, {
        'Accept': ['application/json']
    });
}
