import { useMutation, useQueryClient } from '@tanstack/react-query';

import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import UserNotification from 'util/UserNotification';
import { USER_FILTER_QUERY_KEY } from 'search-filter/hooks/useUserSearchFiltersQuery';
import type { SearchFilterEdit } from 'search-filter/components/search-filter-bar/SearchFilterForm';
import { USER_SEARCH_FILTER_TYPE } from 'search-filter/constants';

const urlPrefix = '/plugins/org.graylog.plugins.searchfilters';

const postUserSearchFilter = async ({ data }: { data: SearchFilterEdit }) => {
  const url = `${urlPrefix}/search_filters`;

  return fetch('POST', qualifyUrl(url), { type: USER_SEARCH_FILTER_TYPE, ...data });
};

const deleteUserSearchFilter = (id: string) => {
  const url = `${urlPrefix}/search_filters/${id}`;

  return fetch('DELETE', qualifyUrl(url));
};

const putUserSearchFilter = ({ id, data }: {
  data: SearchFilterEdit,
  id: string,
}) => {
  const url = `${urlPrefix}/search_filters/${id}`;

  return fetch('PUT', qualifyUrl(url), { type: USER_SEARCH_FILTER_TYPE, ...data });
};

const useUserSearchFilterMutation = () => {
  const queryClient = useQueryClient();
  const invalidateUserFilters = () => queryClient.invalidateQueries([USER_FILTER_QUERY_KEY]);

  const postMutation = useMutation(postUserSearchFilter, {
    onSuccess: async () => {
      UserNotification.success('Created search filter successfully.', 'Success');
      await invalidateUserFilters();
    },
    onError: (errorThrown) => {
      UserNotification.error(`Creating search filter failed with status: ${errorThrown}`,
        'Could not create search filter');
    },
  });
  const putMutation = useMutation(putUserSearchFilter, {
    onSuccess: async () => {
      UserNotification.success('Updated search filter successfully.', 'Success');
      await invalidateUserFilters();
    },
    onError: (errorThrown) => {
      UserNotification.error(`Updating search filter failed with status: ${errorThrown}`,
        'Could not update search filter');
    },
  });
  const deleteMutation = useMutation(deleteUserSearchFilter, {
    onSuccess: async () => {
      UserNotification.success('Deleted search filter successfully.', 'Success');
      await invalidateUserFilters();
    },
    onError: (errorThrown) => {
      UserNotification.error(`Deleting search filter failed with status: ${errorThrown}`,
        'Could not delete search filter');
    },
  });

  return { post: postMutation.mutateAsync, put: putMutation.mutateAsync, delete: deleteMutation.mutateAsync };
};

export default useUserSearchFilterMutation;
