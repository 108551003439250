import * as React from 'react';
import PropTypes from 'prop-types';

import { LinkContainer } from 'components/common/router';
import { Button, ButtonToolbar } from 'components/bootstrap';
import Routes from 'routing/Routes';

const ReportSubNav = ({ reportId }: { reportId: string }) => (
  <ButtonToolbar>
    <LinkContainer to={Routes.pluginRoute('REPORTS_REPORTID_CONTENTS')(reportId)}>
      <Button bsStyle="success">Modify content</Button>
    </LinkContainer>
    <LinkContainer to={Routes.pluginRoute('REPORTS_REPORTID_CONFIGURATION')(reportId)}>
      <Button bsStyle="success">Edit configuration</Button>
    </LinkContainer>
    <LinkContainer to={Routes.pluginRoute('REPORTS_REPORTID_HISTORY')(reportId)}>
      <Button bsStyle="success">History</Button>
    </LinkContainer>
  </ButtonToolbar>
);

ReportSubNav.propTypes = {
  reportId: PropTypes.string.isRequired,
};

export default ReportSubNav;
