import * as React from 'react';
import { useEffect } from 'react';

import { Table } from 'components/bootstrap';
import Spinner from 'components/common/Spinner';
import { useStore } from 'stores/connect';

import { TrafficLimitViolationConfigActions, TrafficLimitViolationConfigStore } from './TrafficLimitViolationConfigStore';
import type { TrafficLimitViolationConfigType } from './TrafficLimitViolationConfigStore';
import TrafficLimitViolationConfigForm from './TrafficLimitViolationConfigForm';

const handleLoadTrafficLimitViolationConfig = () => TrafficLimitViolationConfigActions.getConfig();
const handleUpdateTrafficLimitViolationConfig = (config: TrafficLimitViolationConfigType) => TrafficLimitViolationConfigActions.updateConfig(config);

const EmailRecipientsOverview = ({ recipients }: { recipients: Array<string> }) => (
  <Table striped bordered condensed className="top-margin">
    <thead>
      <tr>
        <th>#</th>
        <th>Email</th>
      </tr>
    </thead>
    <tbody>
      {recipients.map((recipient, idx) => (
        <tr key={recipient}>
          <td>{idx + 1}</td>
          <td>{recipient}</td>
        </tr>
      ))}
    </tbody>
  </Table>
);

const TrafficLimitViolationConfig = () => {
  const { emailRecipients } = useStore(TrafficLimitViolationConfigStore);

  const _saveConfig = (values) => {
    handleUpdateTrafficLimitViolationConfig(values);
  };

  useEffect(() => {
    handleLoadTrafficLimitViolationConfig();
  }, []);

  return (
    <div>
      <h2>Traffic Limit Violation</h2>
      <p>Email recipients that should receive notifications when a traffic limit violation is detected.</p>
      {!emailRecipients ? <Spinner /> : (
        <>
          <EmailRecipientsOverview recipients={emailRecipients} />
          <TrafficLimitViolationConfigForm saveConfig={_saveConfig} recipients={emailRecipients} />
        </>
      )}
    </div>
  );
};

export default TrafficLimitViolationConfig;
