import type { AppDispatch } from 'stores/useAppDispatch';
import { addWidget } from 'views/logic/slices/widgetActions';
import LogViewWidget from 'logview/logic/LogViewWidget';

import LogViewWidgetConfig from './logic/LogViewWidgetConfig';

export const CreateLogView = () => LogViewWidget.builder()
  .newId()
  .config(LogViewWidgetConfig.createDefault())
  .build();

export default () => async (dispatch: AppDispatch) => dispatch(addWidget(CreateLogView()));
