import React, { useState } from 'react';
import toUpper from 'lodash/toUpper';
import snakeCase from 'lodash/snakeCase';

import { Wizard } from 'components/common';
import Routes from 'routing/Routes';
import useHistory from 'routing/useHistory';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import type { EventType } from 'logic/telemetry/Constants';
import { TELEMETRY_EVENT_TYPE } from 'logic/telemetry/Constants';

import WelcomeStep from './WelcomeStep';
import SelectForwarderStep from './SelectForwarderStep';
import ConfigureForwarderStep from './ConfigureForwarderStep';
import AddInputsStep from './AddInputsStep';
import SummaryStep from './SummaryStep';
import ForwarderWizardProvider from './ForwarderWizardProvider';

const STEP_KEYS = ['welcome', 'select-forwarder', 'configure-forwarder', 'inputs', 'summary'];

type ForwarderWizardProps = {
  forwarderId?: string;
};

const ForwarderWizard = ({ forwarderId }: ForwarderWizardProps) => {
  const [activeStep, setActiveStep] = useState(forwarderId ? STEP_KEYS[2] : STEP_KEYS[0]);
  const history = useHistory();
  const sendTelemetry = useSendTelemetry();

  const triggerTelemetry = (event: EventType) => {
    sendTelemetry(event, {
      app_pathname: 'forwarder',
      app_section: 'forwarder-wizard',
    });
  };

  const onWizardStepChange = (step: string) => {
    setActiveStep(step);
    triggerTelemetry(TELEMETRY_EVENT_TYPE.FORWARDER[`FORWARDER_WIZARD_${toUpper(snakeCase(step))}_STEP_OPENED`]);
  };

  const introSteps = [
    {
      key: STEP_KEYS[0],
      title: (<>Welcome</>),
      component: <WelcomeStep onStepComplete={() => onWizardStepChange(STEP_KEYS[1])} />,
    },
    {
      key: STEP_KEYS[1],
      title: (<>Select Forwarder</>),
      component: (
        <SelectForwarderStep onStepComplete={() => onWizardStepChange(STEP_KEYS[2])} />
      ),
    },
  ];

  const configureSteps = [
    {
      key: STEP_KEYS[2],
      title: (<>Configure Forwarder</>),
      component: (
        <ConfigureForwarderStep forwarderId={forwarderId}
                                onStepComplete={() => onWizardStepChange(STEP_KEYS[3])} />
      ),
    },
    {
      key: STEP_KEYS[3],
      title: (<>Add Inputs</>),
      component: (
        <AddInputsStep onStepComplete={() => onWizardStepChange(STEP_KEYS[4])} />
      ),
    },
    {
      key: STEP_KEYS[4],
      title: (<>Summary</>),
      component: (
        <SummaryStep hideConfigureAnother={!!forwarderId}
                     onWizardExit={() => history.push(Routes.pluginRoute('SYSTEM_FORWARDERS'))}
                     onWizardRestart={() => onWizardStepChange(STEP_KEYS[1])} />
      ),
    },
  ];

  const steps = forwarderId ? configureSteps : [...introSteps, ...configureSteps];

  return (
    <ForwarderWizardProvider>
      <Wizard steps={steps}
              activeStep={activeStep}
              onStepChange={onWizardStepChange}
              horizontal
              justified
              containerClassName=""
              hidePreviousNextButtons />
    </ForwarderWizardProvider>
  );
};

ForwarderWizard.defaultProps = {
  forwarderId: undefined,
};

export default ForwarderWizard;
