import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import type { ColorVariant } from '@graylog/sawmill';

import { Alert, Button, Panel } from 'components/bootstrap';

interface Props {
  bsStyle: ColorVariant,
  shortMessage: string,
  longMessage?: string,
  hiddenTitle?: boolean,
  dismissible?: boolean,
  title: string,
}

const FlexyWrap = styled.div`
  display: flex;
  align-items: center;
`;

const ShortContent = styled.p`
  flex: 1;
  margin: 0;
  font-weight: bold;
`;

const LongContent = styled.div<{ $visible?: boolean }>(({ $visible }) => css`
  white-space: pre-wrap;
  display: ${$visible ? 'block' : 'none'};
  padding-top: 12px;
`);

const NotificationExample = ({ bsStyle, shortMessage, longMessage, hiddenTitle, title, dismissible }: Props) => {
  const [showReadMore, setShowReadMore] = useState<boolean>(false);

  const toggleReadMore = () => {
    setShowReadMore(!showReadMore);
  };

  if (!title || !shortMessage) {
    return (
      <Panel bsStyle="warning">
        <Panel.Body>
          Fill in the <strong>Title</strong> and <strong>Short Message</strong> to begin seeing an example of your Notification.
        </Panel.Body>
      </Panel>
    );
  }

  return (
    <Alert bsStyle={bsStyle} onDismiss={dismissible ? () => {} : undefined} title={!hiddenTitle && title}>
      <FlexyWrap>
        <ShortContent>{shortMessage}</ShortContent>
        {longMessage && <Button bsStyle="link" onClick={toggleReadMore}>Read {showReadMore ? 'Less' : 'More'}</Button>}
      </FlexyWrap>

      {longMessage && <LongContent $visible={showReadMore}>{longMessage}</LongContent>}
    </Alert>
  );
};

NotificationExample.propTypes = {
  bsStyle: PropTypes.string.isRequired,
  shortMessage: PropTypes.string.isRequired,
  longMessage: PropTypes.string,
};

NotificationExample.defaultProps = {
  longMessage: undefined,
  hiddenTitle: false,
  dismissible: false,
};

export default NotificationExample;
