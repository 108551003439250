import * as React from 'react';
import styled from 'styled-components';
import { useContext, useCallback, useState } from 'react';
import type * as Immutable from 'immutable';

import { Alert, Button, Row } from 'components/bootstrap';
import QueryEditModeContext from 'views/components/contexts/QueryEditModeContext';
import type { QueryEditMode } from 'views/components/contexts/QueryEditModeContext';
import createParametersFromNames from 'enterprise/parameters/components/CreateParametersFromNames';
import useParametersMap from 'views/hooks/useParametersMap';
import useUndeclaredParameters from 'enterprise/parameters/components/useUndeclaredParameters';
import useAppDispatch from 'stores/useAppDispatch';
import type Parameter from 'views/logic/parameters/Parameter';
import { declareParameters } from 'views/logic/slices/searchExecutionSlice';

import ParameterDeclarationForm from './ParameterDeclarationForm';

const StyledAlert = styled(Alert)`
  margin-bottom: 6px;
`;

type WrapperProps = {
  children: React.ReactNode,
};

const wrapperForMode = (mode: QueryEditMode): React.ComponentType<WrapperProps> => {
  switch (mode) {
    case 'query': return ({ children }: WrapperProps) => (
      <Row>
        {children}
      </Row>
    );
    // eslint-disable-next-line react/jsx-no-useless-fragment
    case 'widget': return ({ children }: WrapperProps) => <>{children}</>;
    default: throw new Error(`Invalid query edit mode: ${mode}`);
  }
};

const ParameterBar = () => {
  const [showDeclarationForm, setShowDeclarationForm] = useState(false);
  const existingParameters = useParametersMap();
  const undeclaredParameterNames = useUndeclaredParameters();
  const queryEditContext = useContext(QueryEditModeContext);
  const dispatch = useAppDispatch();

  const toggleDeclarationForm = useCallback(() => {
    setShowDeclarationForm((cur) => !cur);
  }, []);
  const onSave = useCallback((newParameters: Immutable.Map<string, Parameter>) => (dispatch(declareParameters(newParameters)).then(
    () => { toggleDeclarationForm(); })
  ), [dispatch, toggleDeclarationForm]);

  if (undeclaredParameterNames.size === 0) {
    return null;
  }

  const Wrapper = wrapperForMode(queryEditContext);
  const undeclaredParameters = createParametersFromNames(undeclaredParameterNames);

  return (
    <Wrapper>
      <StyledAlert bsStyle="danger" title={`Undeclared query parameters: ${undeclaredParameterNames.join(', ')}`}>
        <p>All parameters used in the search query need to be declared before the query can be used:</p>
        <br />
        <Button bsStyle="primary" bsSize="small" onClick={toggleDeclarationForm}>Declare parameters</Button>
      </StyledAlert>
      {showDeclarationForm && (
        <ParameterDeclarationForm existingParameters={existingParameters}
                                  parameters={undeclaredParameters}
                                  onSave={onSave}
                                  onClose={toggleDeclarationForm} />
      )}

    </Wrapper>
  );
};

export default ParameterBar;
