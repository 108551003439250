import type { CrowdStrikeGenericInputCreateRequest, CrowdStrikeInputCreateRequest, FormDataType } from '../types';

export const toCrowdStrikeInputCreateRequest = ({
  crowdstrikeName,
  crowdstrikeStoreFullMessage,
  crowdstrikeThrottleEnabled,
  checkpointIntervalSeconds,
  crowdstrikeClientId,
  crowdstrikeClientSecret,
  crowdstrikeBaseUrl,
}: FormDataType): CrowdStrikeInputCreateRequest => ({
  name: crowdstrikeName.value,
  checkpoint_interval_seconds: checkpointIntervalSeconds.value,
  store_full_message: !!crowdstrikeStoreFullMessage?.value,
  enable_throttling: !!crowdstrikeThrottleEnabled?.value,
  crowdstrike_base_url: crowdstrikeBaseUrl.value,
  crowdstrike_client_id: crowdstrikeClientId.value,
  crowdstrike_client_secret: crowdstrikeClientSecret.value,
});

export const toGenericInputCreateRequest = ({
  crowdstrikeName,
  crowdstrikeStoreFullMessage,
  crowdstrikeThrottleEnabled,
  crowdstrikeClientId,
  crowdstrikeClientSecret,
  crowdstrikeBaseUrl,
  checkpointIntervalSeconds,
}: FormDataType): CrowdStrikeGenericInputCreateRequest => ({
  type: 'org.graylog.enterprise.integrations.crowdstrike.CrowdStrikeInput',
  title: crowdstrikeName.value,
  global: false,
  configuration: {
    checkpoint_interval_seconds: checkpointIntervalSeconds.value,
    store_full_message: !!crowdstrikeStoreFullMessage?.value,
    throttling_allowed: !!crowdstrikeThrottleEnabled?.value,
    crowdstrike_base_url: crowdstrikeBaseUrl.value,
    crowdstrike_client_id: crowdstrikeClientId.value,
    crowdstrike_client_secret: crowdstrikeClientSecret.value,
  },
});
