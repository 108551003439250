import * as React from 'react';

import { PageHeader, DocumentTitle, Spinner } from 'components/common';
import DocsHelper from 'util/DocsHelper';
import FiltersOverview from 'search-filter/components/filter-management-pages/UserSearchFiltersOverview/FiltersOverview';
import SearchFiltersPageSubactions from 'search-filter/components/filter-management-pages/SearchFiltersPageSubactions';
import { InvalidLicenseWarning } from 'license/LicenseCheck';
import useSearchFilterLicenseStatus from 'search-filter/hooks/useSearchFilterLicenseStatus';

const MyFiltersOverviewPage = () => {
  const { isFetchingLicense, isValidLicense } = useSearchFilterLicenseStatus();

  if (isFetchingLicense) {
    return <Spinner />;
  }

  return (
    <DocumentTitle title="My Filters">
      <PageHeader title="My Filters"
                  actions={<SearchFiltersPageSubactions licenseIsValid={isValidLicense} />}
                  documentationLink={{
                    title: 'Search filter documentation',
                    path: DocsHelper.PAGES.WELCOME,
                  }}>
        <span>
          Overview of your &#0034;My Filters&#0034; collection. It contains all filters created by you and all filters you have the &#0034;own&#0034; permission for.
          All filters can be included in any search and dashboard
          widget by using the controls in the related search bar. Please note, changing a filter will affect all saved
          searches and dashboard widgets which include this filter.
        </span>
      </PageHeader>
      {isValidLicense ? <FiltersOverview /> : (
        <InvalidLicenseWarning featureName="search filter"
                               licenseSubject="/license/enterprise/search-filter"
                               displayWarningContainer />
      )}
    </DocumentTitle>
  );
};

export default MyFiltersOverviewPage;
