import React from 'react';
import PropTypes from 'prop-types';

import { OverlayTrigger, Icon } from 'components/common';
import { Tooltip } from 'components/bootstrap';

interface Props {
  message: string,
}

const NotificationHelp = ({ message }: Props) => (
  <OverlayTrigger placement="top"
                  overlay={<Tooltip id="tooltip-help-message">{message}</Tooltip>}
                  trigger={['hover', 'click']}
                  rootClose>
    <span>
      <Icon name="question-circle" />
    </span>
  </OverlayTrigger>
);

NotificationHelp.propTypes = {
  message: PropTypes.string.isRequired,
};

export default NotificationHelp;
