import * as React from 'react';

import type { ColumnRenderers } from 'components/common/EntityDataTable';
import type { InstantArchivingConfig } from 'instant-archiving/Types';

import StreamsCell from './cells/StreamsCell';
import EnabledCell from './cells/EnabledCell';

const customColumnRenderers = (): ColumnRenderers<InstantArchivingConfig> => ({
  attributes: {
    enabled: {
      renderCell: (enabled: boolean, _archiveConfig: InstantArchivingConfig) => (
        <EnabledCell enabled={enabled} />
      ),
      staticWidth: 100,
    },
    streams: {
      renderCell: (_streams: string, archiveConfig: InstantArchivingConfig) => (
        <StreamsCell streamIds={archiveConfig.stream_ids} />
      ),
      width: 2,
    },
  },
});

export default customColumnRenderers;
