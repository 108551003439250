import * as React from 'react';
import { useState, useCallback } from 'react';

import { Col, Row } from 'components/bootstrap';
import PaginatedList from 'components/common/PaginatedList';
import EntityList from 'components/common/EntityList';
import SearchForm from 'components/common/SearchForm';
import Spinner from 'components/common/Spinner';
import useUserSearchFiltersQuery from 'search-filter/hooks/useUserSearchFiltersQuery';
import QueryHelper from 'components/common/QueryHelper';
import { FILTER_SCOPES } from 'search-filter/constants';
import usePaginationQueryParameter from 'hooks/usePaginationQueryParameter';

import FiltersOverviewItem from './FiltersOverviewItem';

const FiltersOverview = () => {
  const { page, pageSize, resetPage } = usePaginationQueryParameter();
  const [filterQuery, setSearchFilter] = useState('');
  const { data: { filters = [], pagination }, isFetching } = useUserSearchFiltersQuery({ query: filterQuery, page, per_page: pageSize, scope: FILTER_SCOPES.MY_FILTERS });
  const handleSearch = useCallback((newSearchQuery: string | undefined) => {
    setSearchFilter(newSearchQuery);
    resetPage();
  }, [resetPage, setSearchFilter]);
  const handleReset = useCallback(() => {
    handleSearch(undefined);
  }, [handleSearch]);

  return (
    <Row className="content">
      <Col xs={12}>
        <PaginatedList totalItems={pagination?.total ?? 0}>
          <SearchForm onSearch={handleSearch}
                      onReset={handleReset}
                      topMargin={0}
                      queryHelpComponent={<QueryHelper entityName="filter" commonFields={['id', 'title', 'description']} fieldMap={{ queryString: 'Query string of the filter' }} />} />
          <EntityList items={filters.map((filter) => <FiltersOverviewItem key={filter.id} filter={filter} />)}
                      noItemsText={isFetching ? <Spinner delay={0} /> : 'No filters have been found.'} />
        </PaginatedList>
      </Col>
    </Row>
  );
};

export default FiltersOverview;
