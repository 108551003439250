import { useQuery } from '@tanstack/react-query';

import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import { INSTANT_ARCHIVING_API_ROUTES, INSTANT_ARCHIVING_CONFIG_QUERY_KEYS } from 'instant-archiving/Constants';
import type { InstantArchivingConfig } from 'instant-archiving/Types';

const fetchInstantArchivingConfig = (id: string) => fetch('GET', qualifyUrl(`${INSTANT_ARCHIVING_API_ROUTES.InstantArchivingAPIController.config.base().url}/${id}`));

const useInstantArchivingConfig = (id: string, enabled = true): {
  data: InstantArchivingConfig;
  refetch: () => void;
  isInitialLoading: boolean,
  isSuccess: boolean,
  error: Error,
} => {
  const { data, refetch, isInitialLoading, isSuccess, error } = useQuery<InstantArchivingConfig, Error>(
    [...INSTANT_ARCHIVING_CONFIG_QUERY_KEYS, id],
    () => fetchInstantArchivingConfig(id),
    {

      keepPreviousData: true,
      enabled,
    },
  );

  return ({
    data: data,
    refetch,
    isInitialLoading,
    isSuccess,
    error,
  });
};

export default useInstantArchivingConfig;
