import React, { useEffect } from 'react';

import { DocumentTitle, PageHeader } from 'components/common';
import { Row, Col } from 'components/bootstrap';
import InstantArchivingConfigOverview from 'instant-archiving/InstantArchivingConfig/InstantArchivingConfigOverview';
import InstantArchivingPageNavigation from 'instant-archiving/components/InstantArchivingPageNavigation';
import InstantArchivingConfigButton from 'instant-archiving/InstantArchivingConfig/InstantArchivingConfigButton';
import type { LicenseStatus } from 'archive/types';
import { useStore } from 'stores/connect';
import ArchiveLicenseStore from 'archive/ArchiveLicenseStore';
import ArchiveLicenseActions from 'archive/ArchiveLicenseActions';
import InstantArchivingLicenseStatusContainer from 'instant-archiving/InstantArchivinLicenseStatusContainer';

const InstantArchivingsPage = () => {
  const licenseStatus: LicenseStatus = useStore(ArchiveLicenseStore);

  useEffect(() => {
    ArchiveLicenseActions.getLicenseStatus();
  }, []);

  return (

    <DocumentTitle title="Instant Archiving Configurations">
      <InstantArchivingPageNavigation />
      <PageHeader title="Instant Archiving Configurations"
                  actions={<InstantArchivingConfigButton licenseStatus={licenseStatus} />}>
        <span>
          Manage your configurations
        </span>
      </PageHeader>
      <InstantArchivingLicenseStatusContainer />
      <Row className="content">
        <Col md={12}>
          <InstantArchivingConfigOverview />
        </Col>
      </Row>
    </DocumentTitle>
  );
};

export default InstantArchivingsPage;
