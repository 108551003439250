import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'components/common/router';
import { Alert, Col, Row } from 'components/bootstrap';
import { IfPermitted } from 'components/common';
import Routes from 'routing/Routes';
import withHideOnCloud from 'util/conditional/withHideOnCloud';

class AuditLogLicenseStatus extends React.Component {
  static propTypes = {
    licenseStatus: PropTypes.shape({
      status: PropTypes.object,
      missing: PropTypes.bool.isRequired,
      loading: PropTypes.bool.isRequired,
    }).isRequired,
    colWidth: PropTypes.number,
    rowClass: PropTypes.string,
  };

  static defaultProps = {
    colWidth: 12,
    rowClass: 'content',
  };

  renderStatus = () => (
    <Alert bsStyle="danger" title="Auditlog is disabled">
      <p>
        Graylog will not write any new auditlog entries because there is no valid license.
      </p>
      <IfPermitted permissions="licenses:create">
        <p>
          See <Link to={Routes.pluginRoute('SYSTEM_LICENSES')}>Licenses page</Link> for details.
        </p>
      </IfPermitted>
    </Alert>
  );

  render() {
    const { status, missing } = this.props.licenseStatus;

    if (!missing && (status && status.valid)) {
      return null;
    }

    const { rowClass, colWidth } = this.props;

    return (
      <Row className={rowClass}>
        <Col md={colWidth}>
          {this.renderStatus()}
        </Col>
      </Row>
    );
  }
}

export default withHideOnCloud(AuditLogLicenseStatus);
