import * as React from 'react';
import * as Immutable from 'immutable';
import isNil from 'lodash/isNil';

import type { ViewHook } from 'views/logic/hooks/ViewHook';
import RequiredParametersForViewForm from 'enterprise/parameters/components/RequiredParametersForViewForm';
import type ValueParameter from 'views/logic/parameters/ValueParameter';
import ParameterBinding from 'views/logic/parameters/ParameterBinding';
import type SearchExecutionState from 'views/logic/search/SearchExecutionState';
import type Parameter from 'views/logic/parameters/Parameter';

const parameterHasValue = (parameter: ValueParameter, executionState: SearchExecutionState) => (
  executionState.parameterBindings.has(parameter.name)
  && !isNil(executionState.parameterBindings.get(parameter.name).value)
  && executionState.parameterBindings.get(parameter.name).value !== ''
);

const showParameterFormIfMissingValues: ViewHook = async ({ view: { search }, view, retry, executionState }) => {
  const { parameters } = search;
  const { requiredParameters } = search;
  const requiresParameters = !requiredParameters
    .filter((p) => !parameterHasValue(p as ValueParameter, executionState))
    .isEmpty();

  if (requiresParameters) {
    const boundParameters = Immutable.Map<string, Parameter>(parameters
      .filter((p) => (!p.optional && p.needsBinding))
      .map((p: ValueParameter) => {
        const { value } = executionState.parameterBindings.get(p.name, ParameterBinding.empty());

        return value ? p.toBuilder().binding(ParameterBinding.forValue(value)).build() : p;
      })
      .map((p) => [p.name, p]));

    // eslint-disable-next-line no-throw-literal
    throw <RequiredParametersForViewForm parameters={boundParameters} onSubmit={retry} view={view} executionState={executionState} />;
  }

  return [view, executionState];
};

export default showParameterFormIfMissingValues;
