import AppConfig from 'util/AppConfig';

import UploadCSVFileAdapterFieldSet from './adapters/uploadcsvfile/UploadCSVFileAdapterFieldSet';
import UploadCSVFileAdapterSummary from './adapters/uploadcsvfile/UploadCSVFileAdapterSummary';
import UploadCSVFileAdapterDocumentation from './adapters/uploadcsvfile/UploadCSVFileAdapterDocumentation';
import CloudEmailFormGroup from './user/CloudEmailFormGroup';
import CloudPasswordFormGroup, { validateCloudPasswords } from './user/CloudPasswordFormGroup';
import { parseErrorResponse, customizeUserPayload } from './user/Utils';
import CloudFooter from './footer/CloudFooter';

const cloudExport = AppConfig.isCloud() ? {
  cloud: {
    oktaUserForm: {
      fields: {
        username: null,
        email: CloudEmailFormGroup,
        password: CloudPasswordFormGroup,
      },
      validations: {
        password: validateCloudPasswords,
      },
      extractSubmitError: parseErrorResponse,
      onCreate: customizeUserPayload,
    },
  },
  lookupTableAdapters: [
    {
      type: 'uploadcsvfile',
      displayName: 'CSV File (Upload)',
      formComponent: UploadCSVFileAdapterFieldSet,
      summaryComponent: UploadCSVFileAdapterSummary,
      documentationComponent: UploadCSVFileAdapterDocumentation,
    },
  ],
  pageFooter: [
    {
      component: CloudFooter,
    },
  ],
} : {};

export default cloudExport;
