import * as React from 'react';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Paragraph = styled.p`
  color: #a6a6a6;
  font-size: 0.8em;
  margin: 10px auto 0 auto;
  text-align: center;
  width: 75%;
  
  @media print {
    page-break-inside: avoid;

    /* This is the desired behaviour, but currently unimplemented in Chrome. It may be implemented in a future version, so we leave it here. */
    page-break-before: avoid;
    break-before: avoid;
  }
`;

type Props = {
  text: React.ReactNode
}

const Caption = forwardRef<HTMLParagraphElement, Props>(({ text }, ref) => (
  <Paragraph ref={ref}>{text}</Paragraph>
));

Caption.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

export default Caption;
