import * as React from 'react';
import styled, { css } from 'styled-components';

const Steps = styled.div<{ $gap: string }>`
  display: flex;
  flex-direction: row;
  align-items: flex-bottom;
  gap: ${({ $gap }) => $gap};
`;

const Step = styled.div<{ $active?: boolean, $error: boolean, $disabled: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 8px;
  font-size: 12px;
  cursor: pointer;
  color: ${({ theme, $active, $error, $disabled }) => {
    if ($disabled) return theme.colors.input.placeholder;
    if ($error) return theme.colors.variant.danger;
    if ($active) return theme.colors.global.link;

    return theme.colors.gray[40];
  }};

  border-bottom: 1px solid ${({ theme, $active, $error, $disabled }) => {
    if ($disabled) return theme.colors.input.placeholder;
    if ($error) return theme.colors.variant.danger;
    if ($active) return theme.colors.global.link;

    return theme.colors.gray[40];
  }};

  &:hover {
    ${({ theme, $disabled }) => ($disabled
    ? css`
      cursor: not-allowed;
    ` : css`
      color: ${theme.colors.global.linkHover};
      border-color: ${theme.colors.global.linkHover};
    `)}
  }
`;

export type StepType = {
  id: string;
  title: string;
  valid: boolean;
  disabled?: boolean;
};

type Props = {
  steps: StepType[];
  activeStep: number;
  gap?: string;
  setActiveStep: (stepId: number) => void;
};

function Stepper({ steps, activeStep, gap, setActiveStep }: Props) {
  return (
    <Steps $gap={gap}>
      {steps.map((step, index) => (
        <Step key={step.id}
              $active={index === activeStep}
              $error={!step.valid}
              $disabled={step.disabled}
              onClick={() => step.disabled || setActiveStep(index)}>
          {step.title}
        </Step>
      ))}
    </Steps>
  );
}

Stepper.defaultProps = {
  gap: '8px',
};

export default Stepper;
