import type { UserSearchFilter } from 'search-filter/types';
import type { SearchFilter } from 'views/types';

// Search filters have this type when they are being used independently of e.g. a search.
// This is for example the case in the search filter collection of a user.
export const USER_SEARCH_FILTER_TYPE: UserSearchFilter['type'] = 'queryFilter';

// This type is being used for search filters which are part of a search.
// Filter with type "referenced", reference a user search filter by its id.
export const REFERENCED_USER_SEARCH_FILTER_TYPE: SearchFilter['type'] = 'referenced';
export const INLINE_SEARCH_FILTER_TYPE: SearchFilter['type'] = 'inlineQueryString';

export const FILTER_SCOPES = { MY_FILTERS: 'OWNED', ALL: 'ALL' };
export const FILTER_USAGE_ENTITY_TYPE = { SAVED_SEARCHES: 'SEARCHES', DASHBOARD_WIDGET: 'DASHBOARD_WIDGET' };
