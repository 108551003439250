import __request__ from 'routing/request';
/**
 * Get status of this Graylog server node for load balancers. Returns ALIVE with HTTP 200, DEAD with HTTP 503, or THROTTLED with HTTP 429.
 */
export function status(): Promise<unknown> {
    return __request__('GET', '/system/lbstatus', null, {}, {
        'Accept': ['text/plain']
    });
}
/**
 * Override load balancer status of this Graylog server node. Next lifecycle change will override it again to its default. Set to ALIVE, DEAD, or THROTTLED.
 */
export function override(status?: string): Promise<void> {
    return __request__('PUT', `/system/lbstatus/override/${status}`, null, {}, {
        'Accept': ['application/json']
    });
}
