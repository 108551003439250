import * as React from 'react';
import type { PluginExports } from 'graylog-web-plugin/plugin';

import LogViewComponent from 'logview/components/LogViewWidget';
import CreateLogViewFromMessageTable from 'logview/CreateLogViewFromMessageTable';
import Widget from 'views/logic/widgets/Widget';
import validLicensePresent from 'license/ValidLicensePresent';
import Icon from 'components/common/Icon';

import LogViewEditComponent from './components/LogViewEditWidget';
import AddLogViewActionHandler, { CreateLogView } from './AddLogViewActionHandler';
import LogViewWidget from './logic/LogViewWidget';
import LogViewHandler from './LogViewHandler';
import LogViewConfigGenerator from './LogViewConfigGenerator';
import LogViewExportSettings from './components/LogViewExportSettings';

Widget.registerSubtype(LogViewWidget.type, LogViewWidget);

const logviewBindings: PluginExports = {
  enterpriseWidgets: [
    {
      type: 'LOGS',
      displayName: 'Log View',
      defaultHeight: 4,
      defaultWidth: 12,
      hasEditSubmitButton: true,
      visualizationComponent: LogViewComponent,
      editComponent: LogViewEditComponent,
      needsControlledHeight: () => false,
      searchResultTransformer: (data: Array<unknown>) => data[0],
      searchTypes: LogViewConfigGenerator,
      titleGenerator: () => 'Log View',
      exportComponent: LogViewExportSettings,
    },
  ],
  widgetCreators: [{
    title: 'Log View',
    func: CreateLogView,
    icon: () => <Icon name="table-list" />,
  }],
  creators: [
    {
      type: 'preset',
      title: 'Log View',
      func: AddLogViewActionHandler,
      condition: validLicensePresent,
    },
  ],
  searchTypes: [
    {
      type: LogViewWidget.type,
      handler: LogViewHandler,
      defaults: {},
    },
  ],
  'views.widgets.actions': [CreateLogViewFromMessageTable],
};

export default logviewBindings;
