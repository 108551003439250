const handleFileRead = (fileReader: FileReader, file, callback: (fileName: string, fileContent: string) => void) => {
  const dataUrl = fileReader.result;

  if (dataUrl && typeof dataUrl === 'string') {
    const dataString = dataUrl.replace(/^data:[\s\S]+\/[\s\S]+;base64,/, '');
    callback(file.name, dataString);
  }
};

const handleFileUpload = (file: File, callback: (fileName: string, fileContent: string) => void) => {
  const fileReader = new FileReader();

  fileReader.onloadend = (_) => handleFileRead(fileReader, file, callback);

  if (file) {
    fileReader.readAsDataURL(file);
  }
};

export default handleFileUpload;
