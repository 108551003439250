import React from 'react';

import { DocumentTitle, PageHeader } from 'components/common';

const OidcConfiguration = () => (
  <DocumentTitle title="OpenID Connect Authenticator">
    <span>
      <PageHeader title="OpenID Connect Authenticator" subpage>
        <span>
          This authenticator uses <a href="https://www.okta.com">Okta</a> as OpenID Connect identity provider
          using the <em>authorization code</em> granty type. Users are synchronized from the identity provider
          to graylog upon login of the user and a graylog session will be created for the user.
        </span>
      </PageHeader>
      <span>
        This authenticator is required to provide users the ability to log in to graylog with a username and a
        password. It cannot be disabled.
      </span>
    </span>
  </DocumentTitle>
);

export default OidcConfiguration;
