import __request__ from 'routing/request';
interface Throughput {
    readonly throughput: number;
}
/**
 * Current throughput of this node in messages per second
 */
export function total(): Promise<Throughput> {
    return __request__('GET', '/system/throughput', null, {}, {
        'Accept': ['application/json']
    });
}
