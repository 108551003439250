import * as React from 'react';
import styled from 'styled-components';
import { PluginStore } from 'graylog-web-plugin/plugin';
import type Immutable from 'immutable';

import InteractiveContext from 'views/components/contexts/InteractiveContext';
import WidgetVisualizationNotFound from 'components/widgets/WidgetVisualizationNotFound';
import { Spinner } from 'components/common';
import type Widget from 'views/logic/widgets/Widget';
import type FieldTypeMapping from 'views/logic/fieldtypes/FieldTypeMapping';

const Center = styled.div`
  font-size: 70px;
  text-align: center;
  vertical-align: middle;
`;

type Props = {
  widget: { type: Widget['type'], config: Widget['config'] },
  widgetId: string,
  height: number,
  width: number,
  interactive: boolean,
  limitHeight: boolean,
  result: any,
  hasError: boolean,
  errorMessage: string | undefined,
  types: Immutable.List<FieldTypeMapping>,
  headingHeight: number,
  captionHeight: number,
}

const ReportingWidgetVisualization = ({
  widget, widgetId, result, hasError, errorMessage, types, headingHeight, captionHeight,
  height, width, interactive, limitHeight,
}: Props) => {
  if (widget.type === '') {
    return null;
  }

  if (result === undefined) {
    return (
      <Center><Spinner text="" /></Center>
    );
  }

  if (result === 'N/A') {
    return (
      <>
        <Center>{result}</Center>
        {hasError && errorMessage}
      </>
    );
  }

  const widgetPlugin = PluginStore.exports('enterpriseWidgets').find((w) => w.type.toUpperCase() === widget.type.toUpperCase());

  if (!widgetPlugin) {
    return <WidgetVisualizationNotFound widgetClassName={widget.type} />;
  }

  const marginHeight = 10;
  const plotLegend = 55;
  const Visualization = widgetPlugin.visualizationComponent;
  const maxHeight = limitHeight || widgetPlugin.needsControlledHeight(widget) ? height - headingHeight - captionHeight - marginHeight - plotLegend : '100%';
  const maxWidth = width;

  return (
    <InteractiveContext.Provider value={interactive}>
      <div style={{ maxHeight, maxWidth, height: maxHeight, width: maxWidth, margin: 'auto' }}>
        <Visualization id={widgetId}
                       config={widget.config}
                       editing={false}
                       data={result}
                       fields={types}
          // @ts-expect-error
                       height={height - headingHeight - captionHeight - marginHeight}
                       width={width}
                       locked />
      </div>
    </InteractiveContext.Provider>
  );
};

export default ReportingWidgetVisualization;
