import * as React from 'react';
import { useParams } from 'react-router-dom';

import { LinkContainer } from 'components/common/router';
import { Button, ButtonToolbar } from 'components/bootstrap';
import Routes from 'routing/Routes';

const SearchFilterPageSubactions = () => {
  const { filterId } = useParams();

  return (
    <ButtonToolbar>
      <LinkContainer to={Routes.pluginRoute('MY-FILTERS_DETAILS_FILTERID')(filterId)}>
        <Button bsStyle="success">View Filter And Usages</Button>
      </LinkContainer>
      <LinkContainer to={Routes.pluginRoute('MY-FILTERS_EDIT_FILTERID')(filterId)}>
        <Button bsStyle="success">Edit Filter</Button>
      </LinkContainer>
    </ButtonToolbar>
  );
};

export default SearchFilterPageSubactions;
