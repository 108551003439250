import * as React from 'react';

import type { InstantArchive } from 'instant-archiving/Types';

type Props = {
  archive: InstantArchive
}

const RestoredArchivesCell = ({ archive }: Props) => (
  <span>{archive.restore_history == null ? 0 : archive.restore_history.length}</span>
);

export default RestoredArchivesCell;
