import type { PluginExports } from 'graylog-web-plugin/plugin';

import { SecurityLayout } from 'security-app/components/Layout';
import {
  Anomaly,
  DetectorConfiguration,
  HostActivity,
  NetworkActivity,
  UserActivity,
  Overview,
  Sigma,
  SigmaConfig,
  AnomalyDetectionEdit,
  Investigations,
  InvestigationsConfig,
  MachineAssets,
  UserAssets,
  AssetsConfig,
  AssetSources,
  AssetSourcesForms,
  AssetSourcesView,
} from 'security-app/pages';
import {
  CreateInvestigationNotificationDetails,
  CreateInvestigationNotificationForm,
  CreateInvestigationNotificationSummary,
} from 'security-app/components/Investigations/notifications';
import { PaginationProvider } from 'security-app/components/common/contexts';
import LicenseCheckProvider from 'license/LicenseCheckProvider';

import investigationsPlugin from './components/Investigations/plugin';
import assetsPlugin from './components/Assets/plugin';
import { InvestigationDrawerProvider } from './components/Investigations/contexts';

const SECURITY_VIEW_ID_PREFIX = 'graylog-security-views__';

const allRoutes = [
  { path: '/security', entityId: `${SECURITY_VIEW_ID_PREFIX}overview`, component: Overview, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/user-activity', entityId: `${SECURITY_VIEW_ID_PREFIX}user-activity`, component: UserActivity, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/host-activity', entityId: `${SECURITY_VIEW_ID_PREFIX}host-activity`, component: HostActivity, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/network-activity', entityId: `${SECURITY_VIEW_ID_PREFIX}network-activity`, component: NetworkActivity, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/anomalies', entityId: `${SECURITY_VIEW_ID_PREFIX}anomalies`, component: Anomaly, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/anomalies/detectors', component: DetectorConfiguration, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/anomalies/detector/create', component: AnomalyDetectionEdit, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/anomalies/detector/edit/:id', component: AnomalyDetectionEdit, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/sigma', component: Sigma, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/sigma/configuration', component: SigmaConfig, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/investigations', component: Investigations, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/investigations/:id', component: Investigations, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/investigations/archived', component: Investigations, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/investigations/configuration', component: InvestigationsConfig, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/assets', component: MachineAssets, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/assets/users', component: UserAssets, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/assets/configuration', component: AssetsConfig, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/assets/sources', component: AssetSources, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/assets/sources/:sourceType/:sourceId', component: AssetSourcesView, permissions: '*', parentComponent: SecurityLayout },
  { path: '/security/assets/sources/:sourceType/forms/', component: AssetSourcesForms, permissions: 'asset:edit', parentComponent: SecurityLayout },
  { path: '/security/assets/sources/:sourceType/forms/:sourceId', component: AssetSourcesForms, permissions: 'asset:edit', parentComponent: SecurityLayout },
  { path: '/security/assets/sources/:sourceType/forms/:sourceId/mappings', component: AssetSourcesForms, permissions: 'asset:edit', parentComponent: SecurityLayout },
  { path: '/security/assets/sources/:sourceType/forms/:sourceId/mappings/:mapId', component: AssetSourcesForms, permissions: 'asset:edit', parentComponent: SecurityLayout },
];

const createInvestigationNotificationDefaultConfig = {
  create_new_investigation: false,
  investigation_assigned_to: null,
  investigation_id: null,
  investigation_priority: null,
  investigation_status: null,
  type: 'create-investigation-notification-v1',
};

const entityRoutesResolver = (id: string, type: string) => {
  if (id.startsWith(SECURITY_VIEW_ID_PREFIX) && type === 'dashboard') {
    return allRoutes.find(({ entityId }) => entityId === id)?.path;
  }

  return null;
};

const pluginExports: PluginExports = {
  routes: allRoutes,
  entityRoutes: [entityRoutesResolver, entityRoutesResolver],
  globalContextProviders: [
    InvestigationDrawerProvider,
    LicenseCheckProvider,
    PaginationProvider,
  ],
  eventNotificationTypes: [
    {
      type: 'create-investigation-notification-v1',
      displayName: 'Create Investigation Notification [Security]',
      formComponent: CreateInvestigationNotificationForm,
      summaryComponent: CreateInvestigationNotificationSummary,
      detailsComponent: CreateInvestigationNotificationDetails,
      defaultConfig: createInvestigationNotificationDefaultConfig,
    },
  ],
  ...investigationsPlugin,
  ...assetsPlugin,
};

export default pluginExports;
