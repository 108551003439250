import __request__ from 'routing/request';
interface KafkaJournalConfigurationSummary {
    readonly max_age: number;
    readonly segment_size: number;
    readonly segment_age: number;
    readonly flush_age: number;
    readonly flush_interval: number;
    readonly directory: string;
    readonly max_size: number;
}
interface JournalSummaryResponse {
    readonly number_of_segments: number;
    readonly journal_size_limit: unknown;
    readonly journal_size: unknown;
    readonly journal_config: KafkaJournalConfigurationSummary;
    readonly read_events_per_second: number;
    readonly oldest_segment: string;
    readonly append_events_per_second: number;
    readonly enabled: boolean;
    readonly uncommitted_journal_entries: number;
}
/**
 * Get current state of the journal on this node.
 */
export function show(): Promise<JournalSummaryResponse> {
    return __request__('GET', '/system/journal', null, {}, {
        'Accept': ['application/json']
    });
}
