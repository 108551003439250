import notifyingAction from 'domainActions/notifyingAction';

import { WatchlistActions } from '../stores/WatchlistStore';

const deleteAction = notifyingAction({
  action: WatchlistActions.delete,
  success: (_watchlistFieldType, value) => ({
    message: `Field value "${value}" was removed from watchlist successfully`,
  }),
  error: (error, _watchlistFieldType, value) => ({
    message: `Removing field value "${value}" from watchlist failed with status: ${error}`,
  }),
});

const update = notifyingAction({
  action: WatchlistActions.update,
  success: (_watchlistFieldType, value) => ({
    message: `Field value "${value}" was added to watchlist successfully`,
  }),
  error: (error, _watchlistFieldType, value) => ({
    message: `Adding field value "${value}" to watchlist failed with status: ${error}`,
  }),
});

export default {
  update,
  delete: deleteAction,
};
