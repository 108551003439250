import __request__ from 'routing/request';
/**
 * Pause message processing on node
 * @param nodeId The id of the node where processing will be paused.
 */
export function pause(nodeId: string): Promise<void> {
    return __request__('POST', `/cluster/${nodeId}/processing/pause`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Resume message processing on node
 * @param nodeId The id of the node where processing will be resumed.
 */
export function resume(nodeId: string): Promise<void> {
    return __request__('POST', `/cluster/${nodeId}/processing/resume`, null, {}, {
        'Accept': ['application/json']
    });
}
