import styled, { css } from 'styled-components';

const MenuItem = styled.div<{ $variant?: 'default' | 'danger' }>(({ theme, $variant }) => css`
  width: 100%;
  min-width: 95px;
  padding: 6px;
  text-align: left;
  cursor: pointer;
  color: ${$variant === 'danger' ? theme.colors.brand.primary : theme.colors.brand.tertiary};

  &:hover {
    background-color: ${theme.colors.global.contentBackground};
  }
`);

export default MenuItem;
