import type { F5NetworksGenericInputCreateRequest, F5NetworksLogsInputCreateRequest, FormDataType } from '../types';

export const toF5NetworksLogsInputCreateRequest = ({
  managementIP,
  f5Name,
  username,
  password,
  content_type,
  f5ThrottleEnabled,
  f5StoreFullMessage,
  pollingInterval,
  disableTLSCheck,
}: FormDataType): F5NetworksLogsInputCreateRequest => ({
  name: f5Name.value,
  polling_interval: pollingInterval.value,
  polling_time_unit: 'MINUTES',
  management_ip: managementIP.value,
  enable_throttling: !!f5ThrottleEnabled?.value,
  store_full_message: !!f5StoreFullMessage?.value,
  disable_tls_check: !!disableTLSCheck?.value,
  username: username.value,
  password: password.value,
  content_type: content_type.value.split(','),
});

export const toGenericInputCreateRequest = ({
  managementIP,
  username,
  password,
  f5Name,
  f5ThrottleEnabled,
  pollingInterval,
  content_type,
}: FormDataType): F5NetworksGenericInputCreateRequest => ({
  type: 'org.graylog.enterprise.integrations.f5networks.F5Input',
  title: f5Name.value,
  global: false,
  configuration: {
    polling_interval: pollingInterval.value,
    polling_time_unit: 'MINUTES',
    management_ip: managementIP.value,
    throttling_allowed: !!f5ThrottleEnabled?.value,
    username: username.value,
    password: password.value,
    content_type: content_type.value.split(','),
  },
});
