import * as React from 'react';

const help = {
  groupSearchBase: (
    <span>
      The base tree to limit the Active Directory group search query to, e.g. <code key="group-search-base-example">ou=people,dc=example,dc=com</code>.
    </span>
  ),
  groupSearchPattern: (
    <span>
      The search pattern used to find groups in Active Directory for mapping to Graylog teams, e.g. <code key="group-search-pattern">(objectClass=group)</code>.
    </span>
  ),
  teamNameAttribute: (
    <span>
      Which Active Directory attribute to use for the full name of the team in Graylog, e.g. <code key="team-name-attribute-example">cn</code>.
    </span>
  ),
  teamDefaultRoles: (
    'The default Graylog roles synchronized teams will obtain. All users of a team will inherit these roles.'
  ),
};

const excludedFields = {
  teamUniqueIdAttribute: true,
};

const initialValues = {
  teamNameAttribute: 'cn',
  groupSearchPattern: '(objectClass=group)',
};

export default { help, excludedFields, initialValues };
