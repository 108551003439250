import * as React from 'react';
import * as Immutable from 'immutable';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { TextOverflowEllipsis, DataTable, Spinner, PaginatedList, SearchForm, NoSearchResult } from 'components/common';
import { Link } from 'components/common/router';
import Routes from 'routing/Routes';
import { Col, Row } from 'components/bootstrap';
import RolesCell from 'components/permissions/RolesCell';
import AppConfig from 'util/AppConfig';
import usePaginationQueryParameter from 'hooks/usePaginationQueryParameter';

import ActionsCell from './ActionsCell';
import UsersCell from './UsersCell';
import TeamsQueryHelp from './TeamsQueryHelper';
import SourceTypeCell from './SourceTypeCell';

import LicenseCheck from '../../../license/LicenseCheck';
import type { PaginatedTeams } from '../../actions/TeamsActions';
import TeamsDomain from '../../domainActions/TeamsDomain';
import { TeamsActions } from '../../stores/TeamsStore';

const isCloud = AppConfig.isCloud();
const HEADERS_LIST = ['Name', 'Description', 'Source', 'Users', 'Roles', 'Actions'];
const TABLE_HEADERS = (isCloud && HEADERS_LIST.filter((item) => item !== 'Source')) || HEADERS_LIST;

const Container = styled.div`
  .data-table {
    overflow-x: visible;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const LoadingSpinner = styled(Spinner)(({ theme }) => `
  margin-left: 10px;
  font-size: ${theme.fonts.size.h3};
`);

const DescriptionCell = styled.td`
  max-width: 300px;
`;

const _teamFormatter = (team, rolesContext, usersContext, readOnly) => {
  const { roles: teamRolesIds = Immutable.Set() } = team;
  const teamRolesNames = teamRolesIds.map((roleId) => rolesContext.get(roleId)?.title ?? `${roleId} (Name not found)`);

  return (
    <tr key={team.id}>
      <td className="limited"><Link to={Routes.pluginRoute('SYSTEM_TEAMS_TEAMID')(team.id)}>{team.name}</Link></td>
      <DescriptionCell>
        <TextOverflowEllipsis>
          {team.description}
        </TextOverflowEllipsis>
      </DescriptionCell>
      {!isCloud && <SourceTypeCell team={team} />}
      <UsersCell team={team} usersContext={usersContext} />
      <RolesCell roles={teamRolesNames} />
      <ActionsCell team={team} readOnly={readOnly} />
    </tr>
  );
};

const _headerCellFormatter = (header) => {
  switch (header.toLocaleLowerCase()) {
    case 'actions':
      return <th className="actions text-right">{header}</th>;
    default:
      return <th>{header}</th>;
  }
};

const _loadTeams = (pagination, setLoading, setPaginatedTeams) => {
  setLoading(true);

  TeamsDomain.loadTeamsPaginated(pagination).then((paginatedTeams) => {
    setPaginatedTeams(paginatedTeams);
    setLoading(false);
  });
};

const _updateListOnTeamDelete = (pagination, setLoading, setPaginatedTeams, callback: () => void) => TeamsActions.delete.completed.listen(() => {
  _loadTeams(pagination, setLoading, setPaginatedTeams);
  callback();
});

const TeamsOverview = () => {
  const { page, pageSize: perPage, resetPage } = usePaginationQueryParameter();
  const [query, setQuery] = useState('');
  const [paginatedTeams, setPaginatedTeams] = useState<PaginatedTeams | undefined>();
  const [loading, setLoading] = useState(false);

  useEffect(() => _loadTeams({ page, perPage, query }, setLoading, setPaginatedTeams), [page, perPage, query]);
  useEffect(() => _updateListOnTeamDelete({ page, perPage, query }, setLoading, setPaginatedTeams, resetPage), [page, perPage, query, resetPage]);

  const handleSearch = (newQuery) => {
    resetPage();
    setQuery(newQuery);
  };

  const searchFilter = (
    <SearchForm onSearch={handleSearch}
                onReset={() => handleSearch('')}
                wrapperClass="has-bm"
                queryHelpComponent={<TeamsQueryHelp />}
                topMargin={0} />
  );

  if (!paginatedTeams) {
    return <Spinner />;
  }

  return (
    <LicenseCheck displayWarningContainer>
      {({ licenseIsValid }) => (
        <Container>
          <Row className="content">
            <Col xs={12}>
              <Header>
                <h2>Teams</h2>
                {loading && <LoadingSpinner text="" delay={0} />}
              </Header>
              <p className="description">
                Found {paginatedTeams.pagination.total} teams on the system.
              </p>
              <PaginatedList totalItems={paginatedTeams.pagination.total}>
                <DataTable id="teams-overview"
                           className="table-hover"
                           headers={TABLE_HEADERS}
                           rowClassName="no-bm"
                           sortByKey="name"
                           rows={paginatedTeams.list.toJS()}
                           noDataText={<NoSearchResult>No teams have been found.</NoSearchResult>}
                           headerCellFormatter={_headerCellFormatter}
                           dataRowFormatter={(team) => _teamFormatter(team, paginatedTeams.context.roles, paginatedTeams.context.users, !licenseIsValid)}
                           customFilter={searchFilter}
                           filterKeys={[]}
                           filterLabel="Filter Teams" />
              </PaginatedList>
            </Col>
          </Row>
        </Container>
      )}
    </LicenseCheck>
  );
};

export default TeamsOverview;
