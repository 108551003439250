import __request__ from 'routing/request';
/**
 * Override load balancer status of this graylog-server node. Next lifecycle change will override it again to its default. Set to ALIVE, DEAD, or THROTTLED.
 * @param nodeId The id of the node whose LB status will be changed
 */
export function override(nodeId: string, status?: string): Promise<void> {
    return __request__('PUT', `/cluster/${nodeId}/lbstatus/override/${status}`, null, {}, {
        'Accept': ['application/json']
    });
}
