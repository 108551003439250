import React, { useRef } from 'react';

import { Button } from 'components/bootstrap';
import { Icon } from 'components/common';
import SearchFiltersOverviewPopover from 'search-filter/components/search-filter-bar/SearchFiltersOverviewPopover';

type Props = {
  showPopover: boolean,
  toggleShowPopover: () => void,
}

const SearchFiltersOverview = ({ showPopover, toggleShowPopover }: Props) => {
  const loadButtonRef = useRef();

  return (
    <>
      <Button ref={loadButtonRef} onClick={toggleShowPopover} title="Load filter" bsSize="small">
        <Icon name="folder" />
      </Button>
      {showPopover && (
        <SearchFiltersOverviewPopover onClose={toggleShowPopover}
                                      target={loadButtonRef.current}
                                      show />
      )}
    </>
  );
};

export default SearchFiltersOverview;
