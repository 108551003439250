import * as React from 'react';
import styled from 'styled-components';

import Routes from 'routing/Routes';
import { IfPermitted } from 'components/common';
import PageContentLayout from 'components/layout/PageContentLayout';
import { Header, NavRow, NavTabLink, SectionHeading } from 'security-app/components/Layout';

const StyledPageContentLayout = styled(PageContentLayout)`
  overflow: unset;
`;

export default function SigmaLayout({ children }: { children: React.ReactNode }) {
  return (
    <StyledPageContentLayout>
      <Header>
        <SectionHeading>Sigma Rules</SectionHeading>
        <NavRow>
          <NavTabLink to={Routes.pluginRoute('SECURITY_SIGMA')} end>Rules</NavTabLink>
          <IfPermitted permissions="sigma_repository:read">
            <NavTabLink to={Routes.pluginRoute('SECURITY_SIGMA_CONFIGURATION')}>Sigma Repos</NavTabLink>
          </IfPermitted>
        </NavRow>
      </Header>
      <div>{children}</div>
    </StyledPageContentLayout>
  );
}
