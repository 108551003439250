import * as React from 'react';
import PropTypes from 'prop-types';

import { ControlLabel, FormGroup, HelpBlock, BootstrapModalForm, Input } from 'components/bootstrap';
import Select from 'components/common/Select';
import Spinner from 'components/common/Spinner';
import type { ViewSummaries, ViewSummary } from 'views/stores/ViewManagementStore';
import { ViewManagementActions } from 'views/stores/ViewManagementStore';
import type Parameter from 'views/logic/parameters/Parameter';

import styles from './SelectViewWithParameter.css';

type State = {
  viewSummaries: ViewSummaries | undefined | null,
  selectedView: ViewSummary | undefined | null,
  selectedParameter: string | undefined | null,
};

type Props = {
  onSubmit: (selectedView?: string, selectedParameter?: string) => void,
  onClose: () => void,
};

const _renderOption = ({ label, help }: { label: string, help: string }) => <><strong>{label}</strong>{help && ` - ${help}`}</>;

class SelectViewWithParameter extends React.Component<Props, State> {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      viewSummaries: undefined,
      selectedParameter: undefined,
      selectedView: undefined,
    };
  }

  componentDidMount() {
    ViewManagementActions.forValue().then((viewSummaries: ViewSummaries) => this.setState({ viewSummaries }));
  }

  // eslint-disable-next-line react/destructuring-assignment
  _onSubmit = () => this.props.onSubmit(this.state.selectedView && this.state.selectedView.id, this.state.selectedParameter);

  _onChange = (selectedView: string) => {
    const { viewSummaries } = this.state;

    if (selectedView && viewSummaries) {
      const view: ViewSummary | undefined | null = viewSummaries.find((v) => v.id === selectedView);

      if (view) {
        const parameter = view.parameters[0];

        this.setState({
          selectedView: view,
          selectedParameter: parameter.name,
        });

        return;
      }
    }

    this.setState({
      selectedParameter: undefined,
      selectedView: undefined,
    });
  };

  _onParameterChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({ selectedParameter: event.target.value });

  render() {
    const { viewSummaries, selectedView, selectedParameter } = this.state;

    let content = <Spinner />;

    if (viewSummaries) {
      const viewOptions = viewSummaries
        .sort((v1, v2) => v1.title.localeCompare(v2.title))
        .map((viewSummary: ViewSummary) => ({ label: viewSummary.title, value: viewSummary.id, help: viewSummary.summary }));

      let parameterSelection = null;

      if (selectedView) {
        const { parameters } = selectedView;

        parameterSelection = (
          <FormGroup className={styles.indentingParameters}>
            <ControlLabel>Select Parameter:</ControlLabel>
            {parameters.map((parameter: Parameter) => (
              <Input type="radio"
                     key={`radio-parameter-${parameter.name}`}
                     id={parameter.name}
                     name="parameter"
                     value={parameter.name}
                     label={parameter.title}
                     onChange={this._onParameterChange}
                     help={parameter.description}
                     checked={selectedParameter === parameter.name} />
            ))}
          </FormGroup>
        );
      }

      const selectedViewId = selectedView ? selectedView.id : undefined;

      content = (
        <>
          <FormGroup>
            <Select placeholder="Select from dashboard or saved search with parameters"
                    inputProps={{ 'aria-label': 'Select from dashboards or saved searches with parameters' }}
                    options={viewOptions}
                    optionRenderer={_renderOption}
                    onChange={this._onChange}
                    clearable
                    value={selectedViewId} />
            <HelpBlock>
              The value will be passed to the selected dashboard or saved search and used as a value for a parameter.
              Only views containing at least one parameter are listed here.
              In the next step you can select the parameter which will be used, if more than one exists.
            </HelpBlock>
          </FormGroup>

          {parameterSelection}

        </>
      );
    }

    return (
      <BootstrapModalForm title="Select dashboard or saved search"
                          submitButtonDisabled={!selectedParameter}
                          show
                          onCancel={this.props.onClose}
                          onSubmitForm={this._onSubmit}>
        {content}
      </BootstrapModalForm>
    );
  }
}

export default SelectViewWithParameter;
