import React from 'react';

import { ExternalLink } from 'components/common';
import useLicensesStatus from 'license/hooks/useLicensesStatus';
import { UnlicensedText } from 'components/loggers/ClusterSupportBundleInfo';

const EnterpriseSupportBundleInfo = () => {
  const { data: { status: licensesStatus }, isLoading } = useLicensesStatus();

  if (isLoading) {
    return null;
  }

  const isValidLicense = licensesStatus
    ?.filter((s) => s?.license?.subject === '/license/enterprise' && s.license?.enterprise?.traffic_limit >= 10 * 1024 * 1024 * 1024)
    .reduce((result, state) => result || (state.valid && !state.trial), false);

  if (!isValidLicense) {
    return <UnlicensedText />;
  }

  return (
    <>
      <strong>Please examine the bundle content before sending it to Graylog.
        It might contain sensitive data like IP addresses, hostnames or even passwords!
      </strong><br />
      After examination, you may attach it to your support case via &nbsp;
      <ExternalLink href="https://support.graylog.org">
        https://support.graylog.org
      </ExternalLink>
    </>
  );
};

export default EnterpriseSupportBundleInfo;
