export interface OptionSourceJson {
  type: string;
}

abstract class OptionSource {
  abstract get type(): string;

  static fromJSON(json: OptionSourceJson): OptionSource {
    switch (json.type) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      case 'field': return new FieldSource(json as FieldSourceJson);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      case 'static': return new StaticSource(json as StaticSourceJson);
      default: throw new Error(`Invalid type: ${json.type}`);
    }
  }

  abstract toJSON(): OptionSourceJson;
}

interface FieldSourceJson extends OptionSourceJson {
  field: string;
  limit: number | undefined;
}

export const DEFAULT_LIMIT = 20;
export class FieldSource extends OptionSource {
  private readonly TYPE: string = 'field';

  private readonly _field: string;

  private readonly _limit: number;

  constructor({ field, limit }: FieldSourceJson) {
    super();
    this._field = field;
    this._limit = limit ?? DEFAULT_LIMIT;
  }

  static create(field: string, limit: number = DEFAULT_LIMIT) {
    return new FieldSource({ type: 'field', field, limit });
  }

  static createDefault() {
    return new FieldSource({ type: 'field', field: undefined, limit: DEFAULT_LIMIT });
  }

  get type(): string {
    return this.TYPE;
  }

  get field(): string {
    return this._field;
  }

  get limit(): number | undefined {
    return this._limit;
  }

  toJSON(): FieldSourceJson {
    return {
      type: this.TYPE,
      field: this._field,
      limit: this._limit,
    };
  }
}

interface StaticSourceJson extends OptionSourceJson {
  values: Array<string>;
}

export class StaticSource extends OptionSource {
  private readonly TYPE: string = 'static';

  private readonly _values: Array<string>;

  constructor({ values }: StaticSourceJson) {
    super();
    this._values = values;
  }

  static createDefault() {
    return StaticSource.create([]);
  }

  static create(values: Array<string>) {
    return new StaticSource({ type: 'static', values });
  }

  get type(): string {
    return this.TYPE;
  }

  get values() {
    return this._values;
  }

  toJSON(): StaticSourceJson {
    return {
      type: 'static',
      values: this._values,
    };
  }
}

export default OptionSource;
