import * as React from 'react';

import { InvalidLicenseWarning } from 'license/LicenseCheck';
import { PageHeader, DocumentTitle, Spinner } from 'components/common';
import DocsHelper from 'util/DocsHelper';
import UserSearchFilterEdit from 'search-filter/components/filter-management-pages/UserSearchFilterEdit';
import SearchFilterPageSubactions from 'search-filter/components/filter-management-pages/SearchFilterPageSubactions';
import useSearchFilterLicenseStatus from 'search-filter/hooks/useSearchFilterLicenseStatus';

const MyFiltersEditPage = () => {
  const { isFetchingLicense, isValidLicense } = useSearchFilterLicenseStatus();

  if (isFetchingLicense) {
    return <Spinner />;
  }

  return (
    <DocumentTitle title="Edit Filter From &#0034;My Filters&#0034; Collection">
      <PageHeader title="Edit Filter From &#0034;My Filters&#0034; Collection"
                  actions={<SearchFilterPageSubactions />}
                  documentationLink={{
                    title: 'Search filter documentation',
                    path: DocsHelper.PAGES.WELCOME,
                  }}>
        <span>
          Edit a filter from your &#0034;My Filters&#0034; collection.
          Please note, changing a filter will affect all saved
          searches and dashboard widgets which include this filter.
        </span>
      </PageHeader>
      {isValidLicense ? <UserSearchFilterEdit /> : (
        <InvalidLicenseWarning featureName="search filter"
                               licenseSubject="/license/enterprise/search-filter"
                               displayWarningContainer />
      )}
    </DocumentTitle>
  );
};

export default MyFiltersEditPage;
