import React from 'react';
import styled, { css } from 'styled-components';

import { Alert } from 'components/bootstrap';
import useCurrentUser from 'hooks/useCurrentUser';
import StringUtils from 'util/StringUtils';
import DocsHelper from 'util/DocsHelper';
import DocumentationLink from 'components/support/DocumentationLink';
import { Icon, TextOverflowEllipsis } from 'components/common';
import getInaccessibleFiltersFromView from 'search-filter/logic/getInaccessableFiltersFromView';
import useView from 'views/hooks/useView';
import type { SearchFilter } from 'views/types';

const StyledAlert = styled(Alert)`
  margin-bottom: 10px;
  ${(props) => props.$isCompact && css`
    padding: 7px 10px;
  `}
`;

const FilterList = styled.ul<{ $isCompact?: boolean }>`
  padding-left: 20px;
  overflow: auto;
  ${(props) => css`
    max-height: ${props.$isCompact ? '52px' : '100px'};
  `}

  li {
    list-style: initial; 
  }
`;

type Props = {
  disabledViewCreation?: boolean,
  isCompact?: boolean,
}

const SearchFilterTransformationWarning = ({ disabledViewCreation, isCompact }: Props) => {
  const user = useCurrentUser();
  const view = useView();

  if (disabledViewCreation) {
    return null;
  }

  const inaccessibleSearchFilters = getInaccessibleFiltersFromView(view, user.permissions);

  if (!inaccessibleSearchFilters?.size) {
    return null;
  }

  const pluralizedFilter = StringUtils.pluralize(inaccessibleSearchFilters.size, 'filter', 'filters');

  return (
    <StyledAlert bsStyle="warning" $isCompact={isCompact}>
      A copy for {inaccessibleSearchFilters.size} referenced search {pluralizedFilter} will be created, because of missing permissions. <DocumentationLink page={DocsHelper.PAGES.WELCOME} text={<Icon name="lightbulb" />} />
      <FilterList>
        {inaccessibleSearchFilters.toArray().map((filter: SearchFilter) => (
          <li key={filter.id}>
            <TextOverflowEllipsis>
              {filter.title || filter.queryString}
            </TextOverflowEllipsis>
          </li>
        ))}
      </FilterList>
    </StyledAlert>
  );
};

SearchFilterTransformationWarning.defaultProps = {
  isCompact: false,
  disabledViewCreation: false,
};

export default SearchFilterTransformationWarning;
