import * as React from 'react';

import PageNavigation from 'components/common/PageNavigation';
import Routes from 'routing/Routes';
import { Row } from 'components/bootstrap';

const ForwarderPageNavigation = () => {
  const NAV_ITEMS = [
    { title: 'Forwarders', path: Routes.pluginRoute('SYSTEM_FORWARDERS'), exactPathMatch: true },
    { title: 'Input Profiles', path: Routes.pluginRoute('SYSTEM_INPUT_PROFILES') },
  ];

  return (
    <Row>
      <PageNavigation items={NAV_ITEMS} />
    </Row>
  );
};

export default ForwarderPageNavigation;
