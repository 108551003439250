import __request__ from 'routing/request';
interface DeflectorSummary {
    readonly current_target: string;
    readonly is_up: boolean;
}
/**
 * Get current deflector status
 */
export function deprecatedDeflector(): Promise<DeflectorSummary> {
    return __request__('GET', '/system/deflector', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Cycle deflector to new/next index
 */
export function deprecatedCycle(): Promise<void> {
    return __request__('POST', '/system/deflector/cycle', null, {}, {
        'Accept': []
    });
}
/**
 * Get current deflector status in index set
 */
export function deflector(indexSetId?: string): Promise<DeflectorSummary> {
    return __request__('GET', `/system/deflector/${indexSetId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Cycle deflector to new/next index in index set
 */
export function cycle(indexSetId?: string): Promise<void> {
    return __request__('POST', `/system/deflector/${indexSetId}/cycle`, null, {}, {
        'Accept': []
    });
}
