import * as React from 'react';
import { styled } from 'styled-components';

import useStreams from 'components/streams/hooks/useStreams';
import { Spinner } from 'components/common';
import useInstantArchivingConfig from 'instant-archiving/InstantArchivingConfig/hooks/useInstantArchivingConfig';
import InstantArchiveStreamList from 'instant-archiving/components/InstantArchiveStreamList';
import { Alert } from 'components/bootstrap';

type Props = {
  configId: string
  archiveStream: {
    [key: string]: string
  }
}
const StyledDt = styled.dt`
  margin-right: 10px;
`;

const InstantArchiveStreams = ({ configId, archiveStream }: Props) => {
  const { data: config, isInitialLoading: isLoadingConfig, error } = useInstantArchivingConfig(configId);
  const { data: allStreams, isInitialLoading: isLoadingAllStreams } = useStreams(
    { query: '', page: 1, pageSize: 0, sort: { direction: 'asc', attributeId: 'title' } },
  );

  if (isLoadingConfig || isLoadingAllStreams) {
    return <Spinner text="Loading configuration..." />;
  }

  const streamInArchive = Object.keys(archiveStream).map((streamId) => archiveStream[streamId]);

  const archiveStreams = allStreams.elements.filter((stream) => config?.stream_ids?.includes(stream.id))
    .map((s) => s.title);

  return (
    <>
      {error ? <Alert bsStyle="warning">No configuration found</Alert> : (
        <>
          <StyledDt>Configured Streams</StyledDt>
          <dd>
            <InstantArchiveStreamList streamNames={archiveStreams} />
          </dd>
        </>
      )}
      <StyledDt>Stream in Archive</StyledDt>
      <dd>
        <InstantArchiveStreamList streamNames={streamInArchive} isArchiveStream />
      </dd>
    </>
  );
};

export default InstantArchiveStreams;
