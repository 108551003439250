import __request__ from 'routing/request';
interface PaginationInfo {
    readonly per_page: number;
    readonly total: number;
    readonly count: number;
    readonly page: number;
}
interface Configuration {
    readonly template: string;
    readonly color: string;
    readonly collector_id: string;
    readonly name: string;
    readonly id: string;
    readonly tags: string[];
}
interface ConfigurationPreviewRenderResponse {
    readonly preview: string;
}
interface ConfigurationPreviewRequest {
    readonly template: string;
}
interface ConfigurationListResponse {
    readonly total: number;
    readonly pagination: PaginationInfo;
    readonly configurations: ConfigurationSummary[];
    readonly query: string;
    readonly sort: string;
    readonly order: string;
}
interface ConfigurationSummary {
    readonly color: string;
    readonly collector_id: string;
    readonly name: string;
    readonly id: string;
    readonly tags: string[];
}
interface CollectorUpload {
    readonly created: string;
    readonly collector_id: string;
    readonly collector_name: string;
    readonly rendered_configuration: string;
    readonly id: string;
    readonly node_id: string;
}
interface ConfigurationSidecarsResponse {
    readonly sidecar_ids: string[];
    readonly configuration_id: string;
}
interface ValidationResult {
    readonly error_context: {
        readonly [_key: string]: string[];
    };
    readonly failed: boolean;
    readonly errors: {
        readonly [_key: string]: string[];
    };
}
interface CollectorUploadListResponse {
    readonly total: number;
    readonly pagination: PaginationInfo;
    readonly uploads: CollectorUpload[];
}
/**
 * List all configurations
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function listConfigurations(sort: 'name' | 'id' | 'collector_id' = 'name', page: number = 1, per_page: number = 50, query?: string, order: 'asc' | ' desc' = 'asc'): Promise<ConfigurationListResponse> {
    return __request__('GET', '/sidecar/configurations', null, { 'page': page, 'per_page': per_page, 'query': query, 'sort': sort, 'order': order }, {
        'Accept': ['application/json']
    });
}
/**
 * Create new configuration
 */
export function createConfiguration(JSONbody: Configuration): Promise<unknown> {
    return __request__('POST', '/sidecar/configurations', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Render preview of a configuration template
 */
export function renderConfiguration(JSONbody: ConfigurationPreviewRequest): Promise<ConfigurationPreviewRenderResponse> {
    return __request__('POST', '/sidecar/configurations/render/preview', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Render configuration template
 */
export function renderConfigurationBysidecarIdAndconfigurationId(sidecarId: string, configurationId: string): Promise<unknown> {
    return __request__('GET', `/sidecar/configurations/render/${sidecarId}/${configurationId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * List all uploaded configurations
 */
export function listImports(page: number = 1): Promise<CollectorUploadListResponse> {
    return __request__('GET', '/sidecar/configurations/uploads', null, { 'page': page }, {
        'Accept': ['application/json']
    });
}
/**
 * Validates configuration parameters
 * @param configuration configuration
 */
export function validateConfiguration(configuration?: Configuration): Promise<ValidationResult> {
    return __request__('POST', '/sidecar/configurations/validate', configuration, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete a configuration
 */
export function deleteConfiguration(id: string): Promise<unknown> {
    return __request__('DELETE', `/sidecar/configurations/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update a configuration
 */
export function updateConfiguration(id: string, JSONbody: Configuration): Promise<unknown> {
    return __request__('PUT', `/sidecar/configurations/${id}`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Show configuration details
 */
export function getConfigurations(id: string): Promise<Configuration> {
    return __request__('GET', `/sidecar/configurations/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Show sidecars using the given configuration
 */
export function getConfigurationSidecars(id: string): Promise<ConfigurationSidecarsResponse> {
    return __request__('GET', `/sidecar/configurations/${id}/sidecars`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Copy a configuration
 */
export function copyConfiguration(id: string): Promise<unknown> {
    return __request__('POST', `/sidecar/configurations/${id}/${name}`, null, {}, {
        'Accept': ['application/json']
    });
}
