import * as React from 'react';
import { useEffect } from 'react';

import { Col, Row } from 'components/bootstrap';
import { DocumentTitle, PageHeader } from 'components/common';
import DocsHelper from 'util/DocsHelper';
import { useStore } from 'stores/connect';
import type { LicenseStatus } from 'archive/types';

import ReportList from './ReportList';

import ReportValidations from '../common/ReportValidations';
import ReportLicenseActions from '../ReportLicenseActions';
import ReportLicenseStore from '../ReportLicenseStore';
import ReportsSubNav from '../common/ReportsSubNav';

const ReportsPage = () => {
  const licenseStatus: LicenseStatus = useStore(ReportLicenseStore);

  useEffect(() => {
    ReportLicenseActions.getLicenseStatus();
  }, []);

  return (
    <DocumentTitle title="Reports">
      <PageHeader title="Reports"
                  actions={<ReportsSubNav licenseStatus={licenseStatus} />}
                  documentationLink={{
                    title: 'Reporting documentation',
                    path: DocsHelper.PAGES.REPORTING,
                  }}>
        <span>
          Reports let you relay information from Graylog in a presentable form. They are based on dashboard
          widgets, highly customizable and can be scheduled for automatic delivery.
        </span>
      </PageHeader>

      <ReportValidations />

      <Row className="content">
        <Col md={12}>
          <ReportList licenseStatus={licenseStatus} />
        </Col>
      </Row>
    </DocumentTitle>
  );
};

export default ReportsPage;
