// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.plotly-notifier {
    visibility: hidden;
}

.xK5gTnz6x6uLtuCzdS2T .popover-content {
    padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/views/components/visualizations/GenericPlot.lazy.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,UAAU;AACd","sourcesContent":["div.plotly-notifier {\n    visibility: hidden;\n}\n\n:local(.customPopover) .popover-content {\n    padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customPopover": `xK5gTnz6x6uLtuCzdS2T`
};
export default ___CSS_LOADER_EXPORT___;
