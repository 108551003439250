import URI from 'urijs';

import { qualifyUrl } from 'util/URLUtils';

const sourceUrl = '/plugins/org.graylog.plugins.forwarder/forwarder';

const inputsUrl = ({ segments = [], query = {} }) => {
  const uri = new URI(sourceUrl);
  const nextSegments = uri.segment().concat(segments);
  uri.segmentCoded(nextSegments);
  uri.query(query);

  return qualifyUrl(uri.resource());
};

export default inputsUrl;
