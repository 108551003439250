import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';

import { Button, Col, Row, Input } from 'components/bootstrap';
import { useStore } from 'stores/connect';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'logic/telemetry/Constants';

import { StyledTitle } from './StyledWizardComponents';
import { ForwarderWizardContext } from './ForwarderWizardProvider';

import ForwardersStore, { ForwardersActions } from '../stores/ForwardersStore';
import type { Forwarder } from '../Types';

type Props = {
  onStepComplete: () => void,
  forwarderId?: string;
};

const ConfigureForwarderStep = ({ onStepComplete, forwarderId }: Props) => {
  const { selectedForwarder, setSelectedForwarder } = useContext(ForwarderWizardContext);
  const { forwardersPendingConfiguration } = useStore(ForwardersStore);
  const sendTelemetry = useSendTelemetry();

  useEffect(() => {
    if (forwarderId && !selectedForwarder) {
      // fetch available forwarders first
      if (forwardersPendingConfiguration.length === 0) {
        ForwardersActions.listPendingConfiguration({});
      }

      const forwarder = forwardersPendingConfiguration.find((f: Forwarder) => f.id === forwarderId);

      // if forwarder was found, select it
      if (forwarder) {
        setSelectedForwarder(forwarder);
      }
    }
  }, [forwarderId, selectedForwarder, forwardersPendingConfiguration, setSelectedForwarder]);

  if (forwarderId && !selectedForwarder) {
    return <div>Please wait...</div>;
  }

  if (!selectedForwarder) {
    return <div>Please select a Forwarder before continuing to this step.</div>;
  }

  const handleSubmit = (values, { setSubmitting }) => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.FORWARDER.FORWARDER_WIZARD_CONFIGURE_FORWARDER_SAVED, {
      app_pathname: 'forwarder',
      app_section: 'forwarder-create',
      app_action_value: 'save',
    });

    ForwardersActions.update(selectedForwarder.id, values)
      .then((persistedForwarder) => {
        setSelectedForwarder(persistedForwarder);
        setSubmitting(false);
        onStepComplete();
      });
  };

  const initialValues = {
    hostname: selectedForwarder.hostname,
    title: selectedForwarder.title,
    description: selectedForwarder.description,
  };

  return (
    <Row>
      <Col md={7}>
        <StyledTitle>Configure Forwarder</StyledTitle>
        <p>Enter some configuration details for the Forwarder running in <em>{selectedForwarder.hostname}</em>:</p>
        <Formik initialValues={initialValues}
                onSubmit={handleSubmit}>
          {({
            values,
            handleChange,
            isSubmitting,
          }) => (
            <Form>
              <Input id="hostname"
                     name="hostname"
                     label="Hostname"
                     type="text"
                     value={values.hostname}
                     onChange={handleChange}
                     help="Hostname where this Forwarder is running. This is automatically sent by the Forwarder."
                     required
                     disabled />
              <Input id="title"
                     name="title"
                     label="Title"
                     type="text"
                     value={values.title}
                     onChange={handleChange}
                     help="Meaningful name used to identify this Forwarder."
                     required />
              <Input id="description"
                     name="description"
                     label={<span>Description <small>(Optional)</small></span>}
                     type="text"
                     value={values.description}
                     onChange={handleChange}
                     help="Longer description for this Forwarder." />
              <Button type="submit" bsStyle="primary" disabled={isSubmitting}>
                {isSubmitting ? 'Saving your changes...' : 'Add Forwarder inputs'}
              </Button>
            </Form>
          )}
        </Formik>
      </Col>
    </Row>
  );
};

ConfigureForwarderStep.propTypes = {
  onStepComplete: PropTypes.func.isRequired,
  forwarderId: PropTypes.string,
};

ConfigureForwarderStep.defaultProps = {
  forwarderId: undefined,
};

export default ConfigureForwarderStep;
