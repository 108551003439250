import React from 'react';

import PageHeader from 'components/common/PageHeader';
import { ExternalLink } from 'components/common';

import EmbeddedCrowdStrikeApp from './EmbeddedCrowdStrikeApp';

const CrowdStrikeApp: React.FC = () => {
  return (
    <>
      <PageHeader title="CrowdStrike Falcon Integrations">
        <span>This feature retrieves log records from CrowdStrike Platform.</span>
        <p>
          You need to have{' '}
          <ExternalLink href="https://www.crowdstrike.com">
            CrowdStrike Falcon
          </ExternalLink>
          .{' '}
        </p>
      </PageHeader>
      <EmbeddedCrowdStrikeApp />
    </>
  );
};

export default CrowdStrikeApp;
