import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import moment from 'moment';

import NumberUtils from 'util/NumberUtils';

const LicenseDetails = createReactClass({
  propTypes: {
    trial: PropTypes.bool.isRequired,
    version: PropTypes.number.isRequired,
    enterprise: PropTypes.oneOfType([
      PropTypes.shape({
        number_of_nodes: PropTypes.number,
      }),
      PropTypes.shape({
        traffic_limit: PropTypes.number,
        require_remote_check: PropTypes.bool,
        allowed_remote_check_failures: PropTypes.number,
        allowed_traffic_violations: PropTypes.number,
        traffic_check_range: PropTypes.number,
        expiration_warning_range: PropTypes.number,
      }),
    ]).isRequired,
    violations: PropTypes.shape({
      cluster_not_covered: PropTypes.bool,
      nodes_exceeded: PropTypes.bool,
      traffic_exceeded: PropTypes.bool,
      remote_checks_failed: PropTypes.bool,
      expired: PropTypes.bool,
      violated: PropTypes.bool,
    }).isRequired,
  },

  render() {
    const details = [];

    const { trial, version } = this.props;

    // mark trial licenses so they can be spotted more easily when replacing them with a real one
    if (trial) {
      details.push(<span key="trial"><b>Trial License</b><br /></span>);
    }

    const { enterprise } = this.props;

    if (version === 1) {
      details.push(
        <span key="node-count">{`Maximum nodes in cluster: ${enterprise.number_of_nodes}`}<br /></span>,
      );
    } else if (version === 2) {
      const limit = NumberUtils.formatBytes(enterprise.traffic_limit);
      const allowedViolations = enterprise.allowed_traffic_violations;
      const trafficCheckRange = moment.duration(enterprise.traffic_check_range);

      details.push(
        <span key="ingest">{`Daily traffic limit: ${limit} (allowed violations per ${trafficCheckRange.as('days')} days: ${allowedViolations})`}<br /></span>,
      );

      const remoteCheck = enterprise.require_remote_check;

      details.push(
        <span key="remote-check">
          {`Requires remote checks: ${remoteCheck ? 'Yes' : 'No'}`}
          {remoteCheck ? ` (allowed consecutive check failures: ${enterprise.allowed_remote_check_failures})` : null}
          <br />
        </span>,
      );

      const expirationWarning = moment.duration(enterprise.expiration_warning_range);

      details.push(
        <span key="expiration-warn">
          {`License expiration warning: ${expirationWarning.as('days')} days before`}
          <br />
        </span>,
      );
    } else {
      details.push(<span key="unknown-version">Unknown license version, cannot display details.<br /></span>);
    }

    return <div>{details}</div>;
  },
});

export default LicenseDetails;
