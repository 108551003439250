import React from 'react';

import PageHeader from 'components/common/PageHeader';
import { ExternalLink } from 'components/common';

import EmbeddedF5NetworksApp from './EmbeddedF5NetworksApp';

const F5NetworksApp: React.FC = () => {
  return (
    <>
      <PageHeader title="F5 BIG-IP Integrations">
        <span>This feature retrieves log records from F5 BIG-IP Platform.</span>
        <p>
          You need to have{' '}
          <ExternalLink href="https://www.f5.com/trials/big-ip-virtual-edition">
            F5 BIG-IP
          </ExternalLink>
          .{' '}
        </p>
      </PageHeader>
      <EmbeddedF5NetworksApp />
    </>
  );
};

export default F5NetworksApp;
