import * as React from 'react';
import styled from 'styled-components';

import { Button } from 'components/bootstrap';

const FormContent = styled.div`
  display: flex;
  flex-direction row;

  > :not(:last-child) {
    margin-right: 5px;
  }

  > * {
    display: inline-block;
  }
`;

const SearchRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 1rem;
`;

const SearchField = styled.input`
  width: 30%;
  min-width: 300px;
`;

type Props = {
  query?: string,
  onSearch: (query: string) => void,
  onReset?: () => void,
  placeholder?: string,
};

const SearchForm = ({ query, onSearch, onReset, placeholder }: Props) => {
  const [searchTerm, setSearchTerm] = React.useState<string>(query);

  const handleOnSearch = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (searchTerm) onSearch(searchTerm);
    else onReset();
  };

  const handleReset = () => {
    setSearchTerm('');
    onReset();
  };

  return (
    <SearchRow>
      <form className="form-inline" onSubmit={handleOnSearch}>
        <FormContent>
          <SearchField id="search-input"
                       type="text"
                       data-testid="search-input"
                       placeholder={placeholder}
                       autoComplete="off"
                       value={searchTerm}
                       onChange={(e: React.BaseSyntheticEvent) => setSearchTerm(e.target.value)}
                       className="query form-control" />
          <Button bsStyle="primary"
                  data-testid="execute-search"
                  onClick={handleOnSearch}>
            Search
          </Button>
          <Button data-testid="reset-search"
                  onClick={handleReset}>
            Reset
          </Button>
        </FormContent>
      </form>
    </SearchRow>
  );
};

SearchForm.defaultProps = {
  query: '',
  onReset: () => {},
  placeholder: 'Enter search query...',
};

export default SearchForm;
