// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QCm7R4MwlLoTDwu8SNZB {
    padding-left: 0;
    margin-bottom: 20px;
}

.QCm7R4MwlLoTDwu8SNZB .QCm7R4MwlLoTDwu8SNZB {
    margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/ExpandableList.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[":local(.list) {\n    padding-left: 0;\n    margin-bottom: 20px;\n}\n\n:local(.list) :local(.list) {\n    margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `QCm7R4MwlLoTDwu8SNZB`
};
export default ___CSS_LOADER_EXPORT___;
