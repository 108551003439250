import React, { useMemo } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import styled from 'styled-components';

import { PaginatedList, SearchForm, NoSearchResult } from 'components/common';
import QueryHelper from 'components/common/QueryHelper';
import usePaginationQueryParameter from 'hooks/usePaginationQueryParameter';
import EntityDataTable from 'components/common/EntityDataTable';
import useUpdateUserLayoutPreferences from 'components/common/EntityDataTable/hooks/useUpdateUserLayoutPreferences';
import useTableLayout from 'components/common/EntityDataTable/hooks/useTableLayout';
import {
  DEFAULT_LAYOUT,
  ENTITY_TABLE_ID,
  ADDITIONAL_ATTRIBUTES,
} from 'instant-archiving/InstantArchivingOverview/Constants';
import useTableElements from 'instant-archiving/InstantArchivingOverview/hooks/useTableElements';
import useTableEventHandlers from 'components/streams/StreamsOverview/hooks/useTableEventHandlers';
import useInstantArchivings from 'instant-archiving/hooks/useInstantArchivings';
import Spinner from 'components/common/Spinner';
import type { InstantArchive } from 'instant-archiving/Types';

import CustomColumnRenderers from './ColumnRenderers';

const StyledDiv = styled.div`
  margin-bottom: 5px;
`;

const InstantArchivingOverview = () => {
  const { layoutConfig, isInitialLoading: isLoadingLayoutPreferences } = useTableLayout({
    entityTableId: ENTITY_TABLE_ID,
    defaultPageSize: DEFAULT_LAYOUT.pageSize,
    defaultDisplayedAttributes: DEFAULT_LAYOUT.displayedColumns,
    defaultSort: DEFAULT_LAYOUT.sort,
  });
  const [query, setQuery] = useQueryParam('query', StringParam);
  const paginationQueryParameter = usePaginationQueryParameter(undefined, layoutConfig.pageSize, false);

  const { mutate: updateTableLayout } = useUpdateUserLayoutPreferences(ENTITY_TABLE_ID);

  const { entityActions, bulkActions } = useTableElements();
  const {
    onPageSizeChange,
    onSearch,
    onSearchReset,
    onColumnsChange,
    onSortChange,
  } = useTableEventHandlers({
    paginationQueryParameter,
    updateTableLayout,
    setQuery,
  });

  const searchParams = useMemo(() => ({
    query: query,
    page: paginationQueryParameter.page,
    pageSize: layoutConfig.pageSize,
    sort: layoutConfig.sort,

  }), [layoutConfig.pageSize, layoutConfig.sort, paginationQueryParameter.page, query]);
  const {
    data: paginatedArchive,
    isInitialLoading: isLoadingArchive,
  } = useInstantArchivings(searchParams, { enabled: !isLoadingLayoutPreferences });

  const columnRenderers = useMemo(() => CustomColumnRenderers(), []);
  const columnDefinitions = useMemo(
    () => ([...(paginatedArchive.attributes ?? []), ...ADDITIONAL_ATTRIBUTES]),
    [paginatedArchive.attributes],
  );

  if (isLoadingLayoutPreferences || isLoadingArchive) {
    return <Spinner />;
  }

  const { elements, pagination: { total } } = paginatedArchive;

  return (
    <PaginatedList pageSize={layoutConfig.pageSize}
                   showPageSizeSelect={false}
                   totalItems={total}>
      <StyledDiv>
        <SearchForm onSearch={onSearch}
                    onReset={onSearchReset}
                    query={query}
                    queryHelpComponent={<QueryHelper entityName="archive" />} />
      </StyledDiv>
      <div>
        {elements?.length === 0 ? (
          <NoSearchResult>No archives have been found</NoSearchResult>
        ) : (
          <EntityDataTable<InstantArchive> data={elements}
                                           visibleColumns={layoutConfig.displayedAttributes}
                                           columnsOrder={DEFAULT_LAYOUT.columnsOrder}
                                           onColumnsChange={onColumnsChange}
                                           onSortChange={onSortChange}
                                           onPageSizeChange={onPageSizeChange}
                                           pageSize={layoutConfig.pageSize}
                                           bulkSelection={{ actions: bulkActions }}
                                           activeSort={layoutConfig.sort}
                                           rowActions={entityActions}
                                           actionsCellWidth={160}
                                           columnRenderers={columnRenderers}
                                           columnDefinitions={columnDefinitions}
                                           entityAttributesAreCamelCase={false} />
        )}
      </div>
    </PaginatedList>
  );
};

InstantArchivingOverview.propTypes = {};

export default InstantArchivingOverview;
