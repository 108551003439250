import React from 'react';

import { Col, Row } from 'components/bootstrap';
import { EmptyEntity, Spinner } from 'components/common';
import { isPermitted } from 'util/PermissionsMixin';
import { LicenseStatusPropType } from 'archive/propTypes';
import useCurrentUser from 'hooks/useCurrentUser';

import CreateForwarderButton from './CreateForwarderButton';

const EmptyForwarderListComponent = ({ licenseStatus }) => {
  const currentUser = useCurrentUser();

  if (licenseStatus.loading) {
    return <Spinner text="Loading license information" />;
  }

  return (
    <Row className="content">
      <Col md={12}>
        <Row>
          <Col md={6} mdOffset={3} lg={4} lgOffset={4}>
            <EmptyEntity>
              {isPermitted(currentUser?.permissions, 'forwarders:create')
                ? (
                  <>
                    <p>
                      Forwarders allow you to send log messages from your local network to Graylog. Click on
                      the button below to get started and configure your first Forwarder.
                    </p>
                    <CreateForwarderButton licenseStatus={licenseStatus} buttonTitle="Get Started!" />
                  </>
                ) : (
                  <p>Forwarders allow you to send log messages from your local network to Graylog.</p>
                )}
            </EmptyEntity>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

EmptyForwarderListComponent.propTypes = {
  licenseStatus: LicenseStatusPropType.isRequired,
};

export default EmptyForwarderListComponent;
