import * as React from 'react';
import { useState } from 'react';

import { MenuItem } from 'components/bootstrap';
import { ConfirmDialog } from 'components/common';
import { DEFAULT_PAGE } from 'hooks/usePaginationQueryParameter';
import useInstantArchivings from 'instant-archiving/hooks/useInstantArchivings';
import {
  useBulkUpdateInstantArchivingConfig,
} from 'instant-archiving/InstantArchivingConfig/hooks/useInstantArchivingConfigMutation';

type Props = {
  descriptor: string,
  refetchArchives: () => void,
  onSelect?: () => void,
  selectedArchiveIds: Array<string>,
};
const ACTION_TYPES = {
  START_ARCHIVER: 'start',
  STOP_ARCHIVER: 'stop',
};

const ACTION_TEXT = {
  [ACTION_TYPES.START_ARCHIVER]: {
    dialogTitle: 'Start Archiver',
    dialogBody: (count: number, descriptor: string) => `Are you sure you want to start the archiver for ${count} ${(descriptor)}?`,

  },
  [ACTION_TYPES.STOP_ARCHIVER]: {
    dialogTitle: 'Stop Archiver',
    dialogBody: (count: number, descriptor: string) => `Are you sure you want to stop the archiver for ${count} ${(descriptor)}?`,
  },
};

const ArchiverStatusAction = ({ selectedArchiveIds, refetchArchives, descriptor, onSelect }: Props) => {
  const { data, isInitialLoading: isLoadingArchive } = useInstantArchivings({
    query: '',
    page: DEFAULT_PAGE,
    pageSize: 0,
    sort: {
      attributeId: 'id',
      direction: 'desc',
    },
  });
  const { mutateAsync } = useBulkUpdateInstantArchivingConfig();
  const [showDialog, setShowDialog] = useState(false);
  const [actionType, setActionType] = useState(null);

  const updateState = ({ show, type }) => {
    setShowDialog(show);
    setActionType(type);
  };

  const handleAction = (action) => {
    if (typeof onSelect === 'function') {
      onSelect();
    }

    switch (action) {
      case ACTION_TYPES.START_ARCHIVER:
        updateState({ show: true, type: ACTION_TYPES.START_ARCHIVER });

        break;
      case ACTION_TYPES.STOP_ARCHIVER:
        updateState({ show: true, type: ACTION_TYPES.STOP_ARCHIVER });

        break;
      default:
        break;
    }
  };

  const handleClearState = () => {
    updateState({ show: false, type: null });
  };

  const getConfiguration = () => {
    if (isLoadingArchive || !data) {
      return null;
    }

    return data.elements
      .filter((item) => selectedArchiveIds.includes(item.id))
      .reduce((ids, archive) => [...ids, archive.archive_config_id], []);
  };

  const handleConfirm = () => {
    const configurationIds = getConfiguration();

    if (configurationIds) {
      mutateAsync({ config_ids: configurationIds, state: (actionType === ACTION_TYPES.START_ARCHIVER) });
      refetchArchives();
    }

    setShowDialog(false);
  };

  return (
    <>
      <MenuItem bsStyle="default" onClick={() => handleAction(ACTION_TYPES.START_ARCHIVER)}>Start archiver</MenuItem>
      <MenuItem bsStyle="default" onClick={() => handleAction(ACTION_TYPES.STOP_ARCHIVER)}>Stop archiver</MenuItem>
      {showDialog && (
        <ConfirmDialog title={ACTION_TEXT[actionType]?.dialogTitle}
                       show
                       onConfirm={handleConfirm}
                       onCancel={handleClearState}>
          {ACTION_TEXT[actionType]?.dialogBody(selectedArchiveIds?.length, descriptor)}
        </ConfirmDialog>
      )}
    </>
  );
};

ArchiverStatusAction.defaultProps = {
  onSelect: undefined,
};

export default ArchiverStatusAction;
