import __request__ from 'routing/request';
interface anyMap {
    readonly [_key: string]: unknown;
}
/**
 * Get all active notifications
 */
export function listNotifications(): Promise<anyMap> {
    return __request__('GET', '/system/notifications', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete a notification by type
 */
export function deleteNotification(notificationType?: string): Promise<void> {
    return __request__('DELETE', `/system/notifications/${notificationType}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete a notification by type and key
 */
export function deleteKeyedNotification(notificationType?: string, notificationKey?: string): Promise<void> {
    return __request__('DELETE', `/system/notifications/${notificationType}/${notificationKey}`, null, {}, {
        'Accept': ['application/json']
    });
}
