import React, { useCallback } from 'react';

import type { InstantArchivingConfig } from 'instant-archiving/Types';
import InstantArchivingConfigActions from 'instant-archiving/InstantArchivingConfig/InstantArchivingConfigOverview/InstantArchivingConfigActions';
import BulkActions from 'instant-archiving/InstantArchivingConfig/InstantArchivingConfigOverview/BulkActions';

const useTableElements = () => {
  const entityActions = useCallback((archiveConfig: InstantArchivingConfig) => (
    <InstantArchivingConfigActions archiveConfig={archiveConfig} />
  ), []);

  const bulkActions = useCallback((
    selectedArchiveConfigIds: Array<string>,
    setSelectedArchiveConfigIds: (archiveConfigIds: Array<string>) => void,
  ) => (
    <BulkActions selectedArchiveConfigIds={selectedArchiveConfigIds} setSelectedArchiveConfigIds={setSelectedArchiveConfigIds} />
  ), []);

  return {
    entityActions,
    bulkActions,
  };
};

export default useTableElements;
