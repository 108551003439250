import * as React from 'react';
import styled, { css } from 'styled-components';

const _getCellMinWith = (fieldName) => {
  switch (fieldName) {
    case 'timestamp':
      return 205;
    case 'message':
      return 315;
    default:
      return 155;
  }
};

const _getCellFlex = (fieldName) => {
  switch (fieldName) {
    case 'message':
      return 4;
    default:
      return 1;
  }
};

const Cell = styled.div<{ $minWidth?: number, $flex?: number}>(({ $minWidth = 155, $flex = 1 }) => css`
  flex: ${$flex};
  min-width: ${$minWidth}px;
  word-break: break-word;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
`);

type Props = {
  children: React.ReactNode,
  fieldName: string,
}

const LogViewCell = ({ children, fieldName }: Props) => {
  const minWidth = _getCellMinWith(fieldName);
  const flex = _getCellFlex(fieldName);

  return (
    <Cell $minWidth={minWidth} $flex={flex}>
      {children}
    </Cell>
  );
};

export default LogViewCell;
