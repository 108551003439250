import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import { Spinner } from 'components/common';

import OidcLoginForm from './OidcLoginForm';
import { OidcSessionActions } from './OidcSessionStore';
import { LoginMetricsActions } from './LoginMetricsStore';
import type { LoginMetric } from './LoginMetrics';
import { createFailureLoginMetric, GRAYLOG_ERROR_CODES } from './LoginMetrics';

type Props = {
  configuration: {
    oauth_client_id: string;
    base_url: string;
    okta_org_url: string,
    okta_external_idp_id: string,
  };
  nonce: {
    state: string;
    redirectTo: string;
  };
  params: {
    state: string;
    code?: string;
    error_description?: string;
  },
  onErrorChange: (nextError: string) => void;
  regenerateNonce: () => void;
};

const OidcCallback = ({ configuration, nonce, onErrorChange, params, regenerateNonce }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);
  const isRegenerated = useRef(false);

  const generateNonce = useCallback(() => {
    if (!isRegenerated.current) {
      regenerateNonce();
      isRegenerated.current = true;
    }
  }, [regenerateNonce]);

  useEffect(() => {
    const handleLoginFailure = (metric?: LoginMetric) => {
      if (metric) {
        LoginMetricsActions.post(metric);
      }

      setLoginFailed(true);
      generateNonce();
    };

    if (loginFailed) {
      return () => {};
    }

    if (params.error_description) {
      onErrorChange(params.error_description);
      handleLoginFailure(createFailureLoginMetric('login', 'gl', GRAYLOG_ERROR_CODES.oktaLoginRedirectError));

      return () => { };
    }

    if (nonce.state !== params.state) {
      onErrorChange('A possible CSRF attempt has been detected, the state parameter does not match the request. Please contact an administrator.');
      handleLoginFailure(createFailureLoginMetric('tokenExchange', 'gl', GRAYLOG_ERROR_CODES.nonceMismatch));

      return () => {};
    }

    setIsLoading(true);

    const promise = OidcSessionActions.login(params.code);

    promise.catch((error) => {
      if (error.status === 401) {
        onErrorChange('Login failed. This might be a temporary problem. Please try again.');
      } else {
        onErrorChange('Unknown error, please retry or contact an administrator.');
      }

      // Metrics for this failure are handled in the store
      handleLoginFailure();

      setIsLoading(false);

      return error;
    });

    return () => {
      if (promise) {
        promise.cancel();
      }
    };
  }, [generateNonce, loginFailed, nonce.state, onErrorChange, params]);

  if (isLoading) {
    return (
      <p className="loading-text">
        <Spinner text="Loading, please wait..." delay={0} />
      </p>
    );
  }

  return <OidcLoginForm configuration={configuration} nonce={nonce} />;
};

OidcCallback.propTypes = {
  configuration: PropTypes.object.isRequired,
  nonce: PropTypes.shape({
    state: PropTypes.string.isRequired,
    redirectTo: PropTypes.string.isRequired,
  }).isRequired,
  onErrorChange: PropTypes.func.isRequired,
  params: PropTypes.shape({
    state: PropTypes.string,
    code: PropTypes.string,
    error_description: PropTypes.string,
  }).isRequired,
  regenerateNonce: PropTypes.func.isRequired,
};

export default OidcCallback;
