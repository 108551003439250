import React from 'react';

import PageHeader from 'components/common/PageHeader';

import EmbeddedSalesforceApp from './EmbeddedSalesforceApp';

const SalesforceApp: React.FC = () => (
  <>
    <PageHeader title="Salesforce Integrations">
      <span>This feature retrieves log records from Salesforce platform.</span>
    </PageHeader>
    <EmbeddedSalesforceApp />
  </>
);

export default SalesforceApp;
