import type * as Immutable from 'immutable';

import ParameterBinding from 'views/logic/parameters/ParameterBinding';
import type SearchExecutionState from 'views/logic/search/SearchExecutionState';

const mergeParameterValues = (parameterValues: Immutable.Map<string, any>, executionState: SearchExecutionState) => {
  const { parameterBindings } = executionState;
  const parameterMap = parameterValues.map((value) => ParameterBinding.forValue(value)).toMap();

  return executionState.toBuilder().parameterBindings(parameterBindings.merge(parameterMap)).build();
};

export default mergeParameterValues;
