import React from 'react';
import PropTypes from 'prop-types';

import { Input } from 'components/bootstrap';

const URLhausAdapterFieldSet = ({ config, updateConfig, handleFormEvent, validationMessage, validationState }) => {
  return (
    <fieldset>
      <Input type="select"
             id="feed_type"
             name="feed_type"
             label="URLhaus Feed Type"
             onChange={handleFormEvent}
             help={validationMessage('feed_type', 'Select the URLhaus feed to query against.')}
             bsStyle={validationState('feed_type')}
             value={config.feed_type}
             labelClassName="col-sm-3"
             wrapperClassName="col-sm-9"
             required>
        <option key="ONLINE_ONLY" value="ONLINE_ONLY">Online URLs</option>
        <option key="RECENT_ADDITIONS" value="RECENT_ADDITIONS">Recently Added URLs (last 30 days)</option>
      </Input>
      <Input type="number"
             id="refresh_interval"
             name="refresh_interval"
             label="Refresh Interval (seconds)"
             required
             onChange={handleFormEvent}
             help={validationMessage('refresh_interval', 'How often to fetch new data from the source (minimum 300 seconds).')}
             bsStyle={validationState('refresh_interval')}
             value={config.refresh_interval}
             labelClassName="col-sm-3"
             wrapperClassName="col-sm-9" />
      <Input type="checkbox"
             id="case_insensitive_lookup"
             name="case_insensitive_lookup"
             label="Case Insensitive Lookups"
             checked={config.case_insensitive_lookup}
             onChange={handleFormEvent}
             wrapperClassName="col-md-offset-3 col-md-9" />
    </fieldset>
  );
};

URLhausAdapterFieldSet.propTypes = {
  config: PropTypes.shape({
    feed_type: PropTypes.string.isRequired,
    refresh_interval: PropTypes.number.isRequired,
    case_insensitive_lookup: PropTypes.bool.isRequired,
  }).isRequired,
  updateConfig: PropTypes.func.isRequired,
  handleFormEvent: PropTypes.func.isRequired,
  validationState: PropTypes.func.isRequired,
  validationMessage: PropTypes.func.isRequired,
};

export default URLhausAdapterFieldSet;
