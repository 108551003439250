import * as React from 'react';
import styled, { css } from 'styled-components';
import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { ButtonToolbar } from 'components/bootstrap';
import UserNotification from 'util/UserNotification';
import type { InstantArchivingConfig } from 'instant-archiving/Types';
import InstantArchivingConfigButton from 'instant-archiving/InstantArchivingConfig/InstantArchivingConfigButton';
import { DeleteArchiveConfigAction } from 'instant-archiving/InstantArchivingConfig/InstantArchivingConfigOverview/BulkActions';

type Props = {
  archiveConfig: InstantArchivingConfig,
};

const StyledButtonToolbar = styled(ButtonToolbar)(({ theme }) => css`
  &.instant-archiving-config-action-toolbar {


    li {
      list-style: none;
    }
  }

  &.instant-archiving-config-action-toolbar a {
    padding: 3px 10px;
    display: flex;
    font-weight: normal;
    font-size: ${theme.fonts.size.small};
    color: ${theme.colors.global.textDefault};
    text-decoration: none;
    background-color: ${theme.colors.gray[90]};
    margin-left: 5px;

    &:hover,
    &:focus {
      color: ${theme.colors.global.textDefault};
      background-color: ${theme.colors.variant.light.default};
    }
  }
`);

const InstantArchivingConfigActions = ({ archiveConfig }: Props) => {
  const queryClient = useQueryClient();
  const refetchArchives = useCallback(() => queryClient.invalidateQueries(['instant-archiving', 'overview']), [queryClient]);
  const handleFailures = useCallback((failures: Array<{ entity_id: string }>, actionPastTense: string) => {
    if (failures?.length) {
      UserNotification.error(`The archive config ${archiveConfig.id} could not be ${actionPastTense}.`);
    } else {
      UserNotification.success(`The archive config ${archiveConfig.id} was ${actionPastTense} successfully.`, 'Success');
    }
  }, [archiveConfig.id]);

  return (
    <StyledButtonToolbar className="instant-archiving-config-action-toolbar">
      <InstantArchivingConfigButton configurationId={archiveConfig.id} buttonType="menuItem" />
      <DeleteArchiveConfigAction handleFailures={handleFailures}
                                 selectedArchiveConfigIds={[archiveConfig.id]}
                                 refetchArchiveConfigs={refetchArchives} />
    </StyledButtonToolbar>
  );
};

export default InstantArchivingConfigActions;
