import throttle from 'lodash/throttle';

import { LOADING_ROW_HEIGHT } from './LogViewTable';
import type { TableRef } from './LogViewWidget';

const _thresholds = (tableScrollHeight: number, tableClientHeight: number) => ({
  bottomThreshold: Math.round((tableScrollHeight - tableClientHeight) / 5),
  topThreshold: LOADING_ROW_HEIGHT,
});

const _onTopOrBottomReach = throttle(({
  bottomThreshold,
  onBottomReach,
  onTopReach,
  tableClientHeight,
  tableScrollHeight,
  tableScrollTop,
  topThreshold,
}: {
  bottomThreshold: number,
  onBottomReach: () => void,
  onTopReach: () => void,
  tableClientHeight: number,
  tableScrollHeight: number,
  tableScrollTop: number,
  topThreshold: number,
}) => {
  if (tableScrollTop <= topThreshold) {
    onTopReach();
  }

  if (tableScrollHeight - tableScrollTop - tableClientHeight <= bottomThreshold) {
    onBottomReach();
  }
}, 500);

const _onBottomPageReach = ({
  bottomThreshold,
  lastPageHeight,
  onBottomPageReach,
  tableClientHeight,
  tableScrollHeight,
  tableScrollTop,
}: {
  bottomThreshold: number,
  lastPageHeight: number,
  onBottomPageReach: () => void,
  tableClientHeight: number,
  tableScrollHeight: number,
  tableScrollTop: number,
}) => {
  // check if user is about to reach the last page
  if (tableScrollHeight - tableScrollTop - tableClientHeight - bottomThreshold <= lastPageHeight) {
    onBottomPageReach();
  }
};

const onScroll = ({
  lastPageHeight,
  onBottomReach,
  onBottomPageReach,
  onTopReach,
  tableRef,
}: {
  lastPageHeight: number,
  onBottomReach: () => void,
  onBottomPageReach: () => void,
  onTopReach: () => void,
  tableRef: TableRef,
}) => {
  const tableScrollTop = tableRef.current.scrollTop;
  const tableScrollHeight = tableRef.current.scrollHeight;
  const tableClientHeight = tableRef.current.clientHeight;
  const { bottomThreshold, topThreshold } = _thresholds(tableScrollHeight, tableClientHeight);

  _onTopOrBottomReach({
    bottomThreshold,
    lastPageHeight,
    onBottomReach,
    onTopReach,
    tableClientHeight,
    tableScrollHeight,
    tableScrollTop,
    topThreshold,
  });

  _onBottomPageReach({
    bottomThreshold,
    lastPageHeight,
    onBottomPageReach,
    tableClientHeight,
    tableScrollHeight,
    tableScrollTop,
  });
};

export default onScroll;
