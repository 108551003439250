import * as React from 'react';
import { useState } from 'react';
import { FieldArray } from 'formik';
import styled from 'styled-components';

import { ControlLabel, HelpBlock, Button } from 'components/bootstrap';
import SchedulingFrequencyForm from 'report/report-configuration-page/SchedulingFrequencyForm';

import SchedulingFrequenciesList from './SchedulingFrequenciesList';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3px;
`;

type Props = {
  disabled: boolean,
};

const ReportScheduling = ({ disabled }: Props) => {
  const [showCreateModal, setShowCreateModal] = useState(false);

  return (
    <>
      <Header>
        <ControlLabel>Scheduling</ControlLabel>
        <Button bsSize="small"
                disabled={disabled}
                onClick={() => setShowCreateModal(true)}>
          Add frequency
        </Button>
      </Header>
      <FieldArray name="scheduling"
                  render={({ remove, replace, push }) => (
                    <div>
                      <SchedulingFrequenciesList onDelete={(index) => remove(index)}
                                                 onUpdate={(index, newSchedulingFrequency) => replace(index, newSchedulingFrequency)}
                                                 disabled={disabled} />

                      {showCreateModal && (
                        <SchedulingFrequencyForm title="Configure new scheduling frequency"
                                                 submitButtonText="Add frequency"
                                                 submitLoadingText="Adding frequency..."
                                                 onSubmit={(newSchedulingFrequency) => {
                                                   setShowCreateModal(false);
                                                   push(newSchedulingFrequency);
                                                 }}
                                                 onCancel={() => setShowCreateModal(false)} />
                      )}
                    </div>
                  )} />
      <HelpBlock>Configure how often this report should be sent out.</HelpBlock>
    </>
  );
};

export default ReportScheduling;
