import __request__ from 'routing/request';
interface EntitySuggestion {
    readonly id: string;
    readonly value: string;
}
interface PaginationInfo {
    readonly per_page: number;
    readonly total: number;
    readonly count: number;
    readonly page: number;
}
interface EntitySuggestionResponse {
    readonly pagination: PaginationInfo;
    readonly suggestions: EntitySuggestion[];
}
/**
 * Get a paginated list of suggested entities
 */
export function getPage(collection?: string, column: string = 'title', page: number = 1, per_page: number = 10, query?: string): Promise<EntitySuggestionResponse> {
    return __request__('GET', '/entity_suggestions', null, { 'collection': collection, 'column': column, 'page': page, 'per_page': per_page, 'query': query }, {
        'Accept': ['application/json']
    });
}
