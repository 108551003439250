import * as React from 'react';
import { useEffect } from 'react';

import { useStore } from 'stores/connect';

import LicenseTrafficViolationNotification from './LicenseTrafficViolationNotification';

import { LicenseTrafficViolationActions, LicenseTrafficViolationStore } from '../LicenseTrafficViolationStore';

const _loadTrafficViolation = () => {
  LicenseTrafficViolationActions.getTrafficViolation();
};

const LicenseTrafficViolationNotificationContainer = () => {
  const { acknowledged } = useStore(LicenseTrafficViolationStore);

  useEffect(() => _loadTrafficViolation(), []);

  const acknowledgeViolationNotification = () => {
    LicenseTrafficViolationActions.acknowledgeTrafficViolation();
  };

  const shouldShowNotification = acknowledged !== undefined && !acknowledged;

  return (
    <div>
      {shouldShowNotification && (
        <LicenseTrafficViolationNotification onDismissNotification={acknowledgeViolationNotification} />
      )}
    </div>
  );
};

export default LicenseTrafficViolationNotificationContainer;
