import * as Immutable from 'immutable';

import transformInaccessibleFilters from 'search-filter/logic/transformInaccessibleFilters';
import type View from 'views/logic/views/View';
import type { ViewStateMap } from 'views/logic/views/View';
import type Query from 'views/logic/queries/Query';
import createSearch from 'views/logic/slices/createSearch';

const transformInaccessibleFiltersInSearchTypes = (queries: Immutable.Set<Query>, userPermissions: Immutable.List<string>) => queries.map((query) => {
  const updatedSearchTypes = query.searchTypes.map((searchType) => ({
    ...searchType,
    filters: transformInaccessibleFilters(Immutable.List(searchType.filters), userPermissions).toArray(),
  }));

  return query.toBuilder().searchTypes(updatedSearchTypes).build();
});

const transformInaccessibleFiltersInWidgets = (viewStates: ViewStateMap, userPermissions: Immutable.List<string>) => viewStates.map((viewState) => {
  const updatedWidgets = viewState.widgets.map((widget) => {
    const updatedFilters = transformInaccessibleFilters(widget.filters, userPermissions);

    return widget.toBuilder().filters(Immutable.List(updatedFilters)).build();
  });

  return viewState.toBuilder().widgets(updatedWidgets.toList()).build();
});

const transformInaccessibleFiltersInDashboard = async (view: View, userPermissions: Immutable.List<string>) => {
  const updatedQueries = transformInaccessibleFiltersInSearchTypes(view.search.queries, userPermissions);
  const newViewState = transformInaccessibleFiltersInWidgets(view.state, userPermissions);
  const newSearchWithNewId = await createSearch(view.search.toBuilder().queries(updatedQueries.toSet()).build());

  return view.toBuilder().search(newSearchWithNewId).state(newViewState.toMap()).build();
};

export default transformInaccessibleFiltersInDashboard;
