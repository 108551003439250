import React from 'react';
import PropTypes from 'prop-types';

const LDAPAdapterSummary = ({ dataAdapter }) => {
  const { config } = dataAdapter;

  return (
    <dl>
      <dt>LDAP IP Address</dt>
      <dd>{config.ldap_ip}</dd>
      <dt>LDAP Port</dt>
      <dd>{config.ldap_port} </dd>
      <dt>LDAP Connect Timeout</dt>
      <dd>{config.ldap_connect_timeout}</dd>
      <dt>LDAP Pool Max Connections</dt>
      <dd>{config.ldap_pool_max_connections}</dd>
      <dt>Base DN</dt>
      <dd>{config.base_dn || 'n/a'}</dd>
      <dt>User DN</dt>
      <dd>{config.user_dn}</dd>
      <dt>User Password</dt>
      <dd>******</dd>
      <dt>Use TLS?</dt>
      <dd>{config.tls ? 'Yes' : 'No'}</dd>
      <dt>Verify certificate?</dt>
      <dd>{config.verify_certificate ? 'Yes' : 'No'}</dd>
    </dl>
  );
};

LDAPAdapterSummary.propTypes = {
  dataAdapter: PropTypes.shape({
    config: PropTypes.shape({
      ldap_ip: PropTypes.string.isRequired,
      base_dn: PropTypes.string.isRequired,
      ldap_connect_timeout: PropTypes.number.isRequired,
      user_dn: PropTypes.string.isRequired,
      user_passwd: PropTypes.object.isRequired,
      ldap_port: PropTypes.number.isRequired,
      tls: PropTypes.bool.isRequired,
      verify_certificate: PropTypes.bool.isRequired,
      ldap_pool_max_connections: PropTypes.number,
    }).isRequired,
  }).isRequired,
};

export default LDAPAdapterSummary;
