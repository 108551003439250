import React from 'react';
import PropTypes from 'prop-types';

import { Input } from 'components/bootstrap';

const ThreatFoxAdapterFieldSet = ({ config, updateConfig, handleFormEvent, validationMessage, validationState }) => {
  return (
    <fieldset>
      <Input type="checkbox"
             id="include_older_iocs"
             name="include_older_iocs"
             label="Include IOCs Older Than 90 Days"
             checked={config.include_older_iocs}
             onChange={handleFormEvent}
             wrapperClassName="col-md-offset-3 col-md-9" />
      <Input type="number"
             id="refresh_interval"
             name="refresh_interval"
             label="Refresh Interval (seconds)"
             required
             onChange={handleFormEvent}
             help={validationMessage('refresh_interval', 'How often to fetch new data from the source (minimum 3600 seconds).')}
             bsStyle={validationState('refresh_interval')}
             value={config.refresh_interval}
             labelClassName="col-sm-3"
             wrapperClassName="col-sm-9" />
      <Input type="checkbox"
             id="case_insensitive_lookup"
             name="case_insensitive_lookup"
             label="Case Insensitive Lookups"
             checked={config.case_insensitive_lookup}
             onChange={handleFormEvent}
             wrapperClassName="col-md-offset-3 col-md-9" />
    </fieldset>
  );
};

ThreatFoxAdapterFieldSet.propTypes = {
  config: PropTypes.shape({
    include_older_iocs: PropTypes.bool.isRequired,
    refresh_interval: PropTypes.number.isRequired,
    case_insensitive_lookup: PropTypes.bool.isRequired,
  }).isRequired,
  updateConfig: PropTypes.func.isRequired,
  handleFormEvent: PropTypes.func.isRequired,
  validationState: PropTypes.func.isRequired,
  validationMessage: PropTypes.func.isRequired,
};

export default ThreatFoxAdapterFieldSet;
