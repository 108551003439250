import type { ColumnFilterData, FilterData } from './ColumnFilter.types';

// eslint-disable-next-line import/prefer-default-export
export function stringifyFilters(filters: ColumnFilterData) {
  if (!filters || Object.keys(filters).length === 0) return '';

  const fDataByType = (fData: FilterData[]) => {
    const strFData = fData.map(({ value }: { value: string }) => value).join(',');

    return fData[0].type === 'string' ? `'${strFData}'` : strFData;
  };

  return Object.entries(filters)
    .map(([column, fData]: [string, FilterData[]]) => `${column}:${fDataByType(fData)}`)
    .join(';');
}
