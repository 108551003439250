import * as React from 'react';
import styled, { css } from 'styled-components';

import { EmptyList, GLCheckbox } from 'security-app/components/common';
import type { InvestigationAPIType } from 'security-app/hooks/api/investigationsAPI.types';
import { usePagination, useSelectedRows, useSelectedRowsDispatch } from 'security-app/components/common/contexts';

const TD = styled.td<{ $main?: boolean }>(({ theme, $main }) => css`
  vertical-align: middle !important;
  max-width: 300px;
  padding: 16px 8px !important;
  color: ${$main ? theme.colors.global.link : theme.colors.brand.tertiary};
  cursor: ${$main ? 'pointer' : 'normal'};
  font-weight: ${$main ? 'bold' : 'regular'};

  &:hover {
    color: ${$main ? theme.colors.global.linkHover : theme.colors.brand.tertiary};
    text-decoration: ${$main ? 'underline' : 'none'};
  }

  &:not(:last-child) {
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`);

type Props = {
  investigations: InvestigationAPIType[],
};

function ListRows({ investigations }: Props) {
  const localPagination = usePagination();
  const selectedInvestigations = useSelectedRows();
  const selectedInvestigationsDispatch = useSelectedRowsDispatch();

  const onChange = (investigation: InvestigationAPIType) => (e: React.BaseSyntheticEvent) => {
    if (e.target.checked) {
      selectedInvestigationsDispatch({ type: 'add', payload: [investigation] });
    } else {
      selectedInvestigationsDispatch({ type: 'remove', payload: [investigation] });
    }
  };

  const isSelected = (investigation: InvestigationAPIType) => (
    !!selectedInvestigations.find((sInv: InvestigationAPIType) => investigation.id === sInv.id)
  );

  return investigations.length < 1 ? (
    <EmptyList query={localPagination.query}
               filters={localPagination.filters}
               noSearchResultText="No Investigations found for the provided criteria"
               noEntitiesExist="Currently there are no Investigations to list"
               colSpan={3} />
  ) : (
    <>
      {investigations.map((investigation: InvestigationAPIType) => (
        <tr key={`investigation-row-${investigation.id}`} data-testid="open-investigation-row">
          <TD><GLCheckbox checked={isSelected(investigation)}
                          onChange={onChange(investigation)}
                          data-testid="investigation-checkbox" />
          </TD>
          <TD $main data-testid="investigation-name">
            {investigation.name}
          </TD>
          <TD>{investigation.status}</TD>
        </tr>
      ))}
    </>
  );
}

export default ListRows;
