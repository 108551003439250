import { useQuery } from '@tanstack/react-query';

import type { Attribute, SearchParams } from 'stores/PaginationTypes';
import UserNotification from 'util/UserNotification';
import type { InstantArchive } from 'instant-archiving/Types';
import InstantArchivingStore from 'instant-archiving/InstantArchivingStore';

const INITIAL_DATA = {
  pagination: { total: 0 },
  elements: [],
  attributes: [],
};

type Options = {
  enabled: boolean,
}
const refetchInterval = 5000;

const useInstantArchivings = (searchParams: SearchParams, { enabled }: Options = { enabled: true }): {
  data: {
    elements: Array<InstantArchive>,
    pagination: { total: number }
    attributes: Array<Attribute>
  },
  refetch: () => void,
  isInitialLoading: boolean,
} => {
  const { data, refetch, isInitialLoading } = useQuery(
    ['instant-archiving', 'overview', searchParams],
    () => InstantArchivingStore.searchPaginated(
      searchParams.page,
      searchParams.pageSize,
      searchParams.query,
      {
        sort: searchParams?.sort.attributeId,
        order: searchParams?.sort.direction,
      }),
    {
      onError: (errorThrown) => {
        UserNotification.error(`Loading Instant Archiving failed with status: ${errorThrown}`,
          'Could not load Instant Archiving');
      },
      keepPreviousData: true,
      refetchInterval,
      notifyOnChangeProps: ['data', 'error'],
      enabled,
    },
  );

  return ({
    data: data ?? INITIAL_DATA,
    refetch,
    isInitialLoading,
  });
};

export default useInstantArchivings;
