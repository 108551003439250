import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import lowerCase from 'lodash/lowerCase';

import useInstantArchivingConfig from 'instant-archiving/InstantArchivingConfig/hooks/useInstantArchivingConfig';
import {
  useUpdateInstantArchivingConfig,
} from 'instant-archiving/InstantArchivingConfig/hooks/useInstantArchivingConfigMutation';
import type { InstantArchive } from 'instant-archiving/Types';
import { Label, BootstrapModalConfirm } from 'components/bootstrap';
import { Icon } from 'components/common';

type Props = {
  archive: InstantArchive,
};
const StatusLabel = styled(Label)(({ $clickable }: { $clickable: boolean }) => css`
  cursor: ${$clickable ? 'pointer' : 'default'};
  display: inline-flex;
  justify-content: center;
  gap: 4px;
`);

const Spacer = styled.div`
  border-left: 1px solid currentColor;
  height: 1em;
`;

const ArchiverStatus = ({ archive }: Props) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const { data: configuration, isSuccess } = useInstantArchivingConfig(archive.archive_config_id);
  const { mutateAsync } = useUpdateInstantArchivingConfig();
  const status = isSuccess && configuration?.enabled;

  const archiverStatus = () => {
    if (!isSuccess || !configuration) {
      return 'unbound';
    }

    return status ? 'Running' : 'Stopped';
  };

  const title = status ? 'Stop the archiver' : 'Start the archiver';

  const handleStatusChange = () => {
    const updatedConfig = { ...configuration, enabled: !status };
    mutateAsync(updatedConfig);
    setShowConfirmDialog(false);
  };

  return (
    <>
      <StatusLabel bsStyle={status ? 'success' : 'warning'}
                   onClick={() => setShowConfirmDialog(true)}
                   title={isSuccess && title}
                   aria-label={isSuccess && title}
                   role="button"
                   $clickable={isSuccess}>
        {archiverStatus()}

        {isSuccess && (
          <>
            <Spacer />
            <Icon name={configuration?.enabled ? 'pause' : 'play'} />
          </>
        )}
      </StatusLabel>
      {showConfirmDialog && (
        <BootstrapModalConfirm showModal={showConfirmDialog}
                               onConfirm={() => handleStatusChange()}
                               onCancel={() => setShowConfirmDialog(false)}
                               title="Are you sure?">
          <>
            <p>
              You are about to {lowerCase(title)} for <b>{archive.archive_name}</b>.
            </p>

            <p>Do you want to continue?</p>
          </>
        </BootstrapModalConfirm>
      )}
    </>
  );
};

export default ArchiverStatus;
