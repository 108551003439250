import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import URI from 'urijs';
import OktaSignIn from '@okta/okta-signin-widget';
import type { WidgetOptions } from '@okta/okta-signin-widget';
import get from 'lodash/get';

import AppConfig from 'util/AppConfig';
import { Spinner } from 'components/common';
import { FormGroup } from 'components/bootstrap';

import OktaContainerStyles from './OktaContainerStyles';
import CustomChosenStyles from './external-assets/CustomChosenStyles';
import { LoginMetricsActions } from './LoginMetricsStore';
import { createFailureLoginMetric, GRAYLOG_ERROR_CODES } from './LoginMetrics';

const getCallbackUri = () => {
  // Initialize URI this way to avoid keeping current path, query or hashes in the callback
  const redirectUri = new URI(new URI().origin());
  redirectUri.path(AppConfig.gl2AppPathPrefix());
  redirectUri.segment('authorization-code/callback');

  return redirectUri.href();
};

const getExternalIdPConfig = (externalIdPId) => {
  if (!externalIdPId) {
    return null;
  }

  return {
    idpDisplay: 'PRIMARY' as WidgetOptions['idpDisplay'],
    idps: [{ id: `${externalIdPId}`, text: 'Log in via external SAML IdP' }],
  };
};

type OidcLoginFormProps = {
  configuration: {
    oauth_client_id: string;
    base_url: string;
    okta_org_url: string,
    okta_external_idp_id: string,
  };
  nonce: {
    state: string;
    redirectTo: string;
  };
  checkSession?: boolean;
};

const OidcLoginForm = ({ configuration, nonce, checkSession = false }: OidcLoginFormProps) => {
  const widget = useRef<OktaSignIn>();
  const [sessionState, setSessionState] = useState<'INITIAL' | 'CHECKING' | 'DONE'>(
    checkSession ? 'INITIAL' : 'DONE',
  );
  const widgetReady = useRef(false);
  const { base_url: baseUrl, oauth_client_id: oauthClientId, okta_external_idp_id: externalIdpId } = configuration;

  useEffect(() => {
    let shouldCleanUp = false;

    const cleanUp = () => {
      widget.current.off();
      widget.current.remove();

      widgetReady.current = false;
    };

    // eslint-disable-next-line no-console
    const onError = (err) => console.error(err);

    widget.current = new OktaSignIn({
      baseUrl,
      clientId: oauthClientId,
      redirectUri: getCallbackUri(),
      useClassicEngine: true,
      authParams: {
        issuer: baseUrl,
        authorizeUrl: `${baseUrl}/v1/authorize`,
        scopes: ['openid', 'profile', 'email'],
        state: nonce.state,
        responseType: 'code',
        pkce: false,
        // we don't use nonce on the backend so set to `null`
        nonce: null,
      },
      language: 'en',
      i18n: {
        en: {
          'primaryauth.username.placeholder': 'Email',
          'primaryauth.username.tooltip': 'Email',
          'password.forgot.email.or.username.placeholder': 'Email',
          'password.forgot.email.or.username.tooltip': 'Email',
          'error.username.required': 'Please enter an email address',
          'errors.E0000004': 'Invalid email or password',
          'mfa.factors.dropdown.sr.text': 'Using {0}, verify another way?',
          'mfa.factors.dropdown.title': 'Select a verification method',
          'email.code.not.received': 'Haven\'t received an email? ',
        },
      },
      features: {
        hideSignOutLinkInMFA: true,
        webauthn: true,
        multiOptionalFactorEnroll: false,
      },
      ...getExternalIdPConfig(externalIdpId),
    },
    );

    widget.current.on('ready', () => {
      if (shouldCleanUp) {
        cleanUp();

        return;
      }

      widgetReady.current = true;
    });

    widget.current.on('afterError', (context, error) => {
      if (context.controller !== 'primary-auth') {
        return;
      }

      const oktaErrorCode = get(error, 'xhr.responseJSON.errorCode', undefined);

      if (oktaErrorCode) {
        LoginMetricsActions.post(createFailureLoginMetric('login', 'okta', oktaErrorCode));

        return;
      }

      const statusCode = error?.statusCode;

      if (statusCode) {
        LoginMetricsActions.post(createFailureLoginMetric('login', 'http', statusCode));

        return;
      }

      LoginMetricsActions.post(createFailureLoginMetric('login', 'gl', GRAYLOG_ERROR_CODES.oktaUnknownError));
    });

    widget.current.showSignInAndRedirect({ el: '#okta-root' }).catch(onError);

    if (checkSession) {
      setSessionState('CHECKING');

      widget.current.authClient.session.exists()
        .then((exists) => {
          if (exists) {
            const loginUri = new URI(baseUrl);
            loginUri.segment('v1/authorize');

            loginUri.query({
              client_id: oauthClientId,
              response_type: 'code',
              scope: 'openid profile email',
              redirect_uri: getCallbackUri(),
              state: nonce.state,
            });

            window.location.assign(loginUri.href());
          } else {
            setSessionState('DONE');
          }
        });
    }

    return () => {
      if (widget.current && widgetReady.current) {
        cleanUp();

        return;
      }

      // Could not clean up at this stage, ready callback will take care of it
      shouldCleanUp = true;
    };
  }, [checkSession, oauthClientId, baseUrl, nonce.state, externalIdpId]);

  const isBusy = sessionState !== 'DONE';

  return (
    <FormGroup>
      {isBusy && <Spinner />}
      <CustomChosenStyles />
      {/* This DOM element needs to exist at any time because of Okta's bindings to the original DOM elements */}
      <OktaContainerStyles key="okta-root" id="okta-root" style={{ display: isBusy ? 'none' : 'block' }} />
    </FormGroup>
  );
};

OidcLoginForm.defaultProps = {
  checkSession: false,
};

OidcLoginForm.propTypes = {
  configuration: PropTypes.shape({
    base_url: PropTypes.string.isRequired,
    oauth_client_id: PropTypes.string.isRequired,
    okta_org_url: PropTypes.string.isRequired,
    okta_external_idp_id: PropTypes.string,
  }).isRequired,
  nonce: PropTypes.shape({
    state: PropTypes.string.isRequired,
    redirectTo: PropTypes.string.isRequired,
  }).isRequired,
  checkSession: PropTypes.bool,
};

export default OidcLoginForm;
