import * as React from 'react';

import type View from 'views/logic/views/View';
import { MenuItem } from 'components/bootstrap';
import { IfPermitted } from 'components/common';
import useLicenseCheck from 'license/useLicenseCheck';
import type { ModalHandler } from 'security-app/components/Investigations/ModalHandler.type';
import { useInvestigationDrawer } from 'security-app/components/Investigations/contexts';
import { useAddEvidence, useGetInvestigationDetails } from 'security-app/hooks/useInvestigationsAPI';

import AddEvidenceModal from './shared/AddEvidenceModal';

type Props = {
  dashboard: View,
  modalRef: () => ModalHandler,
};

export const InvestigationsModal = React.forwardRef(({ dashboard }: { dashboard: View }, ref: (r: ModalHandler) => void) => {
  const { security } = useLicenseCheck();

  return security?.isValid && (
    <IfPermitted permissions="investigations:edit">
      <AddEvidenceModal id={dashboard.id} type="dashboards" ref={ref} />
    </IfPermitted>
  );
});

const InvestigationsDashboardAction = ({ dashboard, modalRef }: Props) => {
  const { security } = useLicenseCheck();

  const { selectedInvestigationId, setCollapsed } = useInvestigationDrawer();
  const { currentInvestigation } = useGetInvestigationDetails(selectedInvestigationId);

  const { addEvidence } = useAddEvidence();

  const disabledAdd = !currentInvestigation || currentInvestigation?.archived;

  const onAddDashboard = async () => {
    if (disabledAdd) return;

    await addEvidence({
      invId: selectedInvestigationId,
      payload: { dashboards: [dashboard.id] },
    });

    setCollapsed(false);
  };

  return security?.isValid && (
    <IfPermitted permissions="investigations:edit">
      <MenuItem disabled={disabledAdd} onClick={onAddDashboard} icon="puzzle-piece">
        Add to investigation
      </MenuItem>
      <MenuItem icon="puzzle-piece" onClick={() => modalRef()?.toggle()}>
        Select an investigation
      </MenuItem>
    </IfPermitted>
  );
};

export default InvestigationsDashboardAction;
