import React from 'react';

import { ExternalLink } from 'components/common';

const URLhausAdapterDocumentation = () => {
  return (
    <div>
      <p>The abuse.ch URLhaus tracker maintains a database of malicious URLs used for malware distribution.
        You can learn more by visiting the official URLhaus site <ExternalLink href="https://urlhaus.abuse.ch/">here</ExternalLink>
      </p>
    </div>
  );
};

export default URLhausAdapterDocumentation;
