import React from 'react';
import styled from 'styled-components';
import type * as Immutable from 'immutable';
import pick from 'lodash/pick';

import { MenuItem } from 'components/bootstrap';
import HasOwnership from 'components/common/HasOwnership';
import copyToClipboard from 'util/copyToClipboard';
import Routes from 'routing/Routes';
import { isPermitted, isAnyPermitted } from 'util/PermissionsMixin';
import useCurrentUser from 'hooks/useCurrentUser';
import { Link } from 'components/common/router';
import type { SelectedSearchFilter as SelectedSearchFilterType } from 'search-filter/types';
import { INLINE_SEARCH_FILTER_TYPE, REFERENCED_USER_SEARCH_FILTER_TYPE } from 'search-filter/constants';
import useSearchFiltersFormActions from 'search-filter/hooks/useSearchFiltersFormActions';
import useUserSearchFilterMutation from 'search-filter/hooks/useUserSearchFilterMutation';
import useSearchFiltersFormState from 'search-filter/hooks/useSearchFiltersFormState';
import { CurrentUserStore } from 'stores/users/CurrentUserStore';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'logic/telemetry/Constants';

const EditMenuItem = ({
  onClick,
  isReferencedFilter,
  filterId,
}: {
  onClick: () => void,
  isReferencedFilter: boolean,
  filterId: string,
}) => {
  const currentUser = useCurrentUser();
  const disabled = isReferencedFilter && !isPermitted(currentUser.permissions, [`search_filters:edit:${filterId}`]);

  return <MenuItem onSelect={onClick} icon="edit" disabled={disabled}>Edit</MenuItem>;
};

const getFilterReferenceExplanation = (userPermissions: Immutable.List<string>, filterId: string) => {
  if (isPermitted(userPermissions, `search_filters:delete:${filterId}`)) {
    return (
      <>This <Link to={Routes.pluginRoute('MY-FILTERS_DETAILS_FILTERID')(filterId)}>saved filter</Link> is part of
        your <Link to={Routes.pluginRoute('MY-FILTERS')}>&quot;My Filters&quot;</Link> collection.
      </>
    );
  }

  if (isAnyPermitted(userPermissions, [`search_filters:edit:${filterId}`, `search_filters:read:${filterId}`])) {
    return 'This saved filter has been shared with you.';
  }

  return 'You can use this saved filter because it is part of the search.';
};

const ReferenceExplanation = styled.div`
  font-style: italic;
  padding: 0 20px 5px 20px;
  font-size: ${({ theme }) => theme.fonts.size.small};
`;

const ReferencedFilterExplanation = ({ filterId }: { filterId }) => {
  const currentUser = useCurrentUser();

  return (
    <ReferenceExplanation>
      {getFilterReferenceExplanation(currentUser.permissions, filterId)}
    </ReferenceExplanation>
  );
};

const transformToInlineFilter = (filter: SelectedSearchFilterType) => {
  const updatedFilter = { ...filter };
  delete updatedFilter.id;
  updatedFilter.type = INLINE_SEARCH_FILTER_TYPE;

  return updatedFilter;
};

type Props = {
  onItemClick: () => void,
  filter: SelectedSearchFilterType,
  onEditClick: () => void,
  onShareClick: () => void,
}

const SearchFilterActionsPopover = ({
  filter,
  filter: { id, queryString, frontendId, negation, type },
  onItemClick,
  onEditClick,
  onShareClick,
}: Props) => {
  const { updateFilters, updateFilter } = useSearchFiltersFormActions();
  const selectedFilters = useSearchFiltersFormState();
  const { post } = useUserSearchFilterMutation();
  const sendTelemetry = useSendTelemetry();
  const isReferenced = type === REFERENCED_USER_SEARCH_FILTER_TYPE;

  const copyFilterQueryToClipboard = () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.SEARCH_FILTER_ITEM_COPIED, {
      app_pathname: 'search',
      app_section: 'search-filter',
    });

    copyToClipboard(queryString);
    onItemClick();
  };

  const removeFilter = () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.SEARCH_FILTER_ITEM_REMOVED, {
      app_pathname: 'search',
      app_section: 'search-filter',
    });

    updateFilters(selectedFilters.delete(frontendId));
    onItemClick();
  };

  const saveAsReferenced = () => post({ data: pick(filter, ['queryString', 'title', 'description']) }).then((data: SelectedSearchFilterType) => {
    CurrentUserStore.reload();

    updateFilter(frontendId, {
      ...data,
      negation,
      frontendId: filter.frontendId,
      type: REFERENCED_USER_SEARCH_FILTER_TYPE,
    });

    onItemClick();
  });

  const onTransformToInline = () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.SEARCH_FILTER_ITEM_REFERENCE_REPLACED, {
      app_pathname: 'search',
      app_section: 'search-filter',
    });

    updateFilter(frontendId, transformToInlineFilter(filter));
    onItemClick();
  };

  const toggleNegation = () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.SEARCH_FILTER_ITEM_NEGATION_TOGGLED, {
      app_pathname: 'search',
      app_section: 'search-filter',
      event_details: {
        included: !negation,
      },
    });

    updateFilter(frontendId, { ...filter, negation: !negation });
    onItemClick();
  };

  return (
    <>
      <EditMenuItem onClick={onEditClick} isReferencedFilter={isReferenced} filterId={id} />
      <MenuItem onSelect={toggleNegation} icon={`${negation ? 'folder-plus' : 'folder-minus'}`}>
        {` ${negation ? 'Include in' : 'Exclude from'} results`}
      </MenuItem>
      <MenuItem onSelect={removeFilter} icon="trash">Remove from search</MenuItem>
      {isReferenced && (
        <HasOwnership id={id} type="search_filter">
          {({ disabled }) => (
            <MenuItem onSelect={onShareClick} icon="user-plus" disabled={disabled}>
              Share
            </MenuItem>
          )}
        </HasOwnership>
      )}
      <MenuItem onSelect={copyFilterQueryToClipboard} icon="clipboard">
        Copy query to clipboard
      </MenuItem>
      {isReferenced && (
        <MenuItem onSelect={onTransformToInline} icon="exchange">
          Replace reference with copy
        </MenuItem>
      )}
      {!isReferenced && (
        <MenuItem onSelect={saveAsReferenced} icon="save">
          Save to &quot;My Filters&quot;
        </MenuItem>
      )}
      {isReferenced && (
        <>
          <MenuItem divider />
          <ReferencedFilterExplanation filterId={id} />
        </>
      )}
    </>
  );
};

export default SearchFilterActionsPopover;
