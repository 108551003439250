import * as React from 'react';
import styled from 'styled-components';

import type Parameter from 'views/logic/parameters/Parameter';
import ParameterInput from 'enterprise/parameters/components/ParameterInput';

const ParameterInputRow = styled.div`
  &:first-child {
    margin-top: 10px;
  }

  .form-group {
    display: flex;
    align-items: baseline;
  }
`;

type Props = {
  parameters: Array<Parameter>,
  parameterSearchIds: { [key: string]: string | undefined },
  parameterValues: { [key: string]: any },
  onChange: (name: string, value: any) => void,
};

const ReportParameters = ({ parameters, parameterValues, onChange, parameterSearchIds }: Props) => (
  <dl>
    <dt>Required Parameters</dt>
    <dd>
      {parameters.length === 0 && 'No widgets with parameters used in the report.'}
      {parameters.map((parameter) => (
        <ParameterInputRow key={parameter.name}>
          <ParameterInput parameter={parameter}
                          searchId={parameterSearchIds[parameter.name]}
                          value={parameterValues[parameter.name]}
                          onChange={onChange} />
        </ParameterInputRow>
      ))}
    </dd>
  </dl>
);

ReportParameters.propTypes = {};

export default ReportParameters;
