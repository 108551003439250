import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 10px;

  img {
    border: 1px #e3e3e3 solid;
    max-width: 150px;
    max-height: 150px;
  }
`;

type Props = {
  imageSrc: string,
};

const Logo = ({ imageSrc }: Props) => (
  <Container>
    <img src={imageSrc} alt="report-logo" />
  </Container>
);

export default Logo;
