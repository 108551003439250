import React, { useCallback } from 'react';

import InstantArchivingActions from 'instant-archiving/InstantArchivingOverview/InstantArchivingActions';
import BulkActions from 'instant-archiving/InstantArchivingOverview/BulkActions';

const useTableElements = () => {
  const entityActions = useCallback((archive) => (
    <InstantArchivingActions archive={archive} />
  ), []);

  const bulkActions = useCallback((
    selectedArchiveIds: Array<string>,
    setSelectedArchiveIds: (streamIds: Array<string>) => void,
  ) => (
    <BulkActions selectedArchiveIds={selectedArchiveIds} setSelectedArchiveIds={setSelectedArchiveIds} />
  ), []);

  return {
    entityActions,
    bulkActions,
  };
};

export default useTableElements;
