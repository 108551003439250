import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Row, Col } from 'components/bootstrap';
import { PageHeader, DocumentTitle, Spinner, NoEntitiesExist } from 'components/common';
import InstantArchiveSummary from 'instant-archiving/components/InstantArchiveSummary';
import InstantArchiveRestore from 'instant-archiving/components/InstantArchiveRestore';
import useInstantArchiving from 'instant-archiving/hooks/useInstantArchiving';
import InstantArchivingPageNavigation from 'instant-archiving/components/InstantArchivingPageNavigation';
import InstantArchiveDetailsPageSubactions from 'instant-archiving/components/InstantArchiveDetailsPageSubactions';
import InstantArchivingLicenseStatusContainer from 'instant-archiving/InstantArchivinLicenseStatusContainer';
import InstantArchivingJobs from 'instant-archiving/components/InstantArchivingJobs';
import type { LicenseStatus } from 'archive/types';
import { useStore } from 'stores/connect';
import ArchiveLicenseStore from 'archive/ArchiveLicenseStore';
import ArchiveLicenseActions from 'archive/ArchiveLicenseActions';

const InstantArchiveDetailsPage = () => {
  const params = useParams<{
    archiveId?: string
  }>();
  const { archiveId } = params;
  const licenseStatus: LicenseStatus = useStore(ArchiveLicenseStore);
  const {
    instantArchiveData: { data: archive, isFetching, error },
    onDeleteInstantArchiveRestoredOperation,
  } = useInstantArchiving(archiveId);

  useEffect(() => {
    ArchiveLicenseActions.getLicenseStatus();
  }, []);

  if (isFetching) {
    return (
      <DocumentTitle title="View Instant Archive">
        <span>
          <PageHeader title="View Instant Archive">
            <Spinner text="Loading Instant Archive..." />
          </PageHeader>
        </span>
      </DocumentTitle>
    );
  }

  if (error) {
    return (
      <DocumentTitle title="View Instant Archive">
        <PageHeader title="View Instant Archive" />
        <Row className="content">
          <Col md={12}>
            <NoEntitiesExist>No Archive with id {archiveId} found.</NoEntitiesExist>
          </Col>
        </Row>
      </DocumentTitle>
    );
  }

  return (
    <DocumentTitle title={`View ${archive.archive_name} Instant Archive`}>
      <InstantArchivingPageNavigation />
      <PageHeader title={`View ${archive.archive_name} Instant Archive`}
                  actions={(
                    <InstantArchiveDetailsPageSubactions archiveId={archive.id}
                                                         licenseStatus={licenseStatus}
                                                         archiveStreams={archive.stream_map}
                                                         configurationId={archive.archive_config_id} />
                  )}>
        <span>
          Instant Archiving
        </span>
      </PageHeader>
      <InstantArchivingLicenseStatusContainer />
      <InstantArchivingJobs />
      <Row className="content">
        <Col md={12}>
          <InstantArchiveSummary archive={archive} />
        </Col>
      </Row>
      <Row className="content">
        <Col md={12}>
          <InstantArchiveRestore archive={archive} onDeleteOperation={onDeleteInstantArchiveRestoredOperation} />
        </Col>
      </Row>
    </DocumentTitle>
  );
};

export default InstantArchiveDetailsPage;
