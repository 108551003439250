import __request__ from 'routing/request';
/**
 * Get API documentation
 */
export function overview(): Promise<unknown> {
    return __request__('GET', '/api-docs', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get API documentation with cluster global URI path
 */
export function globalOverview(): Promise<unknown> {
    return __request__('GET', '/api-docs/global', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get detailed API documentation of a single resource
 * @param route Route to fetch. For example /system
 */
export function route(route: string): Promise<unknown> {
    return __request__('GET', `/api-docs/${route}`, null, {}, {
        'Accept': ['application/json']
    });
}
