import * as React from 'react';
import styled, { css } from 'styled-components';

import { Icon } from 'components/common';
import { GLCheckbox } from 'security-app/components/common';
import { Backdrop, BrandDropdown } from 'security-app/components/common/BrandedDropdown';

import type { FilterData, ColumnFilterData } from './ColumnFilter.types';
import DateFilter from './DateFilter';

const FilterTrigger = styled.span<{ $open?: boolean }>(({ $open, theme }) => css`
  cursor: pointer;
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  color: ${theme.colors.global.textDefault};

  & > svg {
    transition: rotate 0.2s;
    rotate: ${$open ? 180 : 0}deg;
  }
`);

const FilterItem = styled.li(({ theme }) => css`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  padding: 5px;

  font-weight: normal;
  color: ${theme.colors.global.textDefault};
`);

type Props = {
  filterBy: string,
  type: 'text' | 'date',
  filterData: FilterData[],
  appliedFilters: ColumnFilterData,
  onClose: (filterKey: string, filterValue: FilterData[]) => void,
  children: React.ReactNode,
};

const ColumnFilter = ({ filterBy, filterData, appliedFilters, type, onClose, children }: Props) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [filterValues, setFilterValues] = React.useState<FilterData[]>(appliedFilters[filterBy] || []);

  React.useEffect(() => {
    setFilterValues(appliedFilters[filterBy] || []);
  }, [filterBy, appliedFilters]);

  const closeMenu = (event: React.BaseSyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    onClose(filterBy, (filterValues.length > 0 ? filterValues : null));
    setOpen(false);
  };

  const filterIncluded = (fData: FilterData) => !!filterValues.find(({ value }: { value: string }) => value === fData.value);

  const onFilterChange = (fData: FilterData) => (event: React.BaseSyntheticEvent) => {
    event.stopPropagation();

    if (filterIncluded(fData)) {
      setFilterValues(filterValues.filter(({ value }: { value: string }) => value !== fData.value));
    } else {
      setFilterValues([...filterValues, fData]);
    }
  };

  const filterByType = () => {
    switch (type) {
      case 'date':
        return <DateFilter filterValues={filterValues} setFilterValues={setFilterValues} />;
      default:
        return filterData.filter(({ value }: { value: string}) => !!value).map((fData: FilterData) => (
          <FilterItem key={`filter-${filterBy}-${fData.value}`}>
            <GLCheckbox onChange={onFilterChange(fData)}
                        checked={filterIncluded(fData)}
                        aria-label={`filter-${fData.label}`} />
            <span tabIndex={0}
                  role="checkbox"
                  aria-checked={filterIncluded(fData)}
                  onClick={onFilterChange(fData)}
                  onKeyPress={(e) => { if (e.key === 'enter') onFilterChange(fData); }}>
              {fData.label}
            </span>
          </FilterItem>
        ));
    }
  };

  const showFilters = React.useMemo(() => (type === 'text' && filterData && filterData.length > 1)
      || (type === 'date' && filterData && filterData.length > 0), [filterData, type]);

  return showFilters ? (
    <FilterTrigger $open={open} onClick={() => setOpen(!open)}>
      <Icon name="angle-down" size="xs" />
      {children}
      <BrandDropdown show={open}
                     $posx="right"
                     $posy="bottom"
                     data-testid="menu-body"
                     onClick={(e: React.BaseSyntheticEvent) => e.stopPropagation()}>
        {filterByType()}
      </BrandDropdown>
      <Backdrop data-testid="backdrop"
                aria-label={`filter-backdrop-${filterBy}`}
                $show={open}
                onClick={closeMenu} />
    </FilterTrigger>
  ) : <span>{children}</span>;
};

export default ColumnFilter;
