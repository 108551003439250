import React from 'react';
import PropTypes from 'prop-types';

const URLhausAdapterSummary = ({ dataAdapter }) => {
  const { config } = dataAdapter;

  return (
    <dl>
      <dt>URLhaus Feed Type</dt>
      <dd>{config.feed_type}</dd>
      <dt>Refresh Interval</dt>
      <dd>{config.refresh_interval} seconds </dd>
      <dt>Case Insensitive Lookups</dt>
      <dd>{config.case_insensitive_lookup ? 'Yes' : 'No'}</dd>
    </dl>
  );
};

URLhausAdapterSummary.propTypes = {
  dataAdapter: PropTypes.shape({
    config: PropTypes.shape({
      feed_type: PropTypes.string.isRequired,
      refresh_interval: PropTypes.number.isRequired,
      case_insensitive_lookup: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
};

export default URLhausAdapterSummary;
