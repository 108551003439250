import * as React from 'react';
import styled from 'styled-components';

import { Button } from 'components/bootstrap';
import { SearchForm } from 'security-app/components/common';
import { usePagination, useSetPagination } from 'security-app/components/common/contexts';
import type { ModalHandler } from 'security-app/components/Investigations/ModalHandler.type';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
`;

type Props = {
  onNewInvestigation: () => void,
  newModalRef: React.MutableRefObject<ModalHandler>,
};

function SearchRow({ onNewInvestigation, newModalRef }: Props) {
  const pagination = usePagination();
  const setPagination = useSetPagination();

  const onNew = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onNewInvestigation();
    newModalRef.current.toggle();
  };

  const onSearch = (query: string = null) => {
    setPagination({ ...pagination, page: 1, query });
  };

  return (
    <Row>
      <SearchForm onSearch={onSearch}
                  onReset={onSearch}
                  placeholder="Search for investigations by name..." />
      <Button bsStyle="primary" data-testid="new-btn" onClick={onNew}>
        New Investigation
      </Button>
    </Row>
  );
}

export default SearchRow;
