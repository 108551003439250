import ArchiveBackendsPage from 'archive/ArchiveBackendsPage';
import ArchivePage from 'archive/ArchivePage';
import ArchiveConfigurationPage from 'archive/ArchiveConfigurationPage';
import ArchiveBackendConfigurationPage from 'archive/ArchiveBackendConfigurationPage';
import ArchiveRetentionStrategySummary from 'archive/components/ArchiveRetentionStrategySummary';
import ArchiveRetentionStrategyConfiguration from 'archive/components/ArchiveRetentionStrategyConfiguration';

export default {
  routes: [
    { path: '/system/archives/configuration', component: ArchiveConfigurationPage },
    { path: '/system/archives/backends', component: ArchiveBackendsPage },
    { path: '/system/archives/backends/new', component: ArchiveBackendConfigurationPage },
    { path: '/system/archives/backends/:backendId', component: ArchiveBackendConfigurationPage },
    { path: '/system/archives', component: ArchivePage },
  ],
  indexRetentionConfig: [
    {
      type: 'org.graylog.plugins.archive.indexer.retention.strategies.ArchiveRetentionStrategy',
      displayName: 'Archive Index',
      configComponent: ArchiveRetentionStrategyConfiguration,
      summaryComponent: ArchiveRetentionStrategySummary,
    },
  ],
};
