import * as Immutable from 'immutable';

import type { ConfigFormValues } from 'authentication/components/oidc/config/types';
import type { OktaTeamSyncConfigJson } from 'logic/authentication/okta/types';

const prepareOktaGroupSyncConfig = (formValues: ConfigFormValues, backendId: string, serviceType: string): OktaTeamSyncConfigJson => {
  const {
    teamSelectionType,
    teamSelection = Immutable.Set(),
    defaultRoles,
    oktaApiToken,
  } = formValues;

  return {
    auth_service_backend_id: backendId,
    selection_type: teamSelectionType,
    selection: teamSelection.toJS(),
    default_roles: defaultRoles ? defaultRoles.split(',') : [],
    config: {
      type: serviceType,
      okta_api_token: typeof oktaApiToken === 'undefined' ? { keep_value: true } : { set_value: oktaApiToken },
    },
  };
};

export default prepareOktaGroupSyncConfig;
