import type { Sort } from 'stores/PaginationTypes';

export const ENTITY_TABLE_ID = 'archives';
export const DEFAULT_LAYOUT = {
  pageSize: 20,
  sort: { attributeId: 'archive_name', direction: 'desc' } as Sort,
  displayedColumns: ['archive_name', 'message_count', 'restored_indices', 'timestamp_from', 'timestamp_to', 'archiver_status'],
  columnsOrder: ['archive_name', 'message_count', 'restored_indices', 'timestamp_from', 'timestamp_to', 'archiver_status'],
};

export const ADDITIONAL_ATTRIBUTES = [
  { id: 'restored_indices', title: 'Restore Operations', hidden: false },
  { id: 'archiver_status', title: 'Archiver status', hidden: false },
];
