import Reflux from 'reflux';

import type { Report, ReportDelivery, ReportWidgetPosition, ParameterValues } from 'report/types';

type ReportsActionsType = {
  list: () => Promise<unknown>,
  listPage: (request: { page: number, perPage: number, query: string }) => Promise<unknown>,
  create: (report: Report, reportLogo: string, parameterValues: ParameterValues) => Promise<unknown>,
  get: (reportId: string) => Promise<Report>,
  getReportLogo: (reportId: string) => Promise<{ logo: string }>,
  update: (report: Report, reportLogo: string, parameterValues: ParameterValues) => Promise<unknown>,
  updateDelivery: (reportId: string, delivery: ReportDelivery) => Promise<unknown>,
  updatePositions: (reportId: string, positions: Array<ReportWidgetPosition>) => Promise<unknown>,
  delete: (reportId: string, reportTitle: string) => Promise<unknown>,
  sendEmail: (reportId: string, reportTitle: string) => Promise<unknown>,
  getHistory: (reportId: string, reportTitle: string, skip: number, limit: number) => Promise<unknown>,
};

const ReportsActions = Reflux.createActions<ReportsActionsType>({
  list: { asyncResult: true },
  listPage: { asyncResult: true },
  create: { asyncResult: true },
  get: { asyncResult: true },
  getReportLogo: { asyncResult: true },
  update: { asyncResult: true },
  updateDelivery: { asyncResult: true },
  updatePositions: { asyncResult: true },
  delete: { asyncResult: true },
  sendEmail: { asyncResult: true },
  getHistory: { asyncResult: true },
});

export default ReportsActions;
