export default {
  convert({
    after,
    effective_timerange: effectiveTimerange,
    id,
    messages,
    total_results: total,
    type,
  }) {
    return {
      after,
      effectiveTimerange,
      id,
      messages: messages,
      total,
      type: type,
    };
  },
};
