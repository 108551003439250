import * as React from 'react';
import styled, { css } from 'styled-components';

import { Icon, DatePicker } from 'components/common';
import { Toggle } from 'security-app/components/common';

import type { FilterData } from './ColumnFilter.types';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .6rem;
`;

const DateRow = styled(Row)(({ theme }) => css`
  margin-top: 10px;
  margin-bottom: -10px;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: .9rem;
  font-weight: normal;
  background-color: ${theme.colors.global.background};
  color: ${theme.colors.global.textDefault};
`);

const ClearDate = styled(Icon)(({ theme }) => css`
  padding-left: 5px;
  cursor: pointer;
  color: ${theme.colors.variant.default};
`);

type Props = {
  filterValues: FilterData[],
  setFilterValues: (arg: FilterData[]) => void,
};

export const formatDate = (dateStr: string) => {
  const [year, month, day] = dateStr.split('-');

  return `${month}/${day}/${year}`;
};

const DateFilter = ({ filterValues, setFilterValues }: Props) => {
  const [currentDate, setCurrentDate] = React.useState<string>(null);
  const [dateRange, setDateRange] = React.useState<boolean>(false);

  const toggleDateRange = (e: React.BaseSyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();

    const auxDateRange = !dateRange;
    if (!auxDateRange && filterValues.length === 2) setFilterValues([]);
    setDateRange(auxDateRange);
  };

  const filterIncluded = (fDate: string) => !!filterValues.find(({ value }: { value: string }) => value === fDate);

  const onDateChange = (day: Date, _, e: React.BaseSyntheticEvent) => {
    e.stopPropagation();
    const isoDate = [
      day.getFullYear(),
      (day.getMonth() + 1).toString().padStart(2, '0'),
      day.getDate().toString().padStart(2, '0'),
    ].join('-');

    if (filterIncluded(isoDate)) {
      setFilterValues(filterValues.filter(({ value }: { value: string }) => value !== isoDate));
    } else if (filterValues.length === 2 || !dateRange) {
      setFilterValues([{ type: 'string', label: isoDate, value: isoDate }]);
    } else {
      setFilterValues([...filterValues, { type: 'string', label: isoDate, value: isoDate }]
        .sort((d1: FilterData, d2: FilterData) => {
          const d1Int = new Date(d1.value).getTime();
          const d2Int = new Date(d2.value).getTime();

          if (d1Int > d2Int) return 1;
          if (d1Int < d2Int) return -1;

          return 0;
        }),
      );
    }

    setCurrentDate(isoDate);
  };

  return (
    <Column>
      <Row>
        <span>Single Date</span>
        <Toggle data-testid="range-toggle" onClick={toggleDateRange}>
          <input type="checkbox" onChange={toggleDateRange} checked={dateRange} />
          <span className="slider" />
        </Toggle>
        <span>Range</span>
      </Row>
      {filterValues.length > 0 && (
        <DateRow>
          {filterValues.map(({ value }: { value: string }) => formatDate(value)).join(' to ')}
          <ClearDate data-testid="clear-date"
                     name="times"
                     size="xs"
                     onClick={() => setFilterValues([])} />
        </DateRow>
      )}
      <DatePicker date={currentDate} onChange={onDateChange} />
    </Column>
  );
};

export default DateFilter;
