import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Row = styled.div<{ $gap?: string, $fullWidth?: boolean }>(({ $gap, $fullWidth }) => css`
  display: flex;
  flex-direction: row;
  gap: ${$gap || '1rem'};
  align-items: flex-end;
  width: ${$fullWidth ? '100%' : 'auto'};
`);

export const FormGroup = styled.div`
  margin: 0;
  width: 100%;

  & .form-group {
    margin: 0;
  }
`;

export const EvidenceList = styled.div`
  display: flex;
  flex-direction: column;
  gap: .5rem;
  padding-right: 1rem;

  height: 105px;
  overflow-y: auto;
`;

export const EvidenceRow = styled(Row)`
  align-items: flex-end;

  & svg {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &:hover svg {
    opacity: 1;
  }
`;

export const IconButton = styled.div<{ $danger?: boolean }>(({ theme, $danger }) => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  padding: 0 8px;

  color: ${$danger ? theme.colors.brand.primary : theme.colors.global.textDefault};
`);

export const StyledLink = styled.span(({ theme }) => css`
  font-size: .9rem;
  cursor: pointer;
  color: ${theme.colors.global.textDefault};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
`);
