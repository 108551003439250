import * as React from 'react';

import { PageHeader, DocumentTitle, Spinner } from 'components/common';
import DocsHelper from 'util/DocsHelper';
import UserSearchFilterCreate from 'search-filter/components/filter-management-pages/UserSearchFilterCreate';
import SearchFiltersPageSubactions from 'search-filter/components/filter-management-pages/SearchFiltersPageSubactions';
import { InvalidLicenseWarning } from 'license/LicenseCheck';
import useSearchFilterLicenseStatus from 'search-filter/hooks/useSearchFilterLicenseStatus';

const MyFiltersOverviewPage = () => {
  const { isFetchingLicense, isValidLicense } = useSearchFilterLicenseStatus();

  if (isFetchingLicense) {
    return <Spinner />;
  }

  return (
    <DocumentTitle title="Create Filter For &#0034;MyFilters&#0034; collection">
      <PageHeader title="Create Filter For &#0034;My Filters&#0034; collection"
                  actions={<SearchFiltersPageSubactions licenseIsValid={isValidLicense} />}
                  documentationLink={{
                    title: 'Search filter documentation',
                    path: DocsHelper.PAGES.WELCOME,
                  }}>
        <span>
          Create a new search filter. The filter will be added to your &#0034;My Filters&#0034; collection and can be
          included in any search and dashboard widget by using the controls in the related search bar.
        </span>
      </PageHeader>
      {isValidLicense ? <UserSearchFilterCreate /> : (
        <InvalidLicenseWarning featureName="search filter"
                               licenseSubject="/license/enterprise/search-filter"
                               displayWarningContainer />
      )}
    </DocumentTitle>
  );
};

export default MyFiltersOverviewPage;
