import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Link } from 'components/common/router';
import { Button, ButtonToolbar, Col, Panel, Row } from 'components/bootstrap';
import { Icon, Spinner } from 'components/common';
import { DocumentationLink } from 'components/support';
import DocsHelper from 'util/DocsHelper';
import Routes from 'routing/Routes';
import { useStore } from 'stores/connect';

import { StyledSection, StyledTitle } from './StyledWizardComponents';
import { ForwarderWizardContext } from './ForwarderWizardProvider';

import InputsTable from '../inputs/InputsTable';
import ForwarderInputsStore, { ForwarderInputsActions } from '../stores/ForwarderInputsStore';
import { StyledPanel } from '../CommonStyledComponents';

const StyledHelpPanel = styled(StyledPanel)`
  margin-top: 35px;
`;

const StyledH4 = styled.h4`
  margin-bottom: 5px;
`;

type Props = {
  onWizardExit: () => void,
  onWizardRestart: () => void,
  hideConfigureAnother?: boolean;
};

const SummaryStep = ({ onWizardExit, onWizardRestart, hideConfigureAnother = false }: Props) => {
  const { selectedForwarder, selectedInputProfile, inputTypes } = useContext(ForwarderWizardContext);
  const { forwarderInputs } = useStore(ForwarderInputsStore);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (selectedInputProfile) {
      ForwarderInputsActions.list({ inputProfileId: selectedInputProfile.id })
        .finally(() => setIsLoading(false));
    }
  }, [selectedInputProfile]);

  if (!selectedForwarder || !selectedInputProfile) {
    return <div>Please select a Forwarder and configure its Inputs before continuing to this step.</div>;
  }

  const renderInputs = () => {
    if (isLoading) {
      return <Spinner text="Loading Inputs..." />;
    }

    const inputProfileInputs = forwarderInputs[selectedInputProfile.id];

    if ((inputProfileInputs && inputProfileInputs.length > 0)) {
      return <InputsTable inputs={inputProfileInputs} inputTypes={inputTypes} />;
    }

    return 'Input Profile has no Inputs';
  };

  return (
    <Row>
      <Col md={6}>
        <StyledTitle>Summary</StyledTitle>
        <p>This is a summary of the configured Forwarder:</p>
        <StyledSection>
          <StyledH4>Details</StyledH4>
          <dl>
            <dt>Hostname</dt>
            <dd>{selectedForwarder.hostname}</dd>
            <dt>Name</dt>
            <dd>{selectedForwarder.title}</dd>
            <dt>Description</dt>
            <dd>{selectedForwarder.description || 'No description provided'}</dd>
          </dl>
        </StyledSection>
        <StyledSection>
          <StyledH4>Assigned Input Profile</StyledH4>
          <dl>
            <dt>Title</dt>
            <dd>{selectedInputProfile.title}</dd>
            <dt>Description</dt>
            <dd>{selectedInputProfile.description || 'No description provided'}</dd>
            <dt>Inputs</dt>
            <dd>
              {renderInputs()}
            </dd>
          </dl>
        </StyledSection>
        <ButtonToolbar>
          {!hideConfigureAnother && (
            <Button bsStyle="success" onClick={onWizardRestart}>Configure another Forwarder</Button>
          )}
          <Button bsStyle="primary" onClick={onWizardExit}>Exit configuration</Button>
        </ButtonToolbar>
      </Col>
      <Col md={5} mdOffset={1}>
        <StyledHelpPanel bsStyle="info">
          <Panel.Heading>
            <Panel.Title componentClass="h3"><Icon name="info-circle" /> Get more of your logs</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <p>Use Graylog&apos;s data transformation tools to enrich and transform your logs.</p>
            <h4>Message Processing</h4>
            <p>Understand how Graylog Message Processing work on [this short video].</p>
            <h4>Lookup Tables</h4>
            <p>
              Map message field values into new ones, allowing you to enrich your messages with additional
              information. Go to the <Link to={Routes.SYSTEM.LOOKUPTABLES.OVERVIEW}>Lookup Tables</Link> page to
              get started or read our <DocumentationLink page={DocsHelper.PAGES.LOOKUPTABLES} text="documentation" />
              {' '}about them.
            </p>
            <h4>Processing Pipelines</h4>
            <p>
              Route, blacklist, modify, and enrich messages as they flow through Graylog with Pipelines. You can
              write custom code, similar to Drools rules, to fully customize Graylog&apos;s message processing
              behavior. Go to the <Link to={Routes.SYSTEM.PIPELINES.OVERVIEW}>Pipelines</Link> page to get started
              or read <DocumentationLink page={DocsHelper.PAGES.PIPELINES} text="documentation" /> about them.
            </p>
          </Panel.Body>
        </StyledHelpPanel>
      </Col>
    </Row>
  );
};

SummaryStep.propTypes = {
  hideConfigureAnother: PropTypes.bool,
  onWizardExit: PropTypes.func.isRequired,
  onWizardRestart: PropTypes.func.isRequired,
};

SummaryStep.defaultProps = {
  hideConfigureAnother: false,
};

export default SummaryStep;
