import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, Panel } from 'components/bootstrap';
import { Spinner } from 'components/common';
import UsersDomain from 'domainActions/users/UsersDomain';
import CreateTokenForm from 'components/users/CreateTokenForm';
import type { Token } from 'stores/users/UsersStore';
import type User from 'logic/users/User';

const StyledButton = styled(Button)`
  margin-top: 20px;
`;

type Props = {
  eventKey: string,
  token?: Token,
  user?: User,
  onTokenCreate: (token: Token) => void,
  onStepComplete: () => void,
};

const CreateTokenStep = ({ eventKey, token, user, onTokenCreate, onStepComplete }: Props) => {
  const [isCreatingToken, setIsCreatingToken] = useState<boolean>(false);

  if (!user) {
    return (
      <Panel eventKey={eventKey}>
        <Panel.Heading>
          <Panel.Title>
            <Panel.Toggle tabIndex="0">2. Create API Token</Panel.Toggle>
          </Panel.Title>
        </Panel.Heading>
        <Panel.Body collapsible>
          <Spinner />
        </Panel.Body>
      </Panel>
    );
  }

  const handleCreateToken = (tokenName: string) => {
    setIsCreatingToken(true);

    const promise = UsersDomain.createToken(user.id, tokenName);

    promise
      .then(onTokenCreate)
      .finally(() => setIsCreatingToken(false));
  };

  return (
    <Panel eventKey={eventKey}>
      <Panel.Heading>
        <Panel.Title>
          <Panel.Toggle tabIndex="0">2. Create API Token</Panel.Toggle>
        </Panel.Title>
      </Panel.Heading>
      <Panel.Body collapsible>
        {token ? (
          <p>You already created an API Token for this Forwarder, go to the next step to continue.</p>
        ) : (
          <>
            <p>
              Create an API Token to authenticate the Forwarder with Graylog.
            </p>
            <CreateTokenForm creatingToken={isCreatingToken} onCreate={handleCreateToken} />
            <StyledButton bsStyle="primary" bsSize="sm" onClick={onStepComplete}>Skip this step (not recommended)</StyledButton>
          </>
        )}
      </Panel.Body>
    </Panel>
  );
};

CreateTokenStep.propTypes = {
  eventKey: PropTypes.string.isRequired,
  token: PropTypes.object,
  user: PropTypes.shape({
    id: PropTypes.string,
  }),
  onTokenCreate: PropTypes.func.isRequired,
  onStepComplete: PropTypes.func.isRequired,
};

CreateTokenStep.defaultProps = {
  token: undefined,
  user: undefined,
};

export default CreateTokenStep;
