import * as React from 'react';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Headline = styled.h3`
  margin-bottom: 15px;

  @media print {
    margin-top: 15px;
    font-size: 1.4em;
    font-weight: bold;

    /* This is the desired behaviour, but currently unimplemented in Chrome. It may be implemented in a future version, so we leave it here. */
    page-break-after: avoid;
    page-break-inside: avoid;
    break-after: avoid;
  }
`;

type Props = {
  title: string
}

const Heading = forwardRef<HTMLHeadingElement, Props>(({ title }, ref) => (
  <Headline ref={ref}>{title}</Headline>
));

Heading.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Heading;
