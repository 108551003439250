import __request__ from 'routing/request';
interface PaginationInfo {
    readonly per_page: number;
    readonly total: number;
    readonly count: number;
    readonly page: number;
}
interface EntityAttribute {
    readonly filterable: boolean;
    readonly related_collection: string;
    readonly hidden: boolean;
    readonly sortable: boolean;
    readonly id: string;
    readonly type: 'STRING' | 'DATE' | 'INT' | 'LONG' | 'OBJECT_ID' | 'BOOLEAN';
    readonly title: string;
    readonly searchable: boolean;
    readonly filter_options: FilterOption[];
}
interface EntityDefaults {
    readonly sort: Sorting;
}
interface FilterOption {
    readonly title: string;
    readonly value: string;
}
interface ViewSummaryDTO {
    readonly owner: string;
    readonly summary: string;
    readonly created_at: string;
    readonly description: string;
    readonly id: string;
    readonly type: 'SEARCH' | 'DASHBOARD';
    readonly title: string;
    readonly favorite: boolean;
    readonly properties: string[];
    readonly search_id: string;
    readonly requires: {
        readonly [_key: string]: PluginMetadataSummary;
    };
}
type stringArray = string[];
interface PluginMetadataSummary {
    readonly unique_id: string;
    readonly author: string;
    readonly name: string;
    readonly description: string;
    readonly version: unknown;
    readonly url: string;
}
interface PageListResponse {
    readonly total: number;
    readonly pagination: PaginationInfo;
    readonly defaults: EntityDefaults;
    readonly query: string;
    readonly elements: ViewSummaryDTO[];
    readonly attributes: EntityAttribute[];
    readonly sort: string;
    readonly order: string;
}
interface Sorting {
    readonly id: string;
    readonly direction: 'ASC' | 'DESC';
}
/**
 * Get a list of all dashboards
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function views(sort: 'id' | 'title' | 'created_at' | 'description' | 'summary' | 'owner' = 'title', page: number = 1, per_page: number = 50, order: 'asc' | ' desc' = 'asc', query?: string, filters?: stringArray): Promise<PageListResponse> {
    return __request__('GET', '/dashboards', null, { 'page': page, 'per_page': per_page, 'sort': sort, 'order': order, 'query': query, 'filters': filters }, {
        'Accept': ['application/json']
    });
}
