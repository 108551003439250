import React, { useMemo } from 'react';
import styled from 'styled-components';

import { useStore } from 'stores/connect';

import CounterRate from './CounterRate';

import ForwarderInputStatesStore from '../stores/ForwarderInputStatesStore';
import type { Forwarder, Input, ForwarderInputState } from '../Types';
import { ForwarderInputPropType, ForwarderPropType } from '../Types';

type Props = {
  forwarder: Forwarder;
  input: Input;
};

const StyledP = styled.p`
  margin-bottom: 0;
`;

const ForwarderInputMetricContainer = ({ input, forwarder }: Props) => {
  const { forwarderInputStates: states } = useStore(ForwarderInputStatesStore);

  const currentInputMetric = useMemo(() => {
    const statesByInput = (states || []).filter((state: ForwarderInputState) => state.input_id === input.id);

    if (!forwarder) {
      return null;
    }

    return statesByInput.filter((state) => state.forwarder_id === forwarder.id)[0]?.metrics?.filter((metric) => metric.name === 'incomingMessages');
  }, [input, forwarder, states]);

  const hasResults = currentInputMetric && currentInputMetric.length > 0;

  if (!hasResults) {
    return <StyledP>No metrics available.</StyledP>;
  }

  return (
    <StyledP>
      <CounterRate metric={currentInputMetric[0]} suffix="msg/s" /> averaged in the last minute.
    </StyledP>
  );
};

ForwarderInputMetricContainer.propTypes = {
  forwarder: ForwarderPropType.isRequired,
  input: ForwarderInputPropType.isRequired,
};

export default ForwarderInputMetricContainer;
