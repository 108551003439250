import * as React from 'react';

import { formHasErrors } from 'util/FormsUtils';
import { Col, Form, ButtonToolbar, Button, Alert, Input } from 'components/bootstrap';
import { FormikFormGroup, ExternalLink } from 'components/common';
import { getPathnameWithoutId } from 'util/URLUtils';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import useLocation from 'routing/useLocation';
import { TELEMETRY_EVENT_TYPE } from 'logic/telemetry/Constants';

import type { ConfigFormValues } from '../types';

interface GroupSyncFormProps {
  values: ConfigFormValues;

  isSubmitting: boolean;

  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void,

  validateForm: (values?: any) => Promise<any>

  submitAllError: React.ReactNode | null | undefined;

  goToPrevious: () => void;
}

const OidcGroupSyncForm = ({
  values,
  isSubmitting,
  handleSubmit,
  validateForm,
  submitAllError,
  goToPrevious,
}: GroupSyncFormProps) => {
  const { pathname } = useLocation();
  const sendTelemetry = useSendTelemetry();

  const onSubmitAll = () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.AUTHENTICATION.OIDC_GROUP_SYNC_SAVE_CLICKED, {
      app_pathname: getPathnameWithoutId(pathname),
      app_section: 'oidc-groupsync-step',
      app_action_value: 'save-service',
    });

    if (values.synchronizeGroups) {
      return validateForm().then((errors) => {
        if (!formHasErrors(errors)) {
          handleSubmit();
        }
      });
    }

    return handleSubmit();
  };

  const disableForm = !values.synchronizeGroups;

  return (
    <Col md={7}>
      <Form className="form form-horizontal">
        <div>
          <Alert bsStyle="info" title="Introduction">
            This step enables importing OIDC groups as Graylog teams.
            The groups are retrieved via{' '}
            <ExternalLink href="https://openid.net/specs/openid-connect-core-1_0.html#UserInfo">
              the OIDC userinfo endpoint
            </ExternalLink>.{' '}
            The group synchronization can be activated by clicking on the checkbox with the label{' '}
            <i>Enable Group Synchronization</i>. All groups for a user will be synchronized.
          </Alert>

          <Input label="Enable Group Synchronization"
                 id="synchronize-groups-checkbox"
                 labelClassName="col-sm-3"
                 wrapperClassName="col-sm-9">
            <FormikFormGroup label="Synchronize Groups"
                             wrapperClassName="col-sm-12"
                             name="synchronizeGroups"
                             help="Select this option if you want to import OIDC groups as teams"
                             type="checkbox" />
          </Input>
          <FormikFormGroup label="Groups Claim"
                           type="text"
                           help="Add an OIDC claim name used to sync groups. If you leave it empty 'graylog_teams' will be used as default value."
                           labelClassName="col-sm-3"
                           name="groupsClaim"
                           disabled={disableForm}
                           wrapperClassName="col-sm-9" />
        </div>
        {submitAllError}
        <ButtonToolbar className="pull-right">
          <Button bsStyle="primary"
                  disabled={isSubmitting}
                  onClick={() => {
                    sendTelemetry(TELEMETRY_EVENT_TYPE.AUTHENTICATION.OIDC_PREVIOUS_SERVER_CONFIG_CLICKED, {
                      app_pathname: getPathnameWithoutId(pathname),
                      app_section: 'oidc-groupsync-step',
                      app_action_value: 'server-configuration-button',
                    });

                    goToPrevious();
                  }}
                  type="button">
            Previous: Server Configuration
          </Button>
          <Button disabled={isSubmitting}
                  onClick={onSubmitAll}>
            Finish & Save Service
          </Button>
        </ButtonToolbar>
      </Form>
    </Col>
  );
};

export default OidcGroupSyncForm;
