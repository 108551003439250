import __request__ from 'routing/request';
/**
 * GET request to Data Node's API
 */
export function requestGet(path: string): Promise<unknown> {
    return __request__('GET', `/datanodes/request/${path}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * POST request to Data Node's API
 */
export function requestPost(path: string): Promise<unknown> {
    return __request__('POST', `/datanodes/request/${path}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * PUT request to Data Node's API
 */
export function requestPut(path: string): Promise<unknown> {
    return __request__('PUT', `/datanodes/request/${path}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * DELETE request to Data Node's API
 */
export function requestDelete(path: string): Promise<unknown> {
    return __request__('DELETE', `/datanodes/request/${path}`, null, {}, {
        'Accept': ['application/json']
    });
}
