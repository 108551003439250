import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import URI from 'urijs';

import { Button } from 'components/bootstrap';

import type { Configuration } from './OidcConfigurationStore';

type Props = {
  configuration: Configuration;
  nonce: {
    state: string;
    redirectTo: string;
  };

};

const StyledButton = styled(Button)`
  margin-top: 1em;
  display: block;
  cursor: pointer;
`;

const getAuthUrl = ({ authorizationEndpoint, nonce, clientId, redirectUri }) => {
  const url = URI(authorizationEndpoint)
    .addSearch({
      client_id: clientId,
      redirect_uri: redirectUri,
      response_type: 'code',
      scope: 'openid email profile',
      state: nonce.state,
      nonce: nonce.state,
    });

  return url.toString();
};

function OidcGenericLoginLink({ configuration, nonce }: Props) {
  const {
    oauth_auth_url: authorizationEndpoint,
    oauth_client_id: clientId,
    oauth_callback_url: redirectUri,
    oauth_title: title,
  } = configuration;

  const loginUrl = getAuthUrl({
    authorizationEndpoint,
    nonce,
    clientId,
    redirectUri,
  });

  return (
    <StyledButton href={loginUrl}
                  bsStyle="info">
      {`Login with ${title}`}
    </StyledButton>
  );
}

OidcGenericLoginLink.propTypes = {
  configuration: PropTypes.shape({
    oauth_base_url: PropTypes.string.isRequired,
    oauth_title: PropTypes.string.isRequired,
    oauth_client_id: PropTypes.string.isRequired,
    oauth_auth_url: PropTypes.string.isRequired,
    oauth_callback_url: PropTypes.string.isRequired,
  }).isRequired,
  nonce: PropTypes.shape({
    state: PropTypes.string.isRequired,
    redirectTo: PropTypes.string.isRequired,
  }).isRequired,
};

export default OidcGenericLoginLink;
