/*
 * Copyright (C) 2020 Graylog, Inc.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the Server Side Public License, version 1,
 * as published by MongoDB, Inc.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * Server Side Public License for more details.
 *
 * You should have received a copy of the Server Side Public License
 * along with this program. If not, see
 * <http://www.mongodb.com/licensing/server-side-public-license>.
 */
import * as React from 'react';
import unionWith from 'lodash/unionWith';
import xorWith from 'lodash/xorWith';
import { useCallback } from 'react';

import defaultWidgetTitle from 'views/components/defaultTitle';
import type { Report, AvailableWidgetPreview, BackendReportWidget } from 'report/types';
import type { AvailableDashboard } from 'report/report-contents-page/useAvailableWidgets';
import ContentSelectionSubheader from 'report/report-contents-page/ContentSelectionSubheader';
import { ExpandableList, ExpandableListItem, Pluralize } from 'components/common';
import { getValueFromInput } from 'util/FormsUtils';
import Routes from 'routing/Routes';
import AvailableWidgetsList from 'report/report-contents-page/AvailableWidgetsList';
import { naturalSortIgnoreCase } from 'util/SortUtils';

const MAX_DASHBOARDS_TO_EXPAND = 5;

export type AvailableWidgetUpdate = AvailableWidgetPreview & { dashboard_id: string, dashboard_widget_id: string };

type Props = {
  reportWidgets: Array<BackendReportWidget>,
  dashboards: Array<AvailableDashboard>,
  onReportUpdate: (reportChanges: Partial<Report>) => void,
  hideWidgetQuery: boolean,
  hideWidgetDescription: boolean,
}

const getDashboard = (dashboards: Array<AvailableDashboard>, dashboardId: string) => dashboards.find((d) => d.id === dashboardId);

const compareWidgets = (w1: AvailableWidgetUpdate | BackendReportWidget, w2: AvailableWidgetUpdate | BackendReportWidget) => w1.dashboard_id === w2.dashboard_id && w1.dashboard_widget_id === w2.dashboard_widget_id;

const AvailableDashboardsList = ({ reportWidgets, dashboards, onReportUpdate, hideWidgetQuery, hideWidgetDescription }: Props) => {
  const numberDashboards = dashboards.length;

  const updateWidgets = useCallback((
    updatedWidgets: Array<AvailableWidgetUpdate>,
    mergeFn: (
      reportWidgets: Array<BackendReportWidget>,
      updatedWidgets: Array<AvailableWidgetUpdate>,
      compareFn: (w1: AvailableWidgetUpdate | BackendReportWidget, w2: AvailableWidgetUpdate | BackendReportWidget) => boolean
    ) => Array<BackendReportWidget>,
  ) => {
    const newWidgets = mergeFn(reportWidgets, updatedWidgets, compareWidgets);
    onReportUpdate({ widgets: newWidgets });
  }, [onReportUpdate, reportWidgets]);

  const updateDashboardSelection = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const dashboardId = event.target.value;
    const isDashboardChecked = getValueFromInput(event.target);

    const dashboard = getDashboard(dashboards, dashboardId);
    const widgetsToUpdate = Object.values(dashboard.widgets).flat().filter((widget) => widget.eligible);

    const updatedWidgets = widgetsToUpdate
      .map((originalWidget) => ({ ...originalWidget, dashboard_id: dashboardId, dashboard_widget_id: originalWidget.id }));

    updateWidgets(updatedWidgets, isDashboardChecked ? unionWith : xorWith);
  }, [dashboards, updateWidgets]);

  const updateDashboardPageSelection = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const dashboardId = event.target.name;
    const dashboardPageId = event.target.value;
    const isDashboardPageChecked = getValueFromInput(event.target);

    const dashboard = getDashboard(dashboards, dashboardId);
    const widgetsToUpdate = dashboard.widgets[dashboardPageId].filter((widget) => widget.eligible);

    const updatedWidgets = widgetsToUpdate
      .map((originalWidget) => ({ ...originalWidget, dashboard_id: dashboardId, dashboard_widget_id: originalWidget.id }));

    updateWidgets(updatedWidgets, isDashboardPageChecked ? unionWith : xorWith);
  }, [dashboards, updateWidgets]);

  return (
    <>
      {dashboards.map((dashboard) => {
        const numberPages = Object.keys(dashboard.widgets).length;
        const pagesCount = (
          <span>
            — contains {numberPages} <Pluralize value={numberPages} singular="page" plural="pages" />
          </span>
        );

        const allWidgets = Object.values(dashboard.widgets).flat();
        const allEligibleWidgets = allWidgets.filter((w) => w.eligible).map((w) => w.id);
        const eligibleWidgetsInReport = reportWidgets.filter(({
          dashboard_widget_id,
          dashboard_id,
        }) => (dashboard.id === dashboard_id && allEligibleWidgets.includes(dashboard_widget_id))).length;
        const allEligibleWidgetsCount = allEligibleWidgets.length;

        const dashboardChecked = allEligibleWidgetsCount > 0 && eligibleWidgetsInReport === allEligibleWidgetsCount;
        const dashboardIndetermined = eligibleWidgetsInReport > 0 && !dashboardChecked;
        const dashboardHeader = (
          <ContentSelectionSubheader description={pagesCount}
                                     link={Routes.dashboard_show(dashboard.id)}
                                     entityName="dashboard"
                                     editPermissions={[`view:edit:${dashboard.id}`, 'view:edit']} />
        );

        return (
          <ExpandableListItem key={dashboard.id}
                              header={dashboard.title}
                              name={dashboard.title}
                              value={dashboard.id}
                              subheader={dashboardHeader}
                              checked={dashboardChecked}
                              indetermined={dashboardIndetermined}
                              onChange={updateDashboardSelection}>
            <ExpandableList>
              <>
                {Object.entries(dashboard.widgets).map(([queryId, widgets], index) => {
                  const numberWidgets = widgets.length;
                  const widgetCount = (
                    <span>
                      — contains {numberWidgets} <Pluralize value={numberWidgets} singular="widget" plural="widgets" />
                    </span>
                  );
                  const pageSubheader = (
                    <ContentSelectionSubheader description={widgetCount}
                                               link={`${Routes.dashboard_show(dashboard.id)}?page=${queryId}`}
                                               entityName="dashboard page"
                                               editPermissions={[`view:edit:${dashboard.id}`, 'view:edit']} />
                  );
                  const widgetsWithTitle = widgets.map((widget) => ({
                    ...widget,
                    title: widget.title ?? defaultWidgetTitle(widget),
                  }));
                  const sortedWidgets = widgetsWithTitle.sort((w1, w2) => naturalSortIgnoreCase(w1.title, w2.title));
                  const eligibleWidgets = widgetsWithTitle.filter((widget) => widget.eligible).map((widget) => widget.id);

                  const eligibleDashboardWidgetsInReport = reportWidgets.filter(({ dashboard_widget_id }) => eligibleWidgets.includes(dashboard_widget_id)).length;
                  const eligibleWidgetsCount = eligibleWidgets.length;
                  const isPageChecked = eligibleWidgetsCount > 0 && eligibleDashboardWidgetsInReport === eligibleWidgetsCount;
                  const isPageIndetermined = eligibleDashboardWidgetsInReport > 0 && !isPageChecked;

                  return (
                    <ExpandableListItem key={queryId}
                                        header={dashboard.query_titles[queryId] ?? `Page #${index + 1}`}
                                        name={dashboard.id}
                                        value={queryId}
                                        subheader={pageSubheader}
                                        expanded={numberDashboards <= MAX_DASHBOARDS_TO_EXPAND}
                                        checked={isPageChecked}
                                        indetermined={isPageIndetermined}
                                        onChange={updateDashboardPageSelection}>
                      <ExpandableList>
                        <AvailableWidgetsList widgets={sortedWidgets}
                                              reportWidgets={reportWidgets}
                                              dashboard={dashboard}
                                              queryId={queryId}
                                              hideWidgetQuery={hideWidgetQuery}
                                              hideWidgetDescription={hideWidgetDescription}
                                              updateWidgets={updateWidgets} />
                      </ExpandableList>
                    </ExpandableListItem>
                  );
                })}
              </>
            </ExpandableList>
          </ExpandableListItem>
        );
      })}
    </>
  );
};

export default AvailableDashboardsList;
