import { useQuery } from '@tanstack/react-query';

import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import { useCheckLicenseAfterLicenseUpdate } from 'license/LicenseCheckProvider';

const fetchLicenseStatus = () => fetch(
  'GET',
  qualifyUrl('/plugins/org.graylog.plugins.license/licenses/status/for-subject?subject=/license/enterprise/search-filter'),
).then((response) => !!response?.status?.valid).catch(() => false);

const useSearchFilterLicenseStatus = (): { isValidLicense: boolean, isFetchingLicense: boolean } => {
  const { data: isValidLicense = false, isFetching: isFetchingLicense, refetch } = useQuery(
    ['searchFilterLicenseCheck'],
    () => fetchLicenseStatus(),
  );

  useCheckLicenseAfterLicenseUpdate([refetch]);

  return { isValidLicense, isFetchingLicense };
};

export default useSearchFilterLicenseStatus;
