import __request__ from 'routing/request';
interface LogFile {
    readonly size: number;
    readonly name: string;
    readonly id: string;
    readonly lastModified: string;
}
type BundleFileArray = BundleFile[];
interface BundleEntries {
    readonly logfiles: LogFile[];
}
interface BundleFile {
    readonly size: number;
    readonly file_name: string;
}
interface SupportBundleNodeManifest {
    readonly entries: BundleEntries;
}
/**
 * Downloads the requested bundle
 * @param filename filename
 */
export function download(filename?: string): Promise<unknown> {
    return __request__('GET', `/system/debug/support/bundle/download/${filename}`, null, {}, {
        'Accept': ['application/octet-stream']
    });
}
/**
 * Returns the list of downloadable support bundles
 */
export function listBundles(): Promise<BundleFileArray> {
    return __request__('GET', '/system/debug/support/bundle/list', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete a certain support bundle
 * @param filename filename
 */
export function remove(filename?: string): Promise<unknown> {
    return __request__('DELETE', `/system/debug/support/bundle/${filename}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Retrieve the nodes' server logfile
 * @param id The id of the logfile as referenced from the Support Bundle Manifest
 */
export function getLogFile(id?: string): Promise<unknown> {
    return __request__('GET', `/system/debug/support/logfile/${id}`, null, {}, {
        'Accept': ['application/octet-stream']
    });
}
/**
 * Get a nodes' Support Bundle Manifest
 */
export function getNodeManifest(): Promise<SupportBundleNodeManifest> {
    return __request__('GET', '/system/debug/support/manifest', null, {}, {
        'Accept': ['application/json']
    });
}
