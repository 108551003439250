import React from 'react';
import PropTypes from 'prop-types';

import { Spinner } from 'components/common';
import { Link } from 'components/common/router';
import Routes from 'routing/Routes';
import useReceivedBy from 'forwarder/message-details/useReceivedBy';

type Props = {
  inputId: string,
  forwarderNodeId: string,
};

const ForwarderReceivedBy = ({ inputId, forwarderNodeId }: Props) => {
  const { forwarder, input, isLoading } = useReceivedBy(inputId, forwarderNodeId);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <dt>Received by</dt>
      <dd>
        <em>{input?.title}</em> on{' '}
        <Link to={Routes.pluginRoute('SYSTEM_FORWARDERS_FORWARDERID')(forwarder?.id)}>
          {forwarder?.title}
        </Link>
      </dd>
    </div>
  );
};

ForwarderReceivedBy.propTypes = {
  inputId: PropTypes.string.isRequired,
  forwarderNodeId: PropTypes.string.isRequired,
};

export default ForwarderReceivedBy;
