import __request__ from 'routing/request';
interface TrafficHistogram {
    readonly output: {
        readonly [_key: string]: number;
    };
    readonly input: {
        readonly [_key: string]: number;
    };
    readonly decoded: {
        readonly [_key: string]: number;
    };
    readonly from: string;
    readonly to: string;
}
/**
 * Get the cluster traffic stats
 * @param days For how many days the traffic stats should be returned
 * @param daily Whether the traffic should be aggregate to daily values
 * @param includeToday Whether the traffic should include up to the current date/time (in UTC).
 */
export function get(days: number = 30, daily: boolean = false, includeToday: boolean = true): Promise<TrafficHistogram> {
    return __request__('GET', '/system/cluster/traffic', null, { 'days': days, 'daily': daily, 'includeToday': includeToday }, {
        'Accept': ['application/json']
    });
}
