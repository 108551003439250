import __request__ from 'routing/request';
/**
 * Pauses message processing
 */
export function pauseProcessing(): Promise<void> {
    return __request__('PUT', '/system/processing/pause', null, {}, {
        'Accept': []
    });
}
/**
 * Resume message processing
 */
export function resumeProcessing(): Promise<void> {
    return __request__('PUT', '/system/processing/resume', null, {}, {
        'Accept': []
    });
}
