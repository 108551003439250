import React from 'react';
import PropTypes from 'prop-types';

const ThreatFoxAdapterSummary = ({ dataAdapter }) => {
  const { config } = dataAdapter;

  return (
    <dl>
      <dt>Include Older IOCs</dt>
      <dd>{config.include_older_iocs ? 'Yes' : 'No'}</dd>
      <dt>Refresh Interval</dt>
      <dd>{config.refresh_interval} seconds </dd>
      <dt>Case Insensitive Lookups</dt>
      <dd>{config.case_insensitive_lookup ? 'Yes' : 'No'}</dd>
    </dl>
  );
};

ThreatFoxAdapterSummary.propTypes = {
  dataAdapter: PropTypes.shape({
    config: PropTypes.shape({
      include_older_iocs: PropTypes.bool.isRequired,
      refresh_interval: PropTypes.number.isRequired,
      case_insensitive_lookup: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
};

export default ThreatFoxAdapterSummary;
