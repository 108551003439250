// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form:not(.form-inline) .twitter-typeahead {
  width: 100%;
}
.typeahead,
.tt-query,
.tt-hint {
  border: 2px solid #ccc;
  border-radius: 4px;
  outline: none;
}
.typeahead {
  background-color: #fff;
}
.typeahead:focus {
  border: 2px solid #0097cf;
}
.tt-query {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
input[type="text"].tt-hint {
  color: #999;
}
.tt-menu {
  min-width: 160px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  width: 100%;
}
.tt-dataset {
  margin-top: 10px;
}
.tt-suggestion {
  font-size: 1rem;
  /* theme.fonts.size.body */
  line-height: 20px;
  padding: 3px 20px;
  cursor: pointer;
}
.tt-suggestion:hover,
.tt-suggestion.tt-cursor {
  color: #ffffff;
  text-decoration: none;
  background-color: #0081c2;
  background-image: linear-gradient(to bottom, #0088cc, #0077b3);
  background-repeat: repeat-x;
  outline: 0;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0077b3', GradientType=0);
}
.tt-suggestion p {
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./public/stylesheets/typeahead.less"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AAEA;;;EAGE,sBAAA;EACA,kBAAA;EACA,aAAA;AAAF;AAGA;EACE,sBAAA;AADF;AAIA;EACE,yBAAA;AAFF;AAKA;EACE,gDAAA;AAHF;AAMA;EACE,WAAA;AAJF;AAOA;EACE,gBAAA;EACA,sBAAA;EACA,oCAAA;EACA,kBAAA;EACA,yCAAA;EACA,WAAA;AALF;AAQA;EACE,gBAAA;AANF;AASA;EACE,eAAA;EAPA,0BAA0B;EAQ1B,iBAAA;EACA,iBAAA;EACA,eAAA;AANF;AASA;;EAEE,cAAA;EACA,qBAAA;EACA,yBAAA;EACA,8DAAA;EACA,2BAAA;EACA,UAAA;EACA,sHAAA;AAPF;AAUA;EACE,SAAA;AARF","sourcesContent":["form:not(.form-inline) .twitter-typeahead {\n  width: 100%;\n}\n\n.typeahead,\n.tt-query,\n.tt-hint {\n  border: 2px solid #ccc;\n  border-radius: 4px;\n  outline: none;\n}\n\n.typeahead {\n  background-color: #fff;\n}\n\n.typeahead:focus {\n  border: 2px solid #0097cf;\n}\n\n.tt-query {\n  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);\n}\n\ninput[type=\"text\"].tt-hint {\n  color: #999\n}\n\n.tt-menu {\n  min-width: 160px;\n  background-color: #fff;\n  border: 1px solid rgba(0, 0, 0, 0.2);\n  border-radius: 4px;\n  box-shadow: 0 5px 10px rgba(0,0,0,.2);\n  width: 100%;\n}\n\n.tt-dataset {\n  margin-top: 10px;\n}\n\n.tt-suggestion {\n  font-size: 1rem; /* theme.fonts.size.body */\n  line-height: 20px;\n  padding: 3px 20px;\n  cursor: pointer;\n}\n\n.tt-suggestion:hover,\n.tt-suggestion.tt-cursor {\n  color: #ffffff;\n  text-decoration: none;\n  background-color: #0081c2;\n  background-image: linear-gradient(to bottom, #0088cc, #0077b3);\n  background-repeat: repeat-x;\n  outline: 0;\n  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0077b3', GradientType=0);\n}\n\n.tt-suggestion p {\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
