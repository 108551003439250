import * as React from 'react';
import type * as Immutable from 'immutable';

import type { BackendMessage } from 'views/components/messagelist/Types';
import { singleton } from 'logic/singleton';

export interface ListState {
  actions: {
    cancelLoadPrevPage: () => void
    loadNextPage: () => void,
    loadPrevPage: () => void,
  },
  bottomPageId: number,
  loadedAllPrevMessages: boolean,
  pages: Immutable.OrderedSet<[number, Array<BackendMessage>]>
}

const LogViewStateContext = React.createContext<ListState | undefined>(undefined);

export default singleton('contexts.enterprise.LogViewStateContext', () => LogViewStateContext);
