import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import type { Notification } from 'theme/types';
import notifyingAction from 'domainActions/notifyingAction';
import Store from 'logic/local-storage/Store';

import {
  createPublicNotification,
  deletePublicNotification,
  updatePublicNotification,
  fetchPublicNotifications,
} from './PublicNotificationsAPI';

const LOCAL_STORAGE = 'gl-notifications';
const AUTOLOAD_TIME_MS = 5000;

const handleCreatePublicNotifications = notifyingAction({
  action: createPublicNotification,
  success: () => ({
    message: 'Public Notification successfully created!',
  }),
  error: (error) => ({
    message: `Error Creating: ${error}`,
  }),
});

const handleDeletePublicNotifications = notifyingAction({
  action: deletePublicNotification,
  success: () => ({
    message: 'Public Notification successfully deleted!',
  }),
  error: (error) => ({
    message: `Error Deleting: ${error}`,
  }),
});

const handleUpdatePublicNotifications = notifyingAction({
  action: updatePublicNotification,
  success: () => ({
    message: 'Public Notification successfully edited!',
  }),
  error: (error) => ({
    message: `Error Editing: ${error}`,
  }),
});

const queryKey = ['customization', 'notifications'];

const fetchDismissedNotifications = () => Promise.resolve(new Set(Store.get(LOCAL_STORAGE) ?? []));

const usePublicNotifications = () => {
  const { data: notifications = {} } = useQuery(queryKey, fetchPublicNotifications, { refetchInterval: AUTOLOAD_TIME_MS });
  const { data: dismissedNotifications } = useQuery(['customizations', 'notifications', 'dismissed'], fetchDismissedNotifications);
  const queryClient = useQueryClient();

  const _reload = () => {
    queryClient.invalidateQueries(queryKey);
  };

  const { mutateAsync: onCreatePublicNotification } = useMutation(handleCreatePublicNotifications, { onSuccess: _reload });
  const { mutateAsync: onDeletePublicNotification } = useMutation(handleDeletePublicNotifications, { onSuccess: _reload });
  const { mutateAsync: _onUpdatePublicNotification } = useMutation(handleUpdatePublicNotifications, { onSuccess: _reload });
  const onUpdatePublicNotification = useCallback((id: string, notification: Notification) => _onUpdatePublicNotification({ id, notification }), [_onUpdatePublicNotification]);

  const onDismissPublicNotification = (id: string) => {
    const dismissed = Array.from(dismissedNotifications.add(id));
    Store.set(LOCAL_STORAGE, dismissed);

    _reload();
  };

  return {
    notifications,
    dismissedNotifications,
    onCreatePublicNotification,
    onDeletePublicNotification,
    onDismissPublicNotification,
    onUpdatePublicNotification,
  };
};

export default usePublicNotifications;
