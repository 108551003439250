import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { defaultCompare } from 'logic/DefaultCompare';
import { useStore } from 'stores/connect';
import { PaginatedList, Select } from 'components/common';
import { Alert, ControlLabel, FormGroup, HelpBlock, Button, Panel } from 'components/bootstrap';
import BootstrapModalForm from 'components/bootstrap/BootstrapModalForm';

import type { Forwarder, InputProfile } from '../Types';
import { ForwarderPropType, InputProfilePropType } from '../Types';
import ForwarderInputsStore, { ForwarderInputsActions } from '../stores/ForwarderInputsStore';
import ForwarderInputTypesStore from '../stores/ForwarderInputTypesStore';
import { StyledPanel } from '../CommonStyledComponents';

type Props = {
  currentInputProfile: InputProfile,
  forwarder: Forwarder,
  inputProfiles: Array<InputProfile>,
  onSave: (inputProfile: InputProfile) => void,
};

const StyledUl = styled.ul`
  list-style-type: inherit;
  padding-left: 15px;
`;

const InputProfileSwitchForm = ({ currentInputProfile, forwarder, inputProfiles, onSave }: Props) => {
  const { forwarderInputs, pagination: forwarderInputsPagination } = useStore(ForwarderInputsStore);
  const { forwarderInputTypes } = useStore(ForwarderInputTypesStore);
  const [selectedInput, setSelectedInput] = useState(currentInputProfile);
  const [showModal, setShowModal] = useState<boolean>(false);
  const _openModal = () => setShowModal(true);
  const _closeModal = () => setShowModal(false);

  const _getFormattedInputProfile = () => inputProfiles.filter((ip) => ip.id !== currentInputProfile.id)
    .map((i) => ({ value: i.id, label: i.title }))
    .sort((s1, s2) => defaultCompare(s1.label, s2.label));

  const _onInputProfileChange = (selected: string) => {
    const selectInputProfile = inputProfiles.find((i) => i.id === selected);

    if (selectInputProfile) {
      setSelectedInput(selectInputProfile);
    }
  };

  const _fetchForwarderInputs = (inputProfileId: string, page = 1) => {
    ForwarderInputsActions.list({ inputProfileId: inputProfileId, page });
  };

  const _handlePageChange = (page: number) => {
    _fetchForwarderInputs(selectedInput.id, page);
  };

  useEffect(() => {
    _fetchForwarderInputs(selectedInput.id);
  }, [selectedInput]);

  const renderInputs = () => {
    const inputs = forwarderInputs[selectedInput.id];

    if (!inputs) {
      return <em>Loading Input Profile Inputs...</em>;
    }

    if (inputs.length === 0) {
      return 'Input Profile has no configured Inputs.';
    }

    const { page, perPage, total } = forwarderInputsPagination;

    return (
      <PaginatedList totalItems={total}
                     activePage={page}
                     pageSize={perPage}
                     onChange={_handlePageChange}
                     showPageSizeSelect={false}
                     useQueryParameter={false}>
        <StyledUl>
          {inputs.map((input) => <li key={input.id}>{input.title} ({forwarderInputTypes[input.type] || input.type})</li>)}
        </StyledUl>
      </PaginatedList>
    );
  };

  return (
    <>
      <Button onClick={_openModal} bsStyle="success">
        <span>Switch Input Profile</span>
      </Button>
      <BootstrapModalForm show={showModal}
                          title={<span>Switch Input Profile for <em>{forwarder.title}</em></span>}
                          data-telemetry-title="Switch Input Profile for forwarder"
                          onSubmitForm={() => { onSave(selectedInput); _closeModal(); }}
                          onCancel={_closeModal}
                          submitButtonText="Save">
        <fieldset>
          <FormGroup id="inputProfileSwitch">
            <ControlLabel>Next Input Profile</ControlLabel>
            <HelpBlock>
              Select the Input Profile to replace <em>{currentInputProfile.title}</em> on this Forwarder.
            </HelpBlock>
            <Select options={_getFormattedInputProfile()}
                    onChange={_onInputProfileChange}
                    placeholder="Select an Input Profile"
                    value={selectedInput ? selectedInput.title : undefined} />
            {selectedInput && selectedInput.id !== currentInputProfile.id && (
              <>
                <Alert bsStyle="warning">
                  <i className="fa fa-exclamation-triangle fa-fw fa-lg" />
                  <strong>Warning</strong>
                  <div>
                    This will remove any Inputs currently running on this Forwarder and replace them with Inputs
                    from <em>{selectedInput.title}</em> Input Profile.
                  </div>
                </Alert>
                <br />
                <StyledPanel bsStyle="default">
                  <Panel.Heading>Changes overview</Panel.Heading>
                  <Panel.Body>
                    <dl>
                      <dt>Forwarder</dt>
                      <dd>{forwarder.title}</dd>
                      <dt>Current Input Profile</dt>
                      <dd>{currentInputProfile.title}</dd>
                      <dt>Next Input Profile</dt>
                      <dd>{selectedInput.title}</dd>
                      <dt>Description</dt>
                      <dd>{selectedInput.description || 'No description given'}</dd>
                      <dt>Inputs</dt>
                      <dd>{renderInputs()}</dd>
                    </dl>
                  </Panel.Body>
                </StyledPanel>
              </>
            )}
          </FormGroup>
        </fieldset>
      </BootstrapModalForm>
    </>
  );
};

InputProfileSwitchForm.propTypes = {
  currentInputProfile: InputProfilePropType.isRequired,
  forwarder: ForwarderPropType.isRequired,
  inputProfiles: PropTypes.arrayOf(InputProfilePropType).isRequired,
  onSave: PropTypes.func.isRequired,
};

export default InputProfileSwitchForm;
