import notifyingAction from 'domainActions/notifyingAction';

import AuthenticationActions from '../actions/AuthenticationActions';

const triggerTeamSync = notifyingAction({
  action: AuthenticationActions.triggerTeamSync,
  error: (error, _authBackendId, backendTitle) => ({
    message: `Synchronizing teams for authentication service "${backendTitle}" failed with status: ${error}`,
  }),
});

export default {
  triggerTeamSync,
};
