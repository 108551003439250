import React from 'react';

import ArchivePageHeaderDescription from 'archive/components/ArchivePageHeaderDescription';
import ArchiveConfigForm from 'archive/components/ArchiveConfigForm';
import ArchiveLicenseStatusContainer from 'archive/components/ArchiveLicenseStatusContainer';
import { Row, Col } from 'components/bootstrap';
import { DocumentTitle, PageHeader } from 'components/common';

import ArchivePageNavigation from './components/ArchivePageNavigation';

const ArchiveConfigurationPage = () => (
  <DocumentTitle title="Archives configuration">
    <ArchivePageNavigation />
    <PageHeader title="Edit archives configuration">
      <ArchivePageHeaderDescription />
    </PageHeader>
    <ArchiveLicenseStatusContainer />
    <Row className="content">
      <Col lg={8}>
        <ArchiveConfigForm />
      </Col>
    </Row>

  </DocumentTitle>
);

export default ArchiveConfigurationPage;
