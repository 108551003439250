import * as React from 'react';
import styled, { css } from 'styled-components';
import { useParams } from 'react-router-dom';

import { Row, Col } from 'components/bootstrap';

import Job from './Job';

import useInstantArchivingJobs from '../hooks/useInstantArchivingJobs';

const StyledH2 = styled.h2(({ theme }) => css`
  margin-bottom: ${theme.spacings.xs};
`);

const InstantArchivingJobs = () => {
  const {
    instantArchivingData:
      {
        inProgressCount,
        jobs,
      },
    isLoadingInstantArchiving,
    onCancelInstantArchivingJob,
    onAcknowledgeInstantArchivingJob,
  } = useInstantArchivingJobs();
  const params = useParams<{
    archiveId?: string
  }>();
  const { archiveId } = params;

  if (isLoadingInstantArchiving || inProgressCount === 0 || !jobs) {
    return null;
  }

  const archivingJobs = jobs.filter((j) => {
    if (!archiveId) {
      return true;
    }

    return j.archive.join(',').includes(archiveId);
  }).map((job) => (
    <Job key={job.id}
         job={job}
         onCancel={onCancelInstantArchivingJob}
         onAcknowledge={onAcknowledgeInstantArchivingJob} />
  ));

  return (
    <Row className="content">
      <Col md={12}>
        <StyledH2>Instant Archiving jobs</StyledH2>
        {archivingJobs}
      </Col>
    </Row>
  );
};

export default InstantArchivingJobs;
