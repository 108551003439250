import * as React from 'react';
import { Formik, Form, Field } from 'formik';
import styled, { css } from 'styled-components';
import type { ReactNode } from 'react';
import { useState, useEffect } from 'react';

import { Modal, Input } from 'components/bootstrap';
import { FormikInput, ModalSubmit, Select } from 'components/common';
import useUserDateTime from 'hooks/useUserDateTime';
import { isValidDate } from 'util/DateTime';
import TimeRangeDisplay from 'views/components/searchbar/time-range-filter/TimeRangeDisplay';
import TimeRangeSelectInput from 'instant-archiving/components/TimeRangeSelectInput';
import type { EstimateParams } from 'instant-archiving/hooks/useInstantArchivingEstimate';
import useInstantArchivingEstimate from 'instant-archiving/hooks/useInstantArchivingEstimate';
import ArchiveSizeEstimate from 'instant-archiving/components/ArchiveSizeEstimate';

export type InstantArchivingActionFormValues = {
  from: string,
  to: string,
  streamFilter: string,
  full_delete?: boolean,
  wipe_restores?: boolean,
}

type Props = {
  modalTitle: string,
  archiveId?: string,
  archiveStreams?: {
    [key: string]: string,
  },
  show: boolean,
  onHide: () => void
  submitButtonText: string,
  children: React.ReactNode,
  onSubmit: (values: InstantArchivingActionFormValues) => void,
  hourOnly?: boolean,
  type: 'restore' | 'delete'
};

const StyledP = styled.p`
  margin-top: 10px;
  font-weight: bold;
`;

const FullDelete = styled.div(({ theme }) => css`
  display: flex;
  gap: ${theme.spacings.lg};
  margin-top: ${theme.spacings.lg};
`);

const ErrorMessage = styled.span(({ theme }) => css`
  color: ${theme.colors.variant.dark.danger};
  font-size: ${theme.fonts.size.small};
  font-style: italic;
  padding: ${theme.spacings.xs};
  height: 1.5em;
`);

const getArchiveStreamOptions = (streams: {
  [key: string]: string
}) => Object.keys(streams).map((streamId) => ({ label: streams[streamId], value: streamId }));

const InstantArchivingActionModalForm = ({
  modalTitle,
  archiveId,
  archiveStreams,
  show,
  onSubmit,
  onHide,
  children,
  submitButtonText,
  hourOnly,
  type,
}: Props) => {
  const [estimateParam, setEstimateParam] = useState<EstimateParams>({
    from: '',
    to: '',
    archiveId: archiveId || undefined,
  });
  const {
    data: estimate,
    refetch: refetchEstimate,
  } = useInstantArchivingEstimate(estimateParam);

  const { formatTime } = useUserDateTime();
  const formatError = 'Format must be: YYYY-MM-DD [HH:mm].';
  const rangeError = 'The "To" date must come after the "From" date.';

  useEffect(() => {
    refetchEstimate();
  }, [refetchEstimate, estimateParam]);

  const validate = (values) => {
    const { nextTimeRange: { to, from }, full_delete } = values;
    setEstimateParam({ archiveId, from, to });
    let errors: {
      from?: string,
      to?: string,
    } = {};

    if (!full_delete && !from) {
      errors = { ...errors, from: 'The "From" field is required.' };
    }

    if (!full_delete && !to) {
      errors = { ...errors, to: 'The "To" field is required.' };
    }

    if (!full_delete && from && !isValidDate(from)) {
      errors = { ...errors, from: formatError };
    }

    if (!full_delete && to && !isValidDate(to)) {
      errors = { ...errors, to: formatError };
    }

    if (!full_delete && from >= to) {
      errors = { ...errors, to: rangeError };
    }

    return errors;
  };

  const handleSubmit = ({ nextTimeRange: { from, to }, streamFilter, full_delete, wipe_restores }) => {
    onSubmit({ from, to, streamFilter, full_delete, wipe_restores });
  };

  return (
    <Modal title={modalTitle}
           onHide={() => onHide()}
           show={show}>

      <Formik initialValues={{
        streamFilter: '',
        nextTimeRange: {
          type: 'absolute',
          from: formatTime(new Date().setMinutes(0, 0)),
          to: formatTime(new Date().setMinutes(0, 0)),
        },
      }}
              onSubmit={handleSubmit}
              validate={validate}>
        {({ isSubmitting, isValidating, isValid, values: { full_delete, nextTimeRange }, errors }) => (
          <Form>
            <Modal.Header closeButton>
              <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {children}

              {type === 'delete' && (
              <FullDelete>
                <Input id="full_delete"
                       label="Full Delete:">
                  <FormikInput label="Delete the complete archive"
                               name="full_delete"
                               id="full_delete"
                               type="checkbox" />
                </Input>
                {full_delete && (
                <Input id="wipe_restores"
                       label="Wipe Restores:">
                  <FormikInput label="Wipe all restores"
                               name="wipe_restores"
                               id="wipe_restores"
                               disabled={!full_delete}
                               type="checkbox" />
                </Input>
                )}
              </FullDelete>
              )}

              {!full_delete && (
                <>
                  <StyledP>Time Range: </StyledP>
                  <p>Please select a time range and click {type}.</p>
                  <TimeRangeDisplay timerange={nextTimeRange} />
                  <TimeRangeSelectInput timerange={nextTimeRange}
                                        disableTimeMinute={hourOnly}
                                        disableTimeSecond={hourOnly} />
                </>

              )}

              {archiveId && archiveStreams && type === 'restore' && (
                <Field name="streamFilter"
                       validate={() => {
                       }}>
                  {({ field: { name, value, onChange }, meta: { error, touched } }) => (
                    <Input bsStyle={(touched && error) ? 'error' : undefined}
                           id="streamFilter"
                           label="Streams"
                           error={(touched && error) ? error : undefined}
                           help="Streams to be restored">
                      <Select clearable={false}
                              multi
                              matchProp="value"
                              onChange={(selectedStreams) => {
                                onChange({ target: { value: selectedStreams, name } });
                              }}
                              options={getArchiveStreamOptions(archiveStreams)}
                              placeholder="All streams within the archived"
                              value={value} />
                    </Input>
                  )}
                </Field>
              )}
              {errors && (
                Object.keys(errors).map((value) => (
                  <ErrorMessage>{errors[value] as ReactNode} </ErrorMessage>
                ))
              )}
              {(!full_delete && !errors.to && !errors.from) && estimate && <ArchiveSizeEstimate estimate={estimate} />}
            </Modal.Body>
            <Modal.Footer>
              <ModalSubmit submitButtonText={submitButtonText}
                           submitLoadingText="Submitting request..."
                           onCancel={() => onHide()}
                           disabledSubmit={isValidating || !isValid}
                           isSubmitting={isSubmitting} />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

InstantArchivingActionModalForm.defaultProps = {
  archiveId: undefined,
  archiveStreams: undefined,
  hourOnly: false,
};

export default InstantArchivingActionModalForm;
