import TrafficLimitViolationConfig from './TrafficLimitViolationConfig';

export default {
  systemConfigurations: [
    {
      component: TrafficLimitViolationConfig,
      configType: 'org.graylog.plugins.license.violations.TrafficLimitViolationSettings',
      permissions: 'clusterconfigentry:read',
    },
  ],
};
