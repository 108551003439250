import __request__ from 'routing/request';
interface HelloWorldResponse {
    readonly cluster_id: string;
    readonly tagline: string;
    readonly version: string;
    readonly node_id: string;
}
/**
 * A few details about the Graylog node.
 */
export function helloWorld(): Promise<HelloWorldResponse> {
    return __request__('GET', '/', null, {}, {
        'Accept': ['application/json']
    });
}
