import WatchlistAugmentation from './components/WatchlistAugmentation';

export default {
  messageAugmentations: [
    {
      id: 'watchlist',
      component: WatchlistAugmentation,
    },
  ],
};
