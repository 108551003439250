import * as React from 'react';
import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import styled, { css } from 'styled-components';

import UserNotification from 'util/UserNotification';
import { ButtonToolbar } from 'components/bootstrap';
import type { InstantArchive } from 'instant-archiving/Types';
import DeleteArchiveAction from 'instant-archiving/InstantArchivingOverview/BulkActions/DeleteArchiveAction';
import RestoreArchiveAction from 'instant-archiving/InstantArchivingOverview/BulkActions/RestoreArchiveAction';

type Props = {
  archive: InstantArchive,
};

export const handleArchiveActionFailures = (failures: Array<{
  entity_id: string
}>, actionPastTense: string, archiveId: string) => {
  if (failures?.length) {
    UserNotification.error(`The ${archiveId} archive could not be ${actionPastTense}.`);
  } else {
    UserNotification.success(`The ${archiveId} archive was ${actionPastTense} successfully.`, 'Success');
  }
};

const StyledButtonToolbar = styled(ButtonToolbar)(({ theme }) => css`
  &.instant-archiving-action-toolbar {


    li {
      list-style: none;
    }
  }

  &.instant-archiving-action-toolbar a {
    padding: 3px 10px;
    display: flex;
    font-weight: normal;
    font-size: ${theme.fonts.size.small};
    color: ${theme.colors.global.textDefault};
    text-decoration: none;
    background-color: ${theme.colors.gray[90]};
    margin-left: 5px;

    &:hover,
    &:focus {
      color: ${theme.colors.global.textDefault};
      background-color: ${theme.colors.variant.light.default};
    }
  }
`);

function InstantArchivingActions({ archive }: Props) {
  const queryClient = useQueryClient();
  const refetchArchives = useCallback(() => queryClient.invalidateQueries(['instant-archiving', 'overview']), [queryClient]);

  return (
    <StyledButtonToolbar className="instant-archiving-action-toolbar">
      <RestoreArchiveAction handleFailures={handleArchiveActionFailures}
                            selectedArchiveIds={[archive.id]}
                            archiveStreams={archive.stream_map}
                            refetchArchives={refetchArchives}
                            descriptor="archive" />
      <DeleteArchiveAction handleFailures={handleArchiveActionFailures}
                           selectedArchiveIds={[archive.id]}
                           refetchArchives={refetchArchives}
                           descriptor="archive" />
    </StyledButtonToolbar>
  );
}

export default InstantArchivingActions;
