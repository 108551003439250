import __request__ from 'routing/request';
interface ExposedConfiguration {
    readonly stream_processing_max_faults: number;
    readonly stream_processing_timeout: number;
    readonly allow_highlighting: boolean;
    readonly inputbuffer_wait_strategy: string;
    readonly ring_size: number;
    readonly processor_wait_strategy: string;
    readonly output_batch_size: number;
    readonly stale_master_timeout: number;
    readonly plugin_dir: string;
    readonly processbuffer_processors: number;
    readonly inputbuffer_ring_size: number;
    readonly output_module_timeout: number;
    readonly outputbuffer_processors: number;
    readonly node_id_file: string;
    readonly stale_leader_timeout: number;
    readonly minimum_auto_refresh_interval: string;
    readonly inputbuffer_processors: number;
    readonly allow_leading_wildcard_searches: boolean;
    readonly bin_dir: string;
    readonly data_dir: string;
}
/**
 * Get relevant configuration settings and their values
 */
export function getRelevant(): Promise<ExposedConfiguration> {
    return __request__('GET', '/system/configuration', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get relevant configuration setting value
 */
export function getRelevantByName(name: string): Promise<unknown> {
    return __request__('GET', `/system/configuration/${name}`, null, {}, {
        'Accept': ['application/json']
    });
}
