import React from 'react';
import styled from 'styled-components';

import ForwarderPageNavigation from 'forwarder/ForwarderPageNavigation';
import { DocumentTitle, PageHeader } from 'components/common';
import { Col, Row } from 'components/bootstrap';
import Routes from 'routing/Routes';
import ForwarderDocs from 'forwarder/util/ForwarderDocs';
import useHistory from 'routing/useHistory';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'logic/telemetry/Constants';

import { InputProfilesActions } from './stores/InputProfilesStore';
import withIsPermitted from './util/withIsPermitted';
import type { InputProfile } from './Types';
import InputProfileForm from './input-profiles/InputProfileForm';

const REQUIRED_PERMISSIONS = ['inputprofiles:create'];

const StyledH2 = styled.h2`
  margin-bottom: 20px;
`;

const NewInputProfilePage = () => {
  const history = useHistory();
  const sendTelemetry = useSendTelemetry();

  const _handleInputProfileCreation = (inputProfile: InputProfile) => {
    history.push(Routes.pluginRoute('SYSTEM_INPUT_PROFILES_INPUTPROFILEID')(inputProfile.id));
  };

  const _handleSubmit = (nextInputProfile) => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.FORWARDER.FORWARDER_INPUT_PROFILE_CREATED, {
      app_pathname: 'forwarder',
      app_section: 'input-profile',
    });

    InputProfilesActions.create(nextInputProfile)
      .then((result) => {
        _handleInputProfileCreation(result);

        return result;
      });
  };

  const _handleCancel = () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.FORWARDER.FORWARDER_INPUT_PROFILE_CREATE_CANCELED, {
      app_pathname: 'forwarder',
      app_section: 'input-profile',
    });

    history.push(Routes.pluginRoute('SYSTEM_INPUT_PROFILES'));
  };

  return (
    <DocumentTitle title="New Input Profile">
      <ForwarderPageNavigation />
      <PageHeader title="New Input Profile"
                  documentationLink={{
                    title: 'Input profile documentation',
                    path: ForwarderDocs.INPUT_PROFILE,
                  }}>
        <span>Use Input Profiles to create a set of Inputs that can be shared across many Forwarders.</span>
      </PageHeader>
      <Row className="content">
        <Col md={7}>
          <StyledH2>Input Profile Details</StyledH2>
          <InputProfileForm onCancel={_handleCancel} onSubmit={_handleSubmit} buttonText="Create" />
        </Col>
      </Row>
    </DocumentTitle>
  );
};

NewInputProfilePage.propTypes = {};

export default withIsPermitted(NewInputProfilePage, REQUIRED_PERMISSIONS);
