import styled from 'styled-components';

export const StyledTitle = styled.h3`
  margin-bottom: 10px;

  & > small {
    font-size: 80%;
  }
`;

export const StyledSection = styled.div`
  margin-bottom: 20px;
`;

export const StyledToolbar = styled.div`
  display: inline-block;

  & > * {
    margin-left: 5px;
  }
`;

export const StyledSearchFormWrapper = styled.div`
  margin-bottom: 15px;
`;
