import filterMenuItems, { filterCloudMenuItems } from 'util/conditional/filterMenuItems';
import { IlluminateDropdownBadge, IlluminateLinkBadge } from 'common/components';
import { INSTANT_ARCHIVING_ENABLED } from 'instant-archiving/Constants';

export default {
  navigation: [
    {
      description: 'Enterprise',
      children: filterCloudMenuItems(
        filterMenuItems(
          [
            { path: '/system/enterprise', description: 'Overview', permissions: 'licenseinfos:read' },
            { path: '/system/archives', description: 'Archives', permissions: 'archive:read' },
            { path: '/system/instant-archiving', description: 'Instant Archiving', permissions: 'archive:read' },
            { path: '/system/auditlog', description: 'Audit Log', permissions: 'auditlog_status:read' },
            { path: '/system/licenses', description: 'Licenses', permissions: 'licenses:read' },
            { path: '/reports', description: 'Reports', permissions: 'report:update' },
            { path: '/customization', description: 'Customization', permissions: 'clusterconfig:read' },
            { path: '/system/forwarders', description: 'Forwarders', permissions: 'forwarders:read' },
            { path: '/illuminate', description: 'Illuminate', permissions: '*', BadgeComponent: IlluminateLinkBadge },
            { path: '/my-filters', description: 'My Search Filters', requiredFeatureFlag: 'search_filter' },
          ],
          INSTANT_ARCHIVING_ENABLED ? [] : ['/system/instant-archiving'],
        ),
        ['/system/licenses', '/system/forwarders'],
      ),
      BadgeComponent: IlluminateDropdownBadge,
    },
    {
      description: 'Security',
      children: [
        { path: '/security', description: 'Overview', permissions: 'graylog_security:read', end: true },
        { path: '/security/user-activity', description: 'User Activity', permissions: 'graylog_security:read' },
        { path: '/security/host-activity', description: 'Host Activity', permissions: 'graylog_security:read' },
        { path: '/security/network-activity', description: 'Network Activity', permissions: 'graylog_security:read' },
        { path: '/security/investigations', description: 'Investigations', permissions: 'investigations:read' },
        { path: '/security/anomalies', description: 'Anomalies', permissions: 'anomaly_configuration:read' },
        { path: '/security/sigma', description: 'Sigma Rules', permissions: 'sigma_rules:read' },
        { path: '/security/assets', description: 'Assets', permissions: 'asset:read' },
      ],
    },
  ],
};
