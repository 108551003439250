import * as React from 'react';
import styled, { css } from 'styled-components';
import cloneDeep from 'lodash/cloneDeep';

import { Input, ControlLabel, Label } from 'components/bootstrap';
import { Select } from 'components/common';
import BootstrapModalConfirm from 'components/bootstrap/BootstrapModalConfirm';
import { MDEditor } from 'security-app/components/common';
import {
  useNewInvestigation,
  useGetPriorities,
  useGetStatuses,
  useGetUsers,
  useGetTeams,
} from 'security-app/hooks/useInvestigationsAPI';
import type {
  NewInvestigationAPIType,
  PriorityAPIType,
  StatusAPIType,
  UserAPIType,
  TeamAPIType,
} from 'security-app/hooks/api/investigationsAPI.types';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'logic/telemetry/Constants';

import type { ModalHandler } from './ModalHandler.type';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Row = styled.div<{ $gap?: string }>(({ $gap }) => css`
  display: flex;
  flex-direction: row;
  gap: ${$gap || '1rem'};

  & > * {
    width: 100%;
  }
`);

const FormControl = styled.div<{ $invalid?: boolean }>(({ theme, $invalid }) => {
  if ($invalid) {
    return css`
      & div[class$="control"] {
        border-color: ${theme.colors.variant.danger};
      }

      & label {
        color: ${theme.colors.variant.danger};
      }
    `;
  }

  return '';
});

const DefaultLabel = styled(Label)`
  display: inline-flex;
  margin-left: 5px;
  vertical-align: inherit;
`;

type FormFieldType = {
  name: string,
  value: string | number,
  valid: boolean,
  touched: boolean,
  required: boolean,
  errorMessage?: string,
};

type FormType = {
  [key: string]: FormFieldType,
};

const INIT_INVESTIGATION = {
  name: '',
  assigned_to: '',
  priority: null,
  status: '',
  notes: '',
};

const REQUIRED_FIELDS = ['name'];

const initForm = (priorityOptions = [], statusOptions = []) => Object.entries(INIT_INVESTIGATION).reduce((acc: FormType, [field, value]: string[]) => {
  if (field === 'priority' && priorityOptions.length > 0) {
    const defaultPriority = priorityOptions.find((priority) => priority.default === true);

    acc[field] = {
      name: field,
      value: defaultPriority ? defaultPriority.value : value,
      valid: true,
      touched: false,
      required: false,
    };
  } else if (field === 'status' && statusOptions.length > 0) {
    const defaultStatus = statusOptions.find((status) => status.default === true);

    acc[field] = {
      name: field,
      value: defaultStatus ? defaultStatus.value : value,
      valid: true,
      touched: false,
      required: false,
    };
  } else {
    acc[field] = {
      name: field,
      value: value,
      valid: true,
      touched: false,
      required: false,
    };
  }

  if (REQUIRED_FIELDS.includes(field)) {
    acc[field].valid = false;
    acc[field].required = true;
  }

  return acc;
}, {});

type Props = {
  onClose?: () => void,
};

const NewInvestigation = React.forwardRef<ModalHandler, Props>(({ onClose }: Props, ref: React.MutableRefObject<ModalHandler>) => {
  const [show, setShow] = React.useState<boolean>(false);
  const [form, setForm] = React.useState<FormType>(initForm());
  const [hideNotes, setHideNotes] = React.useState<boolean>(false);

  const sendTelemetry = useSendTelemetry();

  const { priorities, loadingPriorities } = useGetPriorities(show);
  const priorityOptions = React.useMemo(() => (
    priorities.map((priority: PriorityAPIType) => ({
      value: priority.priority.toString(),
      label: priority.text,
      default: priority.default,
    }))
      .sort((a: { value: string; }, b: { value: string; }) => +a.value - +b.value)
  ), [priorities]);

  const { statuses, loadingStatuses } = useGetStatuses(show);
  const statusOptions = React.useMemo(() => (
    statuses.map((status: StatusAPIType) => ({ value: status.status, label: status.status, default: status.default }))
  ), [statuses]);

  const { users } = useGetUsers();
  const { teams } = useGetTeams();
  const assignToOptions = React.useMemo(() => ([
    { value: 'Users', label: 'Users', disabled: true },
    ...users.map((user: UserAPIType) => ({ value: user.id, label: user.full_name }))
      .sort((a: { label: string }, b: { label: string }) => {
        if (a.label > b.label) return 1;
        if (a.label < b.label) return -1;

        return 0;
      }),

    { value: 'Teams', label: 'Teams', disabled: true },
    ...teams.map((team: TeamAPIType) => ({ value: team.id, label: team.name }))
      .sort((a: { label: string }, b: { label: string }) => {
        if (a.label > b.label) return 1;
        if (a.label < b.label) return -1;

        return 0;
      }),
  ]), [users, teams]);

  const { createInvestigation, creatingInvestigation } = useNewInvestigation();

  React.useEffect(() => {
    if (show) {
      if (!loadingPriorities && !loadingStatuses) {
        setForm(initForm(priorityOptions, statusOptions));
      }
    }
  }, [show, priorityOptions, loadingPriorities, statusOptions, loadingStatuses]);

  React.useImperativeHandle(ref, () => ({
    toggle: () => setShow(!show),
  }));

  const handleClose = () => {
    setForm(initForm());
    setShow(false);
    onClose();
  };

  const isFormValid = React.useMemo(() => Object.values(form).every((field: FormFieldType) => field.valid)
    && Object.values(form).some((field: FormFieldType) => field.touched)
    && Object.values(form).some((field: FormFieldType) => field.value), [form]);

  const handleSubmit = async () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.SECURITY_APP.INVESTIGATION_UPDATED, {
      app_pathname: 'security',
      app_section: 'investigation',
    });

    const payload = Object.values(form).reduce((acc: NewInvestigationAPIType, field: FormFieldType) => {
      acc[field.name] = field.value ? field.value : null;

      return acc;
    }, {} as NewInvestigationAPIType);

    await createInvestigation({ payload });

    setForm(initForm());
    setShow(false);
    onClose();
  };

  const validateField = (value: string) => !!value && value.replace(/\s/g, '').length > 0;

  const updateForm = (e: Partial<React.BaseSyntheticEvent>) => {
    const auxForm = cloneDeep(form);
    const { name: field, value } = e.target;

    auxForm[field].touched = true;
    auxForm[field].value = value;

    if (auxForm[field].required) auxForm[field].valid = validateField(value);

    setForm(auxForm);
  };

  const onBlur = (e: Partial<React.BaseSyntheticEvent>) => {
    const auxForm = cloneDeep(form);
    const { name: field } = e.target;

    auxForm[field].touched = true;
    if (auxForm[field].required) auxForm[field].valid = validateField(auxForm[field].value);

    setForm(auxForm);
  };

  const _renderOption = (option) => (
    <span key={option.value} title={option.value}>
      {option.label}{option.default && <DefaultLabel bsStyle="primary" bsSize="xsmall">Default</DefaultLabel>}
    </span>
  );

  const hideModal = (fullView: boolean) => {
    const modal: HTMLElement = document.querySelector('div[role="alertdialog"]');

    if (fullView) {
      modal.style.display = 'none';
    } else {
      modal.style.display = 'block';
      // Refresh notes input when back from full view
      setHideNotes(true);
      setTimeout(() => setHideNotes(false), 10);
    }
  };

  return show && (
    <BootstrapModalConfirm title="Start New Investigation"
                           onCancel={handleClose}
                           onConfirm={handleSubmit}
                           cancelButtonDisabled={creatingInvestigation}
                           confirmButtonDisabled={!isFormValid || creatingInvestigation}
                           showModal>
      <Form>
        <Input id={form.name.name}
               name={form.name.name}
               type="text"
               label="Name *"
               data-testid={form.name.name}
               required={form.name.required}
               value={form.name.value}
               error={(!form.name.valid && form.name.touched) ? 'Required' : ''}
               bsStyle={(!form.name.valid && form.name.touched) ? 'error' : null}
               onChange={updateForm}
               onBlur={onBlur} />
        <div>
          <ControlLabel>Assign To</ControlLabel>
          <Select id={form.assigned_to.name}
                  inputProps={{ name: form.assigned_to.name, 'data-testid': form.assigned_to.name }}
                  placeholder="Select user or team ..."
                  matchProp="label"
                  onChange={(value: string) => updateForm({ target: { name: form.assigned_to.name, value } })}
                  value={form.assigned_to.value}
                  options={assignToOptions}
                  onBlur={onBlur} />
        </div>
        <Row>
          <FormControl $invalid={(!form.priority.valid && form.priority.touched)}>
            <ControlLabel>Priority</ControlLabel>
            <Select id={form.priority.name}
                    inputProps={{ name: form.priority.name, 'data-testid': form.priority.name }}
                    placeholder="Select priority ..."
                    matchProp="label"
                    onChange={(value: number) => updateForm({ target: { name: form.priority.name, value: +value } })}
                    value={form.priority.value ? form.priority.value.toString() : null}
                    options={priorityOptions}
                    onBlur={onBlur}
                    optionRenderer={(option) => _renderOption(option)} />
          </FormControl>
          <FormControl $invalid={(!form.status.valid && form.status.touched)}>
            <ControlLabel>Status</ControlLabel>
            <Select id={form.status.name}
                    inputProps={{ name: form.status.name, 'data-testid': form.status.name }}
                    placeholder="Select status ..."
                    matchProp="value"
                    onChange={(value: string) => updateForm({ target: { name: form.status.name, value } })}
                    value={form.status.value}
                    options={statusOptions}
                    onBlur={onBlur}
                    optionRenderer={(option) => _renderOption(option)} />
          </FormControl>
        </Row>
        {!hideNotes && (
          <FormControl data-testid={form.notes.name}>
            <ControlLabel>Notes</ControlLabel>
            <MDEditor value={form.notes.value as string}
                      height={150}
                      data-testid={form.notes.name}
                      onFullMode={hideModal}
                      onChange={(newValue: string) => updateForm({ target: { name: form.notes.name, value: newValue } })} />
          </FormControl>
        )}
      </Form>
    </BootstrapModalConfirm>
  );
});

NewInvestigation.defaultProps = {
  onClose: () => {
  },
};

export default NewInvestigation;
