import { Builder as FetchBuilder } from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import type { BackendReport } from 'report/types';

const fetchReports = (): Promise<{ [reportId: string]: BackendReport }> => {
  const promise = new FetchBuilder('GET', qualifyUrl('/plugins/org.graylog.plugins.report/reports'))
    .json()
    .ignoreUnauthorized()
    .build();

  return promise.then((response) => Object.fromEntries(response?.reports.map((report) => [report.id, report])))
    .catch(() => ({}));
};

export default fetchReports;
