import * as React from 'react';
import { useEffect } from 'react';

import { useStore } from 'stores/connect';
import { Col, Row } from 'components/bootstrap';
import { DocumentTitle, PageHeader } from 'components/common';
import type { LicenseStatus } from 'archive/types';
import useUserDateTime from 'hooks/useUserDateTime';
import ReportLicenseStatus from 'report/common/validations/ReportLicenseStatus';
import DocsHelper from 'util/DocsHelper';

import ReportContents from './ReportContents';

import ReportLicenseActions from '../ReportLicenseActions';
import ReportLicenseStore from '../ReportLicenseStore';
import { isValidLicense } from '../logic/license';
import ReportsSubNav from '../common/ReportsSubNav';

const ReportContentsPage = () => {
  const { userTimezone } = useUserDateTime();
  const licenseStatus: LicenseStatus = useStore(ReportLicenseStore);
  const initialReport = {
    title: '',
    subtitle: '',
    description: '',
    widgets: [],
    positions: [],
    delivery: undefined,
    timezone: userTimezone,
    parameterValues: undefined,
    layout: undefined,
    hideWidgetQuery: false,
    hideWidgetDescription: false,
  };

  useEffect(() => {
    ReportLicenseActions.getLicenseStatus();
  }, []);

  return (
    <DocumentTitle title="New report">
      <PageHeader title="New report"
                  actions={<ReportsSubNav licenseStatus={licenseStatus} />}
                  documentationLink={{
                    title: 'Reporting documentation',
                    path: DocsHelper.PAGES.REPORTING,
                  }}>
        <span>
          Decide which content should be part of the report while you see its preview. You can schedule and
          configure the report in the next screen.
        </span>
      </PageHeader>

      {licenseStatus && <ReportLicenseStatus licenseStatus={licenseStatus} message="Creating new report definitions is disabled because there is no valid license." />}

      {isValidLicense(licenseStatus) && (
      <Row className="content">
        <Col md={12}>
          <ReportContents key="new" action="create" report={initialReport} />
        </Col>
      </Row>
      )}
    </DocumentTitle>
  );
};

export default ReportContentsPage;
