import * as React from 'react';
import styled from 'styled-components';

import { SortIcon } from 'components/common';

const SortContainer = styled.span`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justtify-content: flex-start;
  gap: .3rem;
`;

type Props = {
  field: string,
  orderBy: string,
  direction: string,
  onSort: (field: string, direction: string) => void,
  children: React.ReactNode,
};

function ColumnSort({ field, orderBy, direction, onSort, children }: Props) {
  const onChange = () => {
    if (direction === 'asc') onSort(field, 'desc');
    else onSort(field, 'asc');
  };

  return (
    <SortContainer>
      {children}
      <SortIcon activeDirection={field === orderBy ? direction : null}
                title={`sort-${field}`}
                ascId="asc"
                descId="desc"
                onChange={onChange} />
    </SortContainer>
  );
}

export default ColumnSort;
