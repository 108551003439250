import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { defaultCompare as naturalSort } from 'logic/DefaultCompare';
import { getValueFromInput } from 'util/FormsUtils';
import StreamsStore from 'stores/streams/StreamsStore';
import { Button, Checkbox, Col, FormControl, ListGroup, ListGroupItem, Row } from 'components/bootstrap';
import { Spinner } from 'components/common';

import type { Stream } from '../types';

const StyledSpinner = styled(Spinner)`
  margin-top: 7px;
`;
const StyledListGroupItem = styled(ListGroupItem)(({ theme }) => css`
  background-color: ${theme.colors.variant.lightest.default};
  color: ${theme.utils.contrastingColor(theme.colors.variant.lightest.default)};
  padding-bottom: 7px;
  padding-top: 5px;
  margin: 0;
`);
const StyledCol = styled(Col)`
  padding-left: 0; 
`;
const ScrollableListWrapper = styled.div`
  position: relative;

  .scrollable-list {
    margin-top: 5px;
    max-height: 425px;
    overflow-y: auto;
  }

  .scrollable-list-header {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    display: block;
    z-index: 10;
  }
  .scrollable-list-item:nth-child(2) {
    margin-top: 40px;
    border-top: none;
  } 
`;

type Props = {
  excludedStreams: Array<string>,
  updateExcludedStreams: (streamsList: Array<string>)=>void,
};

const ArchiveConfigStreamSelectionForm = ({ excludedStreams, updateExcludedStreams }: Props) => {
  const [streams, setStreams] = useState<Array<Stream>>([]);
  const [filter, setFilter] = useState(undefined);

  useEffect(() => {
    StreamsStore.listStreams().then((streamsList: Array<Stream>) => {
      const sortedStreams = streamsList.sort((a, b) => naturalSort(a.title, b.title));

      setStreams(sortedStreams);
    });
  }, []);

  const onUpdateCheckbox = (streamId: string) => (e) => {
    // Clone the excludedStreams array.
    let newExcludes = excludedStreams.slice(0);

    if (e.target.checked) {
      if (newExcludes.indexOf(streamId) !== -1) {
        newExcludes = newExcludes.filter((id) => id !== streamId);
      }
    } else if (newExcludes.indexOf(streamId) === -1) {
      newExcludes.push(streamId);
    }

    updateExcludedStreams(newExcludes);
  };

  const onSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newExcludes = [];

    if (!event.target.checked) {
      newExcludes = streams.map((stream) => stream.id);
    }

    updateExcludedStreams(newExcludes);
  };

  const onFilterChange = (event) => {
    setFilter(getValueFromInput(event.target));
  };

  const onFilterReset = () => {
    setFilter(undefined);
  };

  const isStreamIncluded = (stream: Stream) => excludedStreams && excludedStreams.indexOf(stream.id) === -1;

  const filterListItem = () => (
    <Row>
      <Col xs={9} md={6}>
        <FormControl type="text" onChange={onFilterChange} placeholder="Filter streams" value={filter} />
      </Col>
      <StyledCol xs={3} md={2}>
        <Button onClick={onFilterReset}>Reset</Button>
      </StyledCol>
    </Row>
  );

  const headerItem = () => (
    <StyledListGroupItem containerProps={{ className: 'scrollable-list-header scrollable-list-item' }} key="stream-select-all">
      <Checkbox onChange={onSelectAll}>Select all available streams</Checkbox>
    </StyledListGroupItem>
  );

  const streamItems = () => {
    const items = streams.filter((stream) => {
      const text = `${stream.title} ${stream.description}`;

      return !(filter && text.toLowerCase().indexOf(filter.toLowerCase()) === -1);
    }).map((stream) => (
      <ListGroupItem key={`stream-${stream.id}`} containerProps={{ className: 'scrollable-list-item' }}>
        <Checkbox checked={isStreamIncluded(stream)} onChange={onUpdateCheckbox(stream.id)}>
          <span><strong>{stream.title}</strong> - {stream.description}</span>
        </Checkbox>
      </ListGroupItem>
    ));

    if (items.length > 0) {
      return items;
    }

    return (
      <ListGroupItem className="scrollable-list-item">No streams match the filter.</ListGroupItem>
    );
  };

  if (!streams) {
    return <StyledSpinner text="Loading streams..." />;
  }

  return (
    <div>
      {filterListItem()}
      <ScrollableListWrapper className="scrollable-list-container">
        <ListGroup className="scrollable-list">
          {headerItem()}
          {streamItems()}
        </ListGroup>
      </ScrollableListWrapper>
    </div>
  );
};

ArchiveConfigStreamSelectionForm.propTypes = {
  excludedStreams: PropTypes.arrayOf(PropTypes.string),
  updateExcludedStreams: PropTypes.func.isRequired,
};

ArchiveConfigStreamSelectionForm.defaultProps = {
  excludedStreams: [],
};

export default ArchiveConfigStreamSelectionForm;
